import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderRead } from 'store/order/order.selectors'

import { oneDayInMilliseconds } from 'constants/date'

import { EFFECTIVE_ORDER_STATUSES } from 'constants/order'

import { OrderFields, ResolutionId } from 'types/order'

import { CheckedValueStatus } from './checked-value-status'
import { StatusValue } from './StatusValue'
import classes from './style.module.css'

interface BacklitTextForCheckedValueProps {
  field: string
}

const BacklitTextForCheckedValue: React.FC<BacklitTextForCheckedValueProps> = ({ field }) => {
  const order = useSelector(selectOrderRead)
  const data = order[OrderFields.CHECKED_FIELDS].find((checkedField) => checkedField.field === field)

  const effectiveChecks = useMemo(
    () => data?.data?.filter((check) => EFFECTIVE_ORDER_STATUSES.includes(String(check.resolution_id) as ResolutionId)) || [],
    [data],
  )
  const lastCheck = useMemo(
    () => effectiveChecks.sort((first, second) => (new Date(first.date_create_cus) > new Date(second.date_create_cus) ? -1 : 1))[0],
    [effectiveChecks],
  )
  const numberOfDays = useMemo(
    () => (lastCheck ? Math.trunc((new Date().getTime() - new Date(lastCheck.date_create_cus).getTime()) / oneDayInMilliseconds) : null),
    [lastCheck],
  )

  const status = useMemo(() => CheckedValueStatus.get(data?.field || '', lastCheck, order), [data, lastCheck, order])

  if (!lastCheck) return null

  return (
    <p className="p-0 m-0">
      <a className={classes.prefix} href={`/order/${order[OrderFields.ORDER_NUMBER]}/order-history?field=${field}`}>
        Проверяли:
      </a>
      <StatusValue status={status} numberOfDays={numberOfDays} numberOfChecks={effectiveChecks.length} />
    </p>
  )
}

export default BacklitTextForCheckedValue
