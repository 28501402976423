import React from 'react'
import { useSelector } from 'react-redux'

import { selectSearchOrders } from 'store/orders/orders.selectors'

import SearchOrderBodyHeader from 'components/molecules/SearchOrderBodyHeader'
import OrderList from 'components/molecules/OrderList'

export const SearchOrderBody: React.FC = () => {
  const isSearchOrders = !!useSelector(selectSearchOrders)
  return (
    <div className="list-group w-100 position-relative" style={{ overflow: 'hidden' }}>
      <SearchOrderBodyHeader />
      {isSearchOrders ? <OrderList /> : null}
    </div>
  )
}

export default SearchOrderBody
