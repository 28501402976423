import React from 'react'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

const ViberNumberLink: React.FC<{ phone: string }> = ({ phone }) =>
  phone ? (
    <a target="_blank" rel="noopener noreferrer" href={`viber://chat?number=%2B${phone}`} className={classes.link}>
      <Icon type={IconType.Viber} width="32px" height="32px" />
    </a>
  ) : null

export default ViberNumberLink
