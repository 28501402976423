import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import { Button, Spinner } from 'react-bootstrap'

import { ordersListPath } from 'routes/url-constants'

import { OrderFieldNames } from 'constants/order'

import { OrderFields, ResolutionId } from 'types/order'
import { LoadingStatus } from 'types/loading'

import { useValidation } from 'utils/useValidation'

import { selectLoadingExecutorFormStatus, selectOrderRead } from 'store/order/order.selectors'
import { fetchSetExecutorRequest } from 'store/order/order.actions'
import { selectCurrentUser } from 'store/users/users.selectors'

import TextareaControl from 'components/molecules/TextareaControl'

import getRules from '../utils/getRules'
import { VerifyEmailForm } from './VerifyEmailForm'

const ForExpertForm: React.FC = () => {
  const dispatch = useDispatch()
  const fields = useSelector(selectOrderRead)
  const { orderNumber } = useParams()
  const loadingStatus = useSelector(selectLoadingExecutorFormStatus)
  const currentUser = useSelector(selectCurrentUser)

  const [form, setForm] = useState({
    [OrderFields.ORDER_COMMENT]: fields[OrderFields.ORDER_COMMENT],
  })

  const onChange = (value: string, id: string) => {
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }))
  }

  const rules = useMemo(() => getRules(fields), [fields])
  const { validate } = useValidation(rules)

  const validator = useMemo(() => validate({ ...fields, ...form }), [validate, fields, form])
  validator.passes()
  const errors = useMemo(() => Object.keys(validator.errors.all()), [validator])

  const onSubmit = useCallback(() => {
    if (!errors.length) {
      dispatch(
        fetchSetExecutorRequest({
          [OrderFields.ORDER_NUMBER]: orderNumber,
          fields: {
            [OrderFields.ORDER_COMMENT]: form[OrderFields.ORDER_COMMENT],
          },
        }),
      )
    }
  }, [dispatch, form, orderNumber, errors])

  const isMyOrder = useMemo(() => String(fields[OrderFields.EXECUTOR_L1]) === String(currentUser!.id), [currentUser, fields])

  if (loadingStatus === LoadingStatus.SUCCESS) return <Redirect to={ordersListPath} />

  return (
    <>
      <div style={{ marginBottom: '8px' }}>
        <TextareaControl
          value={form[OrderFields.ORDER_COMMENT]}
          id={OrderFields.ORDER_COMMENT}
          onChangeHandler={onChange}
          placeholder="Внутренний комментарий"
          invalidMessage={!isMyOrder ? 'Вы не являетесь исполнителем данной заявки' : ''}
          isValid={isMyOrder ? undefined : false}
          disabled={!isMyOrder}
          phrases={[
            {
              value: 'Данные уточнены',
              label: 'Данные уточнены',
            },
          ]}
        />
      </div>
      {fields[OrderFields.CARRIER_EMAIL] && isMyOrder ? <VerifyEmailForm onChange={onChange} form={form} /> : null}
      <Button
        onClick={onSubmit}
        className="btn btn-primary col-lg-3 col-md-6 col-sm-12"
        style={{ marginBottom: '10px' }}
        disabled={
          loadingStatus === LoadingStatus.PENDING ||
          !validator.passes() ||
          ![
            ResolutionId.NEW_ORDER,
            ResolutionId.L1_PROCESSING,
            ResolutionId.CLARIFY_DATA,
            ResolutionId.DRIVER_IS_NOT_RESPONDING,
            ResolutionId.COOPERATION_FORBIDDEN,
            ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
            ResolutionId.SHIPMENT_FORBIDDEN,
            ResolutionId.CHECK_CANCELED,
            ResolutionId.FOR_E,
            ResolutionId.E_PROCESSING,
          ].includes(
            // @ts-ignore
            String(fields[OrderFields.RESOLUTION]),
          ) ||
          !isMyOrder
        }
      >
        {loadingStatus === LoadingStatus.PENDING ? (
          <>
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
            &nbsp; Загрузка..
          </>
        ) : (
          <>Передать заявку эксперту</>
        )}
      </Button>
      {errors.length ? (
        <div className="p-0 m-0 small" style={{ color: '#dc3545' }}>
          Для передачи заявки эксперту введите значения:
          <br />
          <ul>
            {errors.map((error) => (
              <li key={error}>{OrderFieldNames[error]}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  )
}

export default ForExpertForm
