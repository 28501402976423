import { captureException } from '@sentry/react'

export interface addFileResponseProps {
  id?: number
  uuid?: string
}

export const addFile = (
  onSuccess: (response: addFileResponseProps, status: number) => void,
  onError: (response: addFileResponseProps, status: number) => void,
  onProgress: (value: number) => void,
  data: FormData,
  token: string | undefined = '',
) => {
  const xhr = new XMLHttpRequest()
  xhr.open('POST', `${process.env.REACT_APP_FILES_SERVER}/file`, true)

  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  xhr.withCredentials = true
  xhr.setRequestHeader('Authorization', `Bearer ${token}`)

  xhr.upload.addEventListener('progress', (e) => {
    onProgress((e.loaded * 100.0) / e.total || 100)
  })

  xhr.addEventListener('readystatechange', () => {
    if (xhr.readyState === 4 && xhr.status < 300 && xhr.status >= 200) {
      const response = JSON.parse(xhr.response)
      onSuccess(response, xhr.status)
    } else if (xhr.readyState === 4 && (xhr.status >= 300 || xhr.status === 0)) {
      onError(xhr.response, xhr.status)
    }
  })

  try {
    xhr.send(data)
  } catch (e) {
    onError({}, 500)
    captureException(e)
  }
}

export default addFile
