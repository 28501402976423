import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { OrderFields, OrderSpaceName } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import { getDateFromInputDate, getDateStringWithDeclension, getYearStringWithDeclension } from 'utils/date'

import { onClickAnchor } from 'components/molecules/OrderSpace/spaces/Header/components/utils/block'
import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'
import CertificateLink from 'components/atoms/CertificateLink'
import { Icon, IconType } from 'components/atoms/Icon'
import BoldText from 'components/atoms/BoldText'
import GrayText from 'components/atoms/GrayText'

const getDateIsRisk = (string: string | null) => {
  const date = getDateFromInputDate(string || '')

  const startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - 15)
  const endDate = new Date()
  endDate.setMonth(startDate.getMonth() - 5)

  return date && !(startDate <= date && date <= endDate)
}

const Transport: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const transportYearIsRisk = useMemo(() => new Date().getFullYear() - 15 >= (form[OrderFields.TRANSPORT_YEAR] || 0), [form])
  const trailerYearIsRisk = useMemo(() => new Date().getFullYear() - 15 >= (form[OrderFields.TRAILER_YEAR] || 0), [form])

  const transportSTSDateIsRisk = useMemo(() => getDateIsRisk(form[OrderFields.TRANSPORT_STS_DATE]), [form])
  const trailerSTSDateIsRisk = useMemo(() => getDateIsRisk(form[OrderFields.TRAILER_STS_DATE]), [form])

  const transportYear = useMemo(() => getYearStringWithDeclension(form[OrderFields.TRANSPORT_YEAR]), [form])
  const trailerYear = useMemo(() => getYearStringWithDeclension(form[OrderFields.TRAILER_YEAR]), [form])
  const transportSTSDate = useMemo(() => getDateStringWithDeclension(form[OrderFields.TRANSPORT_STS_DATE]), [form])
  const trailerSTSDate = useMemo(() => getDateStringWithDeclension(form[OrderFields.TRAILER_STS_DATE]), [form])

  return (
    <>
      <div className={classes.space}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span
            className={classNames(classes.icon, classes.anchor, classes.truck)}
            onClick={() => onClickAnchor(`__${OrderSpaceName.TS}`)}
            role="button"
          >
            <Icon type={IconType.Truck} width="40px" height="40px" />
          </span>
        </div>
        <div className={classes.spaceBody}>
          <div className="d-flex flex-column" style={{ gap: '2px' }}>
            <div style={{ marginBottom: '4px' }}>
              <GrayText text="Транспорт" />
            </div>
            <div>
              <BoldText text={form[OrderFields.TS_OWNER] || ''} />
              {form[OrderFields.TRANSPORT_RENT] ? ' (аренда)' : ''}
            </div>
            {form[OrderFields.TRANSPORT_YEAR] || form[OrderFields.TRANSPORT_STS_DATE] ? (
              <p className="p-0 m-0">
                <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
                  <small>Авто:</small>
                </span>
                &nbsp;
                <span className={transportYearIsRisk ? classes.redBackgroundColor : classes.greyText}>{transportYear || ''}</span>
                &nbsp;
                <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
                  <small>СТС:</small>
                </span>
                &nbsp;
                <span className={transportSTSDateIsRisk ? classes.redBackgroundColor : classes.greyText}>{transportSTSDate || ''}</span>
              </p>
            ) : null}
            <CertificateLink type="vehicle" identityData={{ Госномер: form[OrderFields.TS_OWNER_LICENSE_PLATE] }} />

            {form[OrderFields.TRAILER_STATUS] ? (
              <span className="text-secondary">Без прицепа</span>
            ) : (
              <>
                <BoldText text={form[OrderFields.TRAILER_OWNER] || ''} />
                {form[OrderFields.TRAILER_RENT] ? ' (аренда)' : ''}
                {form[OrderFields.TRAILER_YEAR] || form[OrderFields.TRAILER_STS_DATE] ? (
                  <p className="p-0 m-0">
                    <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
                      <small>Прицеп:</small>
                    </span>
                    &nbsp;
                    <span className={trailerYearIsRisk ? classes.redBackgroundColor : classes.greyText}>{trailerYear || ''}</span>
                    &nbsp;
                    <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
                      <small>СТС:</small>
                    </span>
                    &nbsp;
                    <span className={trailerSTSDateIsRisk ? classes.redBackgroundColor : classes.greyText}>{trailerSTSDate || ''}</span>
                  </p>
                ) : null}
                <CertificateLink type="vehicle" identityData={{ Госномер: form[OrderFields.TRAILER_OWNER_LICENSE_PLATE] }} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Transport
