import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { Alert as BAlert } from 'react-bootstrap'

import { Alert as AlertProps } from 'types/alert'

import { selectAlerts } from 'store/alerts/alerts.selectors'
import { removeAlert } from 'store/alerts/alerts.actions'

import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import classes from './style.module.css'
import './style.css'

export const Alert = () => {
  const dispatchStore = useDispatch()
  const alerts = useSelector(selectAlerts)

  const onCloseHandler = (alert: AlertProps) => dispatchStore(removeAlert(alert))

  return (
    <TransitionGroup component="div" className={classes.container}>
      {alerts.map((alert) => (
        <CSSTransition key={alert.id} classNames="alert" timeout={500} unmountOnExit mountOnEnter>
          <BAlert className={classes.alert} variant={alert.variant} onClose={() => onCloseHandler(alert)} dismissible>
            {alert.header ? (
              <>
                {alert.header}
                <hr className="m-1" />
              </>
            ) : null}
            {alert.withLineBreaks && typeof alert.text === 'string' ? <TextWithLineBreaks text={alert.text} /> : alert.text}
          </BAlert>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default Alert
