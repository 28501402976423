import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchCurrentUserSaga } from './sagas/fetch-current-user'
import { fetchLoginSaga } from './sagas/fetch-login'
import { fetchLogoutSaga } from './sagas/fetch-logout'
import { updateUserSettingsSaga } from './sagas/update-user-settings'

import * as actions from './users.actions'

export default function* () {
  yield all([
    takeLatest(actions.FETCH_CURRENT_USER_REQUEST, fetchCurrentUserSaga),
    takeLatest(actions.FETCH_LOGIN_REQUEST, fetchLoginSaga),
    takeLatest(actions.FETCH_LOGOUT_REQUEST, fetchLogoutSaga),
    takeEvery(actions.UPDATE_USER_SETTINGS_REQUEST, updateUserSettingsSaga),
  ])
}
