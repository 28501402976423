import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'
import { News } from 'types/news'

import * as actions from './news.actions'

export interface NewsState {
  loadingGetNews: LoadingStatus
  news: News
}

export const initialNewsState: NewsState = {
  loadingGetNews: LoadingStatus.NONE,
  news: {},
}

const getNewsPending = (state: NewsState): NewsState => ({
  ...state,
  loadingGetNews: LoadingStatus.PENDING,
})

const getNewsSuccess = (state: NewsState, action: Action<News>): NewsState => ({
  ...state,
  loadingGetNews: LoadingStatus.SUCCESS,
  news: action.payload,
})

const getNewsFailure = (state: NewsState): NewsState => ({
  ...state,
  loadingGetNews: LoadingStatus.FAILED,
})

export default handleActions<NewsState, any>(
  {
    [actions.GET_NEWS_PENDING]: getNewsPending,
    [actions.GET_NEWS_SUCCESS]: getNewsSuccess,
    [actions.GET_NEWS_FAILURE]: getNewsFailure,
  },
  initialNewsState,
)
