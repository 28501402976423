import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectOrderFilterByExpert } from 'store/orders/orders.selectors'
import { changeOrderFilterByExpert } from 'store/orders/orders.actions'

import { SelectItem } from 'types/selectLists'
import { UserRole } from 'types/user'
import { OrderFields } from 'types/order'

import SelectControl from 'components/molecules/SelectControl'
import { useOrderListFilterByExpertLocation } from 'components/molecules/OrderListFilterByExpert/order-list-filter-by-expert-location-hook'

export const OrderListFilterByExpert: React.FC = () => {
  const dispatch = useDispatch()
  const selectLists = useSelector(selectSelectLists)
  const storage = useOrderListFilterByExpertLocation()
  const filter = useSelector(selectOrderFilterByExpert)

  useEffect(() => {
    dispatch(changeOrderFilterByExpert(storage.get()))
  }, [dispatch, storage])

  const onChange = useCallback(
    (val: string) => {
      storage.set(val)
      dispatch(changeOrderFilterByExpert(val))
    },
    [dispatch, storage],
  )

  const options: SelectItem[] = useMemo(() => {
    return [...(selectLists?.[OrderFields.EXECUTOR] || []).filter((item) => item.role === UserRole.EXPERT)]
  }, [selectLists])

  return <SelectControl withEmptyValue value={filter} options={options} id="OrderListFilter" onChangeHandler={onChange} className="m-0" />
}

export default OrderListFilterByExpert
