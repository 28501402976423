import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import classNames from 'classnames'
import { Button, Spinner } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'

import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'
import { UserRole } from 'types/user'
import { AlertVariant } from 'types/alert'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectLoadingTakeOrderStatus } from 'store/orders/orders.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'
import { fetchTakeOrderRequest, setInitialStateOrders } from 'store/orders/orders.actions'
import { fetchGetOrderRequest } from 'store/order/order.actions'
import { addAlert } from 'store/alerts/alerts.actions'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import { getTextOfDataRequestTemplate } from 'utils/order'

import { DropdownMenu } from 'components/molecules/DropdownMenu'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import classes from './style.module.css'

import ForExpertForm from './components/ForExpertForm'

const Manager: React.FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)
  const { orderNumber } = useParams()

  const loadingTakeOrderStatus = useSelector(selectLoadingTakeOrderStatus)
  const takeOrder = useCallback(() => {
    dispatch(fetchTakeOrderRequest(orderNumber))
  }, [dispatch, orderNumber])

  const isFreeOrder = useMemo(() => {
    switch (currentUser!.role) {
      case UserRole.MANAGER:
        return !form[OrderFields.EXECUTOR_L1]

      default:
        return false
    }
  }, [form, currentUser])

  useEffect(() => {
    if (loadingTakeOrderStatus === LoadingStatus.SUCCESS) {
      dispatch(
        fetchGetOrderRequest({
          [OrderFields.ORDER_NUMBER]: orderNumber,
        }),
      )
      dispatch(setInitialStateOrders())
    }
  }, [dispatch, orderNumber, loadingTakeOrderStatus])

  const handleTemplateOrderCopy = useCallback(() => {
    dispatch(
      addAlert({
        header: 'Успешное копирование',
        text: `Шаблон для запроса данных успешно скопирован`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
      }),
    )
  }, [dispatch])

  return (
    <>
      <ul className="list-group">
        <li className="list-group-item row">
          <div className="p-0 m-0">
            <div className="text-right">
              <DropdownMenu
                toggleText="Действия"
                list={[
                  {
                    id: 1,
                    node: (
                      <CopyToClipboard
                        text={`Для проверки заявки необходимы следующие данные:${getTextOfDataRequestTemplate(form)}`}
                        onCopy={handleTemplateOrderCopy}
                      >
                        <span style={{ padding: '8px', display: 'block' }}>Скопировать шаблон для клиента</span>
                      </CopyToClipboard>
                    ),
                    disabled: !getTextOfDataRequestTemplate(form),
                  },
                  {
                    id: 2,
                    node: (
                      <CopyToClipboard text={`Запросили:${getTextOfDataRequestTemplate(form)}`} onCopy={handleTemplateOrderCopy}>
                        <span style={{ padding: '8px', display: 'block' }}>Скопировать шаблон для эксперта</span>
                      </CopyToClipboard>
                    ),
                    disabled: !getTextOfDataRequestTemplate(form),
                  },
                ]}
                toggleVariant="outline-dark"
              />
            </div>
            <p className="p-0 m-0">{form[OrderFields.ORDER_NUMBER]}</p>
            <span className="p-0 m-0">{getStringFromSelectList(form[OrderFields.ORDER_TYPE], selectLists?.orderType)}</span>
            &nbsp;
            <span className="p-0 m-0">{getStringFromSelectList(form[OrderFields.PRIORITY], selectLists?.priority)}</span>
            <br />
            <br />
            <p className="p-0 m-0">{form[OrderFields.LOGISTIC_NAME]}</p>
            <p className="p-0 m-0">
              <span>
                <span style={{ color: 'rgb(153, 153, 153)' }}>Водитель:&nbsp;</span>
                {form[OrderFields.DRIVER_FIO]}
              </span>
            </p>
            <span>{form[OrderFields.ROUTE_DATE_START]}</span>
            &nbsp;
            <span>{form[OrderFields.ROUTE_TRIP]}</span>
            &nbsp;
            <span>{form[OrderFields.CARGO]}</span>
            <br />
            <br />
            {form[OrderFields.EXECUTOR_L1] ? (
              <p className="p-0 m-0">
                <span>
                  L1:&nbsp;
                  {getStringFromSelectList(form[OrderFields.EXECUTOR_L1], selectLists?.executor)}
                </span>
              </p>
            ) : null}
            {form[OrderFields.EXECUTOR_EXPERT] ? (
              <p className="p-0 m-0">
                <span>
                  E:&nbsp;
                  {getStringFromSelectList(form[OrderFields.EXECUTOR_EXPERT], selectLists?.executor)}
                </span>
              </p>
            ) : null}
            <br />
            {isFreeOrder ? (
              <Button
                className={classNames('btn btn-primary col-lg-3 col-md-6 col-sm-12', classes.takeOrder)}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  takeOrder()
                }}
                disabled={loadingTakeOrderStatus === LoadingStatus.PENDING}
                style={{ marginBottom: '12px' }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  {loadingTakeOrderStatus === LoadingStatus.PENDING ? (
                    <>
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      &nbsp; Загрузка..
                    </>
                  ) : (
                    <>Взять заявку</>
                  )}
                </div>
              </Button>
            ) : null}
            <ForExpertForm />
            <br />
            <p className="p-0 m-0">{getStringFromSelectList(form[OrderFields.RESOLUTION], selectLists[OrderFields.RESOLUTION])}</p>
            <TextWithLineBreaks text={form[OrderFields.RESOLUTION_COMMENT]} />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
