import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ordersListPath } from 'routes/url-constants'
import { useMenu } from 'hooks/use-menu'
import { Icon, IconType } from 'components/atoms/Icon'

import Header from 'components/molecules/Header'
import Menu from 'components/molecules/Menu'
import classNames from 'classnames'
import { selectNotifications } from 'store/notifications/notifications.selectors'
import { NotificationsDropdown } from '../NotificationDropdown/NotificationDropdown'
import classes from './style.module.css'

export const OrderListHeader: React.FC = () => {
  const notifications = useSelector(selectNotifications)
  const { menuLinks, isMenuOpen, toggleMenu } = useMenu(ordersListPath)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const onKeyPressHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') toggleMenu()
    },
    [toggleMenu],
  )

  const leftButton = useMemo(
    () => (
      <div className={classes.leftButton} onClick={toggleMenu} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
        <Icon type={IconType.Menu} width="36px" height="36px" />
      </div>
    ),
    [onKeyPressHandler, toggleMenu],
  )

  const rightButton = useMemo(
    () => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={classes.rightButton} onClick={() => setDropdownOpen((prev) => !prev)} title="Уведомления">
        <div className={classNames(classes.rightButton, notifications?.length ? classes.active : '')}>
          <Icon type={IconType.Bell2} width="36px" height="36px" />
        </div>
      </div>
    ),
    [notifications],
  )

  return (
    <>
      <Header leftButton={leftButton} headerName="Список заявок" rightButton={rightButton} />
      <Menu show={isMenuOpen} toggle={toggleMenu} navLinks={menuLinks} />
      <NotificationsDropdown isOpen={isDropdownOpen} onClose={() => setDropdownOpen(false)} />
    </>
  )
}

export default OrderListHeader
