import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { fetchSelectLists } from 'requests/selectLists'

import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchSelectListsSuccess, fetchSelectListsFailure } from '../selectLists.actions'

export function* fetchSelectListsSaga() {
  try {
    const token = yield select(selectToken)
    const payload = yield call(fetchSelectLists, token)

    yield put(fetchSelectListsSuccess(payload))
  } catch (error) {
    yield put(fetchSelectListsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списков значений',
        defaultText: 'Не удалось получить списки значений.',
      }),
    )
    console.error(`[fetchSelectListsSaga]: ${error}`)
    captureException(error)
  }
}
