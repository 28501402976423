import React, { useCallback } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'

import { AlertVariant } from 'types/alert'
import { addAlert } from 'store/alerts/alerts.actions'

import classes from './style.module.css'

interface TextWithPrefixAndCopyProps {
  text: string | React.ReactNode
  prefix: string
  copiedText: string
  copiedTextPrefix?: string
  withLineFeed?: boolean
}

const TextWithPrefixAndCopy: React.FC<TextWithPrefixAndCopyProps> = ({ text, prefix, copiedText, withLineFeed, copiedTextPrefix = '? ' }) => {
  const dispatchStore = useDispatch()

  const handleOrderLinkCopy = useCallback(
    (value: string) => {
      if (copiedText) {
        dispatchStore(
          addAlert({
            text: `Значение "${value}" успешно скопировано`,
            lifetime: 3000,
            variant: AlertVariant.SUCCESS,
          }),
        )
      }
    },
    [dispatchStore, copiedText],
  )

  return (
    <div className="p-0 m-0">
      <CopyToClipboard text={`${copiedTextPrefix}${copiedText}`} onCopy={handleOrderLinkCopy}>
        <span className={classes.prefix}>{prefix}</span>
      </CopyToClipboard>
      {withLineFeed ? <br /> : <>&nbsp;</>}
      {text}
    </div>
  )
}

export default TextWithPrefixAndCopy
