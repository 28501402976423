import { put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { getDateForServer, getTomorrow } from 'utils/date'

import { OrderFormErrorProps } from 'types/order'

import { selectCurrentUser } from 'store/users/users.selectors'

import getOrderFormRules from 'components/organisms/OrderForm/utils/getOrderFormRules'
import formValidator from 'components/organisms/OrderForm/utils/formValidator'

import { errorsOrderHandler } from '../order.actions'

const Validator = require('validatorjs')

export function* validationHandler(action: Action<{ [key: string]: string | boolean | number }>) {
  try {
    const currentUser = yield select(selectCurrentUser)
    const rules: { [key: string]: string | Array<string> } = getOrderFormRules(currentUser)
    const errors: { [key: string]: OrderFormErrorProps } = {}
    const keys = Object.keys(action.payload)

    const tomorrow = getTomorrow()

    for (let i = 0; i < keys.length; i += 1) {
      if (!rules[keys[i]]) {
        errors[keys[i]] = {
          isValid: undefined,
          invalidMessage: '',
        }
      } else {
        const validateProperty = new Validator(
          { [keys[i]]: action.payload[keys[i]], tomorrow: getDateForServer(tomorrow) },
          { [keys[i]]: rules[keys[i]] },
        )
        const isValid = validateProperty.passes()
        if (rules[keys[i]].indexOf('required') === -1 && isValid) {
          errors[keys[i]] = {
            isValid: undefined,
            invalidMessage: '',
          }
        } else {
          errors[keys[i]] = formValidator(keys[i], isValid)
        }
      }
    }
    yield put(errorsOrderHandler(errors))
  } catch (error) {
    console.error(`[validationHandler]: ${error}`)
    captureException(error)
  }
}
