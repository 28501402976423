import { SortableOrder } from '../sortable-order'

export const groupOrders = (
  orders: SortableOrder[],
  isFirstGroup: (order: SortableOrder) => boolean,
): [Array<SortableOrder>, Array<SortableOrder>] => {
  const result = orders.reduce(
    (obj: { firstGroup: SortableOrder[]; secondGroup: SortableOrder[] }, order: SortableOrder) =>
      isFirstGroup(order) ? { ...obj, firstGroup: [...obj.firstGroup, order] } : { ...obj, secondGroup: [...obj.secondGroup, order] },
    { firstGroup: [], secondGroup: [] },
  )

  return [result.firstGroup, result.secondGroup]
}
