import { v3Api, transformResponse } from './axios'

export const getCertificateRequest = (type: string, passport: any, token: string) =>
  transformResponse(
    v3Api.post(
      '/certificate/get',
      { type, passport },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      },
    ),
  )
