import React, { useCallback, useMemo } from 'react'

import classNames from 'classnames'

import Select, { ValueType } from 'react-select'

import { SelectItem } from 'types/selectLists'

import classes from './style.module.css'

export interface SelectControlProps {
  value: string | null
  options: SelectItem[] | undefined
  id: string
  onChangeHandler(value: string | null, id?: string): void
  withEmptyValue?: boolean
  className?: string
  label?: string
  isValid?: boolean | undefined
  invalidMessage?: string
  placeholder?: string
  disabled?: boolean
}

// (New version with react-select)

export const SelectControl: React.FC<SelectControlProps> = ({
  value,
  options = [],
  id,
  onChangeHandler,
  withEmptyValue = false,
  className = '',
  label,
  isValid,
  invalidMessage = '',
  placeholder = ' ',
  disabled = false,
}) => {
  const onChange = useCallback(
    (val: ValueType<any, any>) => {
      if (!val) onChangeHandler('', id)
      else onChangeHandler(val.value, id)
    },
    [id, onChangeHandler],
  )

  const processedOptions = useMemo(() => {
    let opts = options
      ?.filter((option) => option.isHidden !== true || option.id === value)
      .map((option) => ({ value: option.id, label: option.name }))

    if (opts?.length === 1 && value !== opts[0].value) onChange(opts[0])

    if (withEmptyValue && options?.length !== 1) opts = [{ value: '', label: 'Выбрать...' }, ...opts]

    return opts
  }, [options, value, onChange, withEmptyValue])

  const currentValue = useMemo(() => {
    const currentOptions = processedOptions?.filter((option) => String(option.value) === String(value))
    return currentOptions?.length ? currentOptions[0] : { value: '', label: '' }
  }, [processedOptions, value])

  return (
    <div>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select
        value={currentValue}
        id={id}
        className={classNames(
          className,
          // eslint-disable-next-line no-nested-ternary
          isValid === true ? classes.valid : isValid === false ? classes.invalid : '',
          'p-0',
        )}
        onChange={onChange}
        options={processedOptions}
        isClearable={withEmptyValue}
        isSearchable
        placeholder={placeholder}
        isDisabled={disabled}
      />
      {invalidMessage && isValid === false ? <div className={classes['invalid-feedback']}>{invalidMessage}</div> : null}
    </div>
  )
}

export default SelectControl
