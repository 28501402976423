import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import { OrderData, OrderFields } from 'types/order'
import { selectOrderRead } from 'store/order/order.selectors'

interface VerifyEmailFormProps {
  onChange(value: string, id: string): void
  form: Partial<OrderData>
}

export const EMAIL_IS_VERIFIED_TEXT = 'Email найден в ATI перевозчика'
export const EMAIL_IS_UNVERIFIED_TEXT = 'Email НЕ НАЙДЕН в ATI перевозчика'

export const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({ onChange, form }) => {
  const fields = useSelector(selectOrderRead)
  const isVerified = useMemo(() => {
    return form[OrderFields.ORDER_COMMENT]?.includes(EMAIL_IS_VERIFIED_TEXT)
  }, [form])

  const isUnverified = useMemo(() => {
    return form[OrderFields.ORDER_COMMENT]?.includes(EMAIL_IS_UNVERIFIED_TEXT)
  }, [form])

  const verifyEmail = (verified: boolean) => {
    let comment = form[OrderFields.ORDER_COMMENT] || ''
    comment = comment.replace(EMAIL_IS_VERIFIED_TEXT, '').replace(EMAIL_IS_UNVERIFIED_TEXT, '')

    if (verified) comment = [...comment.split('\n'), EMAIL_IS_VERIFIED_TEXT].filter((value) => value).join('\n')
    if (!verified) comment = [...comment.split('\n'), EMAIL_IS_UNVERIFIED_TEXT].filter((value) => value).join('\n')
    onChange(comment, OrderFields.ORDER_COMMENT)
  }

  return (
    <>
      <div style={{ marginBottom: '8px' }}>
        <b>Сверить email с компанией на ATI.</b> <br />
        Email должен находиться именно в аккаунте того перевозчика, которого мы проверяем:
        <TextWithPrefixAndCopy
          text={
            <a target="_blank" rel="noopener noreferrer" href="https://ati.su/">
              {fields[OrderFields.CARRIER_EMAIL]}
            </a>
          }
          copiedText={fields[OrderFields.CARRIER_EMAIL]}
          prefix="Email:"
          copiedTextPrefix=""
        />
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '8px', marginBottom: '16px' }}>
        <Button className="p-0" style={{ flex: '1', marginRight: '4px' }} disabled={isVerified} onClick={() => verifyEmail(true)}>
          Найден
        </Button>
        <Button
          className="p-0"
          style={{ flex: '1', marginLeft: '4px' }}
          disabled={isUnverified}
          onClick={() => {
            verifyEmail(false)
          }}
        >
          Не найден
        </Button>
      </div>
    </>
  )
}
