import React, { useCallback } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { UserRole } from 'types/user'
import { LoadingStatus } from 'types/loading'
import { AutoCheckStatus, AutoCheckType } from 'types/auto-checks'
import { coincidenceFromDGOnFioAndBirth, personFromDGOnFioAndBirth } from 'types/data-gateway'

import { selectCurrentUser } from 'store/users/users.selectors'
import { selectLoadingDataGatewayStatus } from 'store/data-gateway/data-gateway.selectors'
import { fetchDataGatewayRequest } from 'store/data-gateway/data-gateway.actions'
import StatusFlag from 'components/atoms/StatusFlag'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

interface ButtonGetDataFromDGOnTelProps {
  tel: string
  check?: {
    status: AutoCheckStatus
    type: AutoCheckType
    data: {
      persons: personFromDGOnFioAndBirth[]
      coincidences: coincidenceFromDGOnFioAndBirth[]
    }
  }
}

export const ButtonGetDataFromDGOnTel: React.FC<ButtonGetDataFromDGOnTelProps> = ({ tel, check }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const loadingDataGateway = useSelector(selectLoadingDataGatewayStatus)
  const getDataFromDataGateway = useCallback(() => {
    dispatch(
      fetchDataGatewayRequest({
        telephone: tel.slice(1),
      }),
    )
  }, [dispatch, tel])

  const checkHasPersons = Boolean(check?.data?.persons?.length)

  const checkHasPendingStatus = check?.status === AutoCheckStatus.PENDING
  const checkHasFinalEffectiveStatus = [AutoCheckStatus.SUCCESS, AutoCheckStatus.INDETERMINATE, AutoCheckStatus.FAIL].includes(check?.status as any)

  const isLoading = loadingDataGateway === LoadingStatus.PENDING || checkHasPendingStatus

  const isExpert = currentUser!.role === UserRole.EXPERT

  return (
    <div>
      {isExpert && tel ? (
        <>
          <button type="button" onClick={getDataFromDataGateway} disabled={loadingDataGateway === LoadingStatus.PENDING} className={classes.button}>
            <span className={classes.prefix}>DG:</span>
          </button>
          {isLoading ? (
            <>
              &nbsp;
              <Spinner as="span" animation="grow" style={{ width: '1rem', height: '1rem' }} role="status" aria-hidden="true" />
            </>
          ) : null}
          {checkHasPersons && checkHasFinalEffectiveStatus && !isLoading ? (
            <span className={classes[check!.status]}>
              &nbsp;
              <Icon type={IconType.User} width="1rem" height="1rem" className={classes.icon} />x{check!.data?.persons?.length}
            </span>
          ) : null}
          {!checkHasPersons && checkHasFinalEffectiveStatus && !isLoading ? (
            <span>
              &nbsp;
              <StatusFlag status={check!.status} />
            </span>
          ) : null}
          {!checkHasPersons && !checkHasFinalEffectiveStatus && !isLoading ? <span className={classes.none}>&nbsp;—</span> : null}
        </>
      ) : null}
    </div>
  )
}

export default ButtonGetDataFromDGOnTel
