import React from 'react'
import { billingPath } from 'routes/url-constants'

export const BlockingWarning: React.FC = () => {
  return (
    <div className="text-danger p-1" style={{ margin: '0px -15px' }}>
      <h4 className="text-danger p-1">Внимание!</h4>
      <p className="text-danger p-1">
        Вы были заблокированы за неуплату. Для разблокировки просьба оплатить счет. Его можно найти во вкладке{' '}
        <a href={billingPath}>«счета и оплаты»</a>. После этого с квитанцией необходимо обратиться в{' '}
        <a href="https://t.me/ekaterina_valeska" target="_blank" rel="noopener noreferrer">
          поддержку ОТК
        </a>
        !
      </p>
    </div>
  )
}

export default BlockingWarning
