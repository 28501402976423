import { OrderFields } from 'types/order'
import { groupOrders } from './group-orders'
import { SortableOrder } from '../sortable-order'

export class YandexTaxiGroupSorter {
  static sort(orders: SortableOrder[]) {
    const [yandexOrders, regularOrders] = groupOrders(orders, YandexTaxiGroupSorter.isYandexOrder)

    return [...regularOrders, ...yandexOrders]
  }

  static isYandexOrder(order: SortableOrder) {
    return String(order[OrderFields.CLIENT_NAME]) === process.env.REACT_APP_YANDEX_TAXI_ID_CLIENT
  }
}
