import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { addAlert } from 'store/alerts/alerts.actions'
import { selectToken } from 'store/users/users.selectors'

import { AlertVariant } from 'types/alert'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'
import classes from './style.module.css'

const Certificate = () => {
  const token = useSelector(selectToken)
  const dispatchStore = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  const location = useLocation()
  const [, orderNumber] = location.pathname.split('/').filter((chunk: string) => chunk)

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    dispatchStore(
      addAlert({
        header: 'Сертификат',
        text: 'Не удалось загрузить сертификат',
        lifetime: 6000,
        variant: AlertVariant.DANGER,
      }),
    )
  }

  const src = `${process.env.REACT_APP_CERTIFICATES_WEB}/${location.search}&trackingId=order/${orderNumber}`

  return (
    <div className={classes.container}>
      <div className={classes.certificate}>
        <iframe
          key={src}
          src={`${src}&token=${token}`}
          width="100%"
          height="100%"
          className={classNames(isLoaded ? classes.visible : classes.hidden)}
          title="Сертификат"
          onLoad={onLoad}
          onError={onError}
        />
        {isLoaded ? null : <LoadingPlaceholder />}
      </div>
    </div>
  )
}

export default Certificate
