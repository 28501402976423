import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

export const Stories: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    setIsError(true)
  }

  const handleMessage = useCallback((message) => {
    if (message === 'OpenStories') setIsOpen(true)
    if (message === 'CloseStories') setIsOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin === 'https://otk.su') handleMessage(event.data)
    })
  }, [handleMessage])

  const randomNumber = useMemo(() => new Date().getTime(), [])

  if (isError) return null

  return (
    <div className={classNames(classes.iframe_container, isOpen ? classes.open : null)}>
      <iframe
        className={isLoaded ? classes.visible : classes.hidden}
        onLoad={onLoad}
        onError={onError}
        title="Новости"
        key="https://otk.su/stories"
        src={`https://otk.su/stories?randomNumber=${randomNumber}`}
        frameBorder="0"
        allowFullScreen
        height="100px"
        width="100%"
      />
      {!isLoaded ? (
        <div className={classes.loader}>
          <LoadingPlaceholder />
        </div>
      ) : null}
    </div>
  )
}

export default Stories
