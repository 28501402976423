import { Action, handleActions } from 'redux-actions'

import { Template } from 'types/core/template'
import { FieldTemplate } from 'types/core/field-template'
import { LoadingStatus } from 'types/loading'
import * as actions from 'store/template/template.actions'

export interface TemplateState<FieldTemplate> {
  template: Template<FieldTemplate>
  loadingStatus: LoadingStatus
}

export const initialTemplateState: TemplateState<FieldTemplate> = {
  template: { name: '', fields: [], title: '' },
  loadingStatus: LoadingStatus.NONE,
}

const getTemplateRequest = <FieldTemplate>(state: TemplateState<FieldTemplate>): TemplateState<FieldTemplate> => {
  return {
    ...state,
    loadingStatus: LoadingStatus.PENDING,
  }
}

const getTemplateSuccess = <FieldTemplate>(
  state: TemplateState<FieldTemplate>,
  action: Action<Template<FieldTemplate>>,
): TemplateState<FieldTemplate> => {
  return {
    ...state,
    template: action.payload,
    loadingStatus: LoadingStatus.SUCCESS,
  }
}

const getTemplateFailure = <FieldTemplate>(state: TemplateState<FieldTemplate>): TemplateState<FieldTemplate> => {
  return {
    ...state,
    loadingStatus: LoadingStatus.FAILED,
  }
}

export const templateReducer = handleActions<TemplateState<FieldTemplate>, any>(
  {
    [actions.GET_TEMPLATE_REQUEST]: getTemplateRequest,
    [actions.GET_TEMPLATE_SUCCESS]: getTemplateSuccess,
    [actions.GET_TEMPLATE_FAILURE]: getTemplateFailure,
  },
  initialTemplateState,
)
