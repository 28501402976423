import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { deactivateUserSaga } from './sagas/deactivate-user-saga'
import { getCompaniesSaga } from './sagas/get-companies'
import { getCustomersSaga } from './sagas/get-customers'
import { editCustomerSaga } from './sagas/edit-customer'
import { createCustomerSaga } from './sagas/create-customer'
import { editCustomerSettingsSaga } from './sagas/edit-customer-settings'
import { fetchGetUsersSaga } from './sagas/fetch-get-users'
import { fetchCreateUserSaga } from './sagas/fetch-create-user'
import { fetchEditUserSaga } from './sagas/fetch-edit-user'

import * as actions from './admin.actions'

export default function* () {
  yield all([
    takeEvery(actions.DEACTIVATE_USER_REQUEST, deactivateUserSaga),
    takeLatest(actions.GET_COMPANIES_REQUEST, getCompaniesSaga),
    takeLatest(actions.GET_CUSTOMERS_REQUEST, getCustomersSaga),
    takeLatest(actions.EDIT_CUSTOMER_REQUEST, editCustomerSaga),
    takeLatest(actions.CREATE_CUSTOMER_REQUEST, createCustomerSaga),
    takeLatest(actions.EDIT_CUSTOMER_SETTINGS_REQUEST, editCustomerSettingsSaga),
    takeLatest(actions.FETCH_GET_USERS_REQUEST, fetchGetUsersSaga),
    takeEvery(actions.FETCH_CREATE_USER_REQUEST, fetchCreateUserSaga),
    takeEvery(actions.FETCH_EDIT_USER_REQUEST, fetchEditUserSaga),
  ])
}
