import { v3Api } from 'requests/axios'

export default class RemoteUserScheduler {
  public async getScheduleFor(role: any) {
    const token = localStorage.getItem('token')
    const config = { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    const response = await v3Api.get(`/schedule/${role}`, config)
    return response.data
  }

  public async setUsersOnline(setUsersRequest: any) {
    const token = localStorage.getItem('token')
    const config = { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    const response = await v3Api.post('/schedule/set', setUsersRequest, config)
    return response.data
  }
}
