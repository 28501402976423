import React from 'react'

export const DriverHintText = () => (
  <>
    ВОДИТЕЛЬ
    <br />
    <ul>
      <li>паспорт</li>
      <li>водительское удостоверение</li>
      <li>
        трудовой договор ИЛИ гарантийное письмо произвольной формы с текстом, что водитель (паспортные данные водителя) является официально устроенным
        работником и гарантируется доставка груза с участием этого водителя,{' '}
        <a href="https://u.to/Qw0MGw" target="_blank" rel="noopener noreferrer">
          образец ГП
        </a>
      </li>
    </ul>
  </>
)

export default DriverHintText
