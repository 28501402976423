import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'

import { LoadingStatus } from 'types/loading'

import { searchOrdersRequest } from 'store/orders/orders.actions'
import { fetchSelectListsRequest } from 'store/selectLists/selectLists.actions'
import { selectLoadingGetOrdersStatus, selectOrders, selectSearchOrders } from 'store/orders/orders.selectors'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'

import InputControl from 'components/molecules/InputControl'

const queryString = require('query-string')

export const SearchOrderBodyHeader = () => {
  const dispatch = useDispatch()
  const loadingGetOrdersStatus = useSelector(selectLoadingGetOrdersStatus)
  const loadingSelectListsStatus = useSelector(selectLoadingSelectListsStatus)
  const isSearchOrders = !!useSelector(selectSearchOrders)
  const orders = useSelector(selectOrders)

  const location = useLocation()
  const history = useHistory()
  const queryObject = useMemo(() => queryString.parse(location.search), [location])

  const [value, setValue] = useState(queryObject.value ? queryObject.value : '')

  const onChangeHandler = useCallback((val) => setValue(val), [setValue])

  const handleSearch = useCallback(() => {
    dispatch(searchOrdersRequest(value.trim()))
    history.push({ search: `?value=${value.trim()}` })
    if (value) dispatch(fetchSelectListsRequest())
    window.localStorage.search = value.trim()
  }, [dispatch, value, history])

  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault()
      handleSearch()
    },
    [handleSearch],
  )
  useEffect(() => {
    if (!queryObject.value) return
    handleSearch()

    return () => {
      setTimeout(() => {
        if (!window.location.href.includes('/order/')) window.localStorage.search = ''
      }, 100)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const orderCount = orders.length

  return (
    <>
      <div className="list-group-item container" style={{ zIndex: 501 }}>
        <form className="row" onSubmit={handleSubmitForm}>
          <div className="col-12 d-flex">
            <div className="d-inline-block flex-grow-1">
              <InputControl id="search" value={value} onChangeHandler={onChangeHandler} type="search" placeholder="Поиск" />
            </div>
            <Button style={{ marginLeft: '5px' }} onClick={handleSearch}>
              Поиск
            </Button>
          </div>
          {loadingGetOrdersStatus === LoadingStatus.SUCCESS && loadingSelectListsStatus !== LoadingStatus.PENDING && isSearchOrders ? (
            <div className="col-12" style={{ marginTop: '5px' }}>
              {orderCount > 10 ? <p className="text-warning small m-0">Найдено больше 10 заявок, попробуйте сделать запрос более точным.</p> : null}
            </div>
          ) : null}
        </form>
      </div>
    </>
  )
}

export default SearchOrderBodyHeader
