import { Action, handleActions } from 'redux-actions'
import { LoadingStatus } from 'types/loading'
import { TelegramChat } from 'types/telegram-chat'
import * as actions from './telegramChatList.actions'

export interface TelegramChatListState {
  list: TelegramChat[]
  loadingGetList: LoadingStatus
}

export const initialTelegramChatListState: TelegramChatListState = {
  list: [],
  loadingGetList: LoadingStatus.NONE,
}

const fetchCurrentUserRequest = (state: TelegramChatListState): TelegramChatListState => ({
  ...state,
  loadingGetList: LoadingStatus.PENDING,
})

const fetchCurrentUserSuccess = (state: TelegramChatListState, { payload }: Action<TelegramChat[]>): TelegramChatListState => ({
  ...state,
  list: payload,
  loadingGetList: LoadingStatus.SUCCESS,
})

const fetchCurrentUserFailure = (state: TelegramChatListState): TelegramChatListState => ({
  ...state,
  loadingGetList: LoadingStatus.FAILED,
})

const setInitialState = (): TelegramChatListState => ({
  ...initialTelegramChatListState,
})

export default handleActions<TelegramChatListState, any>(
  {
    [actions.FETCH_GET_TELEGRAM_CHAT_LIST_REQUEST]: fetchCurrentUserRequest,
    [actions.FETCH_GET_TELEGRAM_CHAT_LIST_SUCCESS]: fetchCurrentUserSuccess,
    [actions.FETCH_GET_TELEGRAM_CHAT_LIST_FAILURE]: fetchCurrentUserFailure,
    [actions.SET_INITIAL_TELEGRAM_CHAT_LIST]: setInitialState,
  },
  initialTelegramChatListState,
)
