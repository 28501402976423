import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'
import { Customer } from 'types/customer'
import { EntityValue } from 'types/core/entity-value'
import { User } from 'types/user'

import * as actions from './admin.actions'

export interface AdminState {
  users: User[]
  companies: EntityValue[]
  customers: Customer[]
  getCustomersLoadingStatus: LoadingStatus
  editCustomerLoadingStatus: LoadingStatus
  createCustomerLoadingStatus: LoadingStatus
  editCustomerSettingsLoadingStatus: LoadingStatus
  getCompaniesLoadingStatus: LoadingStatus
  deactivateUserLoadingStatus: LoadingStatus
  loadingGetUsers: LoadingStatus
  loadingEditUser: LoadingStatus
  loadingCreateUser: LoadingStatus
}

export const initialAdminState: AdminState = {
  users: [],
  companies: [],
  customers: [],
  getCustomersLoadingStatus: LoadingStatus.NONE,
  editCustomerLoadingStatus: LoadingStatus.NONE,
  createCustomerLoadingStatus: LoadingStatus.NONE,
  editCustomerSettingsLoadingStatus: LoadingStatus.NONE,
  getCompaniesLoadingStatus: LoadingStatus.NONE,
  deactivateUserLoadingStatus: LoadingStatus.NONE,
  loadingGetUsers: LoadingStatus.NONE,
  loadingEditUser: LoadingStatus.NONE,
  loadingCreateUser: LoadingStatus.NONE,
}

const setInitialAdminState = (): AdminState => initialAdminState

const deactivateUserRequest = (state: AdminState): AdminState => {
  return {
    ...state,
    deactivateUserLoadingStatus: LoadingStatus.PENDING,
  }
}

const deactivateUserSuccess = (state: AdminState): AdminState => {
  return {
    ...state,
    deactivateUserLoadingStatus: LoadingStatus.SUCCESS,
  }
}

const deactivateUserFailure = (state: AdminState): AdminState => {
  return {
    ...state,
    deactivateUserLoadingStatus: LoadingStatus.FAILED,
  }
}

const getCompaniesRequest = (state: AdminState): AdminState => ({
  ...state,
  getCompaniesLoadingStatus: LoadingStatus.PENDING,
})

const getCompaniesSuccess = (state: AdminState, action: Action<EntityValue[]>): AdminState => ({
  ...state,
  companies: [...action.payload],
  getCompaniesLoadingStatus: LoadingStatus.SUCCESS,
})

const getCompaniesFailure = (state: AdminState): AdminState => ({
  ...state,
  getCompaniesLoadingStatus: LoadingStatus.FAILED,
})

const getCustomersRequest = (state: AdminState): AdminState => ({
  ...state,
  getCustomersLoadingStatus: LoadingStatus.PENDING,
})

const getCustomersSuccess = (state: AdminState, action: Action<Customer[]>): AdminState => ({
  ...state,
  customers: [...action.payload],
  getCustomersLoadingStatus: LoadingStatus.SUCCESS,
})

const getCustomersFailure = (state: AdminState): AdminState => ({
  ...state,
  getCustomersLoadingStatus: LoadingStatus.FAILED,
})

const editCustomersRequest = (state: AdminState): AdminState => ({
  ...state,
  editCustomerLoadingStatus: LoadingStatus.PENDING,
})

const editCustomersSuccess = (state: AdminState): AdminState => ({
  ...state,
  editCustomerLoadingStatus: LoadingStatus.SUCCESS,
})

const editCustomersFailure = (state: AdminState): AdminState => ({
  ...state,
  editCustomerLoadingStatus: LoadingStatus.FAILED,
})

const editCustomerSettingsRequest = (state: AdminState): AdminState => ({
  ...state,
  editCustomerSettingsLoadingStatus: LoadingStatus.PENDING,
})

const editCustomerSettingsSuccess = (state: AdminState): AdminState => ({
  ...state,
  editCustomerSettingsLoadingStatus: LoadingStatus.SUCCESS,
})

const editCustomerSettingsFailure = (state: AdminState): AdminState => ({
  ...state,
  editCustomerSettingsLoadingStatus: LoadingStatus.FAILED,
})

const createCustomersRequest = (state: AdminState): AdminState => ({
  ...state,
  createCustomerLoadingStatus: LoadingStatus.PENDING,
})

const createCustomerSuccess = (state: AdminState): AdminState => ({
  ...state,
  createCustomerLoadingStatus: LoadingStatus.SUCCESS,
})

const createCustomersFailure = (state: AdminState): AdminState => ({
  ...state,
  createCustomerLoadingStatus: LoadingStatus.FAILED,
})

const fetchGetUsersRequest = (state: AdminState): AdminState => ({
  ...state,
  loadingGetUsers: LoadingStatus.PENDING,
})

const fetchGetUsersSuccess = (state: AdminState, action: Action<User[]>): AdminState => ({
  ...state,
  users: [...action.payload],
  loadingGetUsers: LoadingStatus.SUCCESS,
})

const fetchGetUsersFailure = (state: AdminState): AdminState => ({
  ...state,
  loadingGetUsers: LoadingStatus.FAILED,
})

const fetchEditUserRequest = (state: AdminState): AdminState => ({
  ...state,
  loadingEditUser: LoadingStatus.PENDING,
})

const fetchEditUserSuccess = (state: AdminState): AdminState => ({
  ...state,
  loadingEditUser: LoadingStatus.SUCCESS,
})

const fetchEditUserFailure = (state: AdminState): AdminState => ({
  ...state,
  loadingEditUser: LoadingStatus.FAILED,
})

const fetchCreateUserRequest = (state: AdminState): AdminState => ({
  ...state,
  loadingCreateUser: LoadingStatus.PENDING,
})

const fetchCreateUserSuccess = (state: AdminState): AdminState => ({
  ...state,
  loadingCreateUser: LoadingStatus.SUCCESS,
})

const fetchCreateUserFailure = (state: AdminState): AdminState => ({
  ...state,
  loadingCreateUser: LoadingStatus.FAILED,
})

export default handleActions<AdminState, any>(
  {
    [actions.DEACTIVATE_USER_REQUEST]: deactivateUserRequest,
    [actions.DEACTIVATE_USER_SUCCESS]: deactivateUserSuccess,
    [actions.DEACTIVATE_USER_FAILURE]: deactivateUserFailure,
    [actions.SET_INITIAL_ADMIN_STATE]: setInitialAdminState,
    [actions.GET_COMPANIES_REQUEST]: getCompaniesRequest,
    [actions.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
    [actions.GET_COMPANIES_FAILURE]: getCompaniesFailure,
    [actions.GET_CUSTOMERS_REQUEST]: getCustomersRequest,
    [actions.GET_CUSTOMERS_SUCCESS]: getCustomersSuccess,
    [actions.GET_CUSTOMERS_FAILURE]: getCustomersFailure,
    [actions.EDIT_CUSTOMER_REQUEST]: editCustomersRequest,
    [actions.EDIT_CUSTOMER_SUCCESS]: editCustomersSuccess,
    [actions.EDIT_CUSTOMER_FAILURE]: editCustomersFailure,
    [actions.EDIT_CUSTOMER_SETTINGS_REQUEST]: editCustomerSettingsRequest,
    [actions.EDIT_CUSTOMER_SETTINGS_SUCCESS]: editCustomerSettingsSuccess,
    [actions.EDIT_CUSTOMER_SETTINGS_FAILURE]: editCustomerSettingsFailure,
    [actions.CREATE_CUSTOMER_REQUEST]: createCustomersRequest,
    [actions.CREATE_CUSTOMER_SUCCESS]: createCustomerSuccess,
    [actions.CREATE_CUSTOMER_FAILURE]: createCustomersFailure,
    [actions.FETCH_GET_USERS_REQUEST]: fetchGetUsersRequest,
    [actions.FETCH_GET_USERS_SUCCESS]: fetchGetUsersSuccess,
    [actions.FETCH_GET_USERS_FAILURE]: fetchGetUsersFailure,
    [actions.FETCH_EDIT_USER_REQUEST]: fetchEditUserRequest,
    [actions.FETCH_EDIT_USER_SUCCESS]: fetchEditUserSuccess,
    [actions.FETCH_EDIT_USER_FAILURE]: fetchEditUserFailure,
    [actions.FETCH_CREATE_USER_REQUEST]: fetchCreateUserRequest,
    [actions.FETCH_CREATE_USER_SUCCESS]: fetchCreateUserSuccess,
    [actions.FETCH_CREATE_USER_FAILURE]: fetchCreateUserFailure,
  },
  initialAdminState,
)
