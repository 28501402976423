import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from 'store/users/users.selectors'
import { fetchOrdersRequest } from 'store/orders/orders.actions'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'
import { selectLoadingGetOrdersStatus, selectSearchOrders } from 'store/orders/orders.selectors'

import { UserRole } from 'types/user'
import { LoadingStatus } from 'types/loading'

import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'
import { Icon, IconType } from 'components/atoms/Icon'

import OrderListCustomer from './customer'
import OrderListManager from './manager'

import classes from './style.module.css'
import { getIconUpdateOpacity, getIconUpdateTop, ICON_UPDATE_SIZE } from './utils'

export const OrderList: React.FC = () => {
  const dispatch = useDispatch()

  const loadingGetOrdersStatus = useSelector(selectLoadingGetOrdersStatus)
  const loadingSelectListsStatus = useSelector(selectLoadingSelectListsStatus)
  const isSearchOrders = !!useSelector(selectSearchOrders)

  const [startPosition, setStartPosition] = useState(0)
  const [isZeroOffsetScroll, setIsZeroOffsetScroll] = useState(true)
  const iconUpdate = useRef<HTMLDivElement | null>(null)
  const orderListContainer = useRef<HTMLDivElement | null>(null)
  const [startDirection, setStartDirection] = useState<1 | -1 | null>(null)

  useEffect(() => {
    const onWindowScroll = () => {
      setIsZeroOffsetScroll(window.scrollY <= 0)
    }

    window.addEventListener('scroll', onWindowScroll)

    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [])

  const onTouchStart = useCallback(
    (event) => {
      if (isZeroOffsetScroll) {
        setStartPosition(event.touches[0].screenY)
      }
    },
    [isZeroOffsetScroll],
  )

  const onTouchMove = useCallback(
    (event) => {
      const currentPosition = event.touches[0].screenY
      if (currentPosition - startPosition < -10 || currentPosition - startPosition > 10 || startDirection) {
        if (!startDirection) setStartDirection(currentPosition - startPosition < -10 ? -1 : 1)
        if (startDirection === -1 && startPosition) {
          setStartPosition(0)
          document.body.style.overflow = 'auto'
          iconUpdate.current!.style.top = `-${ICON_UPDATE_SIZE}px`
          iconUpdate.current!.style.opacity = '0'
          iconUpdate.current!.style.transform = `rotate(0deg)`
        }
        if (startDirection === 1 && startPosition && document.body.style.overflow !== 'hidden') {
          document.body.style.overflow = 'hidden'
        }
        if (startPosition) {
          iconUpdate.current!.style.top = `${getIconUpdateTop(startPosition, currentPosition)}px`
          iconUpdate.current!.style.opacity = getIconUpdateOpacity(startPosition, currentPosition)
          iconUpdate.current!.style.transform = `rotate(${currentPosition - startPosition}deg)`
        }
      }
    },
    [startPosition, startDirection],
  )

  const handleReload = useCallback(() => {
    if (startPosition && isZeroOffsetScroll && iconUpdate.current!.style.opacity === '1') dispatch(fetchOrdersRequest())

    setStartPosition(0)
    iconUpdate.current!.style.top = `-${ICON_UPDATE_SIZE}px`
    iconUpdate.current!.style.opacity = '0'
    iconUpdate.current!.style.transform = `rotate(0deg)`
    document.body.style.overflow = 'auto'
    setStartDirection(null)
  }, [isZeroOffsetScroll, startPosition, dispatch])

  const currentUser = useSelector(selectCurrentUser)

  const orderList = useMemo(() => {
    switch (currentUser!.role) {
      case UserRole.CUSTOMER:
        return <OrderListCustomer />

      default:
        return <OrderListManager />
    }
  }, [currentUser])

  if (loadingGetOrdersStatus === LoadingStatus.PENDING || loadingSelectListsStatus === LoadingStatus.PENDING)
    return (
      <div style={{ touchAction: 'pan-down', height: '100%' }}>
        <LoadingPlaceholder />
      </div>
    )

  return (
    <div className="position-relative" style={{ height: '100%' }}>
      <div className={classes.iconUpdate} ref={iconUpdate}>
        <Icon type={IconType.Update} width={`${ICON_UPDATE_SIZE}px`} height={`${ICON_UPDATE_SIZE}px`} />
      </div>
      <div
        onTouchStart={(event) => {
          if (!isSearchOrders) onTouchStart(event)
        }}
        onTouchMove={(event) => {
          if (!isSearchOrders) onTouchMove(event)
        }}
        onTouchEnd={() => {
          if (!isSearchOrders) handleReload()
        }}
        className={classes.orderListContainer}
        ref={orderListContainer}
        style={
          // eslint-disable-next-line no-nested-ternary
          isZeroOffsetScroll ? (startDirection === 1 ? { touchAction: 'none' } : { touchAction: 'pan-down' }) : {}
        }
      >
        {orderList}
      </div>
    </div>
  )
}

export default OrderList
