import { call, put, select } from 'redux-saga/effects'

import { setBillingSettings } from 'requests/billing'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { setBillingSettingsFailure, setBillingSettingsSuccess } from 'store/billing/billing.actions'
import { Action } from 'redux-actions'
import { addAlert } from 'store/alerts/alerts.actions'
import { AlertVariant } from 'types/alert'

export function* setBillingSettingsSaga(action: Action<any>) {
  try {
    const token = yield select(selectToken)
    const { customer, settings } = action.payload

    const response = yield call(setBillingSettings, customer, settings, token)

    yield put(setBillingSettingsSuccess(response))
    yield put(
      addAlert({
        text: 'Настройки биллинга клиента сохранены',
        lifetime: 3000,
        variant: AlertVariant.INFO,
        header: 'Сохранение настроек биллинга',
      }),
    )
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Сохранение настроек биллинга',
        defaultText: 'Не удалось сохранить настройки биллинга.',
      }),
    )
    console.error(`[setBillingSettingsSaga]: ${error}`)
    yield put(setBillingSettingsFailure(error))
  }
}
