import { all, takeLatest } from 'redux-saga/effects'

import * as actions from 'store/orders/orders.actions'
import { fetchOrdersSaga } from 'store/orders/sagas/fetch-orders'
import { fetchTakeOrderSaga } from 'store/orders/sagas/fetch-take-order'
import { fetchTakeNextOrderSaga } from 'store/orders/sagas/fetch-take-next-order'
import { searchOrdersSaga } from 'store/orders/sagas/search-orders'

export default function* () {
  yield all([
    takeLatest(actions.FETCH_ORDERS_REQUEST, fetchOrdersSaga),
    takeLatest(actions.FETCH_TAKE_ORDER_REQUEST, fetchTakeOrderSaga),
    takeLatest(actions.FETCH_TAKE_NEXT_ORDER_REQUEST, fetchTakeNextOrderSaga),
    takeLatest(actions.SEARCH_ORDERS_REQUEST, searchOrdersSaga),
  ])
}
