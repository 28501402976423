import { useHistory, useLocation } from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import { OrderFilterId } from 'types/order'
import { UserRole } from 'types/user'

export const useOrderListFilterLocation = (userRole: UserRole) => {
  const location = useLocation()
  const history = useHistory()
  const queryObject = useMemo(() => queryString.parse(location.search), [location])
  const filterValue = useMemo(() => (Array.isArray(queryObject.filter) ? queryObject.filter[0] : queryObject.filter), [queryObject.filter])

  const defaultFilter = userRole === UserRole.CUSTOMER ? OrderFilterId.ALL_ACTIVE_ORDERS : OrderFilterId.USER_ORDERS
  const [filter, setFilter] = useState<string>(filterValue || defaultFilter)

  useEffect(() => {
    setFilter(filterValue || defaultFilter)
  }, [defaultFilter, filterValue])

  return {
    get: () => {
      return filter
    },
    set: (value: string) => {
      setFilter(value)
      const searchParams = new URLSearchParams(history.location.search)
      searchParams.set('filter', value.trim())
      history.push({ search: searchParams.toString() })
    },
  }
}
