import { OrderTypeId, ResolutionId } from 'types/order'
import { FINISH_POSITIVE_RESOLUTIONS, FINISH_RESOLUTIONS, ORDER_TYPES_TO_CHECK_DRIVER } from '../constants'

export class Resolution {
  private readonly userId: number

  private readonly executor: string

  private readonly currentResolution: ResolutionId

  private readonly orderType: OrderTypeId

  private readonly selectedResolution: ResolutionId

  private readonly driverPhone1: string

  private readonly driverPhone2: string

  constructor(
    userId: number,
    executor: string,
    currentResolution: ResolutionId,
    orderType: OrderTypeId,
    selectedResolution: ResolutionId,
    driverPhone1: string,
    driverPhone2: string,
  ) {
    this.userId = userId
    this.executor = executor
    this.currentResolution = currentResolution
    this.orderType = orderType
    this.selectedResolution = selectedResolution
    this.driverPhone1 = driverPhone1
    this.driverPhone2 = driverPhone2
  }

  public hasDriverPhone() {
    return Boolean(this.driverPhone1 || this.driverPhone2)
  }

  public hasDriverBothPhones() {
    return Boolean(this.driverPhone1 && this.driverPhone2)
  }

  public isUserExecutor(): boolean {
    return String(this.executor) === String(this.userId) && String(this.currentResolution) !== ResolutionId.FOR_E
  }

  public isResolutionFinal(): boolean {
    return FINISH_RESOLUTIONS.includes(String(this.selectedResolution) as ResolutionId)
  }

  public isResolutionPositive(): boolean {
    return FINISH_POSITIVE_RESOLUTIONS.includes(String(this.selectedResolution) as ResolutionId)
  }

  public hasOrderDriver(): boolean {
    return ORDER_TYPES_TO_CHECK_DRIVER.includes(String(this.orderType) as OrderTypeId)
  }

  public canNotSetFinalResolution(firstPhoneIsVerified: boolean, secondPhoneIsVerified: boolean): boolean {
    const userHasOrder = this.isUserExecutor()
    const orderHasDriver = this.hasOrderDriver()
    const resolutionIsPositive = this.isResolutionPositive()
    const driverHasPhone = this.hasDriverPhone()
    const driverHasBothPhones = this.hasDriverBothPhones()

    const finalResolutionIsDisabled =
      orderHasDriver &&
      ((!firstPhoneIsVerified && !secondPhoneIsVerified && driverHasPhone && resolutionIsPositive) ||
        ((!firstPhoneIsVerified || !secondPhoneIsVerified) && driverHasBothPhones && resolutionIsPositive))

    return finalResolutionIsDisabled || !userHasOrder
  }

  public canNotSetPositiveResolution(firstPhoneIsVerified: boolean, secondPhoneIsVerified: boolean): boolean {
    const userHasOrder = this.isUserExecutor()
    const orderHasDriver = this.hasOrderDriver()
    const driverHasPhone = this.hasDriverPhone()
    const driverHasBothPhones = this.hasDriverBothPhones()

    const positiveResolutionIsDisabled =
      orderHasDriver &&
      ((!firstPhoneIsVerified && !secondPhoneIsVerified && driverHasPhone) ||
        ((!firstPhoneIsVerified || !secondPhoneIsVerified) && driverHasBothPhones))

    return !userHasOrder || positiveResolutionIsDisabled
  }
}
