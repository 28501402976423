import axios from 'axios'
import { captureException } from '@sentry/react'

import { transformResponse } from './axios'

export const fetchVersion = async () => {
  try {
    const result = await transformResponse(axios.get(`/version.json?t=${Date.now()}`))

    return result.version
  } catch (e) {
    captureException(e)
    return 1
  }
}
