import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import getStringFromSelectList from 'utils/getStringFromSelectList'

import Title from 'components/atoms/Title'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

const Customer: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <Title text="Заявка" />
          <div className="p-0">
            <p className="p-0 m-0">{getStringFromSelectList(form[OrderFields.ORDER_TYPE], selectLists?.orderType)}</p>
            <p className="p-0 m-0">
              <TextWithLineBreaks text={form[OrderFields.ORDER_TEXT]} />
            </p>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
