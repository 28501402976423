import { handleActions, Action } from 'redux-actions'
import { GetOrdersResponseData, Order } from 'types/order'
import { LoadingStatus } from 'types/loading'
import { User } from 'types/user'
import { SelectLists } from 'types/selectLists'

import { getOrderFilter } from 'utils/order'

import * as actions from './orders.actions'

export interface OrdersState {
  orders: Order[]
  loadingStatus: LoadingStatus
  loadingTakeOrderStatus: LoadingStatus
  loadingTakeNextOrderStatus: LoadingStatus
  takeOrderId: string | ''
  withoutExecutor: number | null
  numberOfOrdersPerDay: number | null
  orderFilter(order?: Order, currentUser?: User, selectLists?: SelectLists): boolean
  orderFilterId: string
  orderFilterByExpert: string
  search: string
}

export const initialOrdersState: OrdersState = {
  orders: [],
  loadingStatus: LoadingStatus.NONE,
  loadingTakeOrderStatus: LoadingStatus.NONE,
  loadingTakeNextOrderStatus: LoadingStatus.NONE,
  takeOrderId: '',
  withoutExecutor: null,
  numberOfOrdersPerDay: null,
  orderFilter: () => true,
  orderFilterId: '1',
  orderFilterByExpert: '',
  search: '',
}

const fetchOrdersRequest = (state: OrdersState, action: Action<string | void>): OrdersState => ({
  ...state,
  loadingStatus: LoadingStatus.PENDING,
  search: action.payload || '',
})

const fetchOrdersPreload = (state: OrdersState): OrdersState => ({
  ...state,
  loadingStatus: LoadingStatus.SUCCESS,
})

const fetchOrdersSuccess = (state: OrdersState, { payload }: Action<GetOrdersResponseData>): OrdersState => ({
  ...state,
  orders: payload!.ordersList,
  withoutExecutor: payload!.withoutExecutor,
  numberOfOrdersPerDay: payload!.numberOfOrdersPerDay,
  loadingStatus: LoadingStatus.SUCCESS,
})

const fetchOrdersFailure = (state: OrdersState): OrdersState => ({
  ...state,
  loadingStatus: LoadingStatus.FAILED,
})

const ordersChangeFilter = (state: OrdersState, action: Action<string>): OrdersState => ({
  ...state,
  orderFilter: getOrderFilter(action.payload),
  orderFilterId: action.payload,
})

const changeFilterByExpert = (state: OrdersState, action: Action<string>): OrdersState => ({
  ...state,
  orderFilterByExpert: action.payload,
})

const setInitialState = (): OrdersState => ({
  ...initialOrdersState,
})

const fetchTakeOrderRequest = (state: OrdersState): OrdersState => ({
  ...state,
  loadingTakeOrderStatus: LoadingStatus.PENDING,
})

const fetchTakeOrderSuccess = (state: OrdersState): OrdersState => ({
  ...state,
  loadingTakeOrderStatus: LoadingStatus.SUCCESS,
})

const fetchTakeOrderFailure = (state: OrdersState): OrdersState => ({
  ...state,
  loadingTakeOrderStatus: LoadingStatus.FAILED,
})

const fetchTakeNextOrderRequest = (state: OrdersState): OrdersState => ({
  ...state,
  loadingTakeNextOrderStatus: LoadingStatus.PENDING,
  takeOrderId: '',
})

const fetchTakeNextOrderSuccess = (state: OrdersState, action: Action<string>): OrdersState => ({
  ...state,
  loadingTakeNextOrderStatus: LoadingStatus.SUCCESS,
  takeOrderId: action.payload,
})

const fetchTakeNextOrderFailure = (state: OrdersState): OrdersState => ({
  ...state,
  loadingTakeNextOrderStatus: LoadingStatus.FAILED,
  takeOrderId: '',
})

export default handleActions<OrdersState, any>(
  {
    [actions.FETCH_ORDERS_REQUEST]: fetchOrdersRequest,
    [actions.FETCH_ORDERS_PRELOADED]: fetchOrdersPreload,
    [actions.FETCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
    [actions.FETCH_ORDERS_FAILURE]: fetchOrdersFailure,
    [actions.SEARCH_ORDERS_REQUEST]: fetchOrdersRequest,
    [actions.SEARCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
    [actions.SEARCH_ORDERS_FAILURE]: fetchOrdersFailure,
    [actions.ORDERS_CHANGE_FILTER]: ordersChangeFilter,
    [actions.CHANGE_ORDER_FILTER_BY_EXPERT]: changeFilterByExpert,
    [actions.SET_INITIAL_STATE_ORDERS]: setInitialState,
    [actions.FETCH_TAKE_ORDER_REQUEST]: fetchTakeOrderRequest,
    [actions.FETCH_TAKE_ORDER_SUCCESS]: fetchTakeOrderSuccess,
    [actions.FETCH_TAKE_ORDER_FAILURE]: fetchTakeOrderFailure,
    [actions.FETCH_TAKE_NEXT_ORDER_REQUEST]: fetchTakeNextOrderRequest,
    [actions.FETCH_TAKE_NEXT_ORDER_SUCCESS]: fetchTakeNextOrderSuccess,
    [actions.FETCH_TAKE_NEXT_ORDER_FAILURE]: fetchTakeNextOrderFailure,
  },
  initialOrdersState,
)
