import { createAction } from 'redux-actions'
import { CreateOrderResponseData, EditOrderData, GetOrderData, OrderData, OrderFormErrorProps } from 'types/order'

export const FETCH_CREATE_ORDER_REQUEST = 'FETCH_CREATE_ORDER_REQUEST'
export const fetchCreateOrderRequest = createAction<void>(FETCH_CREATE_ORDER_REQUEST)

export const FETCH_CREATE_ORDER_SUCCESS = 'FETCH_CREATE_ORDER_SUCCESS'
export const fetchCreateOrderSuccess = createAction<CreateOrderResponseData>(FETCH_CREATE_ORDER_SUCCESS)

export const FETCH_CREATE_ORDER_FAILURE = 'FETCH_CREATE_ORDER_FAILURE'
export const fetchCreateOrderFailure = createAction<Error>(FETCH_CREATE_ORDER_FAILURE)

export const CHANGE_ORDER_HANDLER = 'CHANGE_ORDER_HANDLER'
export const changeOrderHandler = createAction<{ [key: string]: string | boolean | number }>(CHANGE_ORDER_HANDLER)

export const SET_INITIAL_VALUES_ORDER_WRITE = 'SET_INITIAL_VALUES_ORDER_WRITE'
export const setInitialValuesOrderWrite = createAction<{ [key: string]: any }>(SET_INITIAL_VALUES_ORDER_WRITE)

export const SET_INITIAL_VALUES_ORDER_READ = 'SET_INITIAL_VALUES_ORDER_READ'
export const setInitialValuesOrderRead = createAction<{ [key: string]: any }>(SET_INITIAL_VALUES_ORDER_READ)

export const ERRORS_ORDER_HANDLER = 'ERRORS_ORDER_HANDLER'
export const errorsOrderHandler = createAction<{ [key: string]: OrderFormErrorProps }>(ERRORS_ORDER_HANDLER)

export const FETCH_ADD_FILE_ORDER_REQUEST = 'FETCH_ADD_FILE_ORDER_REQUEST'
export const fetchAddFileOrderRequest = createAction<void>(FETCH_ADD_FILE_ORDER_REQUEST)

export const FETCH_ADD_FILE_ORDER_SUCCESS = 'FETCH_ADD_FILE_ORDER_SUCCESS'
export const fetchAddFileOrderSuccess = createAction<number>(FETCH_ADD_FILE_ORDER_SUCCESS)

export const FETCH_ADD_FILE_ORDER_FAILURE = 'FETCH_ADD_FILE_ORDER_FAILURE'
export const fetchAddFileOrderFailure = createAction<void>(FETCH_ADD_FILE_ORDER_FAILURE)

export const FETCH_ADD_FILE_ORDER_FINISH = 'FETCH_ADD_FILE_ORDER_FINISH'
export const fetchAddFileOrderFinish = createAction<void>(FETCH_ADD_FILE_ORDER_FINISH)

export const ORDER_SET_INITIAL_STATE = 'ORDER_SET_INITIAL_STATE'
export const orderSetInitialState = createAction<void>(ORDER_SET_INITIAL_STATE)

export const FETCH_ORDER_GET_LOGISTIC_INFO_REQUEST = 'FETCH_ORDER_GET_LOGISTIC_INFO_REQUEST'
export const fetchOrderGetLogisticInfoRequest = createAction<void>(FETCH_ORDER_GET_LOGISTIC_INFO_REQUEST)

export const FETCH_ORDER_GET_LOGISTIC_INFO_SUCCESS = 'FETCH_ORDER_GET_LOGISTIC_INFO_SUCCESS'
export const fetchOrderGetLogisticInfoSuccess = createAction<void>(FETCH_ORDER_GET_LOGISTIC_INFO_SUCCESS)

export const FETCH_ORDER_GET_LOGISTIC_INFO_FAILURE = 'FETCH_ORDER_GET_LOGISTIC_INFO_FAILURE'
export const fetchOrderGetLogisticInfoFailure = createAction<Error>(FETCH_ORDER_GET_LOGISTIC_INFO_FAILURE)

export const FETCH_GET_ORDER_REQUEST = 'FETCH_GET_ORDER_REQUEST'
export const fetchGetOrderRequest = createAction<GetOrderData>(FETCH_GET_ORDER_REQUEST)

export const FETCH_GET_ORDER_SUCCESS = 'FETCH_GET_ORDER_SUCCESS'
export const fetchGetOrderSuccess = createAction<OrderData>(FETCH_GET_ORDER_SUCCESS)

export const FETCH_GET_ORDER_FAILURE = 'FETCH_GET_ORDER_FAILURE'
export const fetchGetOrderFailure = createAction<Error>(FETCH_GET_ORDER_FAILURE)

export const FETCH_SET_EXECUTOR_REQUEST = 'FETCH_SET_EXECUTOR_REQUEST'
export const fetchSetExecutorRequest = createAction<EditOrderData>(FETCH_SET_EXECUTOR_REQUEST)

export const FETCH_SET_EXECUTOR_SUCCESS = 'FETCH_SET_EXECUTOR_SUCCESS'
export const fetchSetExecutorSuccess = createAction<void>(FETCH_SET_EXECUTOR_SUCCESS)

export const FETCH_SET_EXECUTOR_FAILURE = 'FETCH_SET_EXECUTOR_FAILURE'
export const fetchSetExecutorFailure = createAction<Error>(FETCH_SET_EXECUTOR_FAILURE)

export const FETCH_SET_RESOLUTION_REQUEST = 'FETCH_SET_RESOLUTION_REQUEST'
export const fetchSetResolutionRequest = createAction<EditOrderData>(FETCH_SET_RESOLUTION_REQUEST)

export const FETCH_SET_RESOLUTION_SUCCESS = 'FETCH_SET_RESOLUTION_SUCCESS'
export const fetchSetResolutionSuccess = createAction<void>(FETCH_SET_RESOLUTION_SUCCESS)

export const FETCH_SET_RESOLUTION_FAILURE = 'FETCH_SET_RESOLUTION_FAILURE'
export const fetchSetResolutionFailure = createAction<Error>(FETCH_SET_RESOLUTION_FAILURE)

export const FETCH_EDIT_ORDER_REQUEST = 'FETCH_EDIT_ORDER_REQUEST'
export const fetchEditOrderRequest = createAction<EditOrderData>(FETCH_EDIT_ORDER_REQUEST)

export const FETCH_EDIT_ORDER_SUCCESS = 'FETCH_EDIT_ORDER_SUCCESS'
export const fetchEditOrderSuccess = createAction<void>(FETCH_EDIT_ORDER_SUCCESS)

export const FETCH_EDIT_ORDER_FAILURE = 'FETCH_EDIT_ORDER_FAILURE'
export const fetchEditOrderFailure = createAction<Error>(FETCH_EDIT_ORDER_FAILURE)

export const SET_INITIAL_CHANGED_FIELDS = 'SET_INITIAL_CHANGED_FIELDS'
export const setInitialChangedFields = createAction<void>(SET_INITIAL_CHANGED_FIELDS)

export const FETCH_CLARIFY_DATA_REQUEST = 'FETCH_CLARIFY_DATA_REQUEST'
export const fetchClarifyDataRequest = createAction<EditOrderData>(FETCH_CLARIFY_DATA_REQUEST)

export const FETCH_CLARIFY_DATA_SUCCESS = 'FETCH_CLARIFY_DATA_SUCCESS'
export const fetchClarifyDataSuccess = createAction<void>(FETCH_CLARIFY_DATA_SUCCESS)

export const FETCH_CLARIFY_DATA_FAILURE = 'FETCH_CLARIFY_DATA_FAILURE'
export const fetchClarifyDataFailure = createAction<Error>(FETCH_CLARIFY_DATA_FAILURE)
