import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'

import { useMenu } from 'hooks/use-menu'
import { statisticPath } from 'routes/url-constants'
import { Icon, IconType } from 'components/atoms/Icon'
import Header from 'components/molecules/Header'
import Menu from 'components/molecules/Menu'
import { StatisticBodyType } from 'components/pages/StatisticPage'

import classes from './style.module.css'

interface StatisticHeaderProps {
  statisticBodyType: StatisticBodyType
  setStatisticBodyType(type: StatisticBodyType): void
}

export const StatisticHeader: React.FC<StatisticHeaderProps> = ({ statisticBodyType, setStatisticBodyType }) => {
  const { menuLinks, isMenuOpen, toggleMenu } = useMenu(statisticPath)

  const onKeyPressHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') toggleMenu()
    },
    [toggleMenu],
  )

  const leftButton = useMemo(
    () => (
      <div className={classes.leftButton} onClick={toggleMenu} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
        <Icon type={IconType.Menu} width="36px" height="36px" />
      </div>
    ),
    [onKeyPressHandler, toggleMenu],
  )

  const headerMenuHandler = (event: any) => {
    if (Object.values(StatisticBodyType).includes(event.currentTarget.id)) setStatisticBodyType(event.currentTarget.id)
  }

  return (
    <>
      <div className="sticky-top">
        <Header leftButton={leftButton} headerName="Статистика">
          <div className={classNames('bg-white row', classes.header_menu)}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                'col-4 text-center',
                classes.header_menu_item,
                statisticBodyType === StatisticBodyType.FEED ? classes.active : '',
              )}
              onClick={headerMenuHandler}
              id={StatisticBodyType.FEED}
            >
              <span className={classes.header_menu_item_text}>Лента</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                'col-4 text-center',
                classes.header_menu_item,
                statisticBodyType === StatisticBodyType.EXPERT ? classes.active : '',
              )}
              onClick={headerMenuHandler}
              id={StatisticBodyType.EXPERT}
            >
              <span className={classes.header_menu_item_text}>Эксперты</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                'col-4 text-center',
                classes.header_menu_item,
                statisticBodyType === StatisticBodyType.QUALITY_CONTROL ? classes.active : '',
              )}
              onClick={headerMenuHandler}
              id={StatisticBodyType.QUALITY_CONTROL}
            >
              <span className={classes.header_menu_item_text}>Качество</span>
            </div>
          </div>
        </Header>
      </div>
      <Menu show={isMenuOpen} toggle={toggleMenu} navLinks={menuLinks} />
    </>
  )
}

export default StatisticHeader
