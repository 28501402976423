import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { OrderFields } from 'types/order'

import { fetchGetOrderRequest, orderSetInitialState } from 'store/order/order.actions'
import { checkVersionRequest } from 'store/version/version.actions'
import { autoChecksSetInitialState, fetchAutoChecksRequest } from 'store/autoChecks/autoChecks.actions'
import { fetchSelectListsRequest, setInitialStateSelectLists } from 'store/selectLists/selectLists.actions'

import EditOrderHeader from 'components/organisms/EditOrderHeader'
import EditOrderBody from 'components/organisms/EditOrderBody'

export const EditOrderPage: React.FC = () => {
  const dispatch = useDispatch()
  const { orderNumber } = useParams()

  useEffect(() => {
    dispatch(fetchGetOrderRequest({ [OrderFields.ORDER_NUMBER]: orderNumber }))
  }, [dispatch, orderNumber])

  useEffect(() => {
    dispatch(orderSetInitialState())
    dispatch(fetchSelectListsRequest())
    dispatch(checkVersionRequest())
    dispatch(autoChecksSetInitialState())
    dispatch(fetchAutoChecksRequest(orderNumber))

    return () => {
      dispatch(orderSetInitialState())
      dispatch(setInitialStateSelectLists())
      dispatch(autoChecksSetInitialState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <EditOrderHeader />
      <EditOrderBody />
    </div>
  )
}

export default EditOrderPage
