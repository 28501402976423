import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import { OrderFields, OrderTypeId } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'
import { FileDataType } from 'types/file'

import { useDocumentPreviews } from 'components/molecules/OrderFormSpace/hooks/useDocumentPreviews'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'

import { getDateForWeb, getDateFromServerDate } from 'utils/date'

import InputControl from 'components/molecules/InputControl'
import InputDate from 'components/molecules/InputDate'
import SwitchControl from 'components/molecules/SwitchControl'
import RelatedField from 'components/molecules/RelatedField'
import InputPhone from 'components/molecules/InputPhone'
import InputFio from 'components/molecules/InputFio'
import Gallery from 'components/molecules/Gallery'
import Title from 'components/atoms/Title'

import Checkbox from 'components/molecules/Checkbox'
import { DriverProps } from './index'

import classes from '../Logistic/style.module.css'

const Manager: React.FC<DriverProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const autoCheck = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.DRIVER)

  const checkChapters =
    autoCheck && autoCheck.length && autoCheck[0].checks.filter((check: any) => check.type === 'chapters').length
      ? autoCheck[0].checks.filter((check: any) => check.type === 'chapters')[0]
      : null

  const withoutDTHandler = useCallback(() => {
    onChange('', OrderFields.DRIVER_DATA_START_DT)
  }, [onChange])

  const onChangeHandlerWithUpperCase = useCallback(
    (value, id) => {
      onChange(value ? value.toUpperCase() : '', id)
    },
    [onChange],
  )

  const documentPreviews = useDocumentPreviews(form)

  return (
    <>
      <Title text="Водитель / Сотрудник в штат" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row d-flex align-content-start">
          <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-inline-block">
            <InputFio
              id={OrderFields.DRIVER_FIO}
              value={form[OrderFields.DRIVER_FIO]}
              isValid={errors[OrderFields.DRIVER_FIO].isValid}
              invalidMessage={errors[OrderFields.DRIVER_FIO].invalidMessage}
              onChangeHandler={onChange}
              className="col-12"
              label="ФИО:"
            />
          </div>
          <div className={classes.checkChapters}>
            {checkChapters && checkChapters.data ? (
              <div className="d-flex align-items-center w-100 justify-content-center">
                <span className="text-danger">{checkChapters.data.red}</span>/<span className="text-warning">{checkChapters.data.yellow}</span>/
                <span className="text-success">{checkChapters.data.green}</span>
              </div>
            ) : null}
          </div>
          <div className={classes.hasBelarusians}>
            <SwitchControl
              id={OrderFields.HAS_BELARUSIANS}
              value={form[OrderFields.HAS_BELARUSIANS]}
              onChangeHandler={onChange}
              label="В заявке есть белорусы"
            />
          </div>
          <div className="w-100">
            <RelatedField
              fieldName={OrderFields.DRIVER_FIO}
              fieldToCopy={OrderFields.LOGISTIC_FIO}
              relatedFields={{
                [OrderFields.DRIVER_TEL_1]: OrderFields.LOGISTIC_TEL,
                [OrderFields.DRIVER_TEL_2]: OrderFields.LOGISTIC_TEL_2,
              }}
            />
            <RelatedField
              fieldName={OrderFields.DRIVER_FIO}
              fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE}
              relatedFields={{
                [OrderFields.DRIVER_BITH]: OrderFields.LOGISTIC_FIRST_FACE_BITH,
                [OrderFields.DRIVER_TEL_1]: OrderFields.CARRIER_FIRST_FACE_PHONE_1,
                [OrderFields.DRIVER_TEL_2]: OrderFields.CARRIER_FIRST_FACE_PHONE_2,
              }}
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className={classes.controlWithGallery}>
            <div className="p-0 col-lg-4 col-md-6 col-sm-12">
              <InputDate
                id={OrderFields.DRIVER_BITH}
                value={form[OrderFields.DRIVER_BITH]}
                isValid={errors[OrderFields.DRIVER_BITH].isValid}
                invalidMessage={errors[OrderFields.DRIVER_BITH].invalidMessage}
                onChangeHandler={onChange}
                className="w-100"
                label="Д.Р."
              />
            </div>
            <div className={classes.galleryWrapper}>
              <Gallery
                images={[...(documentPreviews?.[FileDataType.PASSPORT_NAME] || []), ...(documentPreviews?.[FileDataType.PASSPORT_REG] || [])]}
                isRightSide
                classNameForItem={classes.galleryItem}
              />
            </div>
          </div>
          <RelatedField
            fieldName={OrderFields.DRIVER_BITH}
            fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE_BITH}
            relatedFields={{ [OrderFields.DRIVER_FIO]: OrderFields.LOGISTIC_FIRST_FACE }}
          />
        </li>
        {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.DRIVER_DOC_CHECK ? (
          <li className="list-group-item row">
            <div className="d-flex align-items-top flex-wrap">
              <div className="col-lg-4 col-md-6 col-sm-12 p-0">
                <InputControl
                  type="text"
                  id={OrderFields.DRIVER_SER_NUM}
                  value={form[OrderFields.DRIVER_SER_NUM]}
                  isValid={errors[OrderFields.DRIVER_SER_NUM].isValid}
                  invalidMessage={errors[OrderFields.DRIVER_SER_NUM].invalidMessage}
                  onChangeHandler={onChangeHandlerWithUpperCase}
                  className="w-100"
                  label="Паспорт, серия и номер:"
                />
              </div>
            </div>
          </li>
        ) : null}
        {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE ? (
          <>
            <li className="list-group-item row">
              <div className={classes.controlWithGallery}>
                <div className="p-0 col-lg-4 col-md-6 col-sm-12">
                  <InputControl
                    type="text"
                    id={OrderFields.DRIVER_DOC}
                    value={form[OrderFields.DRIVER_DOC]}
                    isValid={errors[OrderFields.DRIVER_DOC].isValid}
                    invalidMessage={errors[OrderFields.DRIVER_DOC].invalidMessage}
                    onChangeHandler={onChangeHandlerWithUpperCase}
                    className="w-100"
                    label="ВУ, номер:"
                  />
                </div>
                <div className={classes.galleryWrapper}>
                  <Gallery
                    images={[
                      ...(documentPreviews?.[FileDataType.DRIVER_LICENSE_FRONT] || []),
                      ...(documentPreviews?.[FileDataType.DRIVER_LICENSE_BACK] || []),
                    ]}
                    isRightSide
                    classNameForItem={classes.galleryItem}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputDate
                  id={OrderFields.DRIVER_DOC_DATE}
                  value={form[OrderFields.DRIVER_DOC_DATE]}
                  isValid={errors[OrderFields.DRIVER_DOC_DATE].isValid}
                  invalidMessage={errors[OrderFields.DRIVER_DOC_DATE].invalidMessage}
                  onChangeHandler={onChange}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="ВУ, дата выдачи:"
                />
              </div>
            </li>
          </>
        ) : null}
        {[OrderTypeId.STATE_DRIVER, OrderTypeId.LOGISTIC_AND_DRIVER, OrderTypeId.DRIVER_AND_TS, OrderTypeId.DRIVER_FOR_LOADING].includes(
          String(form[OrderFields.ORDER_TYPE]) as OrderTypeId,
        ) ? (
          <li className="list-group-item row">
            <a
              id="linkReputation"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://xn--90adear.xn--p1ai/check/driver#${(form[OrderFields.DRIVER_DOC] || '').replace(/\s/g, '')}+${
                getDateForWeb(getDateFromServerDate(form[OrderFields.DRIVER_DOC_DATE])) || ''
              }`}
            >
              {`https://гибдд.рф/check/driver#${(form[OrderFields.DRIVER_DOC] || '').replace(/\s/g, '')}+${
                getDateForWeb(getDateFromServerDate(form[OrderFields.DRIVER_DOC_DATE])) || ''
              }`}
            </a>

            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
              <Button
                className="p-0 col-12"
                disabled={form[OrderFields.IS_DRIVER_DOC_VALID] === null}
                onClick={() => {
                  onChange(null, OrderFields.IS_DRIVER_DOC_VALID)
                }}
              >
                Не проверено
              </Button>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '8px' }}>
              <Button
                className="p-0"
                style={{ flex: '1', marginRight: '4px' }}
                disabled={form[OrderFields.IS_DRIVER_DOC_VALID] === false}
                onClick={() => {
                  onChange(false, OrderFields.IS_DRIVER_DOC_VALID)
                }}
              >
                Недействительно
              </Button>
              <Button
                className="p-0"
                style={{ flex: '1', marginLeft: '4px' }}
                disabled={form[OrderFields.IS_DRIVER_DOC_VALID] === true}
                onClick={() => {
                  onChange(true, OrderFields.IS_DRIVER_DOC_VALID)
                }}
              >
                Действительно
              </Button>
            </div>
          </li>
        ) : null}
        {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE && String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.STATE_DRIVER ? (
          <li className="list-group-item row d-flex align-content-start">
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-inline-block">
              <InputDate
                id={OrderFields.DRIVER_DATA_START_DT}
                value={form[OrderFields.DRIVER_DATA_START_DT]}
                isValid={form[OrderFields.DRIVER_DATA_START_DT_STATUS] ? undefined : errors[OrderFields.DRIVER_DATA_START_DT].isValid}
                invalidMessage={errors[OrderFields.DRIVER_DATA_START_DT].invalidMessage}
                onChangeHandler={onChange}
                className="col-12"
                label="Дата начала ТД:"
                disabled={form[OrderFields.DRIVER_DATA_START_DT_STATUS]}
              />
            </div>
            <div className={classNames('d-inline-block', classes.driverDataStartDtStatus)}>
              <SwitchControl
                id={OrderFields.DRIVER_DATA_START_DT_STATUS}
                value={form[OrderFields.DRIVER_DATA_START_DT_STATUS]}
                onChangeHandler={(value: boolean, id?: string) => {
                  onChange(value, id)
                  withoutDTHandler()
                }}
                label="ТД не нужен"
              />
            </div>
          </li>
        ) : null}
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              id={OrderFields.DRIVER_TEL_1}
              value={form[OrderFields.DRIVER_TEL_1]}
              isValid={errors[OrderFields.DRIVER_TEL_1].isValid}
              invalidMessage={errors[OrderFields.DRIVER_TEL_1].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон 1:"
            />
          </div>
          <RelatedField fieldName={OrderFields.DRIVER_TEL_1} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
          <RelatedField fieldName={OrderFields.DRIVER_TEL_1} fieldToCopy={OrderFields.CARRIER_FIRST_FACE_PHONE_1} relatedFields={{}} />
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              id={OrderFields.DRIVER_TEL_2}
              value={form[OrderFields.DRIVER_TEL_2]}
              isValid={errors[OrderFields.DRIVER_TEL_2].isValid}
              invalidMessage={errors[OrderFields.DRIVER_TEL_2].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон 2:"
            />
          </div>
          <RelatedField fieldName={OrderFields.DRIVER_TEL_2} fieldToCopy={OrderFields.LOGISTIC_TEL_2} relatedFields={{}} />
          <RelatedField fieldName={OrderFields.DRIVER_TEL_2} fieldToCopy={OrderFields.CARRIER_FIRST_FACE_PHONE_2} relatedFields={{}} />
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <Checkbox
              id={OrderFields.DRIVER_CREDIT_RATING}
              value={form[OrderFields.DRIVER_CREDIT_RATING]}
              onChangeHandler={onChange}
              label="Заказать кредитный рейтинг на водителя, +120 руб к стоимости"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
