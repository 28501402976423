import React from 'react'
import classes from './style.module.css'

export const LoadingPlaceholder: React.FC = () => (
  <div className="d-flex justify-content-center">
    <div className={classes['lds-roller']}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)
