import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkVersionRequest } from 'store/version/version.actions'
import { selectCurrentBillingSettings } from 'store/billing/billing.selectors'

import BillingHeader from 'components/organisms/BillingHeader/BillingHeader'
import Billing from 'components/molecules/Billing'
import Balance from 'components/molecules/Billing/balance'

export const BillingPage: React.FC = () => {
  const dispatch = useDispatch()
  const settings = useSelector(selectCurrentBillingSettings)

  useEffect(() => {
    dispatch(checkVersionRequest())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <BillingHeader />
      <Balance />

      {/* {settings?.billingUrl ? <Billing url={settings?.billingUrl as string} /> : null} */}
      {settings?.billingFilesUrl ? <Billing url={settings?.billingFilesUrl as string} /> : null}
    </div>
  )
}

export default BillingPage
