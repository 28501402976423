import React from 'react'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

const WhatsappNumberLink: React.FC<{ phone: string }> = ({ phone }) =>
  phone ? (
    <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/${phone}`} className={classes.link}>
      <Icon type={IconType.Whatsapp} width="32px" height="32px" />
    </a>
  ) : null

export default WhatsappNumberLink
