import { apiDataGateway, transformResponse } from './axios'
import { DGQuery } from '../types/data-gateway'

export const fetchGetIdDataGateway = (data: DGQuery, token: string) =>
  transformResponse(
    apiDataGateway.post('/person/query', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchGetHTMLDataGateway = (id: string) => apiDataGateway.head(`/person/html/${id}`)

export const attachPDFToOrder = (orderNumber: string, data: DGQuery, token: string) =>
  transformResponse(
    apiDataGateway.post(`/person/pdf/order/${orderNumber}`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
