import React from 'react'

import { getPhoneForCopy, getPhoneForPeople } from 'utils/phone'

const PhoneNumberLink: React.FC<{ phone: string; withoutDecoration?: boolean }> = ({ phone, withoutDecoration = false }) => (
  <a target="_blank" rel="noopener noreferrer" href={`tel:${getPhoneForCopy(phone)}`}>
    {withoutDecoration ? phone : getPhoneForPeople(phone)}
  </a>
)

export default PhoneNumberLink
