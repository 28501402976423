import React from 'react'

import { FileDataEntity, FileDataType, FileForDocumentsForm } from 'types/file'

import FormItem from './FormItem'

import classes from './style.module.css'

interface FormProps {
  images: FileForDocumentsForm[]
  handleImageClick(image: FileForDocumentsForm): void
  title: string
  orderNumber: string
  items: {
    title: string
    data: { type?: FileDataType | null; entity?: FileDataEntity | null }
    index?: number
  }[]
}

export const Form: React.FC<FormProps> = ({ images, handleImageClick, title, orderNumber, items }) => {
  return (
    <div>
      <div className={classes.formTitle}>{title}</div>
      <div className={classes.form}>
        {items.map((item) => (
          <FormItem
            data={item.data}
            orderNumber={orderNumber}
            title={item.title}
            handleImageClick={handleImageClick}
            images={images}
            key={`${item?.index}-${item?.data?.type}`}
            index={item?.index || 1}
          />
        ))}
      </div>
    </div>
  )
}

export default Form
