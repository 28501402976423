import React from 'react'

import classes from '../../InputFile.module.css'

import GalleryItem from '../GalleryItem/GalleryItem'
import DownloadAll from '../DownloadAll/DownloadAll'

import { FileProps } from '../../../../../types/file'

interface GalleryProps {
  files: Map<File, FileProps>
  orderNumber?: string
  uploadedFiles?: FileProps[]
  uploadingFiles?: []
}

export const Gallery: React.FC<GalleryProps> = ({ files, uploadedFiles = [], orderNumber }) => {
  const numberOfFiles = (uploadedFiles.length || 0) + files.size
  return (
    <div className={classes.gallery} id="files_gallery">
      {numberOfFiles >= 2 && orderNumber ? <DownloadAll orderNumber={orderNumber} /> : null}
      {uploadedFiles.map((file: FileProps) => (
        <GalleryItem
          key={file.uuid}
          file={{
            uuid: file.uuid,
            name: file.name,
            isLoading: false,
            progress: 100,
            data: file.data,
            createdAt: file.createdAt,
          }}
        />
      ))}
      {Array.from(files.entries()).map(([file, renderingRules]) => (
        <GalleryItem key={`${file.lastModified}${file.name}`} file={renderingRules} />
      ))}
    </div>
  )
}

export default Gallery
