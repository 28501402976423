import React from 'react'
import classNames from 'classnames'
import { AutoCheckStatus } from 'types/auto-checks'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

interface StatusFlagProps {
  status: AutoCheckStatus
}

const AutoCheckStatusClassName = {
  [AutoCheckStatus.NONE]: classes.none,
  [AutoCheckStatus.FAIL]: classes.fail,
  [AutoCheckStatus.SUCCESS]: classes.success,
  [AutoCheckStatus.INDETERMINATE]: classes.indeterminate,
}

const StatusFlag: React.FC<StatusFlagProps> = ({ status }) => (
  <>
    {status === AutoCheckStatus.PENDING ? (
      <>
        <Icon type={IconType.Time} width="1rem" height="1rem" className={classNames(classes.pending)} />
      </>
    ) : (
      <Icon type={IconType.Flag} width="1rem" height="1rem" className={classNames(classes.flag, AutoCheckStatusClassName[status])} />
    )}
  </>
)

export default StatusFlag
