import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'
import { AutoCheck, AutoCheckStatus } from 'types/auto-checks'
import { coincidenceFromDGOnFioAndBirth } from 'types/data-gateway'
import { PropValue } from 'types/core/props'
import { PhoneDictionaryLinkSource, PhoneDictionaryLinkSourceName } from 'types/dictionary'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectLoadingAutoCheck } from 'store/autoChecks/autoChecks.selectors'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import ButtonGetDataFromDGOnTel from 'components/molecules/ButtonGetDataFromDGOnTel'
import WhatsappNumberLink from 'components/atoms/WhatsappNumberLink'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import CertificateLink from 'components/atoms/CertificateLink'
import ViberNumberLink from 'components/atoms/ViberNumberLink'
import PhoneRegion from 'components/atoms/PhoneRegion'
import BoldText from 'components/atoms/BoldText'
import Indent from 'components/atoms/Indent'

import classes from './style.module.css'

interface PhoneForExpertProps {
  field:
    | OrderFields.LOGISTIC_TEL
    | OrderFields.LOGISTIC_TEL_2
    | OrderFields.CARRIER_FIRST_FACE_PHONE_1
    | OrderFields.CARRIER_FIRST_FACE_PHONE_2
    | OrderFields.DRIVER_TEL_1
    | OrderFields.DRIVER_TEL_2
  check: AutoCheck
  methodOfVerification?: PropValue
  children?: React.ReactNode
}

export const PhoneForExpert: React.FC<PhoneForExpertProps> = ({ field, check, methodOfVerification, children }) => {
  const form = useSelector(selectOrderRead)

  const loadingAutoCheckStatus = useSelector(selectLoadingAutoCheck)

  const isLoadingChecks = loadingAutoCheckStatus === LoadingStatus.PENDING
  const hasCoincidences = check?.data?.coincidences?.length
  const isPositiveCheck = [AutoCheckStatus.SUCCESS, AutoCheckStatus.INDETERMINATE].includes(check?.status)

  if (!form[field]) return null

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '2px' }}>
        <div className="d-flex justify-content-between">
          <div className="d-inline-block">
            <TextWithPrefixAndCopy text={<BoldText text={<PhoneNumberLink phone={form[field]} />} />} prefix="Тел:" copiedText={form[field]} />
          </div>
          <div className="d-inline-block flex-grow-1">{children}</div>
        </div>
        <Indent>
          <div className="d-flex align-items-center align-middle">
            <WhatsappNumberLink phone={form[field]} />
            <ViberNumberLink phone={form[field]} />
          </div>

          <PhoneRegion phone={form[field]} />
          {methodOfVerification ? (
            <div style={{ color: 'rgb(153, 153, 153)' }}>
              Способ верификации: {PhoneDictionaryLinkSourceName[methodOfVerification as PhoneDictionaryLinkSource]}
            </div>
          ) : null}
          <ButtonGetDataFromDGOnTel tel={form[field]} check={check} />
          <CertificateLink type="phone" identityData={{ Телефон: form[field] }} />
        </Indent>
      </div>
      {!isLoadingChecks && hasCoincidences && !isPositiveCheck ? (
        <div className={classNames('text-secondary', 'small', classes.coincidencesList)} style={{ marginBottom: '8px', color: 'rgb(153, 153, 153)' }}>
          {check.data.coincidences.map((card: coincidenceFromDGOnFioAndBirth) => (
            <div key={`${card.lastName} ${card.firstName} ${card.middleName}`}>
              {`${card.lastName || ''} ${card.firstName || ''} ${card.middleName || ''}`}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default PhoneForExpert
