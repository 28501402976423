import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps, useLocation } from 'react-router'
import queryString from 'query-string'
import { isCurrentUserLoadingInProgress, selectCurrentUser } from 'store/users/users.selectors'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

export interface AuthRouteRouteProps extends RouteProps {
  redirectAuthorizedTo: string
}

export const AuthRoute: React.FC<AuthRouteRouteProps> = ({ redirectAuthorizedTo, ...routeProps }) => {
  const currentUser = useSelector(selectCurrentUser)
  const isLoadingInProgress = useSelector(isCurrentUserLoadingInProgress)
  const location = useLocation()
  const query = useMemo(() => queryString.parse(location.search), [location])

  if (isLoadingInProgress) {
    return <LoadingPlaceholder />
  }

  if (currentUser && query.backUrl) {
    window.location.href = `${query.backUrl}`
    return <LoadingPlaceholder />
  }

  if (currentUser) {
    return <Redirect to={redirectAuthorizedTo} />
  }

  return <Route {...routeProps} />
}
