import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { OrderFields, OrderSpaceName } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'
import { getTitleForCarrierByOrderType } from 'utils/title'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'
import { getStringWithSpacesFromFields } from 'utils/string'

import { onClickAnchor } from 'components/molecules/OrderSpace/spaces/Header/components/utils/block'
import ButtonGetDataFromDGOnFioAndBirth from 'components/molecules/ButtonGetDataFromDGOnFioAndBirth'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import { CarrierWarning } from 'components/molecules/OrderSpace/CarrierWarning'
import { getCheckByType } from 'components/molecules/OrderSpace/spaces/Driver/utils'
import FoundArticles from 'components/molecules/OrderSpace/components/FoundArticles'
import NegativeStatus from 'components/molecules/NegativeStatus'
import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'
import BacklitTextForCheckedValue from 'components/atoms/BacklitTextForCheckedValue'
import CertificateLink from 'components/atoms/CertificateLink'
import { Icon, IconType } from 'components/atoms/Icon'
import GrayText from 'components/atoms/GrayText'
import BoldText from 'components/atoms/BoldText'
import Indent from 'components/atoms/Indent'

const Carrier: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheckLogisticFirstFace = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.LOGISTIC_FIRST_FACE)

  const checkChaptersLogisticFirstFace = getCheckByType(autoCheckLogisticFirstFace, 'chapters')
  const negativeLogisticFirstFace = getCheckByType(autoCheckLogisticFirstFace, 'negative')

  return (
    <>
      <div className={classes.space}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span className={classNames(classes.icon, classes.anchor)} onClick={() => onClickAnchor(`__${OrderSpaceName.LOGISTIC}`)} role="button">
            <Icon type={IconType.BusinessCenter2} width="24px" height="24px" />
          </span>
        </div>
        <div className={classes.spaceBody}>
          <div style={{ marginBottom: '4px' }}>
            <GrayText text={getTitleForCarrierByOrderType(form[OrderFields.ORDER_TYPE])} />
          </div>
          <div className="d-flex flex-column" style={{ marginBottom: '8px', gap: '2px' }}>
            <BoldText text={form[OrderFields.LOGISTIC_NAME]} />
            <Indent>
              <BacklitTextForCheckedValue field={OrderFields.LOGISTIC_OGRN} />
              <CertificateLink type="company" identityData={{ ИНН: form[OrderFields.LOGISTIC_INN] }} />
            </Indent>
          </div>

          <div className="d-flex flex-column" style={{ marginBottom: '8px', gap: '2px' }}>
            <div>
              <TextWithPrefixAndCopy
                text={
                  <div className="d-inline-block">
                    <BoldText text={form[OrderFields.LOGISTIC_FIRST_FACE]} />
                  </div>
                }
                prefix="1е лицо:"
                copiedText={getStringWithSpacesFromFields(form[OrderFields.LOGISTIC_FIRST_FACE], form[OrderFields.LOGISTIC_FIRST_FACE_BITH])}
              />
              {form[OrderFields.LOGISTIC_FIRST_FACE_BITH] ? <BoldText text={form[OrderFields.LOGISTIC_FIRST_FACE_BITH]} /> : null}
            </div>
            <Indent>
              <div className="d-inline-block">
                <ButtonGetDataFromDGOnFioAndBirth
                  fio={form[OrderFields.LOGISTIC_FIRST_FACE]}
                  date={form[OrderFields.LOGISTIC_FIRST_FACE_BITH]}
                  bases={checkChaptersLogisticFirstFace ? checkChaptersLogisticFirstFace.data : null}
                />
              </div>
              <NegativeStatus check={negativeLogisticFirstFace} hideEmpty />
              <FoundArticles check={negativeLogisticFirstFace} />
            </Indent>
          </div>

          <CarrierWarning />
        </div>
      </div>
    </>
  )
}

export default Carrier
