import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ordersChangeFilter } from 'store/orders/orders.actions'
import { UserRole } from 'types/user'
import { useOrderListFilterLocation } from './order-list-filter-location-hook'

export const useOrderListFilter = (userRole: UserRole) => {
  const dispatch = useDispatch()
  const storage = useOrderListFilterLocation(userRole)
  const [value, setValue] = useState(storage.get())

  useEffect(() => {
    const initialValue = storage.get()
    dispatch(ordersChangeFilter(initialValue))
  }, [userRole, dispatch, storage])

  const onChange = useCallback(
    (newValue: string) => {
      storage.set(newValue)
      setValue(newValue)
      dispatch(ordersChangeFilter(newValue))
    },
    [storage, dispatch],
  )

  return {
    value,
    onChange,
  }
}
