import { Action, handleActions } from 'redux-actions'
import { GetFio } from 'types/dadata'
import { LoadingStatus } from 'types/loading'

import * as actions from './dadata.actions'

export interface DadataState {
  loadingGetFio: LoadingStatus
  dataFio: {
    [text: string]: { data: string[] }
  }
}

export const initialDadataState: DadataState = {
  loadingGetFio: LoadingStatus.NONE,
  dataFio: {},
}

const fetchGetFioPending = (state: DadataState, action: Action<GetFio>): DadataState => ({
  ...state,
  loadingGetFio: LoadingStatus.PENDING,
  dataFio: {
    ...state.dataFio,
    [action.payload.text.trim().toLowerCase()]: { data: [] },
  },
})

const fetchGetFioSuccess = (state: DadataState, action: Action<{ data: string[]; text: string; id: string }>): DadataState => ({
  ...state,
  loadingGetFio: LoadingStatus.SUCCESS,
  dataFio: {
    ...state.dataFio,
    [action.payload.text.trim().toLowerCase()]: { data: action.payload.data },
  },
})

const fetchGetFioFailure = (state: DadataState): DadataState => ({
  ...state,
  loadingGetFio: LoadingStatus.FAILED,
})

export default handleActions<DadataState, any>(
  {
    [actions.FETCH_GET_FIO_PENDING]: fetchGetFioPending,
    [actions.FETCH_GET_FIO_SUCCESS]: fetchGetFioSuccess,
    [actions.FETCH_GET_FIO_FAILURE]: fetchGetFioFailure,
  },
  initialDadataState,
)
