import { AppState } from 'store/root.reducer'

export const selectOrdersState = (state: AppState) => state?.orders

export const selectOrders = (state: AppState) => selectOrdersState(state).orders

export const selectLoadingGetOrdersStatus = (state: AppState) => selectOrdersState(state).loadingStatus

export const selectWithoutExecutor = (state: AppState) => selectOrdersState(state).withoutExecutor

export const selectNumberOfOrdersPerDay = (state: AppState) => selectOrdersState(state).numberOfOrdersPerDay

export const selectOrderFilter = (state: AppState) => selectOrdersState(state)?.orderFilter

export const selectOrderFilterId = (state: AppState) => selectOrdersState(state)?.orderFilterId

export const selectOrderFilterByExpert = (state: AppState) => selectOrdersState(state)?.orderFilterByExpert

export const selectLoadingTakeOrderStatus = (state: AppState) => selectOrdersState(state)?.loadingTakeOrderStatus

export const selectLoadingTakeNextOrderStatus = (state: AppState) => selectOrdersState(state)?.loadingTakeNextOrderStatus

export const selectTakeOrderId = (state: AppState) => selectOrdersState(state)?.takeOrderId

export const selectSearchOrders = (state: AppState) => selectOrdersState(state)?.search
