import React from 'react'

export const StateDriverHintText = () => (
  <>
    ВОДИТЕЛЬ в штат
    <br />
    <ul>
      <li>паспорт</li>
      <li>водительское удостоверение</li>
    </ul>
  </>
)

export default StateDriverHintText
