import {
  GetDictionaryEntryRequest,
  GetDictionaryEntryResponse,
  LinkDictionaryEntryRequest,
  LinkDictionaryEntryResponse,
  UnlinkDictionaryEntryRequest,
} from 'types/dictionary'

import { transformResponse, v3Api } from './axios'

export const getDictionaryLinkedEntries = (data: GetDictionaryEntryRequest, token: string): Promise<GetDictionaryEntryResponse> =>
  transformResponse(
    v3Api.post('/dictionary/get', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const linkDictionaryEntry = (data: LinkDictionaryEntryRequest, token: string): Promise<LinkDictionaryEntryResponse> =>
  transformResponse(
    v3Api.post('/dictionary/link', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const unlinkDictionaryEntry = (data: UnlinkDictionaryEntryRequest, token: string): Promise<LinkDictionaryEntryResponse> =>
  transformResponse(
    v3Api.post('/dictionary/unlink', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
