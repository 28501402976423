import React, { useCallback, useMemo } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import classNames from 'classnames'

import { OrderFormSpaceName } from 'constants/order'

import { useValidation } from 'utils/useValidation'
import { getDateForServer, getTomorrow } from 'utils/date'

import { ordersListPath } from 'routes/url-constants'

import { fetchCreateOrderRequest } from 'store/order/order.actions'
import { selectCurrentUser } from 'store/users/users.selectors'

import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'
import { Feature } from 'types/user'

import {
  selectCreateOrderResponse,
  selectOrderFetchCreateStatus,
  selectOrderFilesStatus,
  selectOrderFormErrors,
  selectOrderLogisticInfoStatus,
  selectOrderWrite,
} from 'store/order/order.selectors'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'

import { BackLink } from 'components/atoms/BackLink'
import Header from 'components/molecules/Header'
import AnchorNavbar from 'components/molecules/AnchorNavbar'
import { getSpaces } from 'components/organisms/OrderForm/utils/getSpaces'
import getCreateOrderRules from 'components/organisms/OrderForm/utils/getSubmitOrderRules'

import classes from './style.module.css'

export const CreateOrderHeader: React.FC = () => {
  const dispatchStore = useDispatch()
  const loadingFetchStatus = useSelector(selectOrderFetchCreateStatus)
  const loadingFilesStatus = useSelector(selectOrderFilesStatus)
  const loadingSelectListsStatus = useSelector(selectLoadingSelectListsStatus)
  const loadingOrderLogisticInfoStatus = useSelector(selectOrderLogisticInfoStatus)
  const responseCreate = useSelector(selectCreateOrderResponse)
  const currentUser = useSelector(selectCurrentUser)
  const form = useSelector(selectOrderWrite)

  const errors = useSelector(selectOrderFormErrors)
  const spaces = useMemo(() => getSpaces(form[OrderFields.ORDER_TYPE], true, currentUser!, errors), [form, currentUser, errors])

  const { validate } = useValidation(getCreateOrderRules(form, currentUser!, spaces))

  const onSubmit = useCallback(() => {
    if (validate({ ...form, tomorrow: getDateForServer(getTomorrow()) }).passes()) dispatchStore(fetchCreateOrderRequest())
  }, [dispatchStore, validate, form])

  const leftButton = useMemo(() => {
    if (
      loadingFetchStatus === LoadingStatus.PENDING ||
      loadingFilesStatus === LoadingStatus.PENDING ||
      loadingOrderLogisticInfoStatus === LoadingStatus.PENDING
    )
      return <BackLink />
  }, [loadingFetchStatus, loadingFilesStatus, loadingOrderLogisticInfoStatus])

  if (responseCreate && responseCreate.fields) {
    return <Redirect to={`${responseCreate.fields[OrderFields.ORDER_NUMBER]}`} />
  }

  const isLoading = loadingSelectListsStatus === LoadingStatus.PENDING
  const orderHasSpaces = spaces?.length > 2
  const userIsBlocked = currentUser!.features?.[Feature.PAYMENT_STATE] === 'blocked'

  return (
    <>
      <Header leftButton={leftButton} headerName="Новая заявка">
        <div className={classNames('sticky-top', classes.header_body)}>
          <div className="row bg-dark" style={{ padding: '8px' }}>
            <div className={classNames('col-6', classes.header_body_leftButton)}>
              <Link to={ordersListPath}>
                <Button
                  type="button"
                  className="btn btn-light col-md-6 col-sm-8 col-xs-12"
                  disabled={
                    loadingFetchStatus === LoadingStatus.PENDING ||
                    loadingFilesStatus === LoadingStatus.PENDING ||
                    loadingOrderLogisticInfoStatus === LoadingStatus.PENDING
                  }
                >
                  Отмена
                </Button>
              </Link>
            </div>
            <div className={classNames('col-6 text-right', classes.header_body_rightButton)}>
              <Button
                type="button"
                className="btn btn-primary col-md-6 col-sm-8 col-xs-12"
                onClick={onSubmit}
                disabled={
                  loadingFetchStatus === LoadingStatus.PENDING ||
                  loadingFilesStatus === LoadingStatus.PENDING ||
                  loadingOrderLogisticInfoStatus === LoadingStatus.PENDING ||
                  !validate({ ...form, tomorrow: getDateForServer(getTomorrow()) }).passes()
                }
              >
                {loadingFetchStatus === LoadingStatus.PENDING ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    &nbsp; Загрузка..
                  </div>
                ) : (
                  <>Создать</>
                )}
              </Button>
            </div>
          </div>
          {!isLoading && orderHasSpaces && !userIsBlocked ? (
            <AnchorNavbar items={spaces.map((space) => ({ name: OrderFormSpaceName[space], id: space }))} />
          ) : null}
        </div>
      </Header>
    </>
  )
}

export default CreateOrderHeader
