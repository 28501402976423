// TODO Remove! Use getStringWithSpacesFromFields
const getStringFromTwoFields = (firstValue: string, secondValue: string) => {
  let text = ''
  if (firstValue) text = firstValue
  if (firstValue && secondValue) text += ' '
  if (secondValue) text += secondValue
  return text
}

export default getStringFromTwoFields
