import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'

import InputControl from 'components/molecules/InputControl'
import InputPhone from 'components/molecules/InputPhone'
import RelatedField from 'components/molecules/RelatedField'
import InputFio from 'components/molecules/InputFio'
import Title from 'components/atoms/Title'

import { ContactProps } from './index'

const Manager: React.FC<ContactProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  return (
    <>
      <Title text="Контакты" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputFio
              value={form[OrderFields.LOGISTIC_FIO]}
              isValid={errors[OrderFields.LOGISTIC_FIO].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_FIO].invalidMessage}
              id={OrderFields.LOGISTIC_FIO}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="ФИО контакта:"
            />
          </div>
          <RelatedField fieldName={OrderFields.LOGISTIC_FIO} fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE} />
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.LOGISTIC_TEL]}
              isValid={errors[OrderFields.LOGISTIC_TEL].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_TEL].invalidMessage}
              id={OrderFields.LOGISTIC_TEL}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон контакта:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.LOGISTIC_TEL_2]}
              isValid={errors[OrderFields.LOGISTIC_TEL_2].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_TEL_2].invalidMessage}
              id={OrderFields.LOGISTIC_TEL_2}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Второй телефон контакта:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="email"
              value={form[OrderFields.CARRIER_EMAIL]}
              isValid={errors[OrderFields.CARRIER_EMAIL].isValid}
              invalidMessage={errors[OrderFields.CARRIER_EMAIL].invalidMessage}
              id={OrderFields.CARRIER_EMAIL}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Email:"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
