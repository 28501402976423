import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'

if (process.env.NODE_ENV === 'development') {
  console.log(`API domain: ${process.env.REACT_APP_SERVER}`)
  console.log(`API AUTO_CHECKS domain: ${process.env.REACT_APP_AUTO_CHECKS_SERVER}`)
  console.log(`API FILES_SERVER domain: ${process.env.REACT_APP_FILES_SERVER}`)
  console.log(`API DATA_GATEWAY domain: ${process.env.REACT_APP_DATA_GATEWAY}`)
}

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SERVER,
  withCredentials: true,
}

const configAutoChecks: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_AUTO_CHECKS_SERVER,
  withCredentials: true,
}

const configFiles: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_FILES_SERVER,
  withCredentials: true,
}

const configDataGateway: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_DATA_GATEWAY,
  withCredentials: true,
}

const v3Config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_V3_SERVER,
  withCredentials: true,
}

export const api: AxiosInstance = axios.create(config)
export const apiAutoChecksServer: AxiosInstance = axios.create(configAutoChecks)
export const apiFiles: AxiosInstance = axios.create(configFiles)
export const apiDataGateway: AxiosInstance = axios.create(configDataGateway)
export const v3Api: AxiosInstance = axios.create(v3Config)

export const transformResponse = async <ResponseDataType>(promise: Promise<AxiosResponse<ResponseDataType>>) => {
  const response: AxiosResponse = await promise
  return response.data
}
