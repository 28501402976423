import { put, select, call, delay } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AutoCheckStatus, GetAutoCheckData } from 'types/auto-checks'

import { fetchGetAutoCheck } from 'requests/auto-checks'

import { fetchAutoChecksSuccess, fetchAutoChecksFailure } from '../autoChecks.actions'

const DELAY_PING_AUTO_CHECK = Number(process.env.REACT_APP_DELAY_PING_AUTO_CHECK || 2000)
const MAX_REQUESTS = Number(process.env.REACT_APP_MAX_REQUESTS_TO_AUTO_CHECK || 2)

export function* fetchAutoCheckSaga(action: Action<any>) {
  try {
    const token = yield select(selectToken)

    let data: GetAutoCheckData | null = null
    let count = 1

    while ((!data || data.status === AutoCheckStatus.PENDING) && count <= MAX_REQUESTS) {
      try {
        data = yield call(fetchGetAutoCheck, typeof action.payload === 'string' ? action.payload : action.payload.orderNumber, token)
      } catch (err) {
        console.error(`[fetchAutoCheckSaga]: ${err}`)
        captureException(err)
      }

      if (data) yield put(fetchAutoChecksSuccess(data))

      count += 1

      if (!data || data.status === AutoCheckStatus.PENDING) {
        yield delay(DELAY_PING_AUTO_CHECK)
      }
    }
  } catch (error) {
    yield put(fetchAutoChecksFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение статуса автопроверок',
        withoutDefaultAlert: true,
        withoutAlertCase: {
          404: true,
        },
      }),
    )
    console.error(`[fetchAutoCheckSaga]: ${error}`)
    captureException(error)
  }
}
