import { Action, handleActions } from 'redux-actions'

import { User, UserSettings } from 'types/user'
import { LoadingStatus } from 'types/loading'

import * as actions from './users.actions'

export interface UsersState {
  currentUser: User | null
  loadingCurrentUser: LoadingStatus
  loadingLogin: LoadingStatus
  loadingUpdateUserSettings: LoadingStatus
}

export const initialUsersState: UsersState = {
  currentUser: null,
  loadingCurrentUser: LoadingStatus.NONE,
  loadingLogin: LoadingStatus.NONE,
  loadingUpdateUserSettings: LoadingStatus.NONE,
}

const fetchCurrentUserRequest = (state: UsersState): UsersState => ({
  ...state,
  loadingCurrentUser: LoadingStatus.PENDING,
})

const fetchCurrentUserPreload = (state: UsersState): UsersState => ({
  ...state,
  loadingCurrentUser: LoadingStatus.SUCCESS,
})

const fetchCurrentUserSuccess = (state: UsersState, { payload }: Action<User>): UsersState => ({
  ...state,
  currentUser: payload,
  loadingCurrentUser: LoadingStatus.SUCCESS,
})

const fetchCurrentUserFailure = (state: UsersState): UsersState => ({
  ...state,
  loadingCurrentUser: LoadingStatus.FAILED,
})

const fetchLoginRequest = (state: UsersState): UsersState => ({
  ...state,
  loadingLogin: LoadingStatus.PENDING,
})

const fetchLoginSuccess = (state: UsersState): UsersState => ({
  ...state,
  currentUser: null,
  loadingLogin: LoadingStatus.SUCCESS,
})

const fetchLoginFailure = (state: UsersState): UsersState => ({
  ...state,
  loadingLogin: LoadingStatus.FAILED,
})

const fetchLogoutRequest = (state: UsersState): UsersState => ({
  ...state,
})

const fetchLogoutSuccess = (state: UsersState): UsersState => ({
  ...state,
  currentUser: null,
  loadingLogin: LoadingStatus.NONE,
})

const fetchLogoutFailure = (state: UsersState): UsersState => ({
  // TODO
  ...state,
})

const updateUserSettingsRequest = (state: UsersState): UsersState => ({
  ...state,
  loadingUpdateUserSettings: LoadingStatus.PENDING,
})

const updateUserSettingsSuccess = (state: UsersState, action: Action<UserSettings>): UsersState => ({
  ...state,
  loadingUpdateUserSettings: LoadingStatus.SUCCESS,
  currentUser: state.currentUser
    ? {
        ...state.currentUser,
        settings: {
          ...state.currentUser?.settings,
          ...action?.payload,
        },
      }
    : null,
})

const updateUserSettingsFailure = (state: UsersState): UsersState => ({
  ...state,
  loadingUpdateUserSettings: LoadingStatus.FAILED,
})

export default handleActions<UsersState, any>(
  {
    [actions.FETCH_CURRENT_USER_REQUEST]: fetchCurrentUserRequest,
    [actions.FETCH_CURRENT_USER_PRELOADED]: fetchCurrentUserPreload,
    [actions.FETCH_CURRENT_USER_SUCCESS]: fetchCurrentUserSuccess,
    [actions.FETCH_CURRENT_USER_FAILURE]: fetchCurrentUserFailure,
    [actions.FETCH_LOGIN_REQUEST]: fetchLoginRequest,
    [actions.FETCH_LOGIN_SUCCESS]: fetchLoginSuccess,
    [actions.FETCH_LOGIN_FAILURE]: fetchLoginFailure,
    [actions.FETCH_LOGOUT_REQUEST]: fetchLogoutRequest,
    [actions.FETCH_LOGOUT_SUCCESS]: fetchLogoutSuccess,
    [actions.FETCH_LOGOUT_FAILURE]: fetchLogoutFailure,
    [actions.UPDATE_USER_SETTINGS_REQUEST]: updateUserSettingsRequest,
    [actions.UPDATE_USER_SETTINGS_SUCCESS]: updateUserSettingsSuccess,
    [actions.UPDATE_USER_SETTINGS_FAILURE]: updateUserSettingsFailure,
  },
  initialUsersState,
)
