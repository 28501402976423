import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { addAlert } from 'store/alerts/alerts.actions'
import { AlertVariant } from 'types/alert'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

interface AutoChecksProps {
  orderNumber: string
  token: string | undefined
}

const AutoChecks: React.FC<AutoChecksProps> = ({ orderNumber, token = '' }) => {
  const dispatchStore = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    dispatchStore(
      addAlert({
        header: 'Автопроверки',
        text: 'Не удалось загрузить автопроверки',
        lifetime: 6000,
        variant: AlertVariant.DANGER,
      }),
    )
  }

  const src = `${process.env.REACT_APP_AUTO_CHECKS_WEB}/?orderNumber=${orderNumber}`

  return (
    <div className={classes.container}>
      <div className={classes.autoChecks}>
        <iframe
          key={src}
          src={`${src}&token=${token}&randomNumber=${Date.now()}`}
          width="100%"
          height="100%"
          frameBorder="0"
          className={classNames(isLoaded ? classes.visible : classes.hidden)}
          title="Автопроверки"
          onLoad={onLoad}
          onError={onError}
        />
        {isLoaded ? null : <LoadingPlaceholder />}
      </div>
    </div>
  )
}

export default AutoChecks
