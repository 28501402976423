import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { selectCurrentUser } from 'store/users/users.selectors'

import { Icon, IconType } from 'components/atoms/Icon'
import { NavLinkProps } from 'types/navLink'

import classes from './style.module.css'

interface MenuProps {
  show: boolean
  toggle(): void
  navLinks: NavLinkProps[]
}

export const Menu: React.FC<MenuProps> = ({ show, toggle, navLinks }) => {
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const onKeyPressHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') toggle()
  }

  const currentUser = useSelector(selectCurrentUser)

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div className={classNames(classes.container, show ? classes.visible : '')} onClick={toggle}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <nav className={classNames(classes.menu, show ? classes.visible : '', 'bg-dark')} onClick={stopPropagation}>
        <div>
          <div className={classNames(classes.header, 'p-2')}>
            <div className={classes.header_close} onClick={toggle} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
              <Icon type={IconType.Close} width="36px" height="36px" />
            </div>
            <div className="text-center text-light w-100">
              <h4 className="m-1">{currentUser!.name}</h4>
            </div>
          </div>
          <hr />
          <ul className={classNames('list-group', classes.nav_links)} role="menu">
            {navLinks
              .filter((link) => !link.hidden)
              .map((link) => (
                <li
                  className={classNames('list-group-item', classes.nav_link, link.isCurrent ? classes.disabled_link : classes.active_link)}
                  key={link.path}
                  role="menuitem"
                >
                  {link.isCurrent ? (
                    <div>{link.name}</div>
                  ) : (
                    <>
                      {link.isExternalLink ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a href={link.path} className="col-12" onClick={link.onClick} target="_blank" rel="noopener noreferrer">
                          {link.name}
                        </a>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link to={link.path} className="col-12" onClick={link.onClick} role="link">
                          {link.name}
                        </Link>
                      )}
                    </>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Menu
