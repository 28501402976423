import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchLastAutoChecks } from 'requests/auto-checks'
import { AutoCheckStatus } from 'types/auto-checks'
import { fetchAutoChecksFailure, fetchAutoChecksSuccess } from '../autoChecks.actions'

export function* fetchLastAutoCheckSaga() {
  try {
    const token = yield select(selectToken)
    const data = yield call(fetchLastAutoChecks, token)
    if (data) yield put(fetchAutoChecksSuccess({ checks: data, status: AutoCheckStatus.NONE }))
  } catch (error) {
    yield put(fetchAutoChecksFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение автопроверок',
        withoutDefaultAlert: true,
        withoutAlertCase: {
          404: true,
        },
      }),
    )
    console.error(`[fetchAutoCheckSaga]: ${error}`)
    captureException(error)
  }
}
