import React, { useCallback, useMemo } from 'react'

import { Icon, IconType } from 'components/atoms/Icon'
import Header from 'components/molecules/Header'
import Menu from 'components/molecules/Menu'
import { useMenu } from 'hooks/use-menu'
import { expertsOnlinePath } from 'routes/url-constants'

import classes from './style.module.css'

export const UserSchedulerHeader: React.FC = () => {
  const { menuLinks, isMenuOpen, toggleMenu } = useMenu(expertsOnlinePath)

  const onKeyPressHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') toggleMenu()
    },
    [toggleMenu],
  )

  const leftButton = useMemo(
    () => (
      <div className={classes.leftButton} onClick={toggleMenu} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
        <Icon type={IconType.Menu} width="36px" height="36px" />
      </div>
    ),
    [onKeyPressHandler, toggleMenu],
  )

  return (
    <>
      <div className="sticky-top">
        <Header leftButton={leftButton} headerName="Онлайн пользователи" />
      </div>
      <Menu show={isMenuOpen} toggle={toggleMenu} navLinks={menuLinks} />
    </>
  )
}

export default UserSchedulerHeader
