import React from 'react'

import OrderListBodyHeader from 'components/molecules/OrderListBodyHeader'
import OrderList from 'components/molecules/OrderList'

export const OrderListBody: React.FC = () => {
  return (
    <div className="list-group w-100 position-relative" style={{ overflow: 'hidden' }}>
      <OrderListBodyHeader />
      <OrderList />
    </div>
  )
}

export default OrderListBody
