import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectToken } from 'store/users/users.selectors'

import { InlineIcon } from '@iconify/react'
import fileZipOutlined from '@iconify/icons-ant-design/file-zip-outlined'

import { getDateFromServerDate, getDateTimeForWeb, setLocalTimezone } from 'utils/date'

import classes from '../../InputFile.module.css'

import { FileProps } from '../../../../../types/file'

export const GalleryItem: React.FC<{ file: FileProps }> = ({ file }) => {
  const { uuid, name, isLoading, progress, createdAt } = file
  const token = useSelector(selectToken)
  if (uuid)
    return (
      <a href={`${process.env.REACT_APP_FILES_SERVER}/file/${uuid}?token=${token}`} target="_blank" rel="noopener noreferrer">
        <div className={classNames(classes.galleryItem, uuid ? classes.hasButDownload : '')}>
          <div className="d-flex">
            <div style={{ flex: 1, minWidth: 0 }}>
              <div style={{ display: 'flex' }}>
                <InlineIcon icon={fileZipOutlined} width={24} height={24} style={{ marginRight: '8px' }} />
                <div className={classes.galleryItemFileName}>{name}</div>
              </div>
            </div>
            {createdAt ? <span className="text-secondary">{getDateTimeForWeb(setLocalTimezone(getDateFromServerDate(createdAt || '')))}</span> : null}
          </div>
          {isLoading ? <progress max={100} value={progress} className={classes.progressBar} /> : null}
        </div>
      </a>
    )
  return (
    <div className={classNames(classes.galleryItem, uuid ? classes.hasButDownload : '')}>
      <div>
        <InlineIcon icon={fileZipOutlined} width={24} height={24} style={{ marginRight: '8px' }} />
        <span>{name}</span>
      </div>
      {isLoading ? <progress max={100} value={progress} className={classes.progressBar} /> : null}
    </div>
  )
}

export default GalleryItem
