import { User, UserRole } from 'types/user'
import { Order, ResolutionId } from 'types/order'

export const ICON_UPDATE_SIZE = 30

export const getIconUpdateOpacity = (startPosition: number, currentPosition: number) => {
  if (!startPosition || currentPosition - startPosition <= 0) return '0'
  if (currentPosition - startPosition <= 9) return `.0${currentPosition - startPosition}`
  if (currentPosition - startPosition >= window.innerHeight / 6) return '1'
  return `.${Math.trunc((currentPosition - startPosition) / (window.innerHeight / 6 / 100))}`
}

export const getIconUpdateTop = (startPosition: number, currentPosition: number) => {
  if (!startPosition) return `${-ICON_UPDATE_SIZE}`
  if (currentPosition - startPosition > window.innerHeight / 6) return `${window.innerHeight / 6 - ICON_UPDATE_SIZE}`
  return `${currentPosition - startPosition - ICON_UPDATE_SIZE}`
}

export const isFreeOrder = (order: Order, currentUser: User | null) => {
  if (currentUser!.role === UserRole.MANAGER) {
    return (
      (String(order.resolution) === ResolutionId.NEW_ORDER && (!order.executorL1 || String(currentUser?.id) === String(order.executorL1))) ||
      ((String(order.resolution) === ResolutionId.L1_PROCESSING ||
        String(order.resolution) === ResolutionId.CLARIFY_DATA ||
        String(order.resolution) === ResolutionId.DRIVER_IS_NOT_RESPONDING) &&
        !order.executorL1)
    )
  }

  if (currentUser!.role === UserRole.EXPERT) {
    return (
      (String(order.resolution) === ResolutionId.FOR_E || String(order.resolution) === ResolutionId.NEW_ORDER) &&
      (!order.executorExpert || String(order.executorExpert) === String(currentUser!.id))
    )
  }

  return false
}
