import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { fetchLogoutRequest } from 'store/users/users.actions'
import { AlertVariant } from 'types/alert'
import { FileData, FileProps } from 'types/file'

import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import classes from './InputFile.module.css'

import addFile, { addFileResponseProps } from './addFile'

import Gallery from './components/Gallery/Gallery'
import Form from './components/Form/Form'

interface InputFileProps {
  id: string
  data?: FileData
  orderNumber?: string
  uploadedFiles?: FileProps[]
  onStart?(): void
  onEnd?(): void
  handleResponse(response: addFileResponseProps, fileName: string, code: number): void
  multiple?: boolean
  disabled?: boolean
  accept?: string
}

export const InputFile: React.FC<InputFileProps> = ({
  id,
  data,
  orderNumber,
  uploadedFiles = [],
  onStart = () => {},
  onEnd = () => {},
  handleResponse,
  multiple = true,
  accept,
}) => {
  const token = useSelector(selectToken)
  const [files, setFiles] = useState(new Map())
  const [inputValue, setInputValue] = useState('')
  const [uploadingFiles, setUploadingFiles] = useState({
    count: 0,
    wasStarted: false,
  })
  const dispatch = useDispatch()
  const dropArea = useRef<HTMLDivElement>(null)

  const addFiles = (items: Map<File, FileProps>) => {
    setFiles((prev) => new Map([...prev.entries(), ...items.entries()]))
  }

  const removeFile = (item: File) => {
    setFiles((prev) => {
      const items = new Map([...prev.entries()])
      items.delete(item)
      return items
    })
  }

  const addFileLink = (item: File, uuid: string) => {
    setFiles((prev) => {
      const items = new Map([...prev.entries()])
      items.get(item).uuid = uuid
      return items
    })
  }

  const updateProgress = (item: File, percent: number) => {
    setFiles((prev) => {
      const items = new Map([...prev.entries()])
      items.get(item).progress = percent
      return items
    })
  }

  const addProgress = (item: File) => {
    setFiles((prev) => {
      const items = new Map([...prev.entries()])
      items.get(item).isLoading = true
      return items
    })
  }

  const removeProgress = (item: File) => {
    setFiles((prev) => {
      const items = new Map([...prev.entries()])
      items.get(item).isLoading = false
      return items
    })
  }

  const uploadFile = (rules: FileProps, file: File) => {
    const formData = new FormData()
    if (orderNumber) formData.append('orderNumber', orderNumber)
    if (data) {
      if (data.entity) formData.append('entity', data.entity)
      if (data.type) formData.append('type', data.type)
      if ('rotation' in data) formData.append('rotation', String(data.rotation))
      if ('inSlot' in data) formData.append('inSlot', String(data.inSlot))
    }
    formData.append('file', file)

    const onSuccess = (response: addFileResponseProps, status: number) => {
      removeProgress(file)
      if (response && response?.uuid) {
        addFileLink(file, response!.uuid)
      }
      handleResponse(response, rules.name, status)

      dispatch(
        addAlert({
          header: 'Загрузка файла',
          text: `Файл ${file.name} успешно загружен`,
          lifetime: 3000,
          variant: AlertVariant.SUCCESS,
        }),
      )
      setUploadingFiles((prev) => ({ ...prev, count: prev.count - 1 }))
    }
    const onError = (response: addFileResponseProps, status: number) => {
      if (!multiple) setInputValue('')
      removeFile(file)
      handleResponse(response, rules.name, status)
      // TODO REFACTOR IT!
      switch (status) {
        case 401:
          dispatch(
            addAlert({
              header: 'Загрузка файла',
              text: React.createElement(TextWithLineBreaks, {
                text: `Файл ${file.name} не удалось загрузить.\nОшибка авторизации!`,
              }),
              lifetime: 3000,
              variant: AlertVariant.DANGER,
            }),
          )
          dispatch(fetchLogoutRequest())
          break

        case 404:
          dispatch(
            addAlert({
              header: 'Загрузка файла',
              text: React.createElement(TextWithLineBreaks, {
                text: `Файл ${file.name} не удалось загрузить.\nОшибка авторизации!`,
              }),
              lifetime: 3000,
              variant: AlertVariant.DANGER,
            }),
          )
          dispatch(fetchLogoutRequest())
          break

        case 500:
          dispatch(
            addAlert({
              header: 'Загрузка файла',
              text: React.createElement(TextWithLineBreaks, {
                text: `Файл ${file.name} не удалось загрузить.\nОшибка сервера.\nОбратитесь в техподдержку!`,
              }),
              lifetime: 3000,
              variant: AlertVariant.DANGER,
            }),
          )
          break

        case 0:
          dispatch(
            addAlert({
              header: 'Загрузка файла',
              text: React.createElement(TextWithLineBreaks, {
                text: `Файл ${file.name} не удалось загрузить.\nПроблемы с интернет соединением.\n Попробуйте ещё раз!`,
              }),
              lifetime: 3000,
              variant: AlertVariant.DANGER,
            }),
          )
          break

        default:
          dispatch(
            addAlert({
              header: 'Загрузка файла',
              text: React.createElement(TextWithLineBreaks, {
                text: `Файл ${file.name} не удалось загрузить, попробуйте ещё раз.\nСтатус: ${status}`,
              }),
              lifetime: 3000,
              variant: AlertVariant.DANGER,
            }),
          )
          break
      }
      setUploadingFiles((prev) => ({ ...prev, count: prev.count - 1 }))
    }
    const onProgress = (percent: number) => {
      updateProgress(file, percent)
    }
    setUploadingFiles((prev) => ({ ...prev, count: prev.count + 1 }))
    addProgress(file)
    addFile(onSuccess, onError, onProgress, formData, token)
  }

  const handleFiles = (items: File[]) => {
    if (multiple) setInputValue('')

    const newFiles = new Map<File, FileProps>(
      items.map((item: File) => [
        item,
        {
          progress: 0,
          uuid: '',
          isLoading: false,
          name: item.name,
          data: {},
        },
      ]),
    )
    addFiles(newFiles)
    newFiles.forEach(uploadFile)
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles([...e.target.files])
  }

  const preventDefaults = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const highlight = (e: React.DragEvent) => {
    preventDefaults(e)
    if (!dropArea.current) return
    if (dropArea.current.classList.contains(classes.highlight)) return
    dropArea.current.classList.add(classes.highlight)
  }

  const unHighlight = (e: React.DragEvent) => {
    preventDefaults(e)
    if (!dropArea.current) return
    dropArea.current.classList.remove(classes.highlight)
  }

  const handleDrop = (e: React.DragEvent) => {
    const dt = e.dataTransfer
    const items = [...dt.files]

    handleFiles(items)
  }

  useEffect(() => {
    if (uploadingFiles.count && !uploadingFiles.wasStarted) {
      try {
        onStart()
      } catch (e) {
        console.error(e)
        captureException(e)
      }
      setUploadingFiles((prev) => ({ ...prev, wasStarted: true }))
    } else if (!uploadingFiles.count && uploadingFiles.wasStarted) {
      try {
        onEnd()
      } catch (e) {
        console.error(e)
        captureException(e)
      }
      setUploadingFiles((prev) => ({ ...prev, wasStarted: false }))
    }
  }, [uploadingFiles, onStart, onEnd])

  return (
    <div
      className={classNames(multiple ? classes.dropArea : classes.wrapperForFile, 'col-12', multiple ? classes.dashed : null)}
      onDragOver={highlight}
      onDragLeave={unHighlight}
      onDrop={(e) => {
        unHighlight(e)
        handleDrop(e)
      }}
      ref={dropArea}
    >
      {multiple ? (
        <Gallery
          files={files}
          orderNumber={orderNumber}
          uploadedFiles={uploadedFiles?.filter((file) => !file?.data?.inSlot && !file?.data?.recognitionType)}
        />
      ) : null}
      <Form id={id} value={inputValue} onChange={onChangeHandler} isValid={!!files.size || !!orderNumber} multiple={multiple} accept={accept} />
    </div>
  )
}

export default InputFile
