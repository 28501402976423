import React from 'react'

import { OrderFormSpaceId } from 'types/order'

import Header from './spaces/Header'
import Order from './spaces/Order'
import Logistic from './spaces/Logistic'
import Driver from './spaces/Driver'
import TS from './spaces/TS'
import Route from './spaces/Route'
import Files from './spaces/Files'
import Contact from './spaces/Contact'

interface OrderFormSpaceProps {
  id: string
  onChange(value: string | boolean, id?: string): void
  isCreate?: boolean
}

export const OrderFormSpace: React.FC<OrderFormSpaceProps> = ({ id, onChange, isCreate = false }) => {
  switch (id) {
    case OrderFormSpaceId.HEADER:
      return <Header onChange={onChange} isCreate={isCreate} />

    case OrderFormSpaceId.ORDER:
      return <Order onChange={onChange} />

    case OrderFormSpaceId.CONTACT:
      return <Contact onChange={onChange} />

    case OrderFormSpaceId.LOGISTIC:
      return <Logistic onChange={onChange} />

    case OrderFormSpaceId.DRIVER:
      return <Driver onChange={onChange} />

    case OrderFormSpaceId.TS:
      return <TS onChange={onChange} />

    case OrderFormSpaceId.ROUTE:
      return <Route onChange={onChange} />

    case OrderFormSpaceId.FILES:
      return <Files />

    default:
      throw new Error('Не обработан блок в форме заявки.')
  }
}

export default OrderFormSpace
