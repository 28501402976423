import { LogisticType, OrderData, OrderFields, ResolutionId } from 'types/order'
import { AutoCheck, AutoCheckType } from 'types/auto-checks'

import { NEGATIVE_ORDER_STATUSES_FOR_LOGISTIC } from 'constants/order'

import { getCheckedValueByType, getLastOrderFromCheckedValue } from 'utils/order'
import { compareTwoFio } from 'utils/string'
import { isTherePhoneMatch } from 'utils/phone'

// TODO Вынести эту логику на backend и реализовать с помощью СТАТУСОВ, а не такими костылями :)
export class Carrier {
  private previousOrder: any

  public constructor(private order: OrderData, private autoChecks: AutoCheck[]) {
    const checkedValueForOGRN = getCheckedValueByType(this.order[OrderFields.CHECKED_FIELDS], OrderFields.LOGISTIC_OGRN)
    this.previousOrder = getLastOrderFromCheckedValue(checkedValueForOGRN)
  }

  public getPreviousOrder() {
    return this.previousOrder
  }

  public isPreviousOrderNegative() {
    return NEGATIVE_ORDER_STATUSES_FOR_LOGISTIC.includes(String(this.previousOrder?.resolution_id) as ResolutionId)
  }

  public hasFirstFaceChanged() {
    return !compareTwoFio(this.previousOrder?.logistic_first_face || '', this.order[OrderFields.LOGISTIC_FIRST_FACE])
  }

  public havePhonesChanged() {
    return !isTherePhoneMatch(
      [this.previousOrder?.logistic_tel, this.previousOrder?.logistic_tel2],
      [this.order[OrderFields.LOGISTIC_TEL], this.order[OrderFields.LOGISTIC_TEL_2]],
    )
  }

  public hasNotTransportOkved() {
    const autoCheckOkved = this.autoChecks?.filter((check) => check.type === AutoCheckType.OKVED)

    return autoCheckOkved?.[0]?.status === 'fail' && !autoCheckOkved?.[0]?.text?.includes('dadata') && !autoCheckOkved?.[0]?.text?.includes('ошибкой')
  }

  public isNotActive() {
    const autoCheckStatusCompany = this.autoChecks?.filter((check) => check.type === AutoCheckType.STATUS_COMPANY)

    return autoCheckStatusCompany?.[0]?.status === 'fail' && !autoCheckStatusCompany?.[0]?.text?.includes('dadata')
  }

  public isRussia() {
    if (this.order[OrderFields.LOGISTIC_TYPE]) {
      return [LogisticType.LEGAL, LogisticType.INDIVIDUAL].includes(this.order[OrderFields.LOGISTIC_TYPE] as LogisticType)
    }

    return [13, 15].includes(this.order[OrderFields.LOGISTIC_OGRN]?.length || 0)
  }

  public areFirstFaceAndContactDifferent() {
    if (!this.order[OrderFields.LOGISTIC_FIO] || !this.order[OrderFields.LOGISTIC_FIRST_FACE]) return false

    return !compareTwoFio(this.order[OrderFields.LOGISTIC_FIO], this.order[OrderFields.LOGISTIC_FIRST_FACE])
  }
}
