import React, { useState } from 'react'

import classNames from 'classnames'

import classes from './style.module.css'

export const GlobalErrorAlert: React.FC = () => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine)

  window.addEventListener('offline', () => setIsOffline(true))
  window.addEventListener('online', () => setIsOffline(false))

  return (
    <>
      <div className={classNames('bg-danger text-center text-light', classes.container, isOffline ? '' : classes.hidden)}>
        Отсутствует интернет соединение
      </div>
      {isOffline ? <div style={{ minHeight: '44px', height: '44px', width: '100%' }} /> : null}
    </>
  )
}

export default GlobalErrorAlert
