import React from 'react'
import classNames from 'classnames'

import classes from './style.module.css'

const LoadingProgressIndeterminate = () => (
  <div className={classes.container}>
    <div className={classNames('progress', classes.progress)}>
      <div className={classNames('progress-bar', classes.progressBar, classes.indeterminate)} />
    </div>
  </div>
)

export default LoadingProgressIndeterminate
