import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'

import { OrderFields, OrderInCheckedValue, ResolutionId } from 'types/order'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import getStringFromSelectList from 'utils/getStringFromSelectList'
import {
  FINISH_MIXED_RESOLUTIONS,
  FINISH_NEGATIVE_RESOLUTIONS,
  FINISH_POSITIVE_RESOLUTIONS,
} from '../OrderSpace/spaces/Header/components/ResolutionForm/constants'

interface HistoryItemProps {
  check: OrderInCheckedValue
}

const EXCLUDED_CONTENT_FIELDS = ['date_create_cus', 'order', 'resolution_id', 'customer_id', 'executor_expert_id']

export const HistoryItem: React.FC<HistoryItemProps> = ({ check }) => {
  const link = `${process.env.REACT_APP_WEB}/order/${check.order}`
  const date = format(new Date(check.date_create_cus), 'dd.MM.yyyy HH:mm.ss')

  const selectLists = useSelector(selectSelectLists)

  const resolutionName = useMemo(
    () => selectLists[OrderFields.RESOLUTION].find((item) => `${item.id}` === `${check.resolution_id}`)?.name || 'Резолюция не определена',
    [check.resolution_id, selectLists],
  )

  const resolutionColor = useMemo(() => {
    const resolutionId: ResolutionId = `${check.resolution_id}` as ResolutionId
    if (FINISH_POSITIVE_RESOLUTIONS.includes(resolutionId)) return 'success'
    if (FINISH_NEGATIVE_RESOLUTIONS.includes(resolutionId)) return 'danger'
    if (FINISH_MIXED_RESOLUTIONS.includes(resolutionId)) return 'warning'
    return 'secondary'
  }, [check.resolution_id])

  const content = useMemo(
    () =>
      Object.entries(check)
        .filter(([key]) => !EXCLUDED_CONTENT_FIELDS.includes(key))
        .map(([, value]) => value)
        .filter((value) => value)
        .join(', '),
    [check],
  )

  const orderTypeName = useMemo(
    () => selectLists[OrderFields.ORDER_TYPE].find((item) => `${item.id}` === `${check.type_order_id}`)?.name || 'Тип заявки не определен',
    [check.type_order_id, selectLists],
  )

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <a target="_blank" href={link} className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{check.order}</h5>
        <small>{date}</small>
      </div>
      <p className="mb-1">Тип заявки: {orderTypeName}</p>
      <p className="mb-1">Клиент: {getStringFromSelectList(check.customer_id, selectLists?.clientName)}</p>
      <p className="mb-1">
        Эксперт: {check.executor_expert_id ? getStringFromSelectList(check.executor_expert_id, selectLists?.executor) : <>нет</>}
      </p>
      <p className="mb-1">{content}</p>
      <p className="mb-1">
        <span className={`badge badge-${resolutionColor}`}>{resolutionName}</span>
      </p>
    </a>
  )
}
