import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import classNames from 'classnames'

import { selectCurrentUser, selectToken } from 'store/users/users.selectors'
import { UserRole } from 'types/user'
import { getCertificateRequest } from 'requests/certificate'

import classes from './style.module.css'

export type CertificateLinkProps = {
  type: string
  identityData: { [key: string]: string }
}

const CertificateLink: React.FC<CertificateLinkProps> = ({ type, identityData }) => {
  const [rating, setRating] = useState('?')
  const token = useSelector(selectToken)
  const { pathname } = useLocation()
  const currentUser = useSelector(selectCurrentUser)
  const [, orderNumber] = pathname.split('/').filter((chunk: string) => chunk)

  const isExpert = currentUser!.role === UserRole.EXPERT
  const identityString = Object.entries(identityData)
    .map(([key, value]) => `passport.${key}=${value}`)
    .join('&')
  const identityHasEmptyValue = Object.values(identityData).some((value: string) => !value)

  useEffect(() => {
    if (identityHasEmptyValue || !isExpert) return
    getCertificateRequest(type, identityData, `${token}`).then((data: any) => {
      setRating(data.factors['В чёрном списке'] === 'true' ? '0' : '—')
    })
  }, [identityData]) // eslint-disable-line react-hooks/exhaustive-deps

  return isExpert && !identityHasEmptyValue ? (
    <p className="p-0 m-0">
      <a
        title="Сертификат"
        rel="noopener noreferrer"
        href={`/order/${orderNumber}/certificate?type=${type}&${identityString}`}
        className={classes.link}
      >
        <span className={classes.link_title}>Сертификат:</span>
      </a>
      &nbsp;
      <span className={classNames(classes.link_value, classes[`link_value__${rating}`])}>{rating === '0' ? 'ЧС' : rating}</span>
    </p>
  ) : null
}

export default CertificateLink
