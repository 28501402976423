import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'

export interface CheckboxProps {
  id?: string
  value: boolean
  onChangeHandler(value: boolean, id?: string): void
  label?: string
  required?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, value, onChangeHandler, label, required = false }) => {
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.checked, id), [onChangeHandler, id])

  return (
    <Form.Group className="m-0">
      <Form.Check type="checkbox" checked={value} onChange={onChange} required={required} label={label} />
    </Form.Group>
  )
}

export default Checkbox
