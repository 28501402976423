export class DriverPhoneVerification {
  public verifiedPhones: string[]

  private readonly phones: string[]

  constructor(verifiedPhones: string[], phones: string[]) {
    this.verifiedPhones = verifiedPhones
    this.phones = phones
  }

  public isVerifiedPhone(phone: string) {
    return this.verifiedPhones.includes(phone)
  }

  public isVerificationValidationMessageVisible(
    orderHasDriver: boolean,
    resolutionIsPositive: boolean,
    driverHasPhone: boolean,
    driverHasBothPhones: boolean,
  ) {
    return (
      (!this.isVerifiedPhone(this.phones[0]) && !this.isVerifiedPhone(this.phones[1]) && orderHasDriver && resolutionIsPositive && driverHasPhone) ||
      ((!this.isVerifiedPhone(this.phones[0]) || !this.isVerifiedPhone(this.phones[1])) &&
        orderHasDriver &&
        resolutionIsPositive &&
        driverHasBothPhones)
    )
  }
}
