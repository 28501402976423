import fields from '../constants/fields'

export const formValidator = (id: string, isValid: boolean) => {
  switch (id) {
    case fields.LOGIN:
      return {
        isValid,
        invalidMessage: 'Логин меньше 6 символов',
      }

    case fields.PASSWORD:
      return {
        isValid,
        invalidMessage: 'Пароль меньше 10 символов',
      }

    default:
      return {
        isValid: false,
        invalidMessage: 'Данное поле не обработано валидатором',
      }
  }
}

export default formValidator
