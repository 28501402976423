import { all, takeEvery } from 'redux-saga/effects'

import { getDictionaryLinkedEntriesSaga } from 'store/dictionary/sagas/get-dictionary-linked-entries'
import { linkDictionaryEntriesSaga } from 'store/dictionary/sagas/link-dictionary-entries'
import { unlinkDictionaryEntriesSaga } from 'store/dictionary/sagas/unlink-dictionary-entries'

import * as actions from './dictionary.actions'

export default function* () {
  yield all([
    takeEvery(actions.GET_DICTIONARY_LINKED_ENTRIES_REQUEST, getDictionaryLinkedEntriesSaga),
    takeEvery(actions.LINK_DICTIONARY_ENTRIES_REQUEST, linkDictionaryEntriesSaga),
    takeEvery(actions.UNLINK_DICTIONARY_ENTRIES_REQUEST, unlinkDictionaryEntriesSaga),
  ])
}
