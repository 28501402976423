import { SelectItem } from 'types/selectLists'

const getIDFromSelectList = (name: string, selectList: SelectItem[] | undefined) => {
  let value = ''

  if (!selectList) return ''

  for (let i = 0; i < selectList.length; i += 1) {
    if (selectList[i].name === name) value = selectList[i].id
  }

  return value
}

export default getIDFromSelectList
