export enum CountryCode {
  RU = 'ru',
  KZ = 'kz',
  UA = 'ua',
  KG = 'kg',
  BY = 'by',
  UZ = 'uz',
}

export const CountryName = {
  [CountryCode.RU]: 'Россия',
  [CountryCode.KZ]: 'Казахстан',
  [CountryCode.BY]: 'Беларусь',
  [CountryCode.KG]: 'Кыргызстан',
  [CountryCode.UA]: 'Украина',
  [CountryCode.UZ]: 'Узбекистан',
}

export const CountryPrefix = {
  [CountryCode.RU]: '+7',
  [CountryCode.KZ]: '+7',
  [CountryCode.BY]: '+375',
  [CountryCode.KG]: '+996',
  [CountryCode.UA]: '+380',
  [CountryCode.UZ]: '+998',
}

export const CountryMask = {
  [CountryCode.RU]: '+7 \\(***\\) ***\\-**\\-**',
  [CountryCode.KZ]: '+7 \\(***\\) ***\\-**\\-**',
  [CountryCode.BY]: '+375 \\(**\\) ***\\-**\\-**',
  [CountryCode.KG]: '+996 \\(***\\) ***\\-***',
  [CountryCode.UA]: '+380 \\(**\\) ***\\-**\\-**',
  [CountryCode.UZ]: '+998 \\(**\\) ***\\-**\\-**',
}

export const CountryLength = {
  [CountryCode.RU]: 11,
  [CountryCode.KZ]: 11,
  [CountryCode.BY]: 12,
  [CountryCode.KG]: 12,
  [CountryCode.UA]: 12,
  [CountryCode.UZ]: 12,
}
