import React from 'react'
import classNames from 'classnames'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from '../../InputFile.module.css'

export const Form: React.FC<{
  id: string
  isValid: boolean
  value: string
  multiple: boolean
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  accept: string | undefined
}> = ({ id, isValid, value, onChange = () => {}, multiple, accept }) => {
  const validClass = isValid ? 'is-valid' : 'is-invalid'
  return (
    <div className={multiple ? classes.form : classes.formForFile}>
      <input
        type="file"
        id={id}
        onChange={onChange}
        value={value}
        className={classNames(classes.input, validClass)}
        multiple={multiple}
        accept={accept}
      />
      {multiple /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ ? (
        <label className="text-center offset-md-3 col-md-6 col-sm-12 btn btn-outline-primary" htmlFor={id}>
          + Добавить файл
        </label>
      ) : (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label style={{ cursor: 'pointer' }} htmlFor={id}>
          <Icon type={IconType.Plus} width="48px" height="48px" className={classes.iconPlus} />
        </label>
      )}

      {multiple ? <div className="invalid-feedback">Загрузите документ</div> : null}
    </div>
  )
}

export default Form
