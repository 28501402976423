import { OrderSpaceName, OrderTypeId, OrderTypeName } from 'types/order'

const spaces = {
  [OrderTypeName.LOGISTIC_AND_DRIVER]: [
    OrderSpaceName.HEADER,
    OrderSpaceName.ORDER,
    OrderSpaceName.CONTACT,
    OrderSpaceName.LOGISTIC,
    OrderSpaceName.DRIVER,
    OrderSpaceName.TS,
    OrderSpaceName.ROUTE,
    OrderSpaceName.FILES,
  ],
  [OrderTypeName.COUNTERPARTY_AND_TS]: [
    OrderSpaceName.HEADER,
    OrderSpaceName.ORDER,
    OrderSpaceName.LOGISTIC,
    OrderSpaceName.TS,
    OrderSpaceName.FILES,
  ],
  [OrderTypeName.DRIVER_AND_TS]: [
    OrderSpaceName.HEADER,
    OrderSpaceName.ORDER,
    OrderSpaceName.DRIVER,
    OrderSpaceName.TS,
    OrderSpaceName.ROUTE,
    OrderSpaceName.FILES,
  ],
  [OrderTypeName.DRIVER]: [OrderSpaceName.HEADER, OrderSpaceName.ORDER, OrderSpaceName.DRIVER, OrderSpaceName.FILES],
  [OrderTypeName.PHONE_CHECK]: [OrderSpaceName.HEADER, OrderSpaceName.ORDER, OrderSpaceName.DRIVER],
  [OrderTypeName.DRIVER_FOR_LOADING]: [
    OrderSpaceName.HEADER,
    OrderSpaceName.ORDER,
    OrderSpaceName.DRIVER,
    OrderSpaceName.ROUTE,
    OrderSpaceName.FILES,
  ],
  [OrderTypeName.LOGISTIC]: [OrderSpaceName.HEADER, OrderSpaceName.ORDER, OrderSpaceName.CONTACT, OrderSpaceName.LOGISTIC, OrderSpaceName.FILES],
  [OrderTypeName.TS]: [OrderSpaceName.HEADER, OrderSpaceName.ORDER, OrderSpaceName.TS, OrderSpaceName.ROUTE, OrderSpaceName.FILES],
}

export const getSpaces = (orderType: string) => {
  switch (String(orderType)) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      return spaces[OrderTypeName.LOGISTIC_AND_DRIVER]

    case OrderTypeId.DRIVER_AND_TS:
      return spaces[OrderTypeName.DRIVER_AND_TS]

    case OrderTypeId.LOGISTIC:
    case OrderTypeId.LOGISTIC_PAYER:
    case OrderTypeId.LOGISTIC_CONTRACTOR:
      return spaces[OrderTypeName.LOGISTIC]

    case OrderTypeId.DRIVER:
    case OrderTypeId.STATE_DRIVER:
      return spaces[OrderTypeName.DRIVER]

    case OrderTypeId.EMPLOYEE:
      return spaces[OrderTypeName.DRIVER]

    case OrderTypeId.DRIVER_FOR_LOADING:
      return spaces[OrderTypeName.DRIVER_FOR_LOADING]

    case OrderTypeId.TS:
      return spaces[OrderTypeName.TS]

    case OrderTypeId.DRIVER_DOC_CHECK:
      return spaces[OrderTypeName.DRIVER]

    case OrderTypeId.COUNTERPARTY_BORROWER_AND_TS:
      return spaces[OrderTypeName.COUNTERPARTY_AND_TS]

    case OrderTypeId.PHONE_CHECK:
      return spaces[OrderTypeName.PHONE_CHECK]

    default:
      return spaces[OrderTypeName.LOGISTIC_AND_DRIVER]
  }
}

export default getSpaces
