import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './root.reducer'
import rootSaga from './root.saga'

const sagaMiddleware = createSagaMiddleware()
const enhancers = []
const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const { __REDUX_DEVTOOLS_EXTENSION__: reduxDevTools } = window
  if (typeof reduxDevTools === 'function') {
    enhancers.push(reduxDevTools())
  }

  /* eslint-disable */
  // @ts-ignore
  Map.prototype.toJSON = function () {
    /* eslint-enable */
    const object: { [key: string]: any } = {}
    this.forEach((value, key) => {
      object[key] = value
    })
    return object
  }
}

export const store = createStore(rootReducer, compose(applyMiddleware(...middlewares), ...enhancers))

sagaMiddleware.run(rootSaga)
