import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser, selectToken } from 'store/users/users.selectors'

import { getPhoneRegionRequest } from 'requests/phone-region'

import classes from './style.module.css'

const PhoneRegion: React.FC<{ phone: string }> = ({ phone }) => {
  const token = useSelector(selectToken)
  const currentUser = useSelector(selectCurrentUser)
  const [state, setState] = useState({
    country: '',
    region: '',
  })

  const isExpert = currentUser!.role === UserRole.EXPERT

  useEffect(() => {
    if (!phone || !isExpert) return
    getPhoneRegionRequest(phone, `${token}`).then((data: any) => {
      setState(data)
    })
  }, [phone]) // eslint-disable-line react-hooks/exhaustive-deps

  return isExpert && phone ? <div className={classes.phoneRegion}>Регион:&nbsp;{state.region || '—'}</div> : null
}

export default PhoneRegion
