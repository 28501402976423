import { useMemo } from 'react'
import { OrderFormData, OrderFields } from 'types/order'
import { FileForDocumentsForm, FileProps, FileType } from 'types/file'

export const useDocumentPreviews = (form: OrderFormData): { [key in FileType]: FileForDocumentsForm[] } =>
  useMemo(() => {
    return form[OrderFields.FILES].length // @ts-ignore TODO remove
      ? form[OrderFields.FILES].reduce((list: { [key in FileType]: FileForDocumentsForm[] }, item: FileProps | number) => {
          if (typeof item === 'number' || (!item.data?.recognitionType && !item.data?.type)) return list

          const type = (item.data?.type || item.data?.recognitionType) as FileType

          const result = {
            ...list,
          }
          result[type] = [
            ...(result[type] || []),
            {
              name: item.name,
              href_file: item.uuid,
              data: item.data,
            },
          ]

          return result
        }, {})
      : {}
  }, [form])
