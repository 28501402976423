import { call, put } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { News } from 'types/news'

import { getNews } from 'requests/news'

import { getNewsPending, getNewsSuccess, getNewsFailure } from '../news.actions'

export function* getNewsSaga() {
  try {
    yield put(getNewsPending())

    const result: News = yield call(getNews)

    yield put(getNewsSuccess(result))
  } catch (error) {
    yield put(getNewsFailure(error))
    console.error(`[fetchLogisticInfoSaga]: ${error}`)
    captureException(error)
  }
}
