import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { OrderFormSpaceName } from 'constants/order'

import { useValidation } from 'utils/useValidation'
import { getDateForServer, getTomorrow } from 'utils/date'

import { fetchEditOrderRequest } from 'store/order/order.actions'
import { selectCurrentUser } from 'store/users/users.selectors'

import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'

import {
  selectOrderChangedFields,
  selectOrderFetchEditStatus,
  selectOrderFetchGetStatus,
  selectOrderFormErrors,
  selectOrderLogisticInfoStatus,
  selectOrderWrite,
} from 'store/order/order.selectors'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'

import Header from 'components/molecules/Header'
import AnchorNavbar from 'components/molecules/AnchorNavbar'
import { getSpaces } from 'components/organisms/OrderForm/utils/getSpaces'
import getCreateOrderRules from 'components/organisms/OrderForm/utils/getSubmitOrderRules'

import classes from './style.module.css'
import { BackLink } from '../../atoms/BackLink'

export const EditOrderHeader: React.FC = () => {
  const dispatchStore = useDispatch()
  const loadingFetchGetStatus = useSelector(selectOrderFetchGetStatus)
  const loadingFetchStatus = useSelector(selectOrderFetchEditStatus)
  const loadingOrderLogisticInfoStatus = useSelector(selectOrderLogisticInfoStatus)
  const loadingSelectListsStatus = useSelector(selectLoadingSelectListsStatus)
  const currentUser = useSelector(selectCurrentUser)
  const form = useSelector(selectOrderWrite)
  const changedFields = useSelector(selectOrderChangedFields)
  const { orderNumber } = useParams()

  const errors = useSelector(selectOrderFormErrors)
  const spaces = useMemo(() => getSpaces(form[OrderFields.ORDER_TYPE], false, currentUser!, errors), [form, currentUser, errors])

  const { validate } = useValidation(getCreateOrderRules(form, currentUser!, spaces))

  const onSubmit = useCallback(() => {
    if (validate({ ...form, tomorrow: getDateForServer(getTomorrow()) }).passes())
      dispatchStore(
        fetchEditOrderRequest({
          [OrderFields.ORDER_NUMBER]: orderNumber,
          fields: { ...changedFields },
        }),
      )
  }, [dispatchStore, validate, form, changedFields, orderNumber])

  const leftButton = useMemo(() => <BackLink />, [])

  const [loadingStatus, setLoadingStatus] = useState<any>(null)

  useEffect(() => {
    if ((loadingStatus === null && loadingFetchGetStatus !== LoadingStatus.SUCCESS) || loadingStatus !== null) {
      setLoadingStatus(loadingFetchStatus)
    }
  }, [loadingFetchGetStatus, loadingFetchStatus, loadingStatus])

  if (loadingStatus === LoadingStatus.SUCCESS) {
    return <Redirect to={`/order/${orderNumber}`} />
  }

  return (
    <>
      <Header leftButton={leftButton} headerName="Редактирование заявки">
        <div className={classNames('sticky-top', classes.header_body)}>
          <div className="row bg-dark" style={{ padding: '8px' }}>
            <div className={classNames('col-6', classes.header_body_leftButton)}>
              <Link to={`/order/${orderNumber}`}>
                <Button
                  type="button"
                  className="btn btn-light col-md-6 col-sm-8 col-xs-12"
                  disabled={loadingFetchStatus === LoadingStatus.PENDING || loadingOrderLogisticInfoStatus === LoadingStatus.PENDING}
                >
                  Отмена
                </Button>
              </Link>
            </div>
            <div className={classNames('col-6 text-right', classes.header_body_rightButton)}>
              <Button
                type="button"
                className="btn btn-primary col-md-6 col-sm-8 col-xs-12"
                onClick={onSubmit}
                disabled={
                  loadingFetchStatus === LoadingStatus.PENDING ||
                  loadingOrderLogisticInfoStatus === LoadingStatus.PENDING ||
                  !validate({ ...form, tomorrow: getDateForServer(getTomorrow()) }).passes() ||
                  !Object.keys(changedFields).length
                }
              >
                {loadingFetchStatus === LoadingStatus.PENDING ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    &nbsp; Загрузка..
                  </div>
                ) : (
                  <>Сохранить</>
                )}
              </Button>
            </div>
          </div>
          {loadingFetchGetStatus !== LoadingStatus.PENDING && loadingSelectListsStatus !== LoadingStatus.PENDING ? (
            <AnchorNavbar items={spaces.map((space) => ({ name: OrderFormSpaceName[space], id: space }))} />
          ) : null}
        </div>
      </Header>
    </>
  )
}

export default EditOrderHeader
