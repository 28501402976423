import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { getOrderTime } from 'requests/order'
import { selectToken } from 'store/users/users.selectors'

import { OrderStatus } from 'types/order'
import { ProgressBar } from '../ProgressBar/ProgressBar'

interface ProgressBarProps {
  orderNumber: string
}

interface OrderProgressBarState {
  status: OrderStatus
  currentTime: number
  maxTime: number
}

export const OrderProgressBar: React.FC<ProgressBarProps> = () => {
  const { orderNumber } = useParams()
  const token = useSelector(selectToken) as string
  const [state, setState] = React.useState<OrderProgressBarState | null>(null)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getProgress = useCallback(() => {
    getOrderTime(orderNumber, token)
      .then((data) => {
        if (data?.status) {
          setState({
            status: data?.status,
            currentTime: Math.floor(Number(data?.currentTime) / 1000 / 60),
            maxTime: Math.floor(Number(data?.maxTime) / 1000 / 60),
          })
        }
      })
      .catch(() => {})
  }, [orderNumber, token])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!orderNumber || !token) return
    getProgress()
    const interval = setInterval(getProgress, 60 * 1000)
    return () => clearInterval(interval)
  }, [getProgress, orderNumber, token])

  if (!state) return null

  const timeLeft = state.maxTime - state.currentTime

  if (state.status === OrderStatus.CLOSED) return null

  return (
    <div className="mb-2 w-100">
      <ProgressBar status={state.status} currentTime={state.currentTime} maxTime={state.maxTime} />
      <div className="mt-1 font-weight-bold d-flex" style={{ gap: '8px' }}>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <span>
          {timeLeft <= 0 ? (
            // eslint-disable-next-line jsx-a11y/accessible-emoji
            <span className="text-info">Задерживаемся😓 Делаем все, чтобы обработать заявку быстрее</span>
          ) : (
            <span>
              {state.status === OrderStatus.CLARIFICATION ? (
                <span className="text-danger">Время остановлено! Работа не ведется, ждём ответа от вас</span>
              ) : (
                <span className="text-success">Осталось {timeLeft} мин, ждите</span>
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  )
}
