import { CountryCode } from 'types/phone'
import { getIntersectionOfTwoArrays } from './array'

export const phoneRegex = 'regex:/^(7[0-9]{10})|(996[0-9]{9})|(375[0-9]{9})|(380[0-9]{9})|(998[0-9]{9})$/'

export const getPhoneForServer = (phone: string) => `${phone}`.replace(/[^0-9]*/g, '')

export const determinePhoneType = (text: string, isFromPaste?: boolean) => {
  const phone = getPhoneForServer(text)

  const ru = isFromPaste ? phone.match(/^[78]?([0-58-9][0-9]{9})$/) : phone.match(/^[78]([0-58-9][0-9]{9})$/)
  if (ru) return { value: `7${ru[1]}`, code: CountryCode.RU }

  const kz = isFromPaste ? phone.match(/^[78]?([67][0-9]{9})$/) : phone.match(/^[78]([67][0-9]{9})$/)
  if (kz) return { value: `7${kz[1]}`, code: CountryCode.KZ }

  const by = phone.match(/^375([0-9]{9})$/)
  if (by) return { value: phone, code: CountryCode.BY }

  const kg = phone.match(/^996([0-9]{9})$/)
  if (kg) return { value: phone, code: CountryCode.KG }

  const ua = phone.match(/^380([0-9]{9})$/)
  if (ua) return { value: phone, code: CountryCode.UA }

  const uz = phone.match(/^998([0-9]{9})$/)
  if (uz) return { value: phone, code: CountryCode.UZ }

  return { value: text?.length > 7 || isFromPaste ? text : '', code: null }
}

export const getPhoneForPeople = (value: string | null) => {
  const phone = `${value}`

  // Российские и Казахские номера +7 (***) ***-**-**
  if (phone.length === 11 && phone[0] === '7')
    return `+7 (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`

  // Кыргызский номер +996 (***) ***-***
  if (phone.length === 12 && phone.substring(0, 3) === '996')
    return `+996 (${phone.substring(3, 6)}) ${phone.substring(6, 9)}-${phone.substring(9, 12)}`

  // Беларуский номер +375 (**) ***-**-**
  if (phone.length === 12 && phone.substring(0, 3) === '375')
    return `+375 (${phone.substring(3, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10, 12)}`

  // Украинский номер +380 (**) ***-**-**
  if (phone.length === 12 && phone.substring(0, 3) === '380')
    return `+380 (${phone.substring(3, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10, 12)}`

  // Узбекский номер +998 (**) ***-**-**
  if (phone.length === 12 && phone.substring(0, 3) === '998')
    return `+998 (${phone.substring(3, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10, 12)}`

  return phone.length > 7 ? phone : ''
}

export const getPhoneForCopy = (phone: string) => `+${phone}`

export const isTherePhoneMatch = (firstPhones: Array<string | undefined>, secondPhones: Array<string | undefined>): boolean =>
  !!getIntersectionOfTwoArrays(firstPhones, secondPhones).length
