import { useHistory, useLocation } from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'

export const useOrderListFilterByExpertLocation = () => {
  const location = useLocation()
  const history = useHistory()
  const queryObject = useMemo(() => queryString.parse(location.search), [location])
  const filterValue = useMemo(() => (Array.isArray(queryObject.expert) ? queryObject.expert[0] : queryObject.expert), [queryObject])

  const [expert, setExpert] = useState<string>(filterValue || '')

  useEffect(() => {
    setExpert(filterValue || '')
  }, [filterValue])

  return {
    get: () => {
      return expert
    },
    set: (value: string) => {
      setExpert(value)
      const searchParams = new URLSearchParams(history.location.search)
      searchParams.set('expert', value)
      if (!value) searchParams.delete('expert')
      history.push({ search: searchParams.toString() })
    },
  }
}
