import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import InputMask from 'react-input-mask'

import { getDateForServer, getDateForWeb } from 'utils/date'
import { addLeadingZeros } from 'utils/string'

import { Icon, IconType } from 'components/atoms/Icon'
import CalendarForInput from 'components/molecules/CalendarForInput'

import onPasteDate from './utils/onPasteDate'

import classes from './style.module.css'

export interface InputControlProps {
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string): void
  className?: string
  label?: string
  invalidMessage?: string
  required?: boolean
  disabled?: boolean
}

const getStringForJSDATEFromDate = (date: string) => {
  const arrayMatch = date.match(/^([0-9]{2})[.]([0-9]{2})[.]([0-9]{4})?$/)
  if (arrayMatch) {
    return `${arrayMatch[3]}-${arrayMatch[2]}-${arrayMatch[1]}`
  }
  return ''
}

const isDateValid = (date: string) => {
  const [, day, month, year] = date.match(/^([0-9]{2})[.]([0-9]{2})[.]([0-9]{4})?$/)
  const tempDate = new Date(Number(year), Number(month) - 1, Number(day))

  return (
    date.length === 10 &&
    Number(year) >= 1920 &&
    Number(year) <= 2100 &&
    String(tempDate.getFullYear()) === year &&
    String(addLeadingZeros(tempDate.getMonth() + 1, 2)) === month &&
    String(addLeadingZeros(tempDate.getDate(), 2)) === day
  )
}

export const InputDate: React.FC<InputControlProps> = ({
  id,
  value,
  isValid,
  onChangeHandler,
  className = '',
  label,
  invalidMessage = '',
  required = false,
  disabled = false,
}) => {
  const [val, setVal] = useState(() => {
    if (value.length === 10 && value.match(/^([0-9]{4}).([0-9]{2}).([0-9]{2})?$/)) {
      return getDateForWeb(new Date(value))
    }
    return value
  })

  const [isCalendarVisible, setIsCalendarVisible] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (value.length === 10 && value.match(/^([0-9]{4}).([0-9]{2}).([0-9]{2})?$/)) {
      setVal(getDateForWeb(new Date(value)))
    } else {
      setVal(value)
    }
  }, [value, setVal])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const changedValue = e.target.value
      setVal(changedValue)
      if (isDateValid(changedValue)) {
        onChangeHandler(getDateForServer(new Date(getStringForJSDATEFromDate(changedValue))), id)
      } else {
        onChangeHandler(changedValue, id)
      }
    },
    [onChangeHandler, id, setVal],
  )

  const CalendarVisibilityHandler = useCallback(() => {
    if (disabled) return
    setIsCalendarVisible((prev) => {
      if (!prev) document.getElementById(id)!.focus() // Ref not work :( // TODO fix it
      return !prev
    })
    // inputMaskRef.current!.focus()
  }, [setIsCalendarVisible, id, disabled])

  const CalendarOnChangeHandler = useCallback(
    (date: Date) => {
      if (date.getFullYear() >= 1920 && date.getFullYear() <= 2100) onChangeHandler(getDateForServer(date) || '', id)
      else onChangeHandler(getDateForWeb(date) || '', id)
    },
    [onChangeHandler, id],
  )

  const stopPropagation = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    return false
  }, [])

  const HandleBlur = useCallback(() => {
    setIsCalendarVisible(false)
    if (val === '00.00.0000') onChangeHandler('', id)
  }, [setIsCalendarVisible, val, id, onChangeHandler])

  // eslint-disable-next-line no-nested-ternary
  const validClass = isValid === false ? 'is-invalid' : isValid === true ? 'is-valid' : ''

  const name = useMemo(() => uuidv4(), [])

  return (
    <Form.Group className="m-0">
      {label ? <Form.Label>{label}</Form.Label> : null}
      <br />
      <div className={classNames(classes.container, className)}>
        <InputMask
          id={id}
          className={classNames('inputMask form-control date', validClass)}
          value={val || ''}
          mask="99.99.9999"
          maskChar="0"
          onChange={onChange}
          onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
            onPasteDate(event, onChangeHandler)
          }}
          required={required}
          autoComplete="none"
          name={name}
          onBlur={HandleBlur}
          disabled={disabled}
        />

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className={!disabled ? classes.calendarIcon : 'd-none'} onClick={CalendarVisibilityHandler} onMouseDown={stopPropagation}>
          <Icon type={IconType.Calendar} width="30px" height="30px" />
        </span>
      </div>

      {isCalendarVisible ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div className={classes.container_calendar} onClick={() => setIsCalendarVisible(false)}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div className={classes.calendar} onMouseDown={stopPropagation} onClick={stopPropagation}>
            <CalendarForInput onChange={CalendarOnChangeHandler} value={value} />
          </div>
        </div>
      ) : null}

      {invalidMessage && isValid === false ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {invalidMessage}
        </div>
      ) : null}
    </Form.Group>
  )
}

export default InputDate
