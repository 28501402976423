import React from 'react'

export const TsHintText = () => (
  <>
    ТЯГАЧ / ПРИЦЕП
    <br />
    <ul>
      <li>стс</li>
      <li>если аренда, то договор аренды</li>
      <li>
        если договор аренды, то паспорт собственника – страницы лицевая и с постоянной регистрацией (только если физлицо, Ген директора ООО – не
        нужно)
      </li>
    </ul>
  </>
)

export default TsHintText
