import { createAction } from 'redux-actions'
import { GetOrdersResponseData } from 'types/order'

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST'
export const fetchOrdersRequest = createAction<void | string>(FETCH_ORDERS_REQUEST)

export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const fetchOrdersSuccess = createAction<GetOrdersResponseData>(FETCH_ORDERS_SUCCESS)

export const FETCH_ORDERS_PRELOADED = 'FETCH_ORDERS_PRELOADED'
export const fetchOrdersPreload = createAction<void>(FETCH_ORDERS_PRELOADED)

export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE'
export const fetchOrdersFailure = createAction<Error>(FETCH_ORDERS_FAILURE)

export const SEARCH_ORDERS_REQUEST = 'SEARCH_ORDERS_REQUEST'
export const searchOrdersRequest = createAction<void | string>(SEARCH_ORDERS_REQUEST)

export const SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS'
export const searchOrdersSuccess = createAction<GetOrdersResponseData>(SEARCH_ORDERS_SUCCESS)

export const SEARCH_ORDERS_FAILURE = 'SEARCH_ORDERS_FAILURE'
export const searchOrdersFailure = createAction<Error>(SEARCH_ORDERS_FAILURE)

export const ORDERS_CHANGE_FILTER = 'ORDERS_CHANGE_FILTER'
export const ordersChangeFilter = createAction<string>(ORDERS_CHANGE_FILTER)

export const CHANGE_ORDER_FILTER_BY_EXPERT = 'CHANGE_ORDER_FILTER_BY_EXPERT'
export const changeOrderFilterByExpert = createAction<string>(CHANGE_ORDER_FILTER_BY_EXPERT)

export const SET_INITIAL_STATE_ORDERS = 'SET_INITIAL_STATE_ORDERS'
export const setInitialStateOrders = createAction<void>(SET_INITIAL_STATE_ORDERS)

export const FETCH_TAKE_ORDER_REQUEST = 'FETCH_TAKE_ORDER_REQUEST'
export const fetchTakeOrderRequest = createAction<string>(FETCH_TAKE_ORDER_REQUEST)

export const FETCH_TAKE_ORDER_SUCCESS = 'FETCH_TAKE_ORDER_SUCCESS'
export const fetchTakeOrderSuccess = createAction<void>(FETCH_TAKE_ORDER_SUCCESS)

export const FETCH_TAKE_ORDER_FAILURE = 'FETCH_TAKE_ORDER_FAILURE'
export const fetchTakeOrderFailure = createAction<Error>(FETCH_TAKE_ORDER_FAILURE)

export const FETCH_TAKE_NEXT_ORDER_REQUEST = 'FETCH_TAKE_NEXT_ORDER_REQUEST'
export const fetchTakeNextOrderRequest = createAction<void>(FETCH_TAKE_NEXT_ORDER_REQUEST)

export const FETCH_TAKE_NEXT_ORDER_SUCCESS = 'FETCH_TAKE_NEXT_ORDER_SUCCESS'
export const fetchTakeNextOrderSuccess = createAction<string>(FETCH_TAKE_NEXT_ORDER_SUCCESS)

export const FETCH_TAKE_NEXT_ORDER_FAILURE = 'FETCH_TAKE_NEXT_ORDER_FAILURE'
export const fetchTakeNextOrderFailure = createAction<Error>(FETCH_TAKE_NEXT_ORDER_FAILURE)
