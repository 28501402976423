import { SelectItem } from 'types/selectLists'
import { OrderTypeId } from 'types/order'

export default class OrderTypes {
  static getForExternalUser(availableOrderTypes: number[], allTypes: SelectItem[]) {
    const types = [...allTypes]

    if (availableOrderTypes.length) {
      return types?.filter((type) => availableOrderTypes.includes(Number(type.id))).map((type) => ({ ...type, isHidden: false }))
    }

    return types
  }

  static getForInternalUser(allTypes: SelectItem[]) {
    const types = [...allTypes]

    const counterpartyAndTsId = types.findIndex((type) => Number(type.id) === Number(OrderTypeId.COUNTERPARTY_BORROWER_AND_TS))

    if (counterpartyAndTsId !== -1) {
      types[counterpartyAndTsId].isHidden = false
    }

    return types
  }
}
