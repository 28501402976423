import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import BoldText from 'components/atoms/BoldText'
import PhoneForExpert from 'components/molecules/OrderSpace/components/PhoneForExpert'

import classes from './style.module.css'
import { getCheckByType } from '../Driver/utils'

const Expert: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheck = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.CONTACT)

  const checkTel1 = getCheckByType(autoCheck, 'tel1')
  const checkTel2 = getCheckByType(autoCheck, 'tel2')

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0 position-relative">
            <div className={classes.title}>Контакты</div>
            <TextWithPrefixAndCopy
              text={<BoldText text={form[OrderFields.LOGISTIC_FIO]} />}
              copiedText={form[OrderFields.LOGISTIC_FIO]}
              prefix="Контакт:"
            />
            <PhoneForExpert field={OrderFields.LOGISTIC_TEL} check={checkTel1} />
            <PhoneForExpert field={OrderFields.LOGISTIC_TEL_2} check={checkTel2} />
            <TextWithPrefixAndCopy
              text={<BoldText text={form[OrderFields.CARRIER_EMAIL]} />}
              copiedText={form[OrderFields.CARRIER_EMAIL]}
              prefix="Email:"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Expert
