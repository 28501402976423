import React from 'react'
import { useSelector } from 'react-redux'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'
import InputControl from 'components/molecules/InputControl'

const ListItem: React.FC<any> = ({ id, onChange, label, readonly }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  return (
    <>
      <li className="list-group-item">
        <div className="p-0">
          <InputControl
            type="text"
            id={id} // @ts-ignore
            value={form[id]} // @ts-ignore
            isValid={errors[id].isValid} // @ts-ignore
            invalidMessage={errors[id].invalidMessage}
            onChangeHandler={onChange}
            className="col-lg-4 col-md-6 col-sm-12"
            label={label}
            readonly={readonly}
          />
        </div>
      </li>
    </>
  )
}

export default ListItem
