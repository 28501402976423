import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields, OrderTypeId } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'

import getStringFromTwoFields from 'utils/getStringFromTwoFields'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import { getCheckByType } from 'components/molecules/OrderSpace/spaces/Driver/utils'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import PassportRegion from 'components/atoms/PassportRegion'
import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

const Manager: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheck = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.DRIVER)

  const checkChapters = getCheckByType(autoCheck, 'chapters')

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.Driver} width="24px" height="24px" />
            </span>
            <div className={classes.title}>Водитель / Сотрудник в штат</div>
            <div className="d-flex justify-content-between">
              <div className="d-inline-block flex-grow-1">
                <TextWithPrefixAndCopy
                  text={getStringFromTwoFields(form[OrderFields.DRIVER_FIO], form[OrderFields.DRIVER_BITH])}
                  prefix="ФИО:"
                  copiedText={getStringFromTwoFields(form[OrderFields.DRIVER_FIO], form[OrderFields.DRIVER_BITH])}
                />
              </div>
              <div className="d-inline-block">
                {checkChapters?.data ? (
                  <div className="d-flex align-items-center w-100 justify-content-center">
                    <span className="text-danger">{checkChapters.data.red}</span>/<span className="text-warning">{checkChapters.data.yellow}</span>/
                    <span className="text-success">{checkChapters.data.green}</span>
                  </div>
                ) : null}
              </div>
            </div>
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.DRIVER_DOC_CHECK ? (
              <div style={{ marginBottom: '4px' }}>
                <TextWithPrefixAndCopy text={form[OrderFields.DRIVER_SER_NUM]} copiedText={form[OrderFields.DRIVER_SER_NUM]} prefix="Пасп:" />
                <PassportRegion passport={form[OrderFields.DRIVER_SER_NUM]} />
              </div>
            ) : null}
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE ? (
              <TextWithPrefixAndCopy
                text={`${getStringFromTwoFields(form[OrderFields.DRIVER_DOC], form[OrderFields.DRIVER_DOC_DATE])} ${
                  // eslint-disable-next-line no-nested-ternary
                  form[OrderFields.IS_DRIVER_DOC_VALID] === true
                    ? '(Действительно)'
                    : form[OrderFields.IS_DRIVER_DOC_VALID] === false
                    ? '(Недействительно)'
                    : '(Не проверяли)'
                }`}
                prefix="ВУ:"
                copiedText={getStringFromTwoFields(form[OrderFields.DRIVER_DOC], form[OrderFields.DRIVER_DOC_DATE])}
              />
            ) : null}
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE ? (
              <>
                {form[OrderFields.DRIVER_DATA_START_DT_STATUS] ? (
                  <p className="p-0 m-0">ТД не нужен</p>
                ) : (
                  <TextWithPrefix text={form[OrderFields.DRIVER_DATA_START_DT]} prefix="ТД:" />
                )}
              </>
            ) : null}
            <TextWithPrefixAndCopy
              text={<PhoneNumberLink phone={form[OrderFields.DRIVER_TEL_1]} />}
              prefix="Тел:"
              copiedText={form[OrderFields.DRIVER_TEL_1]}
            />
            <TextWithPrefixAndCopy
              text={<PhoneNumberLink phone={form[OrderFields.DRIVER_TEL_2]} />}
              prefix="Тел:"
              copiedText={form[OrderFields.DRIVER_TEL_2]}
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
