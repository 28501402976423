import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchCreateOrderSaga } from 'store/order/sagas/fetch-create-order'
import { fetchLogisticInfoSaga } from 'store/order/sagas/fetch-logistic-info'
import { validationHandler } from 'store/order/sagas/validation-handler'
import { fetchGetOrderSaga } from 'store/order/sagas/fetch-get-order'
import { fetchSetExecutorSaga } from 'store/order/sagas/fetch-set-executor'
import { fetchSetResolutionSaga } from 'store/order/sagas/fetch-set-resolution'
import { fetchEditOrderSaga } from 'store/order/sagas/fetch-edit-order'

import * as actions from './order.actions'
import { fetchClarifyDataSaga } from './sagas/fetch-clarify-data'

export default function* () {
  yield all([
    takeEvery(actions.FETCH_CREATE_ORDER_REQUEST, fetchCreateOrderSaga),
    takeLatest(actions.FETCH_ORDER_GET_LOGISTIC_INFO_REQUEST, fetchLogisticInfoSaga),
    takeEvery(actions.CHANGE_ORDER_HANDLER, validationHandler),
    takeLatest(actions.FETCH_GET_ORDER_REQUEST, fetchGetOrderSaga),
    takeLatest(actions.SET_INITIAL_VALUES_ORDER_WRITE, validationHandler),
    takeLatest(actions.FETCH_SET_EXECUTOR_REQUEST, fetchSetExecutorSaga),
    takeLatest(actions.FETCH_SET_RESOLUTION_REQUEST, fetchSetResolutionSaga),
    takeLatest(actions.FETCH_EDIT_ORDER_REQUEST, fetchEditOrderSaga),
    takeLatest(actions.FETCH_CLARIFY_DATA_REQUEST, fetchClarifyDataSaga),
  ])
}
