export enum AutoCheckStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  NONE = 'none',
  PENDING = 'pending',
  INDETERMINATE = 'indeterminate',
}

export enum AutoCheckType {
  OKVED = 'okved',
  COUNT_EMPLOYEES = 'countEmployees',
  REGISTER_DATE = 'registrateDate', // TODO Сделать миграцию и поправить слово registrate на register :)
  CAPITAL = 'capital',
  STATUS_COMPANY = 'statusCompany',
  INCOME = 'income',
  CEO_COMPANIES = 'ceoCompanies',
  DRIVER = 'driver',
  LOGISTIC_FIRST_FACE = 'logisticFirstFace',
  CONTACT = 'contact',
}

export interface AutoCheck {
  orderNumber: string
  type: AutoCheckType
  status: AutoCheckStatus
  data: any
  checks: any
  text: string
  result?: any
  createdAt?: Date
}

export interface GetAutoCheckData {
  status: AutoCheckStatus
  checks: AutoCheck[]
}
