import { put, select, call } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { fetchCurrentUser } from 'requests/user'
import { fetchCurrentUserPreload, fetchCurrentUserSuccess, fetchCurrentUserFailure } from '../users.actions'
import { selectCurrentUser } from '../users.selectors'

function getTokenFromLocalStorage() {
  const token = localStorage.getItem('token')

  if (!token) throw new Error('Нет данных о пользователе.')

  return token
}

export function* fetchCurrentUserSaga() {
  try {
    let currentUser = yield select(selectCurrentUser)
    if (currentUser !== null) {
      yield put(fetchCurrentUserPreload())
      return
    }

    const token = getTokenFromLocalStorage()

    const response = yield call(fetchCurrentUser, token)

    currentUser = {
      token,
      login: response.login,
      role: response.role,
      name: response.name,
      id: response.id,
      features: response.features,
      settings: response.settings,
      debt: response.debt,
    }

    yield put(fetchCurrentUserSuccess(currentUser))
  } catch (error) {
    yield put(fetchCurrentUserFailure(error))
    console.error(`[fetchCurrentUserSaga]: ${error}`)
    if (error.message !== 'Нет данных о пользователе.') captureException(error)
  }
}
