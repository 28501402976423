import { getDateForServer } from 'utils/date'

const getTwoDigitYear = (twoDigit: string) => {
  const nowYear = String(new Date().getFullYear())
  let year
  if (Number(twoDigit) <= Number(nowYear.slice(-2))) year = nowYear.slice(0, 2) + twoDigit
  else year = String(Number(nowYear.slice(0, 2)) - 1) + twoDigit
  return year
}

const pasteDate = (id: string, pastedText: string, onChange: any) => {
  let arrayMatch = pastedText.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[.-]([0-9]{4}) ([0-9]{2}):([0-9]{2})(:([0-9]{2}))?[^]*$/)
  if (arrayMatch) {
    onChange(getDateForServer(new Date(`${arrayMatch[3]}-${arrayMatch[2]}-${arrayMatch[1]}`)), id)
    return
  }

  arrayMatch = pastedText.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[.-]([0-9]{2}) ([0-9]{2}):([0-9]{2})(:([0-9]{2}))?[^]*$/)
  if (arrayMatch) {
    onChange(getDateForServer(new Date(`${getTwoDigitYear(arrayMatch[3])}-${arrayMatch[2]}-${arrayMatch[1]}`)), id)
    return
  }

  arrayMatch = pastedText.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[.-]([0-9]{4})[^]*$/)
  if (arrayMatch) {
    onChange(getDateForServer(new Date(`${arrayMatch[3]}-${arrayMatch[2]}-${arrayMatch[1]}`)), id)
    return
  }

  arrayMatch = pastedText.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[.-]([0-9]{2})[^]*$/)
  if (arrayMatch) {
    onChange(getDateForServer(new Date(`${getTwoDigitYear(arrayMatch[3])}-${arrayMatch[2]}-${arrayMatch[1]}`)), id)
    return
  }

  arrayMatch = pastedText.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[^]*$/)
  if (arrayMatch) {
    onChange(getDateForServer(new Date(`${new Date().getFullYear()}-${arrayMatch[2]}-${arrayMatch[1]}`)), id)
  }
}

const onPasteDate = (event: any, onChange: any) => {
  // TODO
  event.preventDefault()
  const { clipboardData } = event
  const pastedText = clipboardData.getData('text')
  if (event.target.classList.contains('date')) {
    pasteDate(event.target.id, pastedText, onChange)
  } else {
    throw new Error('Попытка вызвать функцию вставки даты не в дату.')
  }
}

export default onPasteDate
