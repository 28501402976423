import { LogisticType, OrderData, OrderFields } from 'types/order'

const getDateForInput = (date: string) => {
  if (!date) return ''
  const year = date.slice(-4)
  const month = date.slice(3, 5)
  const day = date.slice(0, 2)
  return `${year}-${month}-${day}`
}

const getDateTimeForInput = (dateTime: string) => {
  if (!dateTime) return ''
  const year = dateTime.slice(6, 10)
  const month = dateTime.slice(3, 5)
  const day = dateTime.slice(0, 2)
  const hours = dateTime.slice(11, 13)
  const minutes = dateTime.slice(14, 16)
  return `${year}-${month}-${day} ${hours}:${minutes}`
}

const getInitialValues = (form: OrderData) => {
  let logisticType = form[OrderFields.LOGISTIC_TYPE]

  if (!form[OrderFields.LOGISTIC_TYPE]) {
    if (!form[OrderFields.LOGISTIC_INN] || (form[OrderFields.LOGISTIC_INN].length !== 10 && form[OrderFields.LOGISTIC_INN].length !== 12)) {
      logisticType = LogisticType.LEGAL
    } else {
      if (form[OrderFields.LOGISTIC_INN].length === 10) logisticType = LogisticType.LEGAL
      if (form[OrderFields.LOGISTIC_INN].length === 12) logisticType = LogisticType.INDIVIDUAL
    }
  }

  return {
    [OrderFields.EXECUTOR_L1]: form[OrderFields.EXECUTOR_L1], // Исполнитель L1
    [OrderFields.EXECUTOR_EXPERT]: form[OrderFields.EXECUTOR_EXPERT], // Исполнитель E
    [OrderFields.ORDER_DATE]: getDateTimeForInput(form[OrderFields.ORDER_DATE]), // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
    [OrderFields.CLIENT_NAME]: form[OrderFields.CLIENT_NAME], // Клиент - заказчик проверки
    [OrderFields.ORDER_TEXT]: form[OrderFields.ORDER_TEXT], // Текст заявки от клиента
    [OrderFields.ORDER_TYPE]: form[OrderFields.ORDER_TYPE], // Тип заявки
    [OrderFields.PRIORITY]: form[OrderFields.PRIORITY], // Приоритет заявки
    [OrderFields.RESOLUTION]: form[OrderFields.RESOLUTION], // Резолюция
    [OrderFields.RESOLUTION_COMMENT]: form[OrderFields.RESOLUTION_COMMENT], // Комментарий к резолюции
    [OrderFields.ORDER_COMMENT]: form[OrderFields.ORDER_COMMENT], // Внутренний комментарий
    [OrderFields.LOGISTIC_NAME]: form[OrderFields.LOGISTIC_NAME], // Перевозчик. Название юрлица
    [OrderFields.LOGISTIC_INN]: form[OrderFields.LOGISTIC_INN] || '', // Перевозчик. ИНН
    [OrderFields.LOGISTIC_TYPE]: logisticType, // Тип перевозчика. (Юрлицо, ИП, самозанятый)
    [OrderFields.LOGISTIC_OGRN]: form[OrderFields.LOGISTIC_OGRN], // Перевозчик. ОГРН(ИП)
    [OrderFields.LOGISTIC_ATI]: form[OrderFields.LOGISTIC_ATI], // Перевозчик. Ати
    [OrderFields.LOGISTIC_ATI_STATUS]: form[OrderFields.LOGISTIC_ATI_STATUS] === null ? true : form[OrderFields.LOGISTIC_ATI_STATUS], // Перевозчик. Ати. Статус
    [OrderFields.LOGISTIC_FIRST_FACE]: form[OrderFields.LOGISTIC_FIRST_FACE], // Перевозчик.1е лицо
    [OrderFields.LOGISTIC_FIRST_FACE_BITH]: getDateForInput(form[OrderFields.LOGISTIC_FIRST_FACE_BITH]), // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
    [OrderFields.LOGISTIC_FIRST_FACE_INN]: form[OrderFields.LOGISTIC_FIRST_FACE_INN], // Перевозчик.ИНН 1го лица
    [OrderFields.LOGISTIC_FIO]: form[OrderFields.LOGISTIC_FIO], // Перевозчик. ФИО контакта
    [OrderFields.LOGISTIC_TEL]: form[OrderFields.LOGISTIC_TEL], // Перевозчик. Телефон контакта
    [OrderFields.LOGISTIC_TEL_2]: form[OrderFields.LOGISTIC_TEL_2], // Перевозчик. Телефон контакта
    [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: form[OrderFields.CARRIER_FIRST_FACE_PHONE_1], // Перевозчик. Телефон первого лица
    [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: form[OrderFields.CARRIER_FIRST_FACE_PHONE_2], // Перевозчик. Телефон первого лица
    [OrderFields.CARRIER_EMAIL]: form[OrderFields.CARRIER_EMAIL], // Перевозчик. Email контакта
    [OrderFields.DRIVER_FIO]: form[OrderFields.DRIVER_FIO], // Водитель. - Менеджер ФИО
    [OrderFields.DRIVER_BITH]: getDateForInput(form[OrderFields.DRIVER_BITH]), // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_SER_NUM]: form[OrderFields.DRIVER_SER_NUM], // Водитель. Серия и номер паспорта
    [OrderFields.DRIVER_DOC]: form[OrderFields.DRIVER_DOC], // Водитель. Номер ВУ
    [OrderFields.IS_DRIVER_DOC_VALID]: form[OrderFields.IS_DRIVER_DOC_VALID], // Водитель. Действительно ли ВУ
    [OrderFields.DRIVER_DOC_DATE]: getDateForInput(form[OrderFields.DRIVER_DOC_DATE]), // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_DATA_START_DT]: getDateForInput(form[OrderFields.DRIVER_DATA_START_DT]), // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_TEL_1]: form[OrderFields.DRIVER_TEL_1], // Водитель. Телефон 1
    [OrderFields.DRIVER_TEL_2]: form[OrderFields.DRIVER_TEL_2], // Водитель. Телефон 2
    [OrderFields.DRIVER_DATA_START_DT_STATUS]: form[OrderFields.DRIVER_DATA_START_DT_STATUS], // Параметр состояния галочки Дата начала ТД
    [OrderFields.TS_OWNER]: form[OrderFields.TS_OWNER], // ТС. Собственник.
    [OrderFields.TS_OWNER_TEL]: form[OrderFields.TS_OWNER_TEL], // ТС. Собственник. Телефон
    [OrderFields.TS_OWNER_LICENSE_PLATE]: form[OrderFields.TS_OWNER_LICENSE_PLATE],
    [OrderFields.TRAILER_STATUS]: form[OrderFields.TRAILER_STATUS], // Параметр состояния галочки Есть трейлер
    [OrderFields.TRAILER_OWNER]: form[OrderFields.TRAILER_OWNER], // Прицеп. Собственник
    [OrderFields.TRAILER_OWNER_TEL]: form[OrderFields.TRAILER_OWNER_TEL], // Прицеп. Собственник. Телефон
    [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: form[OrderFields.TRAILER_OWNER_LICENSE_PLATE],
    [OrderFields.ROUTE_DATE_START]: getDateForInput(form[OrderFields.ROUTE_DATE_START]), // Рейс. Дата начала. Вид: "yyyy-MM-dd"
    [OrderFields.ROUTE_TRIP]: form[OrderFields.ROUTE_TRIP], // Рейс. Маршрут
    [OrderFields.CARGO]: form[OrderFields.CARGO], // Груз
    [OrderFields.SHIPPER]: form[OrderFields.SHIPPER], // Поле Грузоотправтиль/Заказчик
    [OrderFields.FILES]: form[OrderFields.FILES],
    [OrderFields.TRANSPORT_YEAR]: form[OrderFields.TRANSPORT_YEAR],
    [OrderFields.TRAILER_YEAR]: form[OrderFields.TRAILER_YEAR],
    [OrderFields.TRANSPORT_STS_DATE]: getDateForInput(form[OrderFields.TRANSPORT_STS_DATE]),
    [OrderFields.TRAILER_STS_DATE]: getDateForInput(form[OrderFields.TRAILER_STS_DATE]),
    [OrderFields.TRANSPORT_RENT]: form[OrderFields.TRANSPORT_RENT] || false,
    [OrderFields.TRAILER_RENT]: form[OrderFields.TRAILER_RENT] || false,
    [OrderFields.DRIVER_CREDIT_RATING]: form[OrderFields.DRIVER_CREDIT_RATING] || false,
    [OrderFields.HAS_BELARUSIANS]: form[OrderFields.HAS_BELARUSIANS] || false,
    [OrderFields.CUSTOMER_FEATURES]: form[OrderFields.CUSTOMER_FEATURES] || {},
    [OrderFields.DRIVER_CREDIT_RATING]: form[OrderFields.DRIVER_CREDIT_RATING] || false,
  }
}

export default getInitialValues
