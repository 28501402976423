import React from 'react'
import { PropValue } from 'types/core/props'

const TextWithLineBreaks: React.FC<{ text: PropValue }> = ({ text }) => {
  const textLines = (text ? `${text}` : '').split('\n')

  return (
    <>
      {textLines.map((textLine: string, index: number) => {
        const key = `${index}-${textLine}`
        return [textLine, index === textLines.length - 1 ? null : <br key={key} />]
      })}
    </>
  )
}

export default TextWithLineBreaks
