import React from 'react'

import { AutoCheck } from 'types/auto-checks'

interface FoundArticlesProps {
  check: AutoCheck
}

export const FoundArticles: React.FC<FoundArticlesProps> = ({ check }) => {
  const hasCodes = Boolean(check?.data?.codes?.length)
  if (!hasCodes) return null

  return (
    <div>
      <span style={{ color: 'rgb(153,153,153)' }}>Найденные статьи:</span>
      &nbsp;
      {check?.data?.codes?.join(', ')}
    </div>
  )
}

export default FoundArticles
