import { SelectItem } from 'types/selectLists'

export const getStringFromSelectList = (id: number | string = 0, selectList: SelectItem[] = []) => {
  let value = ''

  if (!selectList) throw new Error('Не получены листы для данного значения.')
  if (typeof id !== 'number') return id

  for (let i = 0; i < selectList.length; i += 1) {
    if (String(selectList[i].id) === String(id)) value = selectList[i].name
  }

  return value
}

export default getStringFromSelectList
