import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectDataFio } from 'store/dadata/dadata.selectors'
import { fetchGetFioRequest } from 'store/dadata/dadata.actions'

import InputTextWithAutocomplete from 'components/molecules/InputTextWithAutocomplete'

export interface InputFioProps {
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string): void
  onBlurHandler?(id?: string): void
  className?: string
  label?: string
  placeholder?: string
  invalidMessage?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
}

export const InputFio: React.FC<InputFioProps> = ({
  id,
  value,
  isValid,
  onChangeHandler,
  onBlurHandler = () => {},
  className,
  label,
  placeholder,
  invalidMessage,
  required = false,
  readonly = false,
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const dataFio = useSelector(selectDataFio)

  const onBlur = useCallback(
    (_id, val) => {
      const currValue = val
      const newValue = currValue.replace(/[^\dА-яA-Za-z-ёЁ\s]/g, '').trim()
      if (currValue !== newValue) onChangeHandler(newValue, id)

      onBlurHandler(id)
    },
    [id, onBlurHandler, onChangeHandler],
  )

  const onChange = useCallback(
    (val, _id, isAutocomplete) => {
      onChangeHandler(val, id)
      if (!isAutocomplete && val && !dataFio[val.trim().toLowerCase()]) dispatch(fetchGetFioRequest({ fio: val, fieldId: id, text: val }))
    },
    [onChangeHandler, id, dispatch, dataFio],
  )

  return (
    <InputTextWithAutocomplete
      id={id}
      value={value}
      isValid={isValid}
      invalidMessage={invalidMessage}
      onBlurHandler={onBlur}
      onChangeHandler={onChange}
      className={className}
      placeholder={placeholder}
      readonly={readonly}
      label={label}
      disabled={disabled}
      required={required}
      autocompleteArray={
        value && dataFio[value.trim().toLowerCase()]
          ? dataFio[value.trim().toLowerCase()].data.filter((val) => val.trim().toLowerCase() !== value.trim().toLowerCase())
          : []
      }
      classNameForAutocompleteContainer={className}
    />
  )
}

export default InputFio
