import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'

import { LoadingStatus } from 'types/loading'

import { selectCurrentUser } from 'store/users/users.selectors'
import { getCompanyAccountRequest } from 'store/billing/billing.actions'
import { selectCompanyAccount, selectGetCompanyAccountLoadingStatus } from 'store/billing/billing.selectors'

import classes from './style.module.css'

const Balance: React.FC = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector(selectCurrentUser)
  const companyAccount = useSelector(selectCompanyAccount)
  const loadingStatus = useSelector(selectGetCompanyAccountLoadingStatus)

  useEffect(() => {
    dispatch(getCompanyAccountRequest('current'))
  }, [currentUser, dispatch])

  const isLoading = [LoadingStatus.NONE, LoadingStatus.PENDING].includes(loadingStatus)
  const isError = loadingStatus === LoadingStatus.FAILED

  if (!companyAccount?.id) return null

  return (
    <div className={classes.container}>
      <div className={classes.balance}>
        <h2>Баланс</h2>

        <div style={{ fontSize: '1.2rem' }}>
          {isError ? (
            <>Ошибка при получении баланса...</>
          ) : (
            <>
              На вашем счету&nbsp;
              <span className="font-weight-bold">
                {isLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : companyAccount.balance}
              </span>
              &nbsp;руб.
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Balance
