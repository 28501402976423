import React, { useCallback } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { UserRole } from 'types/user'
import { LoadingStatus } from 'types/loading'

import { getPartsOfFio } from 'utils/string'

import { selectCurrentUser } from 'store/users/users.selectors'
import { selectLoadingDataGatewayStatus } from 'store/data-gateway/data-gateway.selectors'
import { fetchDataGatewayRequest } from 'store/data-gateway/data-gateway.actions'

import classes from './style.module.css'

interface ButtonGetDataFromDGOnFioAndBirthProps {
  fio: string
  date: string
  bases?: { red: number; yellow: number; green: number } | null
} // name = FIO date = dd.mm.yyyy

export const ButtonGetDataFromDGOnFioAndBirth: React.FC<ButtonGetDataFromDGOnFioAndBirthProps> = ({ fio, date, bases }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const loadingDataGateway = useSelector(selectLoadingDataGatewayStatus)
  const getDataFromDataGateway = useCallback(() => {
    const partsOfDate = date.split('.')

    // eslint-disable-next-line no-nested-ternary
    const nameObject = getPartsOfFio(fio)

    const dateObject =
      partsOfDate.length === 3
        ? {
            yearBirth: Number(partsOfDate[2]),
            monthBirth: Number(partsOfDate[1]),
            dayBirth: Number(partsOfDate[0]),
          }
        : {}

    dispatch(
      fetchDataGatewayRequest({
        ...nameObject,
        ...dateObject,
      }),
    )
  }, [dispatch, fio, date])

  return (
    <p className="m-0 p-0">
      <button type="button" className={classes.button} onClick={getDataFromDataGateway}>
        <span className={classes.prefix}>DG:</span>
      </button>
      &nbsp;
      {currentUser!.role === UserRole.EXPERT && (fio || date) ? (
        <>
          {bases ? (
            <span className="d-inline-block" style={{ color: 'rgb(153, 153, 153)' }}>
              <span className="text-danger">{bases.red}</span>
              &nbsp;|&nbsp;
              <span className="text-warning">{bases.yellow}</span>
              &nbsp;|&nbsp;
              <span className="text-success">{bases.green}</span>
              &nbsp;
            </span>
          ) : (
            <span style={{ color: 'rgb(153, 153, 153)' }}>—</span>
          )}
          {loadingDataGateway === LoadingStatus.PENDING ? (
            <Spinner as="span" animation="grow" style={{ width: '1em', height: '1em' }} role="status" aria-hidden="true" />
          ) : null}
        </>
      ) : null}
    </p>
  )
}

export default ButtonGetDataFromDGOnFioAndBirth
