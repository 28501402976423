import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import { FileData, FileForDocumentsForm, FileProps } from 'types/file'
import { LoadingStatus } from 'types/loading'
import { OrderFields } from 'types/order'

import { selectToken } from 'store/users/users.selectors'
import { updateFileDataRequest } from 'store/file/file.actions'
import { selectUpdateFileDataStatuses } from 'store/file/file.selectors'
import { selectOrderRead } from 'store/order/order.selectors'
import { setInitialValuesOrderRead } from 'store/order/order.actions'

import { Icon, IconType } from 'components/atoms/Icon'
import InputFile from 'components/molecules/InputFile'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

interface DocumentsFormItemProps {
  data: FileData
  orderNumber: string
  title: string
  handleImageClick(image: FileForDocumentsForm): void
  images?: FileForDocumentsForm[]
  index: number
}

const FormItem: React.FC<DocumentsFormItemProps> = ({ data, orderNumber, title, handleImageClick, images = [], index }) => {
  const dispatch = useDispatch()
  const token = useSelector(selectToken)
  const statuses = useSelector(selectUpdateFileDataStatuses)
  const order = useSelector(selectOrderRead)
  const image = useMemo(() => images?.filter((img) => img?.data?.type === data.type)[index - 1], [data.type, images, index])

  const onRemove = useCallback(() => {
    dispatch(updateFileDataRequest({ image: image!, data: { entity: null, type: null } }))
  }, [dispatch, image])

  const onRotate = useCallback(() => {
    dispatch(
      updateFileDataRequest({
        image: image!,
        data: {
          rotation: (image?.data?.rotation || 0) >= 270 ? 0 : (image?.data?.rotation || 0) + 90,
        },
      }),
    )
  }, [dispatch, image])

  const [isLoad, setIsLoad] = useState(false)

  const onStartLoad = useCallback(() => {
    setIsLoad(true)
  }, [])

  const onEndLoad = useCallback(() => {
    setIsLoad(false)
  }, [])

  const handleDownloadResponse = useCallback(
    (file: FileProps, name: string) => {
      dispatch(
        setInitialValuesOrderRead({
          [OrderFields.FILES]: [...order[OrderFields.FILES], { ...file, name }],
        }),
      )
      setIsLoad(false)
    },
    [dispatch, order],
  )

  return (
    <>
      <div className={classes.formItem}>
        {image && !isLoad && !(image?.href_file && statuses[image?.href_file] === LoadingStatus.PENDING) ? (
          <div className={classes.buttons}>
            <Button
              variant="dark"
              className={classNames(classes.icon, classes.turnOver)}
              onClick={onRotate}
              tabIndex={-1}
              style={{ marginRight: '4px' }}
            >
              <Icon type={IconType.Update} width="24px" height="24px" />
            </Button>
            <Button variant="dark" className={classNames(classes.icon)} onClick={onRemove} tabIndex={-1}>
              <Icon type={IconType.Close} width="24px" height="24px" />
            </Button>
          </div>
        ) : null}
        {!(image?.href_file && statuses[image?.href_file] === LoadingStatus.PENDING) && !isLoad ? ( // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div className={classes.image} onClick={image ? () => handleImageClick(image) : () => {}}>
            {image ? (
              <img
                src={`${process.env.REACT_APP_FILES_SERVER}/file/${image.href_file}?token=${token}`}
                alt={`${image?.data?.type} ${image.name}`}
                style={{ transform: `rotate(${image?.data?.rotation || 0}deg)` }}
              />
            ) : (
              <InputFile
                id={`${data.entity}-${data.type}`}
                handleResponse={handleDownloadResponse}
                multiple={false}
                data={{ ...data, inSlot: true }}
                orderNumber={orderNumber}
                onStart={onStartLoad}
                onEnd={onEndLoad}
                accept="image/*"
              />
            )}
          </div>
        ) : null}
        {(image?.href_file && statuses[image?.href_file] === LoadingStatus.PENDING) || isLoad ? (
          <div className={classes.loader}>
            <LoadingPlaceholder />
          </div>
        ) : null}
        <div className={classes.title}>{title}</div>
      </div>
    </>
  )
}

export default FormItem
