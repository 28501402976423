import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'

import classes from './style.module.css'

const Manager: React.FC = () => {
  const form = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0 position-relative">
            <div className={classes.title}>Контакты</div>
            <TextWithPrefixAndCopy text={form[OrderFields.LOGISTIC_FIO]} copiedText={form[OrderFields.LOGISTIC_FIO]} prefix="Контакт:" />
            <div>
              <TextWithPrefixAndCopy
                text={<PhoneNumberLink phone={form[OrderFields.LOGISTIC_TEL]} />}
                copiedText={form[OrderFields.LOGISTIC_TEL]}
                prefix="Тел:"
              />
            </div>
            <div>
              <TextWithPrefixAndCopy
                text={<PhoneNumberLink phone={form[OrderFields.LOGISTIC_TEL_2]} />}
                copiedText={form[OrderFields.LOGISTIC_TEL_2]}
                prefix="Тел:"
              />
            </div>
            <TextWithPrefixAndCopy text={form[OrderFields.CARRIER_EMAIL]} copiedText={form[OrderFields.CARRIER_EMAIL]} prefix="Email:" />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
