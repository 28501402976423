import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { addAlert } from 'store/alerts/alerts.actions'
import { AlertVariant } from 'types/alert'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

interface BillingProps {
  url: string
}

const Billing: React.FC<BillingProps> = ({ url }) => {
  const dispatchStore = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    dispatchStore(
      addAlert({
        header: 'Чат',
        text: 'Не удалось загрузить счета и оплаты',
        lifetime: 6000,
        variant: AlertVariant.DANGER,
      }),
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.billing}>
        <iframe
          key={url}
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          className={classNames(isLoaded ? classes.visible : classes.hidden)}
          title="Биллинг"
          onLoad={onLoad}
          onError={onError}
          style={{ height: '800px' }}
        />
        {isLoaded ? null : (
          <div className={classes.loader}>
            <LoadingPlaceholder />
          </div>
        )}
      </div>
    </div>
  )
}

export default Billing
