import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAddFileOrderFailure, fetchAddFileOrderFinish, fetchAddFileOrderRequest, fetchAddFileOrderSuccess } from 'store/order/order.actions'
import { selectOrderWrite } from 'store/order/order.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'

import { OrderFields } from 'types/order'
import { UserRole } from 'types/user'

import InputFile from 'components/molecules/InputFile'
import { addFileResponseProps } from 'components/molecules/InputFile/addFile'
import Title from 'components/atoms/Title'

import Hint from './components/Hint'

const Customer: React.FC = () => {
  const dispatch = useDispatch()
  const form = useSelector(selectOrderWrite)
  const currentUser = useSelector(selectCurrentUser)

  const DownloadStartHandler = () => {
    dispatch(fetchAddFileOrderRequest())
  }

  const DownloadEndHandler = () => {
    dispatch(fetchAddFileOrderFinish())
  }

  const DownloadAddFileHandler = (response: addFileResponseProps, _fileName: string, status: number) => {
    if (status >= 200 && status < 300 && response?.id) {
      dispatch(fetchAddFileOrderSuccess(response!.id))
    } else {
      dispatch(fetchAddFileOrderFailure())
    }
  }

  return (
    <div className="container p-0">
      <Title text="Документы" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row" style={{ flexWrap: 'nowrap' }}>
          <div className="col-12 p-0">
            {currentUser!.role === UserRole.CUSTOMER ? (
              <div className="text-secondary">
                <Hint orderType={form[OrderFields.ORDER_TYPE]} />
              </div>
            ) : null}
            <InputFile id={OrderFields.FILES} onStart={DownloadStartHandler} onEnd={DownloadEndHandler} handleResponse={DownloadAddFileHandler} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Customer
