import { OrderData, OrderFields, OrderInCheckedValue, ResolutionId } from 'types/order'
import { AutoCheckStatus } from 'types/auto-checks'
import { isTherePhoneMatch } from 'utils/phone'
import { compareTwoFio } from 'utils/string'
import { getDateForWeb, getDateFromServerDate } from 'utils/date'

export class CheckedValueStatus {
  public static get(type: string, check: OrderInCheckedValue, order: OrderData) {
    let status = AutoCheckStatus.NONE

    if (check) {
      switch (String(check.resolution_id)) {
        case ResolutionId.COOPERATION_AGREED:
        case ResolutionId.SHIPMENT_ALLOWED:
          status = AutoCheckStatus.SUCCESS
          break

        case ResolutionId.SHIPMENT_FORBIDDEN:
        case ResolutionId.COOPERATION_FORBIDDEN:
          status = AutoCheckStatus.FAIL
          break

        case ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN:
          if (type === OrderFields.DRIVER_FIO) status = AutoCheckStatus.FAIL
          else status = AutoCheckStatus.SUCCESS
          break

        default:
          break
      }

      if (type === OrderFields.DRIVER_FIO) {
        const lastPhones = [check.driver_tel1, check.driver_tel2]
        const currentPhones = [order[OrderFields.DRIVER_TEL_1], order[OrderFields.DRIVER_TEL_2]]
        if (!isTherePhoneMatch(lastPhones, currentPhones)) {
          status = AutoCheckStatus.FAIL
        }
      }

      if (type === OrderFields.LOGISTIC_FIRST_FACE) {
        const lastPhones = [check.logistic_tel, check.logistic_tel2]
        const currentPhones = [order[OrderFields.LOGISTIC_TEL], order[OrderFields.LOGISTIC_TEL_2]]
        if (!isTherePhoneMatch(lastPhones, currentPhones)) {
          status = AutoCheckStatus.FAIL
        }
      }

      if (type === OrderFields.LOGISTIC_OGRN) {
        const lastPhones = [check.logistic_tel, check.logistic_tel2]
        const currentPhones = [order[OrderFields.LOGISTIC_TEL], order[OrderFields.LOGISTIC_TEL_2]]
        if (
          !isTherePhoneMatch(lastPhones, currentPhones) ||
          !compareTwoFio(check.logistic_first_face || '', order[OrderFields.LOGISTIC_FIRST_FACE]) ||
          getDateForWeb(getDateFromServerDate(check.logistic_first_face_bith || '')) !== order[OrderFields.LOGISTIC_FIRST_FACE_BITH]
        ) {
          status = AutoCheckStatus.FAIL
        }
      }
    }

    return status
  }
}
