import { OrderData, OrderFields, OrderTypeId, ResolutionId } from 'types/order'
import { SelectItem } from 'types/selectLists'

import { getResolutionsForExpertByOrderType } from 'utils/order'

import { ORDER_TYPES_TO_CHECK_DRIVER, PHRASES_OF_RESOLUTION_FOR_DRIVER, PHRASES_OF_RESOLUTION_FOR_LOGISTIC_PAYER } from './constants'

export const getResolutions = (orderFields: OrderData, resolutions: SelectItem[]) => {
  const necessaryResolutions = getResolutionsForExpertByOrderType(orderFields[OrderFields.ORDER_TYPE])
  // Фильтруем. (Либо нужная резолюция, либо текущая)
  return resolutions.filter(
    (item) => necessaryResolutions.includes(String(item.id) as ResolutionId) || orderFields[OrderFields.RESOLUTION] === item.id,
  )
}

export const getPhrasesForResolutionComment = (orderType: string | number) => {
  const phrases: { value: string; label?: string }[] = [{ value: 'Криминала, негатива нет' }]
  if (ORDER_TYPES_TO_CHECK_DRIVER.includes(String(orderType) as OrderTypeId)) phrases.push(...PHRASES_OF_RESOLUTION_FOR_DRIVER)
  if ((String(orderType) as OrderTypeId) === OrderTypeId.LOGISTIC_PAYER) phrases.push(...PHRASES_OF_RESOLUTION_FOR_LOGISTIC_PAYER)

  return phrases
}

export const concatenateNewLineToComment = (comment: string, line: string) => (comment ? `${comment}\n${line}` : line)
