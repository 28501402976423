import { call, put, select } from 'redux-saga/effects'
import { fetchOrderList, fetchTakeOrder } from 'requests/order'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'

import { fetchTakeNextOrderFailure, fetchTakeNextOrderSuccess } from '../orders.actions'
import { SorterFactory } from '../../../components/molecules/OrderList/orders/sorter/sorter-factory'
import { UserRole } from '../../../types/user'
import { Order, ResolutionId } from '../../../types/order'

export function* fetchTakeNextOrderSaga() {
  try {
    const token = yield select(selectToken)
    const { response } = yield call(fetchOrderList, token)
    const orders = response?.ordersList
    const sort = SorterFactory.create(UserRole.EXPERT)
    const nextOrder = (sort(orders) as Order[]).filter((order: Order) => String(order.resolution) === ResolutionId.FOR_E)[0]

    if (!nextOrder) {
      yield put(
        addAlert({
          text: `Нет подходящих заявок.`,
          lifetime: 3000,
          variant: AlertVariant.WARNING,
          header: 'Взятие заявки',
        }),
      )

      yield put(fetchTakeNextOrderFailure(new Error('Нет подходящих заявок.')))
      return
    }

    yield call(fetchTakeOrder, nextOrder.orderNumber, token)
    yield put(
      addAlert({
        text: `Заявка ${nextOrder.orderNumber} успешно взята.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Взятие заявки',
      }),
    )
    yield put(fetchTakeNextOrderSuccess(nextOrder.orderNumber))
  } catch (error) {
    yield put(fetchTakeNextOrderFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Взятие заявки',
        defaultText: 'Не удалось взять заявку',
        textForCase: {
          403: 'Не удалось взять заявку.\n Её уже взяли.\n Попробуйте ещё раз.',
        },
      }),
    )
    console.error(`[fetchTakeOrderSaga]: ${error}`)
    captureException(error)
  }
}
