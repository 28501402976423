import React from 'react'
import { useSelector } from 'react-redux'

import { selectSearchOrders } from 'store/orders/orders.selectors'
import { Order, OrderFields } from 'types/order'

import TextWithSelectedSubstring from 'components/atoms/TextWithSelectedSubstring'
import { OrderFieldNames } from '../../../constants/order'

interface SearchMatchProps {
  order: Order
}

const fields = [
  OrderFields.LOGISTIC_NAME,
  OrderFields.LOGISTIC_INN,
  OrderFields.LOGISTIC_OGRN,
  OrderFields.LOGISTIC_FIRST_FACE,
  OrderFields.LOGISTIC_FIRST_FACE_INN,
  OrderFields.LOGISTIC_FIO,
  OrderFields.LOGISTIC_TEL,
  OrderFields.LOGISTIC_TEL_2,
  OrderFields.DRIVER_FIO,
  OrderFields.DRIVER_TEL_1,
  OrderFields.DRIVER_TEL_2,
  OrderFields.TS_OWNER,
  OrderFields.TS_OWNER_TEL,
  OrderFields.TRAILER_OWNER,
  OrderFields.TRAILER_OWNER_TEL,
  OrderFields.ORDER_NUMBER,
  OrderFields.TS_OWNER_LICENSE_PLATE,
  OrderFields.TRAILER_OWNER_LICENSE_PLATE,
]

export const SearchMatch: React.FC<SearchMatchProps> = ({ order }) => {
  const searchString = useSelector(selectSearchOrders)

  return (
    <>
      {fields.map((field) => (
        <p className="m-0 p-0" key={field}>
          {
            // @ts-ignore
            order[field] &&
            // @ts-ignore
            order[field].toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ? (
              <>
                {`${OrderFieldNames[field]}: `}
                <TextWithSelectedSubstring
                  text={
                    // @ts-ignore
                    order[field]
                  }
                  substring={searchString}
                />
                <br />
              </>
            ) : null
          }
        </p>
      ))}
      <hr />
    </>
  )
}

export default SearchMatch
