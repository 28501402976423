import { combineReducers } from 'redux'
import { FieldTemplate } from 'types/core/field-template'
import ordersReducer, { OrdersState } from 'store/orders/orders.reducer'
import usersReducer, { UsersState } from 'store/users/users.reducer'
import selectListsReducer, { SelectListsState } from 'store/selectLists/selectLists.reducer'
import orderReducer, { OrderState } from 'store/order/order.reducer'
import alertsReducer, { AlertsState } from 'store/alerts/alerts.reducer'
import notificationsReducer, { NotificationsState } from 'store/notifications/notifications.reducer'
import autoChecksReducer, { AutoChecksState } from 'store/autoChecks/autoChecks.reducer'
import telegramChatListReducer, { TelegramChatListState } from 'store/telegramChatList/telegramChatList.reducer'
import HttpErrorReducer, { HttpErrorState } from 'store/http-error/http-error.reducer'
import versionReducer, { VersionState } from 'store/version/version.reducer'
import dadataReducer, { DadataState } from 'store/dadata/dadata.reducer'
import dataGatewayReducer, { DataGatewayState } from 'store/data-gateway/data-gateway.reducer'
import utilsReducer, { UtilsState } from 'store/utils/utils.reducer'
import fileReducer, { FileState } from 'store/file/file.reducer'
import newsReducer, { NewsState } from 'store/news/news.reducer'
import adminReducer, { AdminState } from 'store/admin/admin.reducer'
import expertsOnlineReducer, { ExpertsOnlineState } from 'store/experts-online/experts-online.reducer'
import billingReducer, { BillingState } from 'store/billing/billing.reducer'
import dictionaryReducer, { DictionaryState } from 'store/dictionary/dictionary.reducer'
import { templateReducer, TemplateState } from 'store/template/template.reducer'

export interface AppState {
  orders: OrdersState
  users: UsersState
  selectLists: SelectListsState
  order: OrderState
  alerts: AlertsState
  notifications: NotificationsState
  autochecks: AutoChecksState
  telegramchatlist: TelegramChatListState
  httpError: HttpErrorState
  version: VersionState
  dadata: DadataState
  dataGateway: DataGatewayState
  utils: UtilsState
  file: FileState
  news: NewsState
  admin: AdminState
  expertsOnline: ExpertsOnlineState
  billing: BillingState
  dictionary: DictionaryState
  template: TemplateState<FieldTemplate>
}

export default combineReducers<AppState>({
  orders: ordersReducer,
  users: usersReducer,
  selectLists: selectListsReducer,
  order: orderReducer,
  alerts: alertsReducer,
  notifications: notificationsReducer,
  autochecks: autoChecksReducer,
  telegramchatlist: telegramChatListReducer,
  httpError: HttpErrorReducer,
  version: versionReducer,
  dadata: dadataReducer,
  dataGateway: dataGatewayReducer,
  utils: utilsReducer,
  file: fileReducer,
  news: newsReducer,
  admin: adminReducer,
  expertsOnline: expertsOnlineReducer,
  billing: billingReducer,
  dictionary: dictionaryReducer,
  template: templateReducer,
})
