import React, { useCallback } from 'react'
import classNames from 'classnames'

import classes from './style.module.css'

interface AnchorNavbarProps {
  items: {
    name: string
    id: string
  }[]
}

export const AnchorNavbar: React.FC<AnchorNavbarProps> = ({ items }) => {
  const onClickHandler = useCallback((id) => {
    const block: null | HTMLElement = document.getElementById(`__${id}`)
    if (block) {
      const rect = block.getBoundingClientRect()
      window.scrollTo(window.pageXOffset, window.pageYOffset + rect.top - 108)
    }
  }, [])

  return (
    <div className={classes.container}>
      <div className={classNames('row bg-white', classes.menu)}>
        {items.map((item) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div key={`#__${item.id}`} className={classNames('col text-center align-middle', classes.item)} onClick={() => onClickHandler(item.id)}>
            <span className={classes.header_menu_item_text}>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnchorNavbar
