import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'
import { UserRole } from 'types/user'
import { isCurrentUserLoadingInProgress, selectCurrentUser } from 'store/users/users.selectors'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

export interface PrivateRouteProps extends RouteProps {
  authorizedUserRoles: UserRole[]
  redirectUnauthorizedTo: string
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ authorizedUserRoles, redirectUnauthorizedTo, ...routeProps }) => {
  const currentUser = useSelector(selectCurrentUser)
  const isLoadingInProgress = useSelector(isCurrentUserLoadingInProgress)

  if (isLoadingInProgress) {
    return <LoadingPlaceholder />
  }

  const isUserAllowed = currentUser !== null && (authorizedUserRoles.length ? authorizedUserRoles.includes(currentUser?.role) : true)

  if (!isUserAllowed) {
    return <Redirect to={redirectUnauthorizedTo} />
  }

  return <Route {...routeProps} />
}
