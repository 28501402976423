import React from 'react'

import { AutoCheck } from 'types/auto-checks'

import classes from './style.module.css'

interface NegativeStatusProps {
  check: AutoCheck | null
  hideEmpty?: boolean
}

const NegativeStatus: React.FC<NegativeStatusProps> = ({ check, hideEmpty }) => {
  if (hideEmpty && !check?.data?.groups?.length) return null

  return (
    <p className="p-0 m-0" style={{ color: 'rgb(153,153,153)' }}>
      Н:&nbsp;
      {check?.data?.groups?.length ? (
        <>
          {check?.data?.groups?.includes('Н0') ? (
            <span className={classes.yellowBackgroundColor}>Н0{check?.data?.groups.length > 1 ? ', ' : null}</span>
          ) : null}
          <span className={classes.redBackgroundColor}>{check?.data?.groups?.filter((item: string) => item !== 'Н0')?.join(', ')}</span>
        </>
      ) : (
        <span>Н?</span>
      )}
    </p>
  )
}

export default NegativeStatus
