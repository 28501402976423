import { LogisticType, OrderData, OrderFields, OrderTypeId } from 'types/order'
import { Feature } from 'types/user'
import { EMAIL_IS_UNVERIFIED_TEXT, EMAIL_IS_VERIFIED_TEXT } from '../ForExpertForm/VerifyEmailForm'

const LogisticAndDriverRules = {
  [OrderFields.LOGISTIC_INN]: 'required',
  [OrderFields.LOGISTIC_OGRN]: 'required',
  // [OrderFields.LOGISTIC_FIRST_FACE_BITH]: 'required',
  [OrderFields.LOGISTIC_FIO]: 'required',
  [OrderFields.LOGISTIC_TEL]: 'required',
  [OrderFields.DRIVER_FIO]: 'required',
  [OrderFields.DRIVER_BITH]: 'required',
  [OrderFields.DRIVER_SER_NUM]: 'required',
  [OrderFields.DRIVER_DOC]: 'required',
  [OrderFields.DRIVER_TEL_1]: 'required',
  [OrderFields.TS_OWNER]: 'required',
  [OrderFields.TS_OWNER_TEL]: 'required',
}

const CounterpartyAndTsRules = {
  [OrderFields.LOGISTIC_INN]: 'required',
  [OrderFields.LOGISTIC_OGRN]: 'required',
  [OrderFields.LOGISTIC_FIO]: 'required',
  [OrderFields.LOGISTIC_TEL]: 'required',
  [OrderFields.TS_OWNER]: 'required',
  [OrderFields.TS_OWNER_TEL]: 'required',
}

const DriverAndTSRules = {
  [OrderFields.DRIVER_FIO]: 'required',
  [OrderFields.DRIVER_BITH]: 'required',
  [OrderFields.DRIVER_SER_NUM]: 'required',
  [OrderFields.DRIVER_DOC]: 'required',
  [OrderFields.DRIVER_TEL_1]: 'required',
  [OrderFields.TS_OWNER]: 'required',
  [OrderFields.TS_OWNER_TEL]: 'required',
}

const DriverRules = {
  [OrderFields.DRIVER_FIO]: 'required',
  [OrderFields.DRIVER_BITH]: 'required',
  [OrderFields.DRIVER_SER_NUM]: 'required',
}

const LogisticRules = {
  [OrderFields.LOGISTIC_INN]: 'required',
  [OrderFields.LOGISTIC_OGRN]: 'required',
}

const getRules = (order: OrderData) => {
  let rules: { [key: string]: any } = {}

  switch (String(order?.orderType)) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      rules = { ...LogisticAndDriverRules }
      break

    case OrderTypeId.DRIVER_AND_TS:
      rules = { ...DriverAndTSRules }
      break

    case OrderTypeId.DRIVER:
    case OrderTypeId.EMPLOYEE:
    case OrderTypeId.STATE_DRIVER:
    case OrderTypeId.DRIVER_FOR_LOADING:
      rules = { ...DriverRules }
      break

    case OrderTypeId.LOGISTIC_CONTRACTOR:
    case OrderTypeId.LOGISTIC_PAYER:
    case OrderTypeId.LOGISTIC:
      rules = { ...LogisticRules }
      break

    case OrderTypeId.COUNTERPARTY_BORROWER_AND_TS:
      rules = { ...CounterpartyAndTsRules }
      break

    default:
      break
  }

  if (
    (OrderFields.LOGISTIC_OGRN in rules && order?.[OrderFields.LOGISTIC_TYPE] === LogisticType.SELF_EMPLOYED) ||
    order?.[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN
  )
    delete rules[OrderFields.LOGISTIC_OGRN]

  if (
    order?.[OrderFields.CUSTOMER_FEATURES]?.[Feature.ALL_FIELDS_ARE_REQUIRED] &&
    [OrderTypeId.LOGISTIC_AND_DRIVER, OrderTypeId.DRIVER_AND_TS, OrderTypeId.DRIVER_FOR_LOADING].includes(String(order?.orderType) as OrderTypeId) &&
    !order?.[OrderFields.DRIVER_DATA_START_DT_STATUS]
  ) {
    rules[OrderFields.DRIVER_DATA_START_DT] = 'required'
  } else {
    delete rules[OrderFields.DRIVER_DATA_START_DT]
  }

  if (order?.[OrderFields.CARRIER_EMAIL]) {
    rules[OrderFields.ORDER_COMMENT] = [`required`, `regex:/.*${EMAIL_IS_VERIFIED_TEXT}|${EMAIL_IS_UNVERIFIED_TEXT}.*/`]
  }

  return rules
}

export default getRules
