import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { fetchEditOrder, fetchTransferOrder } from 'requests/order'
import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditOrderData, OrderFields } from 'types/order'

import { fetchSetExecutorFailure, fetchSetExecutorSuccess } from '../order.actions'

export function* fetchSetExecutorSaga(action: Action<EditOrderData>) {
  try {
    const token = yield select(selectToken)

    yield call(fetchTransferOrder, action.payload[OrderFields.ORDER_NUMBER], token)
    yield call(fetchEditOrder, action.payload, token)

    yield put(fetchSetExecutorSuccess())
    yield put(
      addAlert({
        text: `Изменение исполнителя прошло успешно.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Заявка',
      }),
    )
  } catch (error) {
    yield put(fetchSetExecutorFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение исполнителя',
        defaultText: 'Не удалось изменить исполнителя.',
      }),
    )
    console.error(`[fetchSetExecutorSaga]: ${error}`)
    captureException(error)
  }
}
