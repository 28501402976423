import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'
import { Feature } from 'types/user'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import { getStringWithSpacesFromFields } from 'utils/string'

import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'

const GeneralInfo: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)

  return (
    <>
      <p className="p-0 m-0 text-secondary small">
        {getStringWithSpacesFromFields(
          form[OrderFields.ORDER_NUMBER],
          form[OrderFields.EXECUTOR_L1] ? `L1: ${getStringFromSelectList(form[OrderFields.EXECUTOR_L1], selectLists?.executor)}` : '',
          form[OrderFields.EXECUTOR_EXPERT] ? `E: ${getStringFromSelectList(form[OrderFields.EXECUTOR_EXPERT], selectLists?.executor)}` : '',
        )}
      </p>

      {form?.[OrderFields.CUSTOMER_FEATURES]?.[Feature.IMPORTANT_INSTRUCTIONS_FOR_E] ? (
        <div className="p-3 mb-2 bg-danger text-white text-center" style={{ marginTop: '4px' }}>
          <TextWithLineBreaks text={form?.[OrderFields.CUSTOMER_FEATURES]?.[Feature.IMPORTANT_INSTRUCTIONS_FOR_E]} />
        </div>
      ) : null}

      {form[OrderFields.COMPANY_COMMENT] ? (
        <>
          <span className={classes.redBackgroundColor} style={{ padding: '4px 8px', marginTop: '4px' }}>
            Комментарий к клиенту
          </span>
          <div style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '4px' }}>
            <TextWithLineBreaks text={form[OrderFields.COMPANY_COMMENT]} />
          </div>
        </>
      ) : null}
    </>
  )
}

export default GeneralInfo
