import { createAction } from 'redux-actions'
import { SelectLists } from 'types/selectLists'

export const FETCH_SELECT_LISTS_REQUEST = 'FETCH_SELECT_LISTS_REQUEST'
export const fetchSelectListsRequest = createAction<void>(FETCH_SELECT_LISTS_REQUEST)

export const FETCH_SELECT_LISTS_SUCCESS = 'FETCH_SELECT_LISTS_SUCCESS'
export const fetchSelectListsSuccess = createAction<SelectLists>(FETCH_SELECT_LISTS_SUCCESS)

export const FETCH_SELECT_LISTS_FAILURE = 'FETCH_SELECT_LISTS_FAILURE'
export const fetchSelectListsFailure = createAction<Error>(FETCH_SELECT_LISTS_FAILURE)

export const SET_INITIAL_STATE_SELECT_LISTS = 'SET_INITIAL_STATE_SELECT_LISTS'
export const setInitialStateSelectLists = createAction<void>(SET_INITIAL_STATE_SELECT_LISTS)
