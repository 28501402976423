import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import getStringFromSelectList from 'utils/getStringFromSelectList'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'

import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'
import { OrderProgressBar } from 'components/molecules/OrderProgressBar/OrderProgressBar'
import { DataClarification } from './components/DataClarification'

const Customer: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)

  return (
    <>
      <DataClarification />
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          {form[OrderFields.HAS_BELARUSIANS] ? (
            <div className="p-3 mb-2 bg-info text-white text-center">
              В заявке указаны граждане РБ. Проверка граждан РБ занимает от 1 часа до 3 суток
            </div>
          ) : null}
          <OrderProgressBar orderNumber={form[OrderFields.ORDER_NUMBER]} />
          <div className="p-0">
            <p className="p-0 m-0 text-primary">{form[OrderFields.ORDER_TIME]}</p>
            <span className="p-0 m-0 font-weight-bold">{getStringFromSelectList(form[OrderFields.PRIORITY], selectLists?.priority)}</span>
            <p className="p-0 m-0">{form[OrderFields.LOGISTIC_NAME]}</p>
            <p className="p-0 m-0">
              <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
                Вод:
              </span>
              &nbsp;
              {form[OrderFields.DRIVER_FIO]}
            </p>
            <p className="p-0 m-0">
              <TextWithLineBreaks text={form[OrderFields.RESOLUTION_COMMENT]} />
            </p>
            <small style={{ color: 'rgb(153, 153, 153)' }}>{form[OrderFields.ORDER_NUMBER]}</small>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
