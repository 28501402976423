import React, { useCallback } from 'react'
import { Button, Form } from 'react-bootstrap'
import classNames from 'classnames'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

export interface SelectControlProps {
  value: string
  id: string
  onChangeHandler(value: string, id?: string): void
  className?: string
  label?: string
  rows?: number
  isValid?: boolean | undefined
  invalidMessage?: string
  placeholder?: string
  withCross?: boolean
  disabled?: boolean
  phrases?: {
    value: string
    label?: string
  }[]
}

export const TextareaControl: React.FC<SelectControlProps> = ({
  value,
  id,
  onChangeHandler,
  className = '',
  label,
  rows,
  isValid,
  invalidMessage = '',
  placeholder = '',
  withCross = false,
  disabled = false,
  phrases,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(event.target.value, id)
  }

  const cleanOffHandler = useCallback(() => {
    onChangeHandler('', id)
  }, [onChangeHandler, id])

  return (
    <Form.Group className="m-0 w-100">
      {label ? <Form.Label>{label}</Form.Label> : null}
      <div className="position-relative">
        <Form.Control
          as="textarea"
          className={classNames(className, classes.textarea)}
          value={value || ''}
          onChange={onChange}
          rows={rows}
          isValid={isValid}
          placeholder={placeholder}
          isInvalid={isValid === false}
          disabled={disabled}
        />
        {withCross && value && !disabled ? (
          <button type="button" onClick={cleanOffHandler} className={classes.cross}>
            <Icon type={IconType.Close} width="1rem" height="1rem" />
          </button>
        ) : null}
        {invalidMessage && isValid === false ? <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback> : null}
      </div>
      {phrases && !disabled ? (
        <div className={classes.phrases}>
          {phrases.map((phrase, index) => (
            <React.Fragment key={phrase.value}>
              <Button
                variant="link"
                className={classes.phrase}
                onClick={() => onChangeHandler(value ? `${value}\n${phrase.value}` : phrase.value, id)}
                title={phrase.value}
              >
                {phrase.label || phrase.value}
              </Button>
              {index !== phrases?.length - 1 ? ', ' : null}
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </Form.Group>
  )
}

export default TextareaControl
