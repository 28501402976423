import { OrderBodyType, OrderFields, OrderFormSpaceId, ResolutionId } from 'types/order'

export const HIGH_RISK_CITIES = [
  'мо',
  'мск',
  'москва',
  'санкт-петербург',
  'спб',
  'питер',
  'посад',
  'подольск',
  'чехов',
  'домодедово',
  'ворсино',
  'балашиха',
  'ногинск',
  'икша',
  'дзержинск',
  'мытищи',
  'пушкино',
  'дмитров',
  'апрелевка',
  'железнодорожный',
  'ложки',
  'лобня',
  'истра',
  'одинцово',
  'кашира',
  'котельники',
  'коломна',
  'софьино',
  'обухово',
  'реутов',
  'раменское',
  'ступино',
  'химки',
  'видное',
  'томилино',
  'бекасово',
  'сергиев',
  'люберцы',
  'климовск',
  'солнечногорск',
  'бритово',
  'клин',
  'зараменье',
  'коммунарка',
  'егорьевск',
  'глазов',
  'красногорск',
  'лешково',
  'внуково',
  'долгопрудный',
  'дорохово',
  'кузяево',
  'звенигород',
]

export const OrderFormSpaceName = {
  [OrderFormSpaceId.HEADER]: 'Исполнитель',
  [OrderFormSpaceId.ORDER]: 'Заявка',
  [OrderFormSpaceId.CONTACT]: 'Контакты',
  [OrderFormSpaceId.LOGISTIC]: 'Перевозчик',
  [OrderFormSpaceId.DRIVER]: 'Водитель',
  [OrderFormSpaceId.TS]: 'ТС',
  [OrderFormSpaceId.ROUTE]: 'Рейс и маршрут',
  [OrderFormSpaceId.FILES]: 'Документы',
}

export const OrderBodyTypePath = {
  [OrderBodyType.ORDER]: '',
  [OrderBodyType.AUTO_CHECKS]: 'auto-checks',
  [OrderBodyType.EXTERNAL_CHAT]: 'chat',
  [OrderBodyType.INTERNAL_CHAT]: 'internal-chat',
  [OrderBodyType.CERTIFICATE]: 'certificate',
  [OrderBodyType.ORDER_HISTORY]: 'order-history',
}

export const OrderFieldSpaceNames: { [key: string]: string } = {
  [OrderFields.LOGISTIC_NAME]: 'юрлицо',
  [OrderFields.LOGISTIC_FIRST_FACE]: '1-е лицо',
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: '1-е лицо',
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: '1-е лицо',
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: '1-е лицо',
  [OrderFields.LOGISTIC_FIO]: 'контакт',
  [OrderFields.LOGISTIC_TEL]: 'контакт',
  [OrderFields.LOGISTIC_TEL_2]: 'контакт',
  [OrderFields.DRIVER_FIO]: 'водитель',
  [OrderFields.DRIVER_TEL_1]: 'водитель',
  [OrderFields.DRIVER_TEL_2]: 'водитель',
  [OrderFields.TS_OWNER]: 'контакт ТС',
  [OrderFields.TS_OWNER_TEL]: 'контакт ТС',
  [OrderFields.TRAILER_OWNER]: 'контакт Прицепа',
  [OrderFields.TRAILER_OWNER_TEL]: 'контакт Прицепа',
}

export const OrderFieldNames: { [key: string]: string } = {
  [OrderFields.ORDER_TIME]: 'Время создания заявки',
  [OrderFields.ORDER_NUMBER]: 'Номер заявки',
  [OrderFields.EXECUTOR]: 'Исполнитель',
  [OrderFields.EXECUTOR_L1]: 'Исполнитель L1',
  [OrderFields.EXECUTOR_EXPERT]: 'Исполнитель EXPERT',
  [OrderFields.ORDER_DATE]: 'Дата и время заявки от клиента',
  [OrderFields.CLIENT_NAME]: 'Имя клиента',
  [OrderFields.ORDER_TEXT]: 'Текст заявки от клиента',
  [OrderFields.ORDER_TYPE]: 'Тип заявки',
  [OrderFields.PRIORITY]: 'Приоритет заявки',
  [OrderFields.RESOLUTION]: 'Резолюция',
  [OrderFields.RESOLUTION_COMMENT]: 'Комментарий к резолюции',
  [OrderFields.ORDER_COMMENT]: 'Внутренний комментарий',
  [OrderFields.LOGISTIC_NAME]: 'Перевозчик. Название юрлица',
  [OrderFields.LOGISTIC_INN]: 'Перевозчик. ИНН',
  [OrderFields.LOGISTIC_TYPE]: 'Тип перевочика',
  [OrderFields.LOGISTIC_OGRN]: 'Перевозчик. ОГРН(ИП)',
  [OrderFields.LOGISTIC_ATI]: 'Перевозчик. Ати',
  [OrderFields.LOGISTIC_FIRST_FACE]: 'Перевозчик.1-е лицо',
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: 'Перевозчик. ДР 1-го лица',
  [OrderFields.LOGISTIC_FIRST_FACE_INN]: 'Перевозчик.ИНН 1-го лица',
  [OrderFields.LOGISTIC_FIO]: 'Перевозчик. ФИО контакта',
  [OrderFields.LOGISTIC_TEL]: 'Перевозчик. Телефон контакта',
  [OrderFields.LOGISTIC_TEL_2]: 'Перевозчик. Второй телефон контакта',
  [OrderFields.DRIVER_FIO]: 'Водитель. ФИО',
  [OrderFields.DRIVER_BITH]: 'Водитель. Дата рождения',
  [OrderFields.DRIVER_SER_NUM]: 'Водитель. Серия и номер паспорта',
  [OrderFields.DRIVER_DOC]: 'Водитель. Номер ВУ',
  [OrderFields.DRIVER_DOC_DATE]: 'Водитель. ВУ. Дата выдачи',
  [OrderFields.DRIVER_DATA_START_DT]: 'Водитель. Дата начала ТД',
  [OrderFields.DRIVER_TEL_1]: 'Водитель. Первый телефон',
  [OrderFields.DRIVER_TEL_2]: 'Водитель. Второй телефон',
  [OrderFields.TS_OWNER]: 'ТС. Собственник',
  [OrderFields.TS_OWNER_TEL]: 'ТС. Собственник. Телефон',
  [OrderFields.TS_OWNER_LICENSE_PLATE]: 'ТС. Госномер',
  [OrderFields.TRAILER_OWNER]: 'Прицеп. Собственник',
  [OrderFields.TRAILER_OWNER_TEL]: 'Прицеп. Собственник. Телефон',
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: 'Прицеп. Госномер',
  [OrderFields.ROUTE_DATE_START]: 'Рейс. Дата начала',
  [OrderFields.ROUTE_TRIP]: 'Рейс. Маршрут',
  [OrderFields.CARGO]: 'Груз',
  [OrderFields.SHIPPER]: 'Поле Грузоотправитель/Заказчик',
}

export const ACTIVE_ORDER_STATUSES = [
  ResolutionId.WIP,
  ResolutionId.CLARIFY_DATA,
  ResolutionId.NEW_ORDER,
  ResolutionId.L1_PROCESSING,
  ResolutionId.CLARIFIED_DATA,
  ResolutionId.FOR_E,
  ResolutionId.E_PROCESSING,
  ResolutionId.DRIVER_IS_NOT_RESPONDING,
  ResolutionId.PREPARATION,
]

export const EFFECTIVE_ORDER_STATUSES = [
  ResolutionId.COOPERATION_AGREED,
  ResolutionId.SHIPMENT_ALLOWED,
  ResolutionId.SHIPMENT_FORBIDDEN,
  ResolutionId.COOPERATION_FORBIDDEN,
  ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
]

export const NEGATIVE_ORDER_STATUSES_FOR_LOGISTIC = [
  ResolutionId.SHIPMENT_FORBIDDEN,
  ResolutionId.COOPERATION_FORBIDDEN,
  ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
]
