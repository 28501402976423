import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExistingFileTypes, FileDataType, FileForDocumentsForm } from 'types/file'
import { OrderFields, OrderTypeId } from 'types/order'

import { selectToken } from 'store/users/users.selectors'
import { updateFileDataRequest } from 'store/file/file.actions'
import { selectOrderRead } from 'store/order/order.selectors'

import { Icon, IconType } from 'components/atoms/Icon'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'
import DropdownMenu, { DropdownMenuItemProps } from 'components/molecules/DropdownMenu'

import classes from './style.module.css'
import { getFreeTypes } from './utils'

interface DocumentsFormGalleryProps {
  image: FileForDocumentsForm
  existingTypes: ExistingFileTypes[]
  index: number
  handleImageClick(image: FileForDocumentsForm, index: number): void
}

export const GalleryItem: React.FC<DocumentsFormGalleryProps> = ({ image, existingTypes, index, handleImageClick }) => {
  const dispatch = useDispatch()
  const order = useSelector(selectOrderRead)
  const token = useSelector(selectToken)

  const [isLoad, setIsLoad] = useState(false)

  const eventPreventDefaultAndStopPropagation = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const setTypeOfImage = useCallback(
    (item: DropdownMenuItemProps, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      eventPreventDefaultAndStopPropagation(event)
      setIsLoad(true)
      dispatch(
        updateFileDataRequest({
          image,
          data: { type: item.data?.type, inSlot: true },
        }),
      )
    },
    [dispatch, image],
  )

  const freeTypes = useMemo(() => {
    let types = getFreeTypes(existingTypes, String(order[OrderFields.ORDER_TYPE]) as OrderTypeId)

    // Если нет прицепа, то вторые ячейки для СТС не нужны
    if (order[OrderFields.TRAILER_STATUS] && existingTypes.find((type) => type.type === FileDataType.STS_VIN)) {
      types = types.filter((type) => ![FileDataType.STS_VIN, FileDataType.STS_OWNER].includes(type.data?.type))
    }

    return types
  }, [existingTypes, order])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      key={image.href_file}
      onClick={() => {
        if (!isLoad) handleImageClick(image, index)
      }}
      className={classes.galleryItem}
    >
      {!isLoad && freeTypes.length ? (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
        <div className={classes.listOfTypes} onClick={eventPreventDefaultAndStopPropagation}>
          <DropdownMenu
            toggleText={<Icon type={IconType.MenuDotted} width="24px" height="24px" />}
            toggleVariant="dark"
            classNameForToggle={classes.listOfTypesButton}
            classNameForMenu={classes.menuOfTypes}
            classNameForMenuItem={classes.menuOfTypesItem}
            list={freeTypes}
            onClickItem={setTypeOfImage}
          />
        </div>
      ) : null}
      {!isLoad ? (
        <div className="d-flex justify-content-center" style={{ transform: `rotate(${image?.data?.rotation || 0}deg)` }}>
          <img
            src={`${process.env.REACT_APP_FILES_SERVER}/file/${image.href_file}?token=${token}`}
            alt={`${image.data?.recognitionType} ${image.name}`}
          />
        </div>
      ) : null}
      {isLoad ? (
        <div className={classes.loader}>
          <LoadingPlaceholder />
        </div>
      ) : null}
    </div>
  )
}

export default GalleryItem
