import React from 'react'

interface TextWithSelectedSubstringProps {
  text: string
  substring: string
}

export const TextWithSelectedSubstring: React.FC<TextWithSelectedSubstringProps> = ({ text, substring }) => {
  const indexOf = text.toLowerCase().indexOf(substring.toLowerCase())
  const startText = text.slice(0, indexOf)
  const endText = text.slice(indexOf + substring.length, text.length)
  return (
    <>
      <span>{startText}</span>
      <span className="font-weight-bold">{substring}</span>
      <span>{endText}</span>
    </>
  )
}

export default TextWithSelectedSubstring
