import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { selectOrderFetchGetStatus, selectOrderRead } from 'store/order/order.selectors'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'
import { selectCurrentUser, selectToken } from 'store/users/users.selectors'
import { clearDictionary, getDictionaryLinkedEntriesRequest } from 'store/dictionary/dictionary.actions'

import { DictionaryEntryType } from 'types/dictionary'
import { LoadingStatus } from 'types/loading'
import { OrderBodyType, OrderFields } from 'types/order'

import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'
import OrderSpace from 'components/molecules/OrderSpace'
import Chat from 'components/molecules/Chat'
import AutoChecks from 'components/molecules/AutoChecks'
import Certificate from 'components/molecules/Certificate'
import OrderHistory from 'components/molecules/OrderHistory'

import getSpaces from './utils/getSpaces'
import { UserRole } from '../../../types/user'

interface OrderBodyProps {
  type: OrderBodyType
}

export const OrderBody: React.FC<OrderBodyProps> = ({ type }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const form = useSelector(selectOrderRead)
  const token = useSelector(selectToken)
  const { orderNumber } = useParams()
  const orderFetchStatus = useSelector(selectOrderFetchGetStatus)
  const loadingSelectListStatus = useSelector(selectLoadingSelectListsStatus)

  useEffect(() => {
    const userIsExpert = currentUser?.role === UserRole.EXPERT
    const orderIsLoaded = orderFetchStatus === LoadingStatus.SUCCESS
    if (userIsExpert && orderIsLoaded) {
      dispatch(
        getDictionaryLinkedEntriesRequest({
          entry: { name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH], type: DictionaryEntryType.PERSON },
          referenceTypes: [DictionaryEntryType.PHONE],
        }),
      )
    }

    return () => {
      dispatch(clearDictionary())
    }
  }, [dispatch, form, orderFetchStatus, currentUser])

  if (orderFetchStatus === LoadingStatus.NONE || loadingSelectListStatus === LoadingStatus.NONE) return null

  if (orderFetchStatus === LoadingStatus.FAILED || loadingSelectListStatus === LoadingStatus.FAILED) return <span className="m-2">Ошибка!</span>

  if (orderFetchStatus === LoadingStatus.PENDING || loadingSelectListStatus === LoadingStatus.PENDING) return <LoadingPlaceholder />

  const spaces = getSpaces(form.orderType)

  switch (type) {
    case OrderBodyType.ORDER:
      return (
        <ul className="list-group">
          {spaces.map((space) => (
            <div key={space} id={`__${space}`}>
              <OrderSpace id={space} />
            </div>
          ))}
        </ul>
      )

    case OrderBodyType.AUTO_CHECKS:
      return <AutoChecks orderNumber={orderNumber} token={token} />

    case OrderBodyType.EXTERNAL_CHAT:
      return <Chat token={token} orderNumber={orderNumber} />

    case OrderBodyType.INTERNAL_CHAT:
      return <Chat isInternal token={token} orderNumber={orderNumber} />

    case OrderBodyType.CERTIFICATE:
      return <Certificate />

    case OrderBodyType.ORDER_HISTORY:
      return <OrderHistory />
    default:
      throw new Error('Не выбран пункт меню в заявке')
  }
}

export default OrderBody
