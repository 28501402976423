import { transformResponse, v3Api } from './axios'

export const fetchExpertsOnline = (token: string) =>
  transformResponse(
    v3Api.get('/expert/online', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const saveExpertsOnline = (data: number[], token: string) =>
  transformResponse(
    v3Api.post('/expert/online', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
