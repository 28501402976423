import React from 'react'

export enum AlertVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

export interface Alert {
  lifetime?: number
  text: string | React.ReactNode
  header?: string | React.ReactNode
  variant: AlertVariant
  id?: number
  withLineBreaks?: boolean
}
