const reSp = /\s+/
const reJunk = /'&/

interface Options {
  wordsScoreFactor: number
  startsWithScoreFactor: number
  charsScoreFactor: number
}

function getWordsScore(textWords: string[], inputWords: string[]) {
  let sharedWordsCount = 0
  for (let i = 0; i < inputWords.length; i += 1) {
    if (textWords.indexOf(inputWords[i]) !== -1) {
      sharedWordsCount += 1
    }
  }
  return sharedWordsCount / (inputWords.length + textWords.length - sharedWordsCount)
}

function getStartsWithScore(textWords: string[], inputWords: string[]) {
  let sharedWordsStartCount = 0
  for (let i = 0; i < inputWords.length; i += 1) {
    for (let j = 0; j < textWords.length; j += 1) {
      if (textWords[j].indexOf(inputWords[i]) === 0) {
        sharedWordsStartCount += 1
      }
    }
  }
  return sharedWordsStartCount / (inputWords.length + textWords.length - sharedWordsStartCount)
}

function getCharsScore(search: string, input: string) {
  let sharedCharsCount = 0
  for (let i = 0; i < search.length; i += 1) {
    const c = search[i]
    const matchedCharIndex = input.indexOf(c, sharedCharsCount)
    if (matchedCharIndex !== -1) {
      sharedCharsCount += 1
    }
  }
  return sharedCharsCount / (input.length + search.length - sharedCharsCount)
}

function getSimilarityScore(text: string, input: string, opts: Options) {
  const textWords = text.split(reSp)
  const inputWords = input.split(reSp)
  const wordsScore = getWordsScore(textWords, inputWords)
  const startsWithScore = getStartsWithScore(textWords, inputWords)
  const charsScore = getCharsScore(text, input)
  return (
    (wordsScore * opts.wordsScoreFactor + startsWithScore * opts.startsWithScoreFactor + charsScore * opts.charsScoreFactor) /
    (opts.wordsScoreFactor + opts.startsWithScoreFactor + opts.charsScoreFactor)
  )
}

export function fuzzySearch(
  searchString: string,
  inputString: string,
  opts: Options = { wordsScoreFactor: 1, charsScoreFactor: 1, startsWithScoreFactor: 1 },
) {
  if (!inputString) {
    return 0
  }
  return getSimilarityScore(searchString.replace(reJunk, '').toLowerCase(), inputString.replace(reJunk, '').toLowerCase(), opts)
}
