import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import TextWithPrefix from 'components/atoms/TextWithPrefix'
import Title from 'components/atoms/Title'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'

const Customer: React.FC = () => {
  const form = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <Title text="Водитель / Сотрудник в штат" />
          <div className="p-0">
            <TextWithPrefix
              text={form[OrderFields.DRIVER_FIO] + (form[OrderFields.DRIVER_CREDIT_RATING] ? ' (Кредитный рейтинг)' : '')}
              prefix="Водитель:"
            />
            <p className="p-0 m-0">{form[OrderFields.DRIVER_BITH]}</p>
            <TextWithPrefix text={<PhoneNumberLink phone={form[OrderFields.DRIVER_TEL_1]} />} prefix="Телефон 1:" />
            <TextWithPrefix text={<PhoneNumberLink phone={form[OrderFields.DRIVER_TEL_2]} />} prefix="Телефон 2:" />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
