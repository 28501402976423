import { createAction } from 'redux-actions'
import { GetAutoCheckData } from 'types/auto-checks'

export const FETCH_AUTO_CHECKS_REQUEST = 'FETCH_AUTO_CHECKS_REQUEST'
export const fetchAutoChecksRequest = createAction<string>(FETCH_AUTO_CHECKS_REQUEST)

export const FETCH_LAST_AUTO_CHECKS_REQUEST = 'FETCH_LAST_AUTO_CHECKS_REQUEST'
export const fetchLastAutoChecksRequest = createAction<void>(FETCH_LAST_AUTO_CHECKS_REQUEST)

export const FETCH_AUTO_CHECKS_SUCCESS = 'FETCH_AUTO_CHECKS_SUCCESS'
export const fetchAutoChecksSuccess = createAction<GetAutoCheckData>(FETCH_AUTO_CHECKS_SUCCESS)

export const FETCH_AUTO_CHECKS_FAILURE = 'FETCH_AUTO_CHECKS_FAILURE'
export const fetchAutoChecksFailure = createAction<Error>(FETCH_AUTO_CHECKS_FAILURE)

export const AUTO_CHECKS_SET_INITIAL_STATE = 'AUTO_CHECKS_SET_INITIAL_STATE'
export const autoChecksSetInitialState = createAction<void>(AUTO_CHECKS_SET_INITIAL_STATE)
