import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { LinkDictionaryEntryResponse, UnlinkDictionaryEntryRequest } from 'types/dictionary'

import { unlinkDictionaryEntry } from 'requests/dictionary'

import { unlinkDictionaryEntriesFailure, unlinkDictionaryEntriesSuccess } from 'store/dictionary/dictionary.actions'
import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'

export function* unlinkDictionaryEntriesSaga(action: Action<UnlinkDictionaryEntryRequest>) {
  try {
    const token = yield select(selectToken)

    const response: LinkDictionaryEntryResponse = yield call(unlinkDictionaryEntry, action.payload, token)

    yield put(unlinkDictionaryEntriesSuccess({ sourceEntries: action.payload, response }))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Верификация телефона',
        defaultText: 'Не удалось убрать верификацию с телефона.',
      }),
    )
    console.error(`[unlinkDictionaryEntriesSaga]: ${error}`)
    yield put(unlinkDictionaryEntriesFailure(action.payload))
  }
}
