export const getStringWithSpacesFromFields = (...args: Array<string | null | undefined>): string => {
  return args.reduce((result: string, field) => (result ? `${result}${field ? ` ${field}` : ''}` : `${field || ''}`), '')
}

export const getPartsOfFio = (fio: string | null): { firstName?: string; lastName?: string; middleName?: string } => {
  const parts = fio ? fio.replace(/\s+/g, ' ').trim().split(' ') : []

  if (parts.length === 0) return {}
  if (parts.length === 1) return { lastName: parts[0] }
  if (parts.length === 2) return { lastName: parts[0], firstName: parts[1] }
  return { lastName: parts[0], firstName: parts[1], middleName: parts.slice(2).join(' ') }
}

// Формат даты dd.mm.yyyy
export const getPartsOfDate = (date: string | null): { yearBirth?: number; monthBirth?: number; dayBirth?: number } => {
  const parts = date ? date.trim().split('.') : []

  if (parts.length !== 3) return {}
  return { dayBirth: Number(parts[0]), monthBirth: Number(parts[1]), yearBirth: Number(parts[2]) }
}

export const getNounWithDeclension = (number: number, one: string, two: string, five: string) => {
  let n = Math.abs(number)
  n %= 100
  if (n >= 5 && n <= 20) {
    return five
  }
  n %= 10
  if (n === 1) {
    return one
  }
  if (n >= 2 && n <= 4) {
    return two
  }
  return five
}

export const addLeadingZeros = (value: number | string, size: number) => {
  let result = `${value}`
  while (result.length < size) {
    result = `0${result}`
  }
  return result
}

export const compareTwoFio = (firstFio: string, secondFio: string) =>
  firstFio?.replace(/\s+/g, ' ')?.trim()?.toLocaleLowerCase() === secondFio?.replace(/\s+/g, ' ')?.trim()?.toLocaleLowerCase()
