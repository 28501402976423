import { createAction } from 'redux-actions'
import { SelectLists } from 'types/selectLists'

export const FETCH_CURRENT_TIME_REQUEST = 'FETCH_CURRENT_TIME_REQUEST'
export const fetchCurrentTimeRequest = createAction<void>(FETCH_CURRENT_TIME_REQUEST)

export const FETCH_CURRENT_TIME_SUCCESS = 'FETCH_CURRENT_TIME_SUCCESS'
export const fetchCurrentTimeSuccess = createAction<SelectLists>(FETCH_CURRENT_TIME_SUCCESS)

export const FETCH_CURRENT_TIME_FAILURE = 'FETCH_CURRENT_TIME_FAILURE'
export const fetchCurrentTimeFailure = createAction<Error>(FETCH_CURRENT_TIME_FAILURE)

export const SET_INITIAL_STATE_UTILS = 'SET_INITIAL_STATE_UTILS'
export const setInitialStateUtils = createAction<void>(SET_INITIAL_STATE_UTILS)
