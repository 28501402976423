import React, { useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import CopyToClipboard from 'react-copy-to-clipboard'

import { OrderBodyType, OrderFields } from 'types/order'
import { LoadingStatus } from 'types/loading'
import { AlertVariant } from 'types/alert'

import { selectOrderFetchGetStatus, selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectNotifications } from 'store/notifications/notifications.selectors'
import { addAlert } from 'store/alerts/alerts.actions'

import Header from 'components/molecules/Header'
import { Icon, IconType } from 'components/atoms/Icon'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import getNotificationsForOrder from 'utils/getNotificationsForOrder'

import classes from './style.module.css'
import { OrderHeaderProps } from './index'
import { BackLink } from '../../atoms/BackLink'

export const Manager: React.FC<OrderHeaderProps> = ({ orderBodyType, setOrderBodyType }) => {
  const dispatchStore = useDispatch()
  const { orderNumber } = useParams()
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)
  const loadingFetchStatus = useSelector(selectOrderFetchGetStatus)
  const notifications = useSelector(selectNotifications)

  const leftButton = useMemo(() => <BackLink />, [])

  const handleOrderLinkCopy = useCallback(() => {
    dispatchStore(
      addAlert({
        header: 'Успешное копирование',
        text: `Ссылка на заявку ${orderNumber} успешно скопирована`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
      }),
    )
  }, [dispatchStore, orderNumber])

  const headerName = useMemo(
    () => (
      <CopyToClipboard text={`${process.env.REACT_APP_WEB}/order/${orderNumber}`} onCopy={handleOrderLinkCopy}>
        <span className={classes.headerName}>
          {loadingFetchStatus === LoadingStatus.SUCCESS
            ? `${form!.orderTime} ${getStringFromSelectList(form[OrderFields.CLIENT_NAME], selectLists?.clientName)}`
            : 'Заявка'}
        </span>
      </CopyToClipboard>
    ),
    [orderNumber, handleOrderLinkCopy, loadingFetchStatus, form, selectLists],
  )

  const rightButton = useMemo(
    () => (
      <Link to={`/order/${orderNumber}/edit`} className={classes.headerButton}>
        <Icon type={IconType.Edit} width="36px" height="36px" />
      </Link>
    ),
    [orderNumber],
  )

  const headerMenuHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      event.currentTarget.id &&
      !event.currentTarget.classList.contains(classes.disabled) &&
      (event.currentTarget.id === OrderBodyType.ORDER ||
        event.currentTarget.id === OrderBodyType.AUTO_CHECKS ||
        event.currentTarget.id === OrderBodyType.EXTERNAL_CHAT ||
        event.currentTarget.id === OrderBodyType.INTERNAL_CHAT)
    )
      setOrderBodyType(event.currentTarget.id)
  }

  const notificationsForOrder = useMemo(() => getNotificationsForOrder(orderNumber, notifications), [orderNumber, notifications])

  return (
    <div className="sticky-top" id="page-header">
      <Header leftButton={leftButton} headerName={headerName} rightButton={rightButton}>
        {loadingFetchStatus === LoadingStatus.SUCCESS ? (
          <div className={classNames('bg-white row', classes.header_menu)}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames('col-3 text-center', classes.header_menu_item, orderBodyType === OrderBodyType.ORDER ? classes.active : '')}
              onClick={headerMenuHandler}
              id={OrderBodyType.ORDER}
            >
              <Icon type={IconType.CheckCircle} className={classes.orderIcon} />
              <span className={classes.header_menu_item_text}>&nbsp; Заявка</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames('col-3 text-center', classes.header_menu_item, orderBodyType === OrderBodyType.AUTO_CHECKS ? classes.active : '')}
              onClick={headerMenuHandler}
              id={OrderBodyType.AUTO_CHECKS}
            >
              <span className={classes.header_menu_item_text}>&nbsp; Кэп</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                'col-3 text-center',
                classes.header_menu_item,
                orderBodyType === OrderBodyType.EXTERNAL_CHAT ? classes.active : '',
              )}
              onClick={headerMenuHandler}
              id={OrderBodyType.EXTERNAL_CHAT}
            >
              <div>
                <Icon type={IconType.Comment} className={classes.chatIcon} />
                {notificationsForOrder.length === 2 || (notificationsForOrder.length && !notificationsForOrder[0].isInternal) ? (
                  <span className={classNames(classes.dot, 'bg-danger')} />
                ) : null}
              </div>
              <span className={classes.header_menu_item_text}>&nbsp; Клиент</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                'col-3 text-center',
                classes.header_menu_item,
                orderBodyType === OrderBodyType.INTERNAL_CHAT ? classes.active : '',
              )}
              onClick={headerMenuHandler}
              id={OrderBodyType.INTERNAL_CHAT}
            >
              <div>
                <Icon type={IconType.Comment} className={classes.chatIcon} />
                {notificationsForOrder.length === 2 || (notificationsForOrder.length && notificationsForOrder[0].isInternal) ? (
                  <span className={classNames(classes.dot, 'bg-danger')} />
                ) : null}
              </div>
              <span className={classes.header_menu_item_text}>&nbsp; Внутр.</span>
            </div>
          </div>
        ) : null}
      </Header>
    </div>
  )
}

export default Manager
