import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'

import { OrderFields } from 'types/order'
import { LoadingStatus } from 'types/loading'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'
import { changeOrderHandler, setInitialValuesOrderWrite } from 'store/order/order.actions'
import { selectLoadingSelectListsStatus } from 'store/selectLists/selectLists.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'
import { selectLoadingCurrentTimeStatus } from 'store/utils/utils.selectors'

import OrderFormSpace from 'components/molecules/OrderFormSpace'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import getSpaces from './utils/getSpaces'

interface OrderFormProps {
  isCreate?: boolean
  initialValues: {}
}

export const OrderForm: React.FC<OrderFormProps> = ({ isCreate = false, initialValues }) => {
  const form = useSelector(selectOrderWrite)
  const currentUser = useSelector(selectCurrentUser)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setInitialValuesOrderWrite(initialValues))
  }, [dispatch, initialValues])

  const errors = useSelector(selectOrderFormErrors)
  const spaces = useMemo(() => getSpaces(form[OrderFields.ORDER_TYPE], isCreate, currentUser!, errors), [form, isCreate, currentUser, errors])

  const loadingSelectListsStatus = useSelector(selectLoadingSelectListsStatus)
  const loadingCurrentTimeStatus = useSelector(selectLoadingCurrentTimeStatus)
  if (loadingSelectListsStatus === LoadingStatus.PENDING || loadingCurrentTimeStatus === LoadingStatus.PENDING) return <LoadingPlaceholder />

  const onChangeHandler = (value: string | boolean | number, id: string) => {
    dispatch(
      changeOrderHandler({
        [id]: value,
      }),
    )
  }

  return (
    <Form
      onSubmit={(event: React.FormEvent) => {
        event.preventDefault()
      }}
    >
      <ul className="list-group needs-validation" style={{ margin: '0 -15px' }}>
        {spaces.map((space) => (
          <li key={`__${space}`} className="list-group-item" id={`__${space}`}>
            <OrderFormSpace key={space} id={space} onChange={onChangeHandler} isCreate={isCreate} />
          </li>
        ))}
      </ul>
    </Form>
  )
}

export default OrderForm
