import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'
import { AlertVariant } from 'types/alert'
import { saveExpertsOnline } from 'requests/experts-online'
import { fetchExpertsOnlineRequest, saveExpertsOnlineFailure, saveExpertsOnlineSuccess } from '../experts-online.actions'

export function* setExpertsOnlineSaga(action: Action<number[]>) {
  try {
    const token = yield select(selectToken)

    yield call(saveExpertsOnline, action.payload, token)

    yield put(saveExpertsOnlineSuccess())
    yield put(
      addAlert({
        text: `Изменение экспертов online прошло успешно.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Эксперты online',
      }),
    )
    yield put(fetchExpertsOnlineRequest())
  } catch (error) {
    yield put(saveExpertsOnlineFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение экспертов online',
        defaultText: 'Не удалось изменить экспертов online.',
      }),
    )
    console.error(`[setExpertsOnlineSaga]: ${error}`)
  }
}
