import { OrderTypeId, ResolutionId } from 'types/order'

export const ORDER_TYPES_TO_CHECK_DRIVER = [OrderTypeId.LOGISTIC_AND_DRIVER, OrderTypeId.DRIVER_AND_TS, OrderTypeId.DRIVER_FOR_LOADING]

export const FINISH_POSITIVE_RESOLUTIONS = [ResolutionId.SHIPMENT_ALLOWED, ResolutionId.COOPERATION_AGREED]

export const FINISH_NEGATIVE_RESOLUTIONS = [ResolutionId.SHIPMENT_FORBIDDEN, ResolutionId.COOPERATION_FORBIDDEN]

export const FINISH_MIXED_RESOLUTIONS = [ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN]

export const FINISH_RESOLUTIONS = [
  ResolutionId.SHIPMENT_ALLOWED,
  ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
  ResolutionId.SHIPMENT_FORBIDDEN,
  ResolutionId.COOPERATION_AGREED,
  ResolutionId.COOPERATION_FORBIDDEN,
  ResolutionId.CHECK_CANCELED,
]

export const ORDER_TYPE_FOR_FAST_SUBMIT = [
  OrderTypeId.LOGISTIC_PAYER,
  OrderTypeId.STATE_DRIVER,
  OrderTypeId.EMPLOYEE,
  OrderTypeId.DRIVER_DOC_CHECK,
  OrderTypeId.COUNTERPARTY_BORROWER_AND_TS,
  OrderTypeId.PHONE_CHECK,
]

export const PHRASES_OF_RESOLUTION_FOR_DRIVER = [
  { label: 'Водитель отказался говорить', value: 'Водитель отказался говорить.' },
  { label: 'Водитель не на связи', value: 'Водитель не на связи.' },
  { label: 'Негатив по водителю', value: 'Негатив по водителю.' },
  {
    label: 'Номер водителя - чужой',
    value: 'Запросите основной номер водителя. Этот, вероятно, используется другим человеком.',
  },
  {
    label: 'Номер водителя - пустышка',
    value: 'Запросите корректный номер водителя, этот пустышка.',
  },
  {
    label: 'Номер водителя - недоступен/автоответчик',
    value: 'Запросите дополнительный номер водителя, этот недоступен/автоответчик.',
  },
  {
    value: 'Запросите дополнительный номер водителя.',
  },
]

export const PHRASES_OF_RESOLUTION_FOR_LOGISTIC_PAYER = [
  {
    value: 'Основных средств 0. Рекомендована работа по предоплате, либо с ограничением уровня дебиторской задолженности.',
    label: 'Основных средств 0...',
  },
]
