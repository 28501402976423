import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'
import { Action } from 'redux-actions'

import { fetchGetFio } from 'requests/dadata'

import { GetFio } from 'types/dadata'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchGetFioSuccess, fetchGetFioFailure, fetchGetFioPending } from '../dadata.actions'

export function* fetchGetFioSaga(action: Action<GetFio>) {
  try {
    yield put(fetchGetFioPending(action.payload))
    if (!action.payload.fio) {
      yield put(fetchGetFioSuccess({ id: action.payload.fieldId, data: [], text: '' }))
    } else {
      const token = yield select(selectToken)

      const data = {
        fio: action.payload.fio,
      }

      const response: { data: string[] } = yield call(fetchGetFio, data, token)

      yield put(
        fetchGetFioSuccess({
          data: response.data,
          text: action.payload.text,
          id: action.payload.fieldId,
        }),
      )
    }
  } catch (error) {
    yield put(fetchGetFioFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение автозаполнения ФИО',
        defaultText: 'Не удалось получить автозаполнение ФИО.',
      }),
    )
    console.error(`[fetchLogisticInfoSaga]: ${error}`)
    captureException(error)
  }
}
