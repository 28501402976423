import { Action, handleActions } from 'redux-actions'
import { LoadingStatus } from 'types/loading'

import * as actions from './file.actions'
import { UpdateFileData } from '../../types/file'

export interface FileState {
  updateFileDataStatuses: {
    [uuid: string]: LoadingStatus
  }
  rotateFileStatuses: {
    [uuid: string]: LoadingStatus
  }
}

export const initialFileState: FileState = {
  updateFileDataStatuses: {},
  rotateFileStatuses: {},
}

const updateFileDataRequest = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  updateFileDataStatuses: {
    ...state.updateFileDataStatuses,
    [action.payload.image.href_file]: LoadingStatus.PENDING,
  },
})

const updateFileDataSuccess = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  updateFileDataStatuses: {
    ...state.updateFileDataStatuses,
    [action.payload.image.href_file]: LoadingStatus.SUCCESS,
  },
})

const updateFileDataFailure = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  updateFileDataStatuses: {
    ...state.updateFileDataStatuses,
    [action.payload.image.href_file]: LoadingStatus.FAILED,
  },
})

const rotateFileRequest = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  rotateFileStatuses: {
    ...state.rotateFileStatuses,
    [action.payload.image.href_file]: LoadingStatus.PENDING,
  },
})

const rotateFileSuccess = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  rotateFileStatuses: {
    ...state.rotateFileStatuses,
    [action.payload.image.href_file]: LoadingStatus.SUCCESS,
  },
})

const rotateFileFailure = (state: FileState, action: Action<UpdateFileData>): FileState => ({
  ...state,
  rotateFileStatuses: {
    ...state.rotateFileStatuses,
    [action.payload.image.href_file]: LoadingStatus.FAILED,
  },
})

export default handleActions<FileState, any>(
  {
    [actions.UPDATE_FILE_DATA_REQUEST]: updateFileDataRequest,
    [actions.UPDATE_FILE_DATA_SUCCESS]: updateFileDataSuccess,
    [actions.UPDATE_FILE_DATA_FAILURE]: updateFileDataFailure,
    [actions.ROTATE_FILE_REQUEST]: rotateFileRequest,
    [actions.ROTATE_FILE_SUCCESS]: rotateFileSuccess,
    [actions.ROTATE_FILE_FAILURE]: rotateFileFailure,
  },
  initialFileState,
)
