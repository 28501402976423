import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { UserRole } from 'types/user'
import { Order, OrderFields, OrderFilterId } from 'types/order'
import { selectOrderFilterByExpert, selectOrderFilterId, selectOrders } from 'store/orders/orders.selectors'
import { SorterFactory } from '../sorter/sorter-factory'

const excludedFilterIds = [OrderFilterId.ALL_ORDERS, OrderFilterId.ALL_FINISHED_ORDERS]

export const useSortedOrders = (role: UserRole): Order[] => {
  const filterByExpert = useSelector(selectOrderFilterByExpert)
  const orders = useSelector(selectOrders)
  const orderFilterId = useSelector(selectOrderFilterId) as OrderFilterId

  // TODO Сделать общию фильтрацию.
  //  Сейчас у нас есть отедельные функции фильтры и фильтр отедльно по экспертам
  const ordersWithExpert = orders.filter((order) => (filterByExpert ? String(order[OrderFields.EXECUTOR_EXPERT]) === String(filterByExpert) : true))

  return useMemo(() => {
    if (excludedFilterIds.includes(orderFilterId)) {
      return ordersWithExpert
    }

    return SorterFactory.create(role)(ordersWithExpert) as Order[]
  }, [orderFilterId, role, ordersWithExpert])
}
