import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'

import InputDate from 'components/molecules/InputDate'
import RelatedField from 'components/molecules/RelatedField'
import InputPhone from 'components/molecules/InputPhone'
import InputFio from 'components/molecules/InputFio'
import Checkbox from 'components/molecules/Checkbox'
import Title from 'components/atoms/Title'

import { DriverProps } from './index'

const Customer: React.FC<DriverProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  return (
    <>
      <Title text="Водитель / Сотрудник в штат" />
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0">
            <InputFio
              id={OrderFields.DRIVER_FIO}
              value={form[OrderFields.DRIVER_FIO]}
              isValid={errors[OrderFields.DRIVER_FIO].isValid}
              invalidMessage={errors[OrderFields.DRIVER_FIO].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="ФИО:"
            />
          </div>
          <RelatedField
            fieldName={OrderFields.DRIVER_FIO}
            fieldToCopy={OrderFields.LOGISTIC_FIO}
            relatedFields={{ [OrderFields.DRIVER_TEL_1]: OrderFields.LOGISTIC_TEL, [OrderFields.DRIVER_TEL_2]: OrderFields.LOGISTIC_TEL_2 }}
          />
          <RelatedField
            fieldName={OrderFields.DRIVER_FIO}
            fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE}
            relatedFields={{
              [OrderFields.DRIVER_TEL_1]: OrderFields.CARRIER_FIRST_FACE_PHONE_1,
              [OrderFields.DRIVER_TEL_2]: OrderFields.CARRIER_FIRST_FACE_PHONE_2,
            }}
          />
        </li>
        <li className="list-group-item">
          <div className="p-0">
            <InputDate
              id={OrderFields.DRIVER_BITH}
              value={form[OrderFields.DRIVER_BITH]}
              isValid={errors[OrderFields.DRIVER_BITH].isValid}
              invalidMessage={errors[OrderFields.DRIVER_BITH].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Д.Р."
            />
          </div>
        </li>
        <li className="list-group-item">
          <div className="p-0">
            <InputPhone
              id={OrderFields.DRIVER_TEL_1}
              value={form[OrderFields.DRIVER_TEL_1]}
              isValid={errors[OrderFields.DRIVER_TEL_1].isValid}
              invalidMessage={errors[OrderFields.DRIVER_TEL_1].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон 1:"
            />
          </div>
          <RelatedField fieldName={OrderFields.DRIVER_TEL_1} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
          <RelatedField fieldName={OrderFields.DRIVER_TEL_1} fieldToCopy={OrderFields.CARRIER_FIRST_FACE_PHONE_1} relatedFields={{}} />
        </li>
        <li className="list-group-item">
          <div className="p-0">
            <InputPhone
              id={OrderFields.DRIVER_TEL_2}
              value={form[OrderFields.DRIVER_TEL_2]}
              isValid={errors[OrderFields.DRIVER_TEL_2].isValid}
              invalidMessage={errors[OrderFields.DRIVER_TEL_2].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон 2:"
            />
          </div>
        </li>
        <li className="list-group-item">
          <div className="p-0">
            <Checkbox
              id={OrderFields.DRIVER_CREDIT_RATING}
              value={form[OrderFields.DRIVER_CREDIT_RATING]}
              onChangeHandler={onChange}
              label="Заказать кредитный рейтинг на водителя, +120 руб к стоимости"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
