import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { addAlert } from 'store/alerts/alerts.actions'
import { AlertVariant } from 'types/alert'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

interface StatisticProps {
  src: string
}

const Statistic: React.FC<StatisticProps> = ({ src }) => {
  const dispatchStore = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(false)
  }, [src])

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    dispatchStore(
      addAlert({
        header: 'Статистика',
        text: 'Не удалось загрузить статистику',
        lifetime: 6000,
        variant: AlertVariant.DANGER,
      }),
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <iframe
          key={src}
          src={`${src}&randomNumber=${Date.now()}`}
          width="100%"
          height="100%"
          frameBorder="0"
          className={classNames(isLoaded ? classes.visible : classes.hidden)}
          title="Статистика"
          onLoad={onLoad}
          onError={onError}
        />
        {isLoaded ? null : <LoadingPlaceholder />}
      </div>
    </div>
  )
}

export default Statistic
