import React from 'react'

import { AutoCheckStatus } from 'types/auto-checks'

import classes from './style.module.css'

interface StatusValueProps {
  status: AutoCheckStatus
  numberOfDays: number | null
  numberOfChecks: number
}

export const StatusValue: React.FC<StatusValueProps> = ({ status, numberOfDays, numberOfChecks }) => {
  return (
    <>
      &nbsp;
      <span className={classes[status]}>{numberOfChecks ? `${numberOfDays}дн, x${numberOfChecks}` : '—'}</span>
    </>
  )
}
