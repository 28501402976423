import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { createOrderPath, searchOrderPath } from 'routes/url-constants'
import { useMenu } from 'hooks/use-menu'
import { Icon, IconType } from 'components/atoms/Icon'
import Header from 'components/molecules/Header'
import Menu from 'components/molecules/Menu'

import classes from './style.module.css'

export const SearchOrderHeader: React.FC = () => {
  const { menuLinks, isMenuOpen, toggleMenu } = useMenu(searchOrderPath)

  const onKeyPressHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') toggleMenu()
    },
    [toggleMenu],
  )

  const leftButton = useMemo(
    () => (
      <div className={classes.leftButton} onClick={toggleMenu} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
        <Icon type={IconType.Menu} width="36px" height="36px" />
      </div>
    ),
    [onKeyPressHandler, toggleMenu],
  )

  const rightButton = useMemo(
    () => (
      <>
        <Link to={createOrderPath}>
          <div title="Создание заявки" className={classes.rightButton}>
            <Icon type={IconType.Plus} width="36" height="36" />
          </div>
        </Link>
      </>
    ),
    [],
  )

  return (
    <>
      <Header leftButton={leftButton} headerName="Поиск заявок" rightButton={rightButton} />
      <Menu show={isMenuOpen} toggle={toggleMenu} navLinks={menuLinks} />
    </>
  )
}

export default SearchOrderHeader
