import { all, takeLatest } from 'redux-saga/effects'

import * as actions from './experts-online.actions'
import { getExpertsOnlineSaga } from './sagas/get-experts-online.saga'
import { setExpertsOnlineSaga } from './sagas/set-experts-online.saga'

export default function* () {
  yield all([
    takeLatest(actions.FETCH_EXPERTS_ONLINE_REQUEST, getExpertsOnlineSaga),
    takeLatest(actions.SAVE_EXPERTS_ONLINE_REQUEST, setExpertsOnlineSaga),
  ])
}
