import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderRead } from 'store/order/order.selectors'

import { OrderFields } from 'types/order'

import classes from '../../style.module.css'

const LogisticRegionAlert: React.FC = () => {
  const order = useSelector(selectOrderRead)
  const logisticINNIsRisk = useMemo(
    () =>
      order[OrderFields.LOGISTIC_INN] &&
      order[OrderFields.LOGISTIC_FIRST_FACE_INN] &&
      order[OrderFields.LOGISTIC_INN].slice(0, 2) !== order[OrderFields.LOGISTIC_FIRST_FACE_INN].slice(0, 2),
    [order],
  )

  if (!logisticINNIsRisk) return null

  return (
    <div>
      <span className={classes.redBackgroundColor}>Другой регион</span>
      <br />
      <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
        <small>Гендир:</small>
      </span>
      <span className={classes.redBackgroundColor}>{order[OrderFields.LOGISTIC_FIRST_FACE_INN].slice(0, 2)}</span>
      <span>{order[OrderFields.LOGISTIC_FIRST_FACE_INN].slice(2)}</span>
      <br />
      <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
        <small>Юрлицо:</small>
      </span>
      <span className={classes.redBackgroundColor}>{order[OrderFields.LOGISTIC_INN].slice(0, 2)}</span>
      <span>{order[OrderFields.LOGISTIC_INN].slice(2)}</span>
    </div>
  )
}

export default LogisticRegionAlert
