import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { OrderBodyTypePath } from 'constants/order'

import { OrderBodyType, OrderFields } from 'types/order'
import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'

import { fetchGetOrderRequest, orderSetInitialState } from 'store/order/order.actions'
import { autoChecksSetInitialState, fetchAutoChecksRequest } from 'store/autoChecks/autoChecks.actions'
import { fetchSelectListsRequest, setInitialStateSelectLists } from 'store/selectLists/selectLists.actions'
import { checkVersionRequest } from 'store/version/version.actions'

import OrderHeader from 'components/organisms/OrderHeader'
import OrderBody from 'components/organisms/OrderBody'

export const OrderPage: React.FC = () => {
  const dispatch = useDispatch()
  const { orderNumber } = useParams()
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()

  useEffect(() => {
    return () => {
      dispatch(orderSetInitialState())
      dispatch(autoChecksSetInitialState())
      dispatch(setInitialStateSelectLists())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(orderSetInitialState())
    dispatch(autoChecksSetInitialState())
    dispatch(fetchSelectListsRequest())
    if (currentUser!.role !== UserRole.CUSTOMER) dispatch(fetchAutoChecksRequest(orderNumber))
  }, [dispatch, orderNumber, currentUser])

  useEffect(() => {
    dispatch(fetchGetOrderRequest({ [OrderFields.ORDER_NUMBER]: orderNumber }))
  }, [dispatch, orderNumber])

  useEffect(
    () => () => {
      window.localStorage.search = ''
    },
    [],
  )

  const [orderBodyType, setOrderBodyType] = useState(OrderBodyType.ORDER)

  const changeOrderBodyType = useCallback(
    (type: OrderBodyType) => {
      history.push(`/order/${orderNumber}/${OrderBodyTypePath[type]}`)
      setOrderBodyType(type)
    },
    [history, orderNumber],
  )

  useEffect(() => {
    const partsOfTheLocation = history.location.pathname?.split('/').filter((s: string) => s)

    if (partsOfTheLocation.length === 3 && partsOfTheLocation[2]) {
      const bodyType = Object.keys(OrderBodyTypePath).find(
        (key) => OrderBodyTypePath[key as OrderBodyType] === partsOfTheLocation[2],
      ) as OrderBodyType
      setOrderBodyType(bodyType)
    } else {
      setOrderBodyType(OrderBodyType.ORDER)
    }
  }, [history.location.pathname, orderNumber])

  useEffect(() => {
    dispatch(checkVersionRequest())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <OrderHeader orderBodyType={orderBodyType} setOrderBodyType={changeOrderBodyType} />
      <OrderBody type={orderBodyType} />
    </div>
  )
}

export default OrderPage
