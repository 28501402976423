import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { fetchLogisticInfo } from 'requests/order'
import { LogisticInfoProps, LogisticType, OrderFields } from 'types/order'
import { AlertVariant } from 'types/alert'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { selectOrderWrite } from '../order.selectors'

import { fetchOrderGetLogisticInfoSuccess, fetchOrderGetLogisticInfoFailure, changeOrderHandler } from '../order.actions'

export function* fetchLogisticInfoSaga() {
  try {
    const token = yield select(selectToken)
    const form = yield select(selectOrderWrite)

    const data = {
      [OrderFields.LOGISTIC_INN]: form[OrderFields.LOGISTIC_INN],
    }

    const info: LogisticInfoProps = yield call(fetchLogisticInfo, data, token)
    yield put(fetchOrderGetLogisticInfoSuccess())
    if (info.fields[OrderFields.LOGISTIC_NAME]) {
      yield put(
        changeOrderHandler({
          [OrderFields.LOGISTIC_FIRST_FACE_INN]: info.fields[OrderFields.LOGISTIC_FIRST_FACE_INN] || '',
          [OrderFields.LOGISTIC_OGRN]: info.fields[OrderFields.LOGISTIC_OGRN] || '',
          [OrderFields.LOGISTIC_FIRST_FACE]: info.fields[OrderFields.LOGISTIC_FIRST_FACE] || '',
          [OrderFields.LOGISTIC_FIRST_FACE_BITH]: info.fields[OrderFields.LOGISTIC_FIRST_FACE_BITH] || '',
          [OrderFields.LOGISTIC_NAME]: info.fields[OrderFields.LOGISTIC_NAME] || '',
          [OrderFields.LOGISTIC_ATI]: info.fields[OrderFields.LOGISTIC_ATI] || '',
          [OrderFields.LOGISTIC_ATI_STATUS]: !!info.fields[OrderFields.LOGISTIC_ATI_STATUS],
          [OrderFields.LOGISTIC_TYPE]: info.fields.organType === 'INDIVIDUAL' ? LogisticType.INDIVIDUAL : LogisticType.LEGAL,
        }),
      )

      yield put(
        addAlert({
          text: 'Данные о компании получены',
          lifetime: 3000,
          variant: AlertVariant.SUCCESS,
          header: 'Компания по ИНН',
        }),
      )
    } else {
      yield put(
        changeOrderHandler({
          [OrderFields.LOGISTIC_FIRST_FACE_INN]: '',
          [OrderFields.LOGISTIC_OGRN]: '',
          [OrderFields.LOGISTIC_FIRST_FACE]: '',
          [OrderFields.LOGISTIC_FIRST_FACE_BITH]: '',
          [OrderFields.LOGISTIC_NAME]: '',
        }),
      )
      yield put(
        addAlert({
          text: 'Компания по ИНН не найдена',
          lifetime: 3000,
          variant: AlertVariant.WARNING,
          header: 'Компания по ИНН',
        }),
      )
    }
  } catch (error) {
    yield put(fetchOrderGetLogisticInfoFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение компании по ИНН',
        defaultText: 'Не удалось получить компанию по ИНН.',
      }),
    )
    console.error(`[fetchLogisticInfoSaga]: ${error}`)
    captureException(error)
  }
}
