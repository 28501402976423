import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { getCompanyAccountSuccess, getCompanyAccountFailure } from 'store/billing/billing.actions'

import { getCompanyAccount } from 'requests/company'

export function* getCompanyAccountSaga(action: Action<string>) {
  try {
    const token = yield select(selectToken)

    const response = yield call(getCompanyAccount, action.payload, token)

    yield put(getCompanyAccountSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Получение аккаунта компании',
        defaultText: 'Не удалось получить аккаунт компании.',
      }),
    )
    console.error(`[getCompanyAccountSaga]: ${error}`)
    yield put(getCompanyAccountFailure(error))
  }
}
