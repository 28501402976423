import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'store/users/users.selectors'
import Manager from './Manager'

export interface RouteProps {
  onChange(value: string, id?: string): void
}

export const Route: React.FC<RouteProps> = ({ onChange }) => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    default:
      return <Manager onChange={onChange} />
  }
}

export default Route
