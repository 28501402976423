import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { AlertVariant } from 'types/alert'

import { addHttpError } from 'store/http-error/http-error.actions'
import { addAlert } from 'store/alerts/alerts.actions'
import { selectToken } from 'store/users/users.selectors'

import { deactivateUser } from 'requests/user'

import { deactivateUserFailure, deactivateUserSuccess } from '../admin.actions'

export function* deactivateUserSaga(action: Action<string>) {
  try {
    const token = yield select(selectToken)
    const login = action.payload

    const response = yield call(deactivateUser, login, token)

    yield put(
      addAlert({
        text: response,
        lifetime: 3000,
        variant: AlertVariant.INFO,
        header: 'Отключение пользователя',
      }),
    )
    yield put(deactivateUserSuccess())
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Отключение пользователя',
        defaultText: 'Не получилось отключить пользователя',
      }),
    )
    console.error(`[deactivateUserSaga]: ${error}`)
    yield put(deactivateUserFailure(error))
  }
}
