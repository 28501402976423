import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'

import {
  DictionaryEntry,
  DictionaryEntryState,
  DictionaryId,
  GetDictionaryEntryRequest,
  GetDictionaryEntryResponse,
  LinkDictionaryEntryRequest,
  LinkDictionaryEntryResponse,
  UnlinkDictionaryEntryRequest,
} from 'types/dictionary'

import { DictionaryIdentityHash } from './dictionary-identity-hash'

import * as actions from './dictionary.actions'

export interface DictionaryState {
  dictionary: { [key in DictionaryId]: DictionaryEntryState }
}

export const initialDictionaryState: DictionaryState = {
  dictionary: {},
}

const getDictionaryLinkedEntriesRequest = (state: DictionaryState, action: Action<GetDictionaryEntryRequest>): DictionaryState => ({
  ...state,
  dictionary: {
    ...state.dictionary,
    [DictionaryIdentityHash.get(action.payload.entry)]: {
      response: null,
      status: LoadingStatus.PENDING,
    },
  },
})

const getDictionaryLinkedEntriesSuccess = (
  state: DictionaryState,
  action: Action<{ sourceEntry: DictionaryEntry; response: GetDictionaryEntryResponse }>,
): DictionaryState => ({
  ...state,
  dictionary: {
    ...state.dictionary,
    [DictionaryIdentityHash.get(action.payload.sourceEntry)]: { response: action.payload.response, status: LoadingStatus.SUCCESS },
  },
})

const getDictionaryLinkedEntriesFailure = (state: DictionaryState, action: Action<GetDictionaryEntryRequest>): DictionaryState => ({
  ...state,
  dictionary: {
    ...state.dictionary,
    [DictionaryIdentityHash.get(action.payload.entry)]: { response: null, status: LoadingStatus.FAILED },
  },
})

const linkDictionaryEntryRequest = (state: DictionaryState, action: Action<LinkDictionaryEntryRequest>): DictionaryState => {
  const firstHash = DictionaryIdentityHash.get(action.payload.entries[0])
  const secondHash = DictionaryIdentityHash.get(action.payload.entries[1])

  return {
    ...state,
    dictionary: {
      ...state.dictionary,
      [firstHash]: {
        response: state.dictionary?.[firstHash]?.response || null,
        status: LoadingStatus.PENDING,
      },
      [secondHash]: {
        response: state.dictionary?.[secondHash]?.response || null,
        status: LoadingStatus.PENDING,
      },
    },
  }
}

const linkDictionaryEntrySuccess = (
  state: DictionaryState,
  action: Action<{ sourceEntries: DictionaryEntry[]; response: LinkDictionaryEntryResponse }>,
): DictionaryState => ({
  ...state,
  dictionary: {
    ...state.dictionary,
    [DictionaryIdentityHash.get(action.payload.sourceEntries[0])]: { response: action.payload.response[0], status: LoadingStatus.SUCCESS },
    [DictionaryIdentityHash.get(action.payload.sourceEntries[1])]: { response: action.payload.response[1], status: LoadingStatus.SUCCESS },
  },
})

const linkDictionaryEntryFailure = (state: DictionaryState, action: Action<LinkDictionaryEntryRequest>): DictionaryState => {
  const firstHash = DictionaryIdentityHash.get(action.payload.entries[0])
  const secondHash = DictionaryIdentityHash.get(action.payload.entries[1])

  return {
    ...state,
    dictionary: {
      ...state.dictionary,
      [firstHash]: {
        response: state.dictionary?.[firstHash]?.response || null,
        status: LoadingStatus.FAILED,
      },
      [secondHash]: {
        response: state.dictionary?.[secondHash]?.response || null,
        status: LoadingStatus.FAILED,
      },
    },
  }
}

const unlinkDictionaryEntryRequest = (state: DictionaryState, action: Action<UnlinkDictionaryEntryRequest>): DictionaryState => {
  const firstHash = DictionaryIdentityHash.get(action.payload[0])
  const secondHash = DictionaryIdentityHash.get(action.payload[1])

  return {
    ...state,
    dictionary: {
      ...state.dictionary,
      [firstHash]: {
        response: state.dictionary?.[firstHash]?.response || null,
        status: LoadingStatus.PENDING,
      },
      [secondHash]: {
        response: state.dictionary?.[secondHash]?.response || null,
        status: LoadingStatus.PENDING,
      },
    },
  }
}

const clearDictionary = () => initialDictionaryState

export default handleActions<DictionaryState, any>(
  {
    [actions.GET_DICTIONARY_LINKED_ENTRIES_REQUEST]: getDictionaryLinkedEntriesRequest,
    [actions.GET_DICTIONARY_LINKED_ENTRIES_SUCCESS]: getDictionaryLinkedEntriesSuccess,
    [actions.GET_DICTIONARY_LINKED_ENTRIES_FAILURE]: getDictionaryLinkedEntriesFailure,
    [actions.LINK_DICTIONARY_ENTRIES_REQUEST]: linkDictionaryEntryRequest,
    [actions.LINK_DICTIONARY_ENTRIES_SUCCESS]: linkDictionaryEntrySuccess,
    [actions.LINK_DICTIONARY_ENTRIES_FAILURE]: linkDictionaryEntryFailure,
    [actions.UNLINK_DICTIONARY_ENTRIES_REQUEST]: unlinkDictionaryEntryRequest,
    [actions.UNLINK_DICTIONARY_ENTRIES_SUCCESS]: linkDictionaryEntrySuccess,
    [actions.UNLINK_DICTIONARY_ENTRIES_FAILURE]: linkDictionaryEntryFailure,
    [actions.CLEAR_DICTIONARY]: clearDictionary,
  },
  initialDictionaryState,
)
