import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from '../Driver/style.module.css'

const Manager: React.FC = () => {
  const form = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            onClick={() => {
              window.scrollTo(0, 0)
            }}
            className={classNames(classes.anchor)}
          >
            <Icon type={IconType.Box} width="24px" height="24px" />
          </span>
          <div className={classes.title}>Рейс</div>
          <div className="p-0">
            <p className="m-0 p-0">{form[OrderFields.ROUTE_DATE_START]}</p>
            <p className="m-0 p-0">{form[OrderFields.SHIPPER]}</p>
            <p className="m-0 p-0">{form[OrderFields.ROUTE_TRIP]}</p>
            <p className="m-0 p-0">{form[OrderFields.CARGO]}</p>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
