import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields, OrderTypeId } from 'types/order'
import { getTitleForCarrierByOrderType } from 'utils/title'

import { selectOrderRead } from 'store/order/order.selectors'

import TextWithPrefix from 'components/atoms/TextWithPrefix'
import Title from 'components/atoms/Title'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'

const Customer: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const orderTypeId = String(form[OrderFields.ORDER_TYPE])
  const titleText = getTitleForCarrierByOrderType(orderTypeId as OrderTypeId)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <Title text={titleText} />
          <div className="p-0">
            <TextWithPrefix text={form[OrderFields.LOGISTIC_INN]} prefix="ИНН:" />
            <p className="p-0 m-0">{form[OrderFields.LOGISTIC_NAME]}</p>
            <TextWithPrefix text={<PhoneNumberLink phone={form[OrderFields.CARRIER_FIRST_FACE_PHONE_1]} />} prefix="Телефон 1:" />
            <TextWithPrefix text={<PhoneNumberLink phone={form[OrderFields.CARRIER_FIRST_FACE_PHONE_2]} />} prefix="Телефон 2:" />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
