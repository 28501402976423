import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields, OrderTypeId } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'
import { Feature } from 'types/user'
import { DictionaryEntryType } from 'types/dictionary'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'
import { usePhoneDictionaryLinksByEntry } from 'store/dictionary/dictionary.hooks'

import getStringFromTwoFields from 'utils/getStringFromTwoFields'

import NegativeStatus from 'components/molecules/NegativeStatus'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import ButtonGetDataFromDGOnTel from 'components/molecules/ButtonGetDataFromDGOnTel'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import ButtonGetDataFromDGOnFioAndBirth from 'components/molecules/ButtonGetDataFromDGOnFioAndBirth'
import BacklitTextForCheckedValue from 'components/atoms/BacklitTextForCheckedValue'
import PhoneForExpert from 'components/molecules/OrderSpace/components/PhoneForExpert'
import PhoneVerificationPanel from 'components/molecules/OrderSpace/components/PhoneVerificationPanel'
import PhonesFromDictionary from 'components/molecules/OrderSpace/components/PhonesFromDictionary'
import FoundArticles from 'components/molecules/OrderSpace/components/FoundArticles'
import { Icon, IconType } from 'components/atoms/Icon'
import CertificateLink from 'components/atoms/CertificateLink'
import PassportRegion from 'components/atoms/PassportRegion'
import BoldText from 'components/atoms/BoldText'
import GrayText from 'components/atoms/GrayText'
import Indent from 'components/atoms/Indent'

import { getCheckByType } from './utils'

import classes from './style.module.css'

const Expert: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheck = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.DRIVER)

  const checkTel1 = getCheckByType(autoCheck, 'tel1')
  const checkTel2 = getCheckByType(autoCheck, 'tel2')
  const checkChapters = getCheckByType(autoCheck, 'chapters')
  const foundPhones = getCheckByType(autoCheck, 'find_phones')
  const negative = getCheckByType(autoCheck, 'negative')

  const phonesAreFound = useMemo(() => !!foundPhones?.data?.phones?.length, [foundPhones])

  const [dictionaryLinkOfFirstPhone, dictionaryLinkOfSecondPhone] = usePhoneDictionaryLinksByEntry(
    {
      name: form[OrderFields.DRIVER_FIO],
      birthday: form[OrderFields.DRIVER_BITH],
      type: DictionaryEntryType.PERSON,
    },
    [form[OrderFields.DRIVER_TEL_1], form[OrderFields.DRIVER_TEL_2]],
  )

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.Driver} width="24px" height="24px" />
            </span>
            <div className={classes.title}>Водитель / Сотрудник в штат</div>
            <TextWithPrefixAndCopy
              text={<BoldText text={getStringFromTwoFields(form[OrderFields.DRIVER_FIO], form[OrderFields.DRIVER_BITH])} />}
              prefix="ФИО:"
              copiedText={getStringFromTwoFields(form[OrderFields.DRIVER_FIO], form[OrderFields.DRIVER_BITH])}
            />
            <Indent>
              <BacklitTextForCheckedValue field={OrderFields.DRIVER_FIO} />
              <ButtonGetDataFromDGOnFioAndBirth
                fio={form[OrderFields.DRIVER_FIO]}
                date={form[OrderFields.DRIVER_BITH]}
                bases={checkChapters ? checkChapters.data : null}
              />
              <CertificateLink type="person" identityData={{ ФИО: form[OrderFields.DRIVER_FIO], ДР: form[OrderFields.DRIVER_BITH] }} />
              <NegativeStatus check={negative} />
              <FoundArticles check={negative} />
            </Indent>
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.DRIVER_DOC_CHECK ? (
              <div style={{ marginBottom: '4px' }}>
                <TextWithPrefixAndCopy
                  text={<BoldText text={form[OrderFields.DRIVER_SER_NUM]} />}
                  copiedText={form[OrderFields.DRIVER_SER_NUM]}
                  prefix="Паспорт:"
                />
                <Indent>
                  <PassportRegion passport={form[OrderFields.DRIVER_SER_NUM]} />
                </Indent>
              </div>
            ) : null}
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE ? (
              <TextWithPrefixAndCopy
                text={
                  <>
                    <BoldText text={getStringFromTwoFields(form[OrderFields.DRIVER_DOC], form[OrderFields.DRIVER_DOC_DATE])} />
                    &nbsp;
                    <span className={form[OrderFields.IS_DRIVER_DOC_VALID] === false ? classes.redBackgroundColor : ''}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {form[OrderFields.IS_DRIVER_DOC_VALID] === true
                        ? '(Действительно)'
                        : form[OrderFields.IS_DRIVER_DOC_VALID] === false
                        ? '(Недействительно)'
                        : '(Не проверяли)'}
                    </span>
                  </>
                }
                prefix="ВУ:"
                copiedText={getStringFromTwoFields(form[OrderFields.DRIVER_DOC], form[OrderFields.DRIVER_DOC_DATE])}
              />
            ) : null}
            {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.EMPLOYEE ? (
              <>
                {form[OrderFields.DRIVER_DATA_START_DT_STATUS] ? (
                  <p className="p-0 m-0">ТД не нужен</p>
                ) : (
                  <TextWithPrefix text={<BoldText text={form[OrderFields.DRIVER_DATA_START_DT]} />} prefix="ТД:" />
                )}
              </>
            ) : null}

            <div className="mt-2">
              <GrayText text="Телефоны в заявке:" />
            </div>
            <div className="mb-2" style={{ marginTop: '2px' }}>
              <PhoneForExpert check={checkTel1} field={OrderFields.DRIVER_TEL_1} methodOfVerification={dictionaryLinkOfFirstPhone?.data?.linkSource}>
                <PhoneVerificationPanel
                  person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
                  phone={form[OrderFields.DRIVER_TEL_1]}
                />
              </PhoneForExpert>
            </div>
            <PhoneForExpert check={checkTel2} field={OrderFields.DRIVER_TEL_2} methodOfVerification={dictionaryLinkOfSecondPhone?.data?.linkSource}>
              <PhoneVerificationPanel
                person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
                phone={form[OrderFields.DRIVER_TEL_2]}
              />
            </PhoneForExpert>

            <hr className={classes.hr} />
            <GrayText text="Телефоны из справочника:" />

            <div style={{ marginTop: '2px' }}>
              <PhonesFromDictionary
                person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
                existingPhones={[form[OrderFields.DRIVER_TEL_1], form[OrderFields.DRIVER_TEL_2]]}
              />
            </div>

            {!form[OrderFields.CUSTOMER_FEATURES]?.[Feature.HIDE_FOUND_PHONES] && phonesAreFound ? (
              <>
                <hr className={classes.hr} />
                <GrayText text="Телефоны, которые сами нашли:" />
              </>
            ) : null}
            {!form[OrderFields.CUSTOMER_FEATURES]?.[Feature.HIDE_FOUND_PHONES] && phonesAreFound
              ? foundPhones.data?.phones?.map((phone: string) => (
                  <div key={phone} style={{ margin: '2px 0' }}>
                    <div className="d-inline-block d-flex align-items-center">
                      <TextWithPrefixAndCopy text={<PhoneNumberLink phone={`7${phone}`} />} prefix="Тел:" copiedText={`7${phone}`} />
                    </div>
                    <Indent>
                      <ButtonGetDataFromDGOnTel tel={`7${phone}`} check={foundPhones} />
                    </Indent>
                  </div>
                ))
              : null}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Expert
