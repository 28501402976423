import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields, OrderTypeId } from 'types/order'
import { getTitleForCarrierByOrderType } from 'utils/title'

import { selectOrderRead } from 'store/order/order.selectors'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import { Icon, IconType } from 'components/atoms/Icon'

import getStringFromTwoFields from 'utils/getStringFromTwoFields'

import classes from './style.module.css'

const Manager: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const orderTypeId = String(form[OrderFields.ORDER_TYPE])
  const titleText = getTitleForCarrierByOrderType(orderTypeId as OrderTypeId)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0 position-relative">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.BusinessCenter2} width="24px" height="24px" />
            </span>
            <div className={classes.title}>{titleText}</div>
            <p className="p-0 m-0">{form[OrderFields.LOGISTIC_NAME]}</p>
            <TextWithPrefixAndCopy text={form[OrderFields.LOGISTIC_INN]} prefix="ИНН:" copiedText={form[OrderFields.LOGISTIC_INN]} />
            <TextWithPrefixAndCopy
              text={
                <a target="_blank" rel="noopener noreferrer" href={`https://reputation.ru/${form[OrderFields.LOGISTIC_OGRN]}`}>
                  {form[OrderFields.LOGISTIC_OGRN]}
                </a>
              }
              prefix="ОГРН(ИП):"
              copiedText={form[OrderFields.LOGISTIC_OGRN]}
            />

            {/* В данной проверке есть смысл! Дело в том, что сервер возвращает false/true/null. По условию подходит только false! */}
            {form[OrderFields.LOGISTIC_ATI_STATUS] === false ? (
              <TextWithPrefix text="Нет в АТИ" prefix="АТИ:" />
            ) : (
              <TextWithPrefixAndCopy
                text={
                  <a target="_blank" rel="noopener noreferrer" href={`https://ati.su/firms/${form[OrderFields.LOGISTIC_ATI]}/info`}>
                    {form[OrderFields.LOGISTIC_ATI]}
                  </a>
                }
                prefix="АТИ:"
                copiedText={form[OrderFields.LOGISTIC_ATI]}
              />
            )}
            <br />
            <TextWithPrefixAndCopy
              text={getStringFromTwoFields(form[OrderFields.LOGISTIC_FIRST_FACE], form[OrderFields.LOGISTIC_FIRST_FACE_BITH])}
              prefix="ФИО:"
              copiedText={getStringFromTwoFields(form[OrderFields.LOGISTIC_FIRST_FACE], form[OrderFields.LOGISTIC_FIRST_FACE_BITH])}
            />
            <TextWithPrefixAndCopy
              text={form[OrderFields.LOGISTIC_FIRST_FACE_INN]}
              copiedText={form[OrderFields.LOGISTIC_FIRST_FACE_INN]}
              prefix="ИНН 1го лица:"
            />
            <br />

            <TextWithPrefixAndCopy
              text={<PhoneNumberLink phone={form[OrderFields.CARRIER_FIRST_FACE_PHONE_1]} />}
              copiedText={form[OrderFields.CARRIER_FIRST_FACE_PHONE_1]}
              prefix="Тел:"
            />

            <TextWithPrefixAndCopy
              text={<PhoneNumberLink phone={form[OrderFields.CARRIER_FIRST_FACE_PHONE_2]} />}
              copiedText={form[OrderFields.CARRIER_FIRST_FACE_PHONE_2]}
              prefix="Тел:"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
