export enum FileDataRotation {
  ROTATION_O = 0,
  ROTATION_90 = 90,
  ROTATION_180 = 180,
  ROTATION_270 = 270,
}

export enum FileDataEntity {
  LOGISTIC = 'logistic',
  DRIVER = 'driver',
  TRUCK = 'truck',
  TRAILER = 'trailer',
}

export type FileType = FileDataType | OrderRecognizedFileDataType

export enum FileDataType {
  PASSPORT_NAME = 'passport-name',
  PASSPORT_REG = 'passport-reg',
  DRIVER_LICENSE_FRONT = 'driver-license-front',
  DRIVER_LICENSE_BACK = 'driver-license-back',
  STS_OWNER = 'sts-owner',
  STS_VIN = 'sts-vin',
}

export interface FileData {
  rotation?: FileDataRotation | null
  entity?: FileDataEntity | null
  type?: FileDataType | null
  recognitionType?: OrderRecognizedFileDataType | null
  recognizedFiles?: OrderRecognizedFileData[] | null
  inSlot?: boolean
}

export interface FileProps {
  progress?: number
  uuid: string
  isLoading?: boolean
  name: string
  createdAt?: string
  data?: FileData
}

export enum OrderRecognizedFileDataType {
  STS_VIN = 'STS_VIN',
  VU_FOTO = 'VU_Foto',
  PASP_RF_FOTO_VYDAN = 'PaspRF_Foto_Vydan',
  VU_OBOROT = 'VU_Oborot',
  PASS_RF_PROPISKA = 'PassRF_Propiska',
  STS_SOBSTVENNIK = 'STS_Sobstvennik',
}

export const SupportedDocumentTypes = [
  OrderRecognizedFileDataType.STS_VIN,
  OrderRecognizedFileDataType.VU_FOTO,
  OrderRecognizedFileDataType.PASP_RF_FOTO_VYDAN,
  OrderRecognizedFileDataType.VU_OBOROT,
  OrderRecognizedFileDataType.PASS_RF_PROPISKA,
  OrderRecognizedFileDataType.STS_SOBSTVENNIK,
]

export interface OrderRecognizedFileData {
  type: OrderRecognizedFileDataType
  name: string
  key: string
  href_file: string
  confidence: number
  data: FileData
  box: [number, number, number, number]
}

export interface FileForDocumentsForm {
  name: string
  href_file: string
  data: FileData
}

export interface UpdateFileData {
  image: FileForDocumentsForm
  data: FileData
}

export interface ExistingFileTypes {
  type: FileDataType
  entity: FileDataEntity | null
  inSlot: boolean | undefined
}
