import { call, put, select } from 'redux-saga/effects'

import { getBillingSettings } from 'requests/billing'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { getBillingSettingsFailure, getBillingSettingsSuccess } from 'store/billing/billing.actions'

export function* getBillingSettingsSaga() {
  try {
    const token = yield select(selectToken)

    const response = yield call(getBillingSettings, token)

    yield put(getBillingSettingsSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Получение настроек биллинга',
        defaultText: 'Не удалось получить настройки биллинга.',
      }),
    )
    console.error(`[getBillingSettings]: ${error}`)
    yield put(getBillingSettingsFailure(error))
  }
}
