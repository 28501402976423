import { AppState } from 'store/root.reducer'

export const selectOrderState = (state: AppState) => state?.order

export const selectOrderWrite = (state: AppState) => selectOrderState(state)?.orderWrite

export const selectOrderRead = (state: AppState) => selectOrderState(state)?.orderRead

export const selectOrderChangedFields = (state: AppState) => selectOrderState(state)?.changedFields

export const selectOrderFetchCreateStatus = (state: AppState) => selectOrderState(state)?.loadingFetchCreateStatus

export const selectOrderFetchGetStatus = (state: AppState) => selectOrderState(state)?.loadingFetchGetStatus

export const selectOrderFetchEditStatus = (state: AppState) => selectOrderState(state)?.loadingFetchEditStatus

export const selectOrderFilesStatus = (state: AppState) => selectOrderState(state)?.loadingFilesStatus

export const selectOrderLogisticInfoStatus = (state: AppState) => selectOrderState(state)?.loadingLogisticInfoStatus

export const selectCreateOrderResponse = (state: AppState) => selectOrderState(state)?.responseCreate

export const selectOrderFormErrors = (state: AppState) => selectOrderState(state)?.errors

export const selectLoadingExecutorFormStatus = (state: AppState) => selectOrderState(state)?.loadingExecutorFormStatus

export const selectLoadingResolutionFormStatus = (state: AppState) => selectOrderState(state)?.loadingResolutionFormStatus

export const selectLoadingClarifyFormStatus = (state: AppState) => selectOrderState(state)?.loadingClarifyFormStatus
