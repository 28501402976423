import { Action, handleActions } from 'redux-actions'
import { AxiosError } from 'axios'

import * as actions from './http-error.actions'

export interface HttpErrorState {
  errors: AxiosError[]
}

export const initialHttpErrorState: HttpErrorState = {
  errors: [],
}

const addHttpError = (state: HttpErrorState, action: Action<AxiosError>): HttpErrorState => {
  return {
    ...state,
    errors: [...state.errors, action.payload],
  }
}

export default handleActions<HttpErrorState, any>(
  {
    [actions.ADD_HTTP_ERROR]: addHttpError,
  },
  initialHttpErrorState,
)
