import { createAction } from 'redux-actions'

import {
  DictionaryEntry,
  GetDictionaryEntryRequest,
  GetDictionaryEntryResponse,
  LinkDictionaryEntryRequest,
  LinkDictionaryEntryResponse,
  UnlinkDictionaryEntryRequest,
} from 'types/dictionary'

export const GET_DICTIONARY_LINKED_ENTRIES_REQUEST = 'GET_DICTIONARY_LINKED_ENTRIES_REQUEST'
export const getDictionaryLinkedEntriesRequest = createAction<GetDictionaryEntryRequest>(GET_DICTIONARY_LINKED_ENTRIES_REQUEST)

export const GET_DICTIONARY_LINKED_ENTRIES_SUCCESS = 'GET_DICTIONARY_LINKED_ENTRIES_SUCCESS'
export const getDictionaryLinkedEntriesSuccess = createAction<{ sourceEntry: DictionaryEntry; response: GetDictionaryEntryResponse }>(
  GET_DICTIONARY_LINKED_ENTRIES_SUCCESS,
)

export const GET_DICTIONARY_LINKED_ENTRIES_FAILURE = 'GET_DICTIONARY_LINKED_ENTRIES_FAILURE'
export const getDictionaryLinkedEntriesFailure = createAction<GetDictionaryEntryRequest>(GET_DICTIONARY_LINKED_ENTRIES_FAILURE)

export const LINK_DICTIONARY_ENTRIES_REQUEST = 'LINK_DICTIONARY_ENTRIES_REQUEST'
export const linkDictionaryEntriesRequest = createAction<LinkDictionaryEntryRequest>(LINK_DICTIONARY_ENTRIES_REQUEST)

export const LINK_DICTIONARY_ENTRIES_SUCCESS = 'LINK_DICTIONARY_ENTRIES_SUCCESS'
export const linkDictionaryEntriesSuccess = createAction<{ sourceEntries: DictionaryEntry[]; response: LinkDictionaryEntryResponse }>(
  LINK_DICTIONARY_ENTRIES_SUCCESS,
)

export const LINK_DICTIONARY_ENTRIES_FAILURE = 'LINK_DICTIONARY_ENTRIES_FAILURE'
export const linkDictionaryEntriesFailure = createAction<LinkDictionaryEntryRequest>(LINK_DICTIONARY_ENTRIES_FAILURE)

export const UNLINK_DICTIONARY_ENTRIES_REQUEST = 'UNLINK_DICTIONARY_ENTRIES_REQUEST'
export const unlinkDictionaryEntriesRequest = createAction<UnlinkDictionaryEntryRequest>(UNLINK_DICTIONARY_ENTRIES_REQUEST)

export const UNLINK_DICTIONARY_ENTRIES_SUCCESS = 'UNLINK_DICTIONARY_ENTRIES_SUCCESS'
export const unlinkDictionaryEntriesSuccess = createAction<{ sourceEntries: DictionaryEntry[]; response: LinkDictionaryEntryResponse }>(
  UNLINK_DICTIONARY_ENTRIES_SUCCESS,
)

export const UNLINK_DICTIONARY_ENTRIES_FAILURE = 'UNLINK_DICTIONARY_ENTRIES_FAILURE'
export const unlinkDictionaryEntriesFailure = createAction<UnlinkDictionaryEntryRequest>(UNLINK_DICTIONARY_ENTRIES_FAILURE)

export const CLEAR_DICTIONARY = 'CLEAR_DICTIONARY'
export const clearDictionary = createAction<void>(CLEAR_DICTIONARY)
