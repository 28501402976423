import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import { LogisticType, OrderFields } from 'types/order'
import { LoadingStatus } from 'types/loading'

import { fetchOrderGetLogisticInfoRequest } from 'store/order/order.actions'
import { selectOrderFormErrors, selectOrderLogisticInfoStatus, selectOrderWrite } from 'store/order/order.selectors'

import InputControl from 'components/molecules/InputControl'
import LoadingProgressIndeterminate from 'components/atoms/LoadingProgressIndeterminate'

import Company from '../company'

interface InputInnProps {
  onChange(value: string, id: string): void
}

const InnForm: React.FC<InputInnProps> = ({ onChange }) => {
  const dispatch = useDispatch()
  const loadingLogisticInfoStatus = useSelector(selectOrderLogisticInfoStatus)
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const company = useMemo(() => new Company(form[OrderFields.LOGISTIC_INN], form[OrderFields.LOGISTIC_TYPE] as LogisticType), [form])

  const getInfoByCompany = useCallback(() => {
    if (company.isValidInn()) dispatch(fetchOrderGetLogisticInfoRequest())
  }, [company, dispatch])

  const changeLogisticType = useCallback(
    (newType: LogisticType) => {
      onChange(newType, OrderFields.LOGISTIC_TYPE)
      if (newType === LogisticType.SELF_EMPLOYED) onChange('', OrderFields.LOGISTIC_NAME)

      if ([10, 12].includes(form[OrderFields.LOGISTIC_INN].length) && [LogisticType.LEGAL, LogisticType.INDIVIDUAL].includes(newType)) {
        dispatch(fetchOrderGetLogisticInfoRequest())
      }
    },
    [dispatch, onChange, form],
  )

  const isLoading = loadingLogisticInfoStatus === LoadingStatus.PENDING

  return (
    <li className="list-group-item container">
      <div className="p-0">
        <InputControl
          type="number"
          id={OrderFields.LOGISTIC_INN}
          value={form[OrderFields.LOGISTIC_INN]}
          isValid={form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN ? undefined : errors[OrderFields.LOGISTIC_INN].isValid}
          invalidMessage={errors[OrderFields.LOGISTIC_INN].invalidMessage}
          onChangeHandler={onChange}
          onBlurHandler={getInfoByCompany}
          className="col-lg-4 col-md-6 col-sm-12"
          label="ИНН:"
          disabled={isLoading}
        />
      </div>
      <div>
        <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
          <Button
            className="p-0"
            style={{ flex: '1' }}
            disabled={form[OrderFields.LOGISTIC_TYPE] === LogisticType.SELF_EMPLOYED || isLoading}
            onClick={() => changeLogisticType(LogisticType.SELF_EMPLOYED)}
          >
            Самозанятый
          </Button>
          <Button
            className="p-0"
            style={{ flex: '1', marginLeft: '4px' }}
            disabled={form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN || isLoading}
            onClick={() => changeLogisticType(LogisticType.FOREIGN)}
          >
            Иност. компания
          </Button>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '4px' }}>
          <Button
            className="p-0 w-100"
            disabled={[LogisticType.LEGAL, LogisticType.INDIVIDUAL].includes(form[OrderFields.LOGISTIC_TYPE] as LogisticType) || isLoading}
            onClick={() => changeLogisticType(form[OrderFields.LOGISTIC_INN].length === 12 ? LogisticType.INDIVIDUAL : LogisticType.LEGAL)}
          >
            Юр.лицо или ИП
          </Button>
        </div>
        {isLoading ? (
          <div style={{ marginTop: '12px' }}>
            <LoadingProgressIndeterminate />
          </div>
        ) : null}
      </div>
    </li>
  )
}

export default InnForm
