import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import { FileForDocumentsForm, OrderRecognizedFileData } from 'types/file'

import { selectToken } from 'store/users/users.selectors'
import { rotateFileRequest } from 'store/file/file.actions'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

export enum ViewerImageButton {
  CLOSE = 'close',
  BACK = 'back',
  NEXT = 'next',
  ROTATE = 'rotate',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
}

interface ViewerImageProps {
  image: OrderRecognizedFileData | FileForDocumentsForm
  isRightSide?: boolean
  onClose(): void
  onBack?(): void
  onNext?(): void
  buttons?: ViewerImageButton[]
}

interface ViewerState {
  rotate: number
  scale: number
}

interface CoordinatesState {
  isMoving: boolean
  isSwiping: boolean
  initialX: number | null
  initialY: number | null
  top: number
  left: number
}

const initialViewerState = {
  rotate: 0,
  scale: 1,
}

const initialCoordinatesState = {
  isMoving: false,
  isSwiping: false,
  initialX: null,
  initialY: null,
  top: 0,
  left: 0,
}

const LEFT_MOUSE_BUTTON = 0
const SCALE_STEP = 0.5
const MAX_SCALE = 2.5
const MIN_SCALE = 1
const INITIAL_PADDING_OF_FULL_PIC = 12
const MIN_SWIPE_LENGTH = 100

export const ViewerImage: React.FC<ViewerImageProps> = ({
  image,
  isRightSide,
  onClose,
  onNext = () => {},
  onBack = () => {},
  buttons = [ViewerImageButton.CLOSE, ViewerImageButton.ROTATE, ViewerImageButton.ZOOM_IN, ViewerImageButton.ZOOM_OUT],
}) => {
  const dispatch = useDispatch()
  const token = useSelector(selectToken)
  const [viewerState, setViewerState] = useState<ViewerState>({
    ...initialViewerState,
    rotate: image?.data?.rotation || 0,
  })
  const [coordinatesState, setCoordinatesState] = useState<CoordinatesState>(initialCoordinatesState)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const viewerWrapper = useRef<any>(null)

  useEffect(() => {
    setViewerState({ ...initialViewerState, rotate: image?.data?.rotation || 0 })
  }, [image])

  const handleImageBackClick = useCallback(() => {
    if (buttons?.includes(ViewerImageButton.BACK)) {
      onBack()
      setCoordinatesState(initialCoordinatesState)
    }
  }, [buttons, onBack])

  const handleImageNextClick = useCallback(() => {
    if (buttons?.includes(ViewerImageButton.NEXT)) {
      onNext()
      setCoordinatesState(initialCoordinatesState)
    }
  }, [buttons, onNext])

  const closeFullScreenView = useCallback(() => {
    setViewerState(initialViewerState)
    setCoordinatesState(initialCoordinatesState)
    onClose()
  }, [onClose])

  const turnOverImage = useCallback(() => {
    setViewerState((prev) => {
      dispatch(rotateFileRequest({ data: { rotation: prev.rotate + 90 }, image }))
      return { ...prev, rotate: prev.rotate === 270 ? 0 : prev.rotate + 90 }
    })
    setCoordinatesState(initialCoordinatesState)
  }, [dispatch, image])

  const zoomInImage = useCallback(() => {
    setViewerState((prev) => ({
      ...prev,
      scale: prev.scale > MAX_SCALE - SCALE_STEP ? MAX_SCALE : prev.scale + SCALE_STEP,
    }))
    setCoordinatesState(initialCoordinatesState)
  }, [setViewerState])

  const zoomOutImage = useCallback(() => {
    setViewerState((prev) => ({
      ...prev,
      scale: prev.scale < MIN_SCALE + SCALE_STEP ? MIN_SCALE : prev.scale - SCALE_STEP,
    }))
    setCoordinatesState(initialCoordinatesState)
  }, [setViewerState])

  const eventPersist = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    event.persist()
  }, [])

  const startMoveScaledImage = useCallback(
    (x: number, y: number) => {
      const scaleIsNotEnable = viewerState.scale < MIN_SCALE + SCALE_STEP
      if (scaleIsNotEnable) return
      setCoordinatesState((prev) => ({
        ...prev,
        initialX: prev.left ? x - prev.left : x,
        initialY: prev.top ? y - prev.top : y,
        isMoving: true,
      }))
    },
    [viewerState, setCoordinatesState],
  )

  const onMouseDown = useCallback(
    (event) => {
      eventPersist(event)
      if (event.button === LEFT_MOUSE_BUTTON) startMoveScaledImage(event.pageX, event.pageY)
    },
    [eventPersist, startMoveScaledImage],
  )

  const startSwipeImage = useCallback(
    (x: number) => {
      setCoordinatesState((prev) => ({
        ...prev,
        initialX: prev.left ? x - prev.left : x,
        isSwiping: true,
      }))
    },
    [setCoordinatesState],
  )

  const onTouchStart = useCallback(
    (event) => {
      eventPersist(event)

      const x = event?.touches[0]?.pageX
      const y = event?.touches[0]?.pageY

      const scaleIsNotEnable = viewerState.scale < MIN_SCALE + SCALE_STEP
      if (scaleIsNotEnable) startSwipeImage(x)
      else startMoveScaledImage(x, y)
    },
    [eventPersist, viewerState.scale, startMoveScaledImage, startSwipeImage],
  )

  const moveScaledImage = useCallback(
    (x: number, y: number, element: HTMLElement) => {
      if (coordinatesState.isMoving) {
        setCoordinatesState((prev) => {
          const wHeight = viewerWrapper.current?.offsetHeight
          const wWidth = viewerWrapper.current?.offsetWidth

          let top = prev.initialY ? y - prev.initialY : 0
          let left = prev.initialX ? x - prev.initialX : 0

          const topOffset = top - prev.top
          const leftOffset = left - prev.left

          const boundingClientRect = element.getBoundingClientRect()

          const maxTop = prev.top - boundingClientRect.top

          // Проверяем, нужно ли вообще двигать картинку по вертикали
          const heightOfPicIsGreaterThanHeightOfScreen = boundingClientRect.height > wHeight
          if (heightOfPicIsGreaterThanHeightOfScreen) {
            // Ограничение по вертикали (Верх)
            const topOfPicIsOutOfBounds = boundingClientRect.top + topOffset > 0
            if (topOfPicIsOutOfBounds) top = maxTop
            // Ограничение по вертикали (Низ)
            const bottomOfPicIsOutOfBounds = boundingClientRect.bottom + topOffset < wHeight
            if (bottomOfPicIsOutOfBounds) top = -maxTop
          } else {
            top = prev.top
          }

          // Проверяем, нужно ли вообще двигать картинку по горизонтали
          const widthOfPicIsGreaterThanWidthOfScreen = boundingClientRect.width > wWidth
          if (widthOfPicIsGreaterThanWidthOfScreen) {
            const maxLeft = prev.left - boundingClientRect.left + (isRightSide && windowWidth > 768 ? wWidth : 0)
            // Ограничение по горизонтали (Лево)
            const leftOfPicIsOutOfBounds = boundingClientRect.left + leftOffset > (isRightSide && windowWidth > 768 ? wWidth : 0)
            if (leftOfPicIsOutOfBounds) left = maxLeft
            // Ограничение по горизонтали (Право)
            const rightOfPicIsOutOfBounds = boundingClientRect.right + leftOffset < (isRightSide && windowWidth >= 768 ? wWidth * 2 : wWidth)
            if (rightOfPicIsOutOfBounds) left = -maxLeft
          } else {
            left = prev.left
          }

          return {
            ...prev,
            top,
            left,
          }
        })
      }
    },
    [coordinatesState, isRightSide, windowWidth],
  )

  const swipeImage = useCallback(
    (x: number, element: HTMLElement) => {
      if (coordinatesState.isSwiping) {
        setCoordinatesState((prev) => {
          let left = prev.initialX ? x - prev.initialX : 0
          const boundingClientRect = element.getBoundingClientRect()
          const wWidth = viewerWrapper.current?.offsetWidth
          const leftOffset = left - prev.left

          const hasBackImage = buttons.includes(ViewerImageButton.NEXT)
          const minLeft = hasBackImage ? Math.floor(boundingClientRect.width / 2) - Math.floor(wWidth / 2) - MIN_SWIPE_LENGTH : 0

          const hasNextImage = buttons.includes(ViewerImageButton.BACK)
          const maxLeft = hasNextImage ? Math.floor(wWidth / 2) + MIN_SWIPE_LENGTH - Math.floor(boundingClientRect.width / 2) : 0

          if (left + leftOffset < minLeft) left = minLeft
          if (left + leftOffset > maxLeft) left = maxLeft

          return {
            ...prev,
            left,
          }
        })
      }
    },
    [buttons, coordinatesState.isSwiping],
  )

  const onMouseMove = useCallback(
    (event) => {
      eventPersist(event)

      moveScaledImage(event.pageX, event.pageY, event.target)
    },
    [eventPersist, moveScaledImage],
  )

  const onTouchMove = useCallback(
    (event) => {
      eventPersist(event)

      if (!event.touches || !event.touches.length) return

      const x = event.touches[0].pageX
      const y = event.touches[0].pageY

      if (coordinatesState.isSwiping) swipeImage(x, event.target)
      else moveScaledImage(x, y, event.target)
    },
    [coordinatesState, eventPersist, moveScaledImage, swipeImage],
  )

  const endMoveImage = useCallback(
    (event) => {
      eventPersist(event)

      if (coordinatesState.isSwiping) {
        if (coordinatesState.left >= MIN_SWIPE_LENGTH) onBack()
        if (coordinatesState.left <= -MIN_SWIPE_LENGTH) onNext()
        setCoordinatesState(initialCoordinatesState)
      } else setCoordinatesState((prev) => ({ ...prev, isMoving: false }))
    },
    [eventPersist, coordinatesState, onNext, onBack],
  )

  useEffect(() => {
    const onWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])

  useEffect(() => {
    const onKeypress = (event: { key: string }) => {
      switch (event.key) {
        case 'ArrowLeft':
          handleImageBackClick()
          break

        case 'ArrowRight':
          handleImageNextClick()
          break

        case 'Escape':
          closeFullScreenView()
          break

        default:
          break
      }
    }
    document.addEventListener('keydown', onKeypress)

    return () => {
      document.removeEventListener('keydown', onKeypress)
    }
  }, [closeFullScreenView, handleImageBackClick, handleImageNextClick])

  return (
    <>
      <div className={classNames(classes.viewerWrapper, isRightSide ? classes.viewWrapper_rightSide : null)} ref={viewerWrapper}>
        <div className={classes.buttons}>
          {buttons?.includes(ViewerImageButton.ZOOM_IN) ? (
            <Button variant="dark" onClick={zoomInImage} tabIndex={-1}>
              <Icon type={IconType.ZoomIn} width="24px" height="24px" />
            </Button>
          ) : null}
          {buttons?.includes(ViewerImageButton.ZOOM_OUT) ? (
            <Button variant="dark" onClick={zoomOutImage} style={{ marginLeft: '8px' }} tabIndex={-1}>
              <Icon type={IconType.ZoomOut} width="24px" height="24px" />
            </Button>
          ) : null}
          {buttons?.includes(ViewerImageButton.ROTATE) ? (
            <Button
              variant="dark"
              className={classNames(classes.icon, classes.turnOver)}
              onClick={turnOverImage}
              style={{ marginLeft: '8px' }}
              tabIndex={-1}
            >
              <Icon type={IconType.Update} width="24px" height="24px" />
            </Button>
          ) : null}
          {buttons?.includes(ViewerImageButton.CLOSE) ? (
            <Button variant="dark" className={classes.icon} onClick={closeFullScreenView} style={{ marginLeft: '8px' }} tabIndex={-1}>
              <Icon type={IconType.Close} width="24px" height="24px" />
            </Button>
          ) : null}
        </div>
        {buttons?.includes(ViewerImageButton.BACK) ? (
          <Button
            variant="dark"
            className={classNames(classes.icon, classes.iconBackArrow, classes.buttonBack)}
            onClick={handleImageBackClick}
            style={{ marginLeft: '8px' }}
            tabIndex={-1}
          >
            <Icon type={IconType.Arrow} width="24px" height="24px" />
          </Button>
        ) : null}
        {buttons?.includes(ViewerImageButton.NEXT) ? (
          <Button variant="dark" className={classNames(classes.icon, classes.buttonNext)} onClick={handleImageNextClick} tabIndex={-1}>
            <Icon type={IconType.Arrow} width="24px" height="24px" />
          </Button>
        ) : null}
        <div className={classes.image} style={{ padding: `${INITIAL_PADDING_OF_FULL_PIC}px` }}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={`${process.env.REACT_APP_FILES_SERVER}/file/${image.href_file}?token=${token}`}
            alt={`${image?.data?.type || image?.data?.recognitionType} ${image.name}`}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={endMoveImage}
            onMouseLeave={endMoveImage}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={endMoveImage}
            onTouchCancel={endMoveImage}
            style={{
              transform: `rotate(${viewerState.rotate}deg) scale(${viewerState.scale})`,
              maxWidth: `calc(${
                [1, 3].includes(viewerState.rotate / 90)
                  ? `100vh - ${INITIAL_PADDING_OF_FULL_PIC * 2}px`
                  : `${isRightSide && windowWidth > 768 ? '50vw' : '100vw'} - ${INITIAL_PADDING_OF_FULL_PIC * 2}px`
              })`,
              maxHeight: `calc(${
                [1, 3].includes(viewerState.rotate / 90)
                  ? `${isRightSide && windowWidth > 768 ? '50vw' : '100vw'} - ${INITIAL_PADDING_OF_FULL_PIC * 2}px`
                  : `100vh - ${INITIAL_PADDING_OF_FULL_PIC * 2}px`
              })`,
              top: `${coordinatesState.top}px`,
              left: `${coordinatesState.left}px`,
            }}
            className={viewerState.scale < MIN_SCALE + SCALE_STEP ? '' : classes.cursorMove}
          />
        </div>
      </div>
    </>
  )
}
