import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { orderSetInitialState } from 'store/order/order.actions'
import { fetchSelectListsRequest, setInitialStateSelectLists } from 'store/selectLists/selectLists.actions'
import { checkVersionRequest } from 'store/version/version.actions'
import { setInitialStateUtils, fetchCurrentTimeRequest } from 'store/utils/utils.actions'

import CreateOrderHeader from 'components/organisms/CreateOrderHeader'
import CreateOrderBody from 'components/organisms/CreateOrderBody'

export const CreateOrderPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(orderSetInitialState())
    dispatch(fetchSelectListsRequest())
    dispatch(checkVersionRequest())
    dispatch(fetchCurrentTimeRequest())

    return () => {
      dispatch(orderSetInitialState())
      dispatch(setInitialStateSelectLists())
      dispatch(setInitialStateUtils())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames('container page-content d-flex flex-column flex-grow-1')}>
      <CreateOrderHeader />
      <CreateOrderBody />
    </div>
  )
}

export default CreateOrderPage
