import { Action, handleActions } from 'redux-actions'
import { LoadingStatus } from 'types/loading'

import * as actions from './experts-online.actions'

export interface ExpertsOnlineState {
  online: number[]
  loadingStatus: LoadingStatus
}

export const initialExpertsOnlineState: ExpertsOnlineState = {
  online: [],
  loadingStatus: LoadingStatus.NONE,
}

const fetchExpertsOnlineRequest = (state: ExpertsOnlineState): ExpertsOnlineState => ({
  ...state,
  loadingStatus: LoadingStatus.PENDING,
})

const fetchExpertsOnlineSuccess = (state: ExpertsOnlineState, { payload }: Action<number[]>): ExpertsOnlineState => ({
  ...state,
  online: payload,
  loadingStatus: LoadingStatus.SUCCESS,
})

const fetchExpertsOnlineFailure = (state: ExpertsOnlineState): ExpertsOnlineState => ({
  ...state,
  loadingStatus: LoadingStatus.FAILED,
})

const setInitialState = () => ({
  ...initialExpertsOnlineState,
})

export default handleActions<ExpertsOnlineState, any>(
  {
    [actions.FETCH_EXPERTS_ONLINE_REQUEST]: fetchExpertsOnlineRequest,
    [actions.FETCH_EXPERTS_ONLINE_SUCCESS]: fetchExpertsOnlineSuccess,
    [actions.FETCH_EXPERTS_ONLINE_FAILURE]: fetchExpertsOnlineFailure,
    [actions.SET_INITIAL_STATE_EXPERTS_ONLINE]: setInitialState,
  },
  initialExpertsOnlineState,
)
