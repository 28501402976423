import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { createOrderPath } from 'routes/url-constants'

import { useOrderTaking } from 'hooks/use-order-taking'

import { UserRole } from 'types/user'
import { LoadingStatus } from 'types/loading'

import { selectCurrentUser } from 'store/users/users.selectors'
import { selectNumberOfOrdersPerDay, selectWithoutExecutor } from 'store/orders/orders.selectors'

import OrderListFilter from 'components/molecules/OrderListFilter'
import NewsCarousel from 'components/molecules/NewsCarousel'
import OrderListFilterByExpert from 'components/molecules/OrderListFilterByExpert'
import Stories from 'components/molecules/Stories'

export const OrderListBodyHeader = () => {
  const numberOfOrdersPerDay = useSelector(selectNumberOfOrdersPerDay)
  const withoutExecutor = useSelector(selectWithoutExecutor)
  const currentUser = useSelector(selectCurrentUser)

  const { takeNextOrderStatus, takeOrderId } = useOrderTaking()

  if (takeNextOrderStatus === LoadingStatus.SUCCESS && takeOrderId) {
    return <Redirect to={`/order/${takeOrderId}`} />
  }

  const isSuperExpert = [7, 1843].includes(Number(currentUser?.id))

  return (
    <>
      {currentUser!.role === UserRole.CUSTOMER && <NewsCarousel />}
      {currentUser!.role === UserRole.CUSTOMER && <Stories />}

      {(numberOfOrdersPerDay || withoutExecutor) && currentUser!.role !== UserRole.CUSTOMER ? (
        <div className="list-group-item container" style={{ zIndex: 501 }}>
          <div className="row">
            <div className="col-12" style={{ margin: 'auto' }}>
              {numberOfOrdersPerDay ? <span>{`Сегодня заявок: ${numberOfOrdersPerDay}`}</span> : null}
              {numberOfOrdersPerDay && withoutExecutor ? <br /> : null}
              {withoutExecutor ? <span>{`Новых заявок: ${withoutExecutor}`}</span> : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="list-group-item container" style={{ zIndex: 502 }}>
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <div className="flex-grow-1 p-0 col-lg-3 col-md-6 col-sm-6">
              <OrderListFilter />
            </div>
          </div>
          {isSuperExpert ? (
            <div className="col-12 d-flex justify-content-between" style={{ marginTop: '12px' }}>
              <div className="flex-grow-1 p-0 col-lg-3 col-md-6 col-sm-6">
                <OrderListFilterByExpert />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {currentUser!.role !== UserRole.EXPERT ? (
        <div className="list-group-item container" style={{ zIndex: 501 }}>
          <div className="row">
            <div className="col-12" style={{ margin: 'auto' }}>
              <Link to={createOrderPath} className="btn btn-outline-primary col-lg-3 col-md-6 col-sm-12">
                + Новая заявка
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default OrderListBodyHeader
