import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { OrderFields, OrderSpaceName } from 'types/order'
import { FileForDocumentsForm } from 'types/file'

import { getExistingTypesOfImages, getImages, getImagesPreview } from 'utils/file'

import { selectOrderRead } from 'store/order/order.selectors'

import { ViewerImage, ViewerImageButton } from 'components/molecules/ViewerImage'
import getSpaces from 'components/organisms/OrderBody/utils/getSpaces'

import { FORM_ITEMS_FOR_DRIVER_LICENSE, FORM_ITEMS_FOR_PASSPORTS, FORM_ITEMS_FOR_STS, ALL_VIEWER_BUTTONS, VIEWER_BUTTONS_FOR_FORM } from './constants'

import GalleryItem from './GalleryItem'
import Form from './Form'

import classes from './style.module.css'

interface DocumentsFormProps {}

interface DocumentsFormState {
  isViewerEnabled: boolean
  currentImage: FileForDocumentsForm | null
  currentImageIndex: number | null
  buttonsForViewer: ViewerImageButton[]
}

const initialDocumentsState = {
  isViewerEnabled: false,
  currentImage: null,
  currentImageIndex: null,
  buttonsForViewer: ALL_VIEWER_BUTTONS,
}

const DocumentsForm: React.FC<DocumentsFormProps> = () => {
  const form = useSelector(selectOrderRead)
  const { orderNumber } = useParams()
  const [state, setState] = useState<DocumentsFormState>(initialDocumentsState)

  const spaces = getSpaces(form[OrderFields.ORDER_TYPE])

  const imagesPreview = useMemo(() => getImagesPreview(form?.[OrderFields.FILES]), [form])

  const images = useMemo(() => getImages(form?.[OrderFields.FILES]), [form])

  const existingTypes = useMemo(() => getExistingTypesOfImages(images), [images])

  const handleImageInGalleryClick = useCallback(
    (image: FileForDocumentsForm, index: number) => {
      setState((prev) => ({
        ...prev,
        isViewerEnabled: true,
        currentImage: image,
        currentImageIndex: index,
        buttonsForViewer: ALL_VIEWER_BUTTONS,
      }))
    },
    [setState],
  )

  const handleImageInFormClick = useCallback(
    (image: FileForDocumentsForm) => {
      setState((prev) => ({
        ...prev,
        isViewerEnabled: true,
        currentImage: image,
        buttonsForViewer: VIEWER_BUTTONS_FOR_FORM,
      }))
    },
    [setState],
  )

  const handleImageBackClick = useCallback(() => {
    setState((prev) => {
      if (!prev.currentImageIndex) return prev

      return {
        ...prev,
        currentImage: imagesPreview[prev.currentImageIndex - 1],
        currentImageIndex: prev.currentImageIndex - 1,
        buttonsForViewer: prev.buttonsForViewer,
      }
    })
  }, [imagesPreview])

  const handleImageNextClick = useCallback(() => {
    setState((prev) => {
      if (prev.currentImageIndex === null || prev.currentImageIndex === imagesPreview.length - 1) return prev

      return {
        ...prev,
        currentImage: imagesPreview[prev.currentImageIndex + 1],
        currentImageIndex: prev.currentImageIndex + 1,
        buttonsForViewer: prev.buttonsForViewer,
      }
    })
  }, [imagesPreview])

  const onCloseViewer = () => {
    setState(initialDocumentsState)
  }

  return (
    <div className={classes.wrapper}>
      {spaces.includes(OrderSpaceName.LOGISTIC) || spaces.includes(OrderSpaceName.DRIVER) ? (
        <Form
          images={images}
          handleImageClick={handleImageInFormClick}
          title="Паспорта"
          orderNumber={orderNumber}
          items={
            spaces.includes(OrderSpaceName.LOGISTIC) && spaces.includes(OrderSpaceName.DRIVER)
              ? FORM_ITEMS_FOR_PASSPORTS
              : FORM_ITEMS_FOR_PASSPORTS.filter((item) => item.index === 1)
          }
        />
      ) : null}
      {spaces.includes(OrderSpaceName.DRIVER) ? (
        <Form images={images} handleImageClick={handleImageInFormClick} title="ВУ" orderNumber={orderNumber} items={FORM_ITEMS_FOR_DRIVER_LICENSE} />
      ) : null}
      {spaces.includes(OrderSpaceName.TS) ? (
        <Form
          images={images}
          handleImageClick={handleImageInFormClick}
          title="СТС"
          orderNumber={orderNumber}
          items={!form[OrderFields.TRAILER_STATUS] ? FORM_ITEMS_FOR_STS : FORM_ITEMS_FOR_STS.filter((item) => item.index === 1)}
        />
      ) : null}
      {imagesPreview ? (
        <div className={classes.galleryWrapper}>
          {imagesPreview.map((image: FileForDocumentsForm, index: number) => (
            <GalleryItem
              image={image}
              existingTypes={existingTypes}
              index={index}
              handleImageClick={handleImageInGalleryClick}
              key={image.href_file}
            />
          ))}
        </div>
      ) : null}
      {state.isViewerEnabled && state.currentImage ? (
        <ViewerImage
          image={state.currentImage}
          isRightSide
          onBack={handleImageBackClick}
          onNext={handleImageNextClick}
          onClose={onCloseViewer}
          buttons={state.buttonsForViewer}
        />
      ) : null}
    </div>
  )
}

export default DocumentsForm
