import React from 'react'

import { OrderSpaceName } from 'types/order'

import Header from './spaces/Header'
import Order from './spaces/Order'
import Contact from './spaces/Contact'
import Logistic from './spaces/Logistic'
import Driver from './spaces/Driver'
import TS from './spaces/TS'
import Route from './spaces/Route'
import Files from './spaces/Files'

export const OrderSpace: React.FC<{ id: string }> = ({ id }) => {
  switch (id) {
    case OrderSpaceName.HEADER:
      return <Header />

    case OrderSpaceName.ORDER:
      return <Order />

    case OrderSpaceName.CONTACT:
      return <Contact />

    case OrderSpaceName.LOGISTIC:
      return <Logistic />

    case OrderSpaceName.DRIVER:
      return <Driver />

    case OrderSpaceName.TS:
      return <TS />

    case OrderSpaceName.ROUTE:
      return <Route />

    case OrderSpaceName.FILES:
      return <Files />

    default:
      throw new Error('Не обработан блок в форме заявки.')
  }
}

export default OrderSpace
