import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'store/users/users.selectors'

import { UserRole } from 'types/user'
import { OrderBodyType } from 'types/order'

import Customer from './Customer'
import Manager from './Manager'

export interface OrderHeaderProps {
  orderBodyType: OrderBodyType
  setOrderBodyType(type: OrderBodyType): void
}

export const OrderHeader: React.FC<OrderHeaderProps> = ({ orderBodyType, setOrderBodyType }) => {
  const currentUser = useSelector(selectCurrentUser)

  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer orderBodyType={orderBodyType} setOrderBodyType={setOrderBodyType} />

    default:
      return <Manager orderBodyType={orderBodyType} setOrderBodyType={setOrderBodyType} />
  }
}

export default OrderHeader
