import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'

import * as actions from './utils.actions'

export interface UtilsState {
  currentTime: string
  loadingStatus: LoadingStatus
}

export const initialUtilsState: UtilsState = {
  currentTime: '',
  loadingStatus: LoadingStatus.NONE,
}

const fetchCurrentTimeRequest = (state: UtilsState): UtilsState => ({
  ...state,
  loadingStatus: LoadingStatus.PENDING,
})

const fetchCurrentTimeSuccess = (state: UtilsState, { payload }: Action<string>): UtilsState => ({
  ...state,
  currentTime: payload,
  loadingStatus: LoadingStatus.SUCCESS,
})

const fetchCurrentTimeFailure = (state: UtilsState): UtilsState => ({
  ...state,
  loadingStatus: LoadingStatus.FAILED,
})

const setInitialState = () => ({
  ...initialUtilsState,
})

export default handleActions<UtilsState, any>(
  {
    [actions.FETCH_CURRENT_TIME_REQUEST]: fetchCurrentTimeRequest,
    [actions.FETCH_CURRENT_TIME_SUCCESS]: fetchCurrentTimeSuccess,
    [actions.FETCH_CURRENT_TIME_FAILURE]: fetchCurrentTimeFailure,
    [actions.SET_INITIAL_STATE_UTILS]: setInitialState,
  },
  initialUtilsState,
)
