import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderFetchGetStatus, selectOrderRead } from 'store/order/order.selectors'

import { LoadingStatus } from 'types/loading'

import OrderForm from 'components/organisms/OrderForm'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import getInitialValues from './utils/getInitialValues'

export const EditOrderBody: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const loadingFetchGetStatus = useSelector(selectOrderFetchGetStatus)
  const initialValues = useMemo(() => getInitialValues(form), [form])

  if (loadingFetchGetStatus === LoadingStatus.PENDING) return <LoadingPlaceholder />

  return <OrderForm initialValues={initialValues} />
}

export default EditOrderBody
