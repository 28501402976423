import { nameOfMonths, oneDayInMilliseconds, oneHourInMilliseconds } from 'constants/date'
import { getNounWithDeclension } from './string'

export const checkThatTwoDigits = (value: number | string) => {
  if (Number(value) < 10) {
    return `0${Number(value)}`
  }
  return value
}

export const getFullYear = (day: Date) => {
  let year = String(day.getFullYear())
  while (year.length < 4) year = `0${year}`
  return year
}

export const getDateFromServerDate = (serverDate: string) => {
  const arrayMatch = serverDate.match(/^[^]*([0-9]{4})[.-]([0-9]{2})[.-]([0-9]{2}) ?([0-9]{2})?:?([0-9]{2})?[^]*$/)

  if (arrayMatch)
    return new Date(Number(arrayMatch[1]), Number(arrayMatch[2]) - 1, Number(arrayMatch[3]), Number(arrayMatch[4] || 0), Number(arrayMatch[5] || 0))

  return null
}

export const getDateFromInputDate = (inputDate: string) => {
  const arrayMatch = inputDate.match(/^[^]*([0-9]{2})[.-]([0-9]{2})[.-]([0-9]{4}) ?([0-9]{2})?:?([0-9]{2})?[^]*$/)

  if (arrayMatch)
    return new Date(Number(arrayMatch[3]), Number(arrayMatch[2]) - 1, Number(arrayMatch[1]), Number(arrayMatch[4] || 0), Number(arrayMatch[5] || 0))

  return null
}

export const getDateForServer = (date: Date | null): string => {
  if (!date) return ''
  return `${getFullYear(date)}-${checkThatTwoDigits(Number(date.getMonth()) + 1)}-${checkThatTwoDigits(date.getDate())}`
}

export const getDateForWeb = (date: Date | null): string | null => {
  if (!date) return null
  return `${checkThatTwoDigits(date.getDate())}.${checkThatTwoDigits(Number(date.getMonth()) + 1)}.${date.getFullYear()}`
}

export const getDateTimeForInput = (date: Date | null): string | null => {
  if (!date) return null
  return `${checkThatTwoDigits(date.getDate())}-${checkThatTwoDigits(Number(date.getMonth()) + 1)}-${getFullYear(date)} ${checkThatTwoDigits(
    date.getHours(),
  )}:${checkThatTwoDigits(date.getMinutes())}`
}

export const getDateTimeForServer = (date: Date | null): string => {
  if (!date) return ''
  return `${getFullYear(date)}-${checkThatTwoDigits(Number(date.getMonth()) + 1)}-${checkThatTwoDigits(date.getDate())} ${checkThatTwoDigits(
    date.getHours(),
  )}:${checkThatTwoDigits(date.getMinutes())}`
}

export const getDateTimeForWeb = (date: Date | null): string | null => {
  if (!date) return null
  return `${checkThatTwoDigits(date.getDate())}.${checkThatTwoDigits(Number(date.getMonth()) + 1)}.${date.getFullYear()} ${checkThatTwoDigits(
    date.getHours(),
  )}:${checkThatTwoDigits(date.getMinutes())}`
}

export const getMoscowDate = (): Date => {
  const today = new Date()

  const now_utc: Date = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    today.getUTCHours(),
    today.getUTCMinutes(),
    today.getUTCSeconds(),
  )

  return new Date(Number(now_utc) + oneHourInMilliseconds * 3)
}

export const setMoscowTimezone = (date: Date): Date => {
  const date_utc: Date = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )

  return new Date(Number(date_utc) + oneHourInMilliseconds * 3)
}

export const getNameOfMonth = (date: Date | null) => {
  if (!date) return null
  return nameOfMonths[date.getMonth()]
}

export const setLocalTimezone = (date: Date | null) => {
  if (!date) return null

  const offset = new Date().getTimezoneOffset() / 60

  return new Date(Number(date) - oneHourInMilliseconds * offset)
}

export const getYearStringWithDeclension = (year: number | null) => {
  const currentDate = new Date()
  const count = year ? currentDate.getFullYear() - year : null
  if (count || count === 0) {
    return `${count} ${getNounWithDeclension(count, 'год', 'года', 'лет')}`
  }
  return null
}

export const getDateStringWithDeclension = (string: string | null) => {
  if (!string) return null

  const currentDate = new Date()
  const currentDateWithoutTime: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  const date: Date | null = getDateFromInputDate(string)
  if (!date) return null

  const countOfYear = new Date(currentDateWithoutTime.getTime() - date.getTime()).getFullYear() - 1970
  if (countOfYear) return `${countOfYear} ${getNounWithDeclension(countOfYear, 'год', 'года', 'лет')}`

  const countOfMonth = new Date(currentDateWithoutTime.getTime() - date.getTime()).getMonth()
  if (countOfMonth) return `${countOfMonth} мес.`

  const countOfDay = new Date(currentDateWithoutTime.getTime() - date.getTime()).getDate() - 1
  if (countOfDay) return `${countOfDay} д.`

  return 'Сегодня'
}

export const getTomorrow = () => {
  const currentDate = new Date()
  const tomorrow = new Date(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime() + oneDayInMilliseconds)

  return tomorrow
}
