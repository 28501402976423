import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AlertVariant } from 'types/alert'

import { addAlert } from 'store/alerts/alerts.actions'
import { selectCurrentUser } from 'store/users/users.selectors'

import RemoteUserScheduler from 'components/pages/UserSchedulerPage/remote-user-scheduler'
import UserSchedulerItem from 'components/pages/UserSchedulerPage/UserSchedulerBody/user-scheduler-item'

const userScheduler = new RemoteUserScheduler()

const UserSchedulerBody: React.FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const [users, setUsers] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const currentUserScheduler = users.find((user: any) => user.id === currentUser!.id)

  useEffect(() => {
    userScheduler
      .getScheduleFor(currentUser!.role)
      .then((scheduledUsers) => {
        setUsers(scheduledUsers)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [currentUser])

  const onSubmit = (changedUsers: any) => {
    setIsLoading(true)
    userScheduler
      .setUsersOnline(changedUsers)
      .then(() => {
        setIsLoading(false)
        dispatch(addAlert({ text: 'Запрос выполнен успешно', lifetime: 3000, variant: AlertVariant.SUCCESS }))
      })
      .catch(() => {
        setIsLoading(false)
        dispatch(addAlert({ text: 'Не получилось отправить запрос', lifetime: 3000, variant: AlertVariant.DANGER }))
      })
  }

  const onChange = (value: any, id: any, userId: any) => {
    const usersCopy = [...users]
    const changedUser = usersCopy.find((user) => user.id === userId)
    changedUser[id] = value
    setUsers(usersCopy)
    onSubmit(changedUser)
  }

  return (
    <div className="row">
      {currentUserScheduler ? (
        <ul className="list-group col-12 p-0" style={{ borderBottom: '3px solid rgba(0,0,0,.125)' }}>
          <UserSchedulerItem user={currentUserScheduler} onChange={onChange} isLoading={isLoading} key={currentUserScheduler.id} />
        </ul>
      ) : null}
      <ul className="list-group col-12 p-0">
        {users.map((user: any) =>
          user.id !== currentUser!.id ? <UserSchedulerItem user={user} onChange={onChange} isLoading={isLoading} key={user.id} /> : null,
        )}
      </ul>
    </div>
  )
}

export default UserSchedulerBody
