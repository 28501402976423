import { createAction } from 'redux-actions'
import { GetFio } from 'types/dadata'

export const FETCH_GET_FIO_REQUEST = 'FETCH_GET_FIO_REQUEST'
export const fetchGetFioRequest = createAction<GetFio>(FETCH_GET_FIO_REQUEST)

export const FETCH_GET_FIO_PENDING = 'FETCH_GET_FIO_PENDING'
export const fetchGetFioPending = createAction<GetFio>(FETCH_GET_FIO_PENDING)

export const FETCH_GET_FIO_SUCCESS = 'FETCH_GET_FIO_SUCCESS'
export const fetchGetFioSuccess = createAction<{ data: string[]; text: string; id: string }>(FETCH_GET_FIO_SUCCESS)

export const FETCH_GET_FIO_FAILURE = 'FETCH_GET_FIO_FAILURE'
export const fetchGetFioFailure = createAction<Error>(FETCH_GET_FIO_FAILURE)
