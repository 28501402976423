import React from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { selectOrderWrite } from 'store/order/order.selectors'

import { LogisticType, OrderFields } from 'types/order'

import Button from 'components/molecules/Button'

import Labeler from '../labeler'

const DirectorAlert: React.FC<any> = ({ onButtonClick }) => {
  const form = useSelector(selectOrderWrite)

  const labeler = new Labeler(form[OrderFields.LOGISTIC_TYPE] as LogisticType, form[OrderFields.LOGISTIC_FIRST_FACE])

  const buttonLabel = `У меня есть телефон ${labeler.getDirectorNameForButton()}`

  return (
    <li className="list-group-item">
      <div>
        <Alert variant="warning" className="m-0">
          Внимание! <br />
          Если у вас нет телефона {labeler.getDirectorNameForAlert()}, то время рассмотрения заявки может быть больше, а вероятность позитивной
          резолюции - ниже.
        </Alert>
        <Button className="p-0 m-0" onClick={onButtonClick} content={buttonLabel} variant="link" />
      </div>
    </li>
  )
}

export default DirectorAlert
