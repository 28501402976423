import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTakeNextOrderRequest, fetchTakeOrderRequest } from '../store/orders/orders.actions'
import { selectLoadingTakeNextOrderStatus, selectLoadingTakeOrderStatus, selectTakeOrderId } from '../store/orders/orders.selectors'
import { selectCurrentUser } from '../store/users/users.selectors'

export const useOrderTaking = () => {
  const dispatch = useDispatch()
  const [currentOrder, setCurrentOrder] = useState('')
  const takeNextOrderStatus = useSelector(selectLoadingTakeNextOrderStatus)
  const takeOrderStatus = useSelector(selectLoadingTakeOrderStatus)
  const takeOrderId = useSelector(selectTakeOrderId) || currentOrder
  const currentUser = useSelector(selectCurrentUser)

  const takeNextOrder = () => dispatch(fetchTakeNextOrderRequest())
  const takeOrder = (orderNumber: string) => {
    setCurrentOrder(currentOrder)
    dispatch(fetchTakeOrderRequest(orderNumber))
  }

  const date = new Date()
  const hours = date.getUTCHours() + 3
  const isHoliday = [0, 6].includes(date.getDay())
  const isWorkingHours = hours >= 8 && hours <= 18
  const canSelectOrderToTake = true || isHoliday || !isWorkingHours || [7, 1843].includes(currentUser?.id || 0)

  return {
    takeNextOrder,
    takeOrder,
    takeNextOrderStatus,
    takeOrderStatus,
    takeOrderId,
    canSelectOrderToTake,
  }
}
