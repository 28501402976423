import React from 'react'

import UserSchedulerBody from 'components/pages/UserSchedulerPage/UserSchedulerBody'
import UserSchedulerHeader from 'components/pages/UserSchedulerPage/UserSchedulerHeader'

const UserSchedulerPage: React.FC = () => {
  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <UserSchedulerHeader />
      <UserSchedulerBody />
    </div>
  )
}

export default UserSchedulerPage
