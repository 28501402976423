import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'

import getStringFromSelectList from 'utils/getStringFromSelectList'

import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'
import TextWithPrefix from 'components/atoms/TextWithPrefix'

import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'

const PreviousResolution: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)

  return (
    <>
      {form[OrderFields.PREVIOUS_EXECUTOR_EXPERT] || form[OrderFields.PREVIOUS_RESOLUTION] || form[OrderFields.PREVIOUS_RESOLUTION_COMMENT] ? (
        <>
          <hr style={{ borderTop: '2px solid rgba(0,0,0,.125)', margin: '1rem -20px' }} />
          <span className={classNames('p-0 m-0', classes.redBackgroundColor)}>Предварительная проверка</span>
        </>
      ) : null}

      {form[OrderFields.PREVIOUS_EXECUTOR_EXPERT] ? (
        <TextWithPrefix
          text={getStringFromSelectList(Number(form[OrderFields.PREVIOUS_EXECUTOR_EXPERT]), selectLists?.executor)}
          prefix="Предыдущий эксперт:"
        />
      ) : null}
      {form[OrderFields.PREVIOUS_RESOLUTION] ? (
        <div>{getStringFromSelectList(Number(form[OrderFields.PREVIOUS_RESOLUTION]), selectLists?.resolution)}</div>
      ) : null}
      {form[OrderFields.PREVIOUS_RESOLUTION_COMMENT] ? (
        <div>
          <TextWithLineBreaks text={form[OrderFields.PREVIOUS_RESOLUTION_COMMENT]} />
        </div>
      ) : null}
    </>
  )
}

export default PreviousResolution
