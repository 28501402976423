import { OrderFields, ResolutionId } from 'types/order'
import { groupOrders } from './group-orders'
import { SortableOrder } from '../sortable-order'

export class BelarusSorter {
  static sort(orders: SortableOrder[]) {
    const [belarusOrders, regularOrders] = groupOrders(orders, BelarusSorter.isBelarusOrder)

    return [...regularOrders, ...belarusOrders]
  }

  static isBelarusOrder(order: SortableOrder): boolean {
    return String(order[OrderFields.RESOLUTION]) === ResolutionId.E_PROCESSING && !!order[OrderFields.HAS_BELARUSIANS]
  }
}
