import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { selectSearchOrders, selectOrderFilter, selectOrders } from 'store/orders/orders.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectNotifications } from 'store/notifications/notifications.selectors'

import { ResolutionId } from 'types/order'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import isOrderInNotifications from 'utils/isOrderInNotifications'

import { Icon, IconType } from 'components/atoms/Icon'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import classes from './style.module.css'
import SearchMatch from './SearchMatch'

export const OrderListCustomer = () => {
  const orders = useSelector(selectOrders)
  const orderFilter = useSelector(selectOrderFilter)
  const notifications = useSelector(selectNotifications)
  const isSearchOrders = useSelector(selectSearchOrders)

  const selectLists = useSelector(selectSelectLists)

  const getResolutionClassName = useCallback((resolution) => {
    switch (String(resolution)) {
      case ResolutionId.CLARIFY_DATA:
        return classes.resolution_green

      case ResolutionId.WIP:
        return classes.resolution_orange

      default:
        return classes.resolution_gray
    }
  }, [])

  return (
    <>
      {orders.map((order) => {
        if (orderFilter(order)) {
          return (
            <Link
              key={order.orderNumber}
              to={`/order/${order.orderNumber}`}
              style={{ textDecoration: 'none' }}
              className={classNames(
                'list-group-item list-group-item-action flex-column align-items-start',
                String(order.resolution) === '2' ? 'list-group-item-warning' : '',
              )}
            >
              {isSearchOrders ? <SearchMatch order={order} /> : null}
              {order.resolution && selectLists?.resolution ? (
                <div
                  className={classNames(
                    'font-weight-normal col-lg-3 col-md-6 col-sm-12 text-center',
                    classes.resolution,
                    getResolutionClassName(order.resolution),
                  )}
                >
                  {getStringFromSelectList(order.resolution, selectLists?.resolution)}
                </div>
              ) : null}
              <div className="d-flex w-100 justify-content-between">
                <span className="text-primary">{order.orderTime}</span>
                {notifications && notifications.length && isOrderInNotifications(order.orderNumber, notifications) ? (
                  <div title="Новое сообщение">
                    <Icon className={classes.notificationIcon} type={IconType.Comment2} />
                  </div>
                ) : // <span className={"badge badge-danger text-danger " + classes.header_newMessage} title="Новое сообщение">0</span>
                null}
              </div>
              <p className="mb-1">
                <span className="font-weight-bold">{getStringFromSelectList(order.priority, selectLists?.priority)}</span>
                <br />
                <span className="font-weight-normal">{order.logisticName}</span>
                <br />
                <span className="font-weight-normal">{order.driverFio}</span>
                <br />
                <span className="font-weight-normal">
                  <TextWithLineBreaks text={order.resolutionComment} />
                </span>
                <br />
              </p>
              <span color="#999999">
                <small>{order.orderNumber}</small>
              </span>
            </Link>
          )
        }
        return null
      })}
    </>
  )
}

export default OrderListCustomer
