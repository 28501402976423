import React, { CSSProperties, MouseEvent, ReactNode } from 'react'
import { Spinner, Button as BootstrapButton } from 'react-bootstrap'

import { ButtonVariant } from 'react-bootstrap/types'

interface ButtonProps {
  onClick(event: MouseEvent): void
  className?: string
  disabled?: boolean
  isLoading?: boolean
  content?: string | ReactNode
  variant?: ButtonVariant
  style?: CSSProperties
  hideLoadingText?: boolean
  children?: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  disabled,
  isLoading,
  content,
  variant,
  style,
  hideLoadingText = false,
  children,
}) => {
  return (
    <BootstrapButton onClick={onClick} className={className} disabled={disabled || isLoading} variant={variant} style={style}>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
          {hideLoadingText ? null : <>&nbsp; Загрузка..</>}
        </div>
      ) : (
        <>{content || children}</>
      )}
    </BootstrapButton>
  )
}

export default Button
