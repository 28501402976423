import React, { useCallback, useState } from 'react'
import { Button, Form, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { LoginData } from 'types/user'
import { LoadingStatus } from 'types/loading'

import { fetchLoginRequest } from 'store/users/users.actions'
import { selectLoadingLogin } from 'store/users/users.selectors'

import { useValidation } from 'utils/useValidation'

import InputControl from 'components/molecules/InputControl'
import PasswordInput from 'components/molecules/PasswordInput'

import { fields } from './constants/fields'
import formValidator from './utils/formValidator'
import AuthFormRules from './constants/rules'

export const AuthForm: React.FC = () => {
  const [form, setForm] = useState<LoginData>({
    login: '',
    password: '',
  })

  const [errors, setErrors] = useState<{
    [key: string]: { isValid: undefined | boolean; invalidMessage: string }
  }>({
    [fields.LOGIN]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [fields.PASSWORD]: {
      isValid: undefined,
      invalidMessage: '',
    },
  })

  const dispatchStore = useDispatch()

  const { validate, validateProperty } = useValidation(AuthFormRules)

  const onChangeHandler = useCallback(
    (value: string, id: string) => {
      setForm((prev) => ({
        ...prev,
        [id]: value.trim(),
      }))

      const validator = validateProperty(id, value.trim())
      setErrors((prev) => ({
        ...prev,
        [id]: formValidator(id, validator.passes()),
      }))
    },
    [setForm, validateProperty],
  )

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault()

      const validator = validate(form)
      if (validator.passes()) dispatchStore(fetchLoginRequest(form))
    },
    [dispatchStore, form, validate],
  )

  const loadingLogin = useSelector(selectLoadingLogin)

  return (
    <Form onSubmit={onSubmitHandler}>
      {loadingLogin === LoadingStatus.FAILED ? <Alert variant="danger">Ошибка!</Alert> : null}

      <InputControl
        onChangeHandler={onChangeHandler}
        value={form.login}
        id={fields.LOGIN}
        isValid={errors[fields.LOGIN].isValid}
        placeholder="Логин"
        label="Введите логин"
        type="text"
        invalidMessage={errors[fields.LOGIN].invalidMessage}
        required
      />

      <PasswordInput
        onChangeHandler={onChangeHandler}
        value={form.password}
        isValid={errors[fields.PASSWORD].isValid}
        id={fields.PASSWORD}
        placeholder="Пароль"
        label="Введите пароль"
        type="password"
        invalidMessage={errors[fields.PASSWORD].invalidMessage}
        required
      />

      <div className="text-right">
        <Button variant="dark" type="submit" className="col-lg-5 col-md-12" disabled={loadingLogin === LoadingStatus.PENDING}>
          {loadingLogin === LoadingStatus.PENDING ? (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
              &nbsp; Загрузка..
            </div>
          ) : (
            <>Войти</>
          )}
        </Button>
      </div>
    </Form>
  )
}
