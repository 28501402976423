import { AppState } from 'store/root.reducer'
import { UserRole } from 'types/user'

export const selectSelectListsState = (state: AppState) => state?.selectLists

export const selectSelectLists = (state: AppState) => selectSelectListsState(state)?.lists

export const selectLoadingSelectListsStatus = (state: AppState) => selectSelectListsState(state)?.loadingStatus

export const selectExperts = (state: AppState) =>
  (selectSelectLists(state)?.executor || [])
    .filter((executor) => executor.role === UserRole.EXPERT)
    .map((executor) => ({
      id: Number(executor.id),
      name: executor.name,
    }))
