import { Props } from 'types/core/props'
import { Template } from 'types/core/template'
import { v3Api, transformResponse } from './axios'

export const getTemplateRequest = async <FieldTemplate>(filter: Props, token: string): Promise<Template<FieldTemplate>> => {
  const data = await transformResponse(
    v3Api.get('/template', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      params: filter,
    }),
  )
  return (
    data || {
      name: '',
      title: '',
      fields: [],
    }
  )
}
