import { createAction } from 'redux-actions'

export const FETCH_EXPERTS_ONLINE_REQUEST = 'FETCH_EXPERTS_ONLINE_REQUEST'
export const fetchExpertsOnlineRequest = createAction<void>(FETCH_EXPERTS_ONLINE_REQUEST)

export const FETCH_EXPERTS_ONLINE_SUCCESS = 'FETCH_EXPERTS_ONLINE_SUCCESS'
export const fetchExpertsOnlineSuccess = createAction<number[]>(FETCH_EXPERTS_ONLINE_SUCCESS)

export const FETCH_EXPERTS_ONLINE_FAILURE = 'FETCH_EXPERTS_ONLINE_FAILURE'
export const fetchExpertsOnlineFailure = createAction<Error>(FETCH_EXPERTS_ONLINE_FAILURE)

export const SAVE_EXPERTS_ONLINE_REQUEST = 'SAVE_EXPERTS_ONLINE_REQUEST'
export const saveExpertsOnlineRequest = createAction<number[]>(SAVE_EXPERTS_ONLINE_REQUEST)

export const SAVE_EXPERTS_ONLINE_SUCCESS = 'SAVE_EXPERTS_ONLINE_SUCCESS'
export const saveExpertsOnlineSuccess = createAction<void>(SAVE_EXPERTS_ONLINE_SUCCESS)

export const SAVE_EXPERTS_ONLINE_FAILURE = 'SAVE_EXPERTS_ONLINE_FAILURE'
export const saveExpertsOnlineFailure = createAction<Error>(SAVE_EXPERTS_ONLINE_FAILURE)

export const SET_INITIAL_STATE_EXPERTS_ONLINE = 'SET_INITIAL_STATE_EXPERTS_ONLINE'
export const setInitialStateExpertsOnline = createAction<void>(SET_INITIAL_STATE_EXPERTS_ONLINE)
