import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'store/users/users.selectors'

import { SelectItem } from 'types/selectLists'
import { UserRole } from 'types/user'
import { OrderFilterId, OrderFilterName } from 'types/order'

import SelectControl from 'components/molecules/SelectControl'
import { useOrderListFilter } from './order-list-filter-hook'

export const OrderListFilter: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)

  const { value, onChange } = useOrderListFilter(currentUser?.role || UserRole.CUSTOMER)

  // Здесь задаём порядок фильтров в select для разных ролей пользователей.
  const options: SelectItem[] = useMemo(() => {
    if (currentUser!.role === UserRole.CUSTOMER)
      return [
        { id: OrderFilterId.ALL_ACTIVE_ORDERS, name: OrderFilterName.ALL_ACTIVE_ORDERS },
        { id: OrderFilterId.ALL_ORDERS, name: OrderFilterName.ALL_ORDERS },
        { id: OrderFilterId.ALL_FINISHED_ORDERS, name: OrderFilterName.ALL_FINISHED_ORDERS },
      ]

    return [
      { id: OrderFilterId.USER_ORDERS, name: OrderFilterName.USER_ORDERS },
      { id: OrderFilterId.USER_ACTIVE_ORDERS, name: OrderFilterName.USER_ACTIVE_ORDERS },
      { id: OrderFilterId.ALL_ACTIVE_ORDERS, name: OrderFilterName.ALL_ACTIVE_ORDERS },
      { id: OrderFilterId.ALL_ORDERS, name: OrderFilterName.ALL_ORDERS },
      { id: OrderFilterId.ALL_FINISHED_ORDERS, name: OrderFilterName.ALL_FINISHED_ORDERS },
      { id: OrderFilterId.NEW_ORDERS, name: OrderFilterName.NEW_ORDERS },
      { id: OrderFilterId.CLARIFY_DATA, name: OrderFilterName.CLARIFY_DATA },
      { id: OrderFilterId.L1_PROCESSING, name: OrderFilterName.L1_PROCESSING },
      { id: OrderFilterId.E_PROCESSING, name: OrderFilterName.E_PROCESSING },
    ]
  }, [currentUser])

  return <SelectControl value={value} options={options} id="OrderListFilter" onChangeHandler={onChange} className="m-0" />
}

export default OrderListFilter
