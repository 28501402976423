import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { OrderFieldSpaceNames } from 'constants/order'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'
import { changeOrderHandler } from 'store/order/order.actions'

import classes from './style.module.css'

interface RelatedFieldProps {
  fieldName: string
  fieldToCopy: string
  relatedFields?: { [key: string]: string }
}

const RelatedField: React.FC<RelatedFieldProps> = ({ fieldName, fieldToCopy, relatedFields = {} }) => {
  const dispatchStore = useDispatch()
  const form: any = useSelector(selectOrderWrite)
  const errors: any = useSelector(selectOrderFormErrors)

  const buttonDiv: any = useRef(null)

  const onClick = useCallback(() => {
    const relatedFieldsKeys = Object.keys(relatedFields)
    const relatedFieldsData: { [key: string]: string | boolean | number } = {}

    for (let i = 0; i < relatedFieldsKeys.length; i += 1) {
      relatedFieldsData[relatedFieldsKeys[i]] = form[relatedFieldsKeys[i]] || form[relatedFields[relatedFieldsKeys[i]]]
    }

    dispatchStore(
      changeOrderHandler({
        [fieldName]: form[fieldToCopy],
        ...relatedFieldsData,
      }),
    )
  }, [dispatchStore, form, fieldToCopy, fieldName, relatedFields])

  if (form[fieldName] || !form[fieldToCopy] || errors[fieldToCopy].isValid === false) return null

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classes.container}
      onMouseDown={(e) => {
        e.preventDefault()
        // Нужно, чтобы спадал фокус, к примеру, с InputFio
        if (buttonDiv?.current) buttonDiv.current.focus()
        return false
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      ref={buttonDiv}
    >
      <span>Вставить&nbsp;</span>
      <span className="text-primary">{form[fieldToCopy]}</span>
      <span>&nbsp; ({OrderFieldSpaceNames[fieldToCopy]})</span>
    </div>
  )
}

export default RelatedField
