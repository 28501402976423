import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import Title from 'components/atoms/Title'
import InputFile from 'components/molecules/InputFile'

const Customer: React.FC = () => {
  const { orderNumber } = useParams()
  const form = useSelector(selectOrderRead)

  const HandleDownloadFile = () => {}

  return (
    <>
      <ul className="list-group row needs-validation" style={{ flexWrap: 'nowrap' }}>
        <li className="list-group-item">
          <Title text="Документы" />
          <div className="p-0">
            <InputFile id={OrderFields.FILES} uploadedFiles={form[OrderFields.FILES]} handleResponse={HandleDownloadFile} orderNumber={orderNumber} />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
