import { OrderFields } from 'types/order'

export const formValidator = (id: string, isValid: boolean) => {
  switch (id) {
    case OrderFields.ORDER_TYPE:
      return {
        isValid,
        invalidMessage: 'Выберите тип заявки',
      }

    case OrderFields.LOGISTIC_INN:
    case OrderFields.LOGISTIC_FIRST_FACE_INN:
      return {
        isValid,
        invalidMessage: 'ИНН должен состоять из 10 или 12 цифр',
      }

    case OrderFields.LOGISTIC_OGRN:
      return {
        isValid,
        invalidMessage: 'ОГРН(ИП) должен состоять из 13 или 15 цифр',
      }

    case OrderFields.ORDER_DATE:
    case OrderFields.DRIVER_DOC_DATE:
    case OrderFields.DRIVER_DATA_START_DT:
    case OrderFields.LOGISTIC_FIRST_FACE_BITH:
    case OrderFields.DRIVER_BITH:
    case OrderFields.ROUTE_DATE_START:
    case OrderFields.TRANSPORT_STS_DATE:
    case OrderFields.TRAILER_STS_DATE:
      return {
        isValid,
        invalidMessage: 'Введите корректную дату',
      }

    case OrderFields.TRAILER_OWNER_TEL:
    case OrderFields.TS_OWNER_TEL:
    case OrderFields.DRIVER_TEL_1:
    case OrderFields.DRIVER_TEL_2:
    case OrderFields.LOGISTIC_TEL:
    case OrderFields.LOGISTIC_TEL_2:
    case OrderFields.CARRIER_FIRST_FACE_PHONE_1:
    case OrderFields.CARRIER_FIRST_FACE_PHONE_2:
      return {
        isValid,
        invalidMessage: 'Введите корректный телефон',
      }

    case OrderFields.LOGISTIC_NAME:
      return {
        isValid,
        invalidMessage: '',
      }

    case OrderFields.CARRIER_EMAIL:
      return {
        isValid,
        invalidMessage: 'Введите корректный email',
      }

    case OrderFields.TRANSPORT_YEAR:
    case OrderFields.TRAILER_YEAR:
      return {
        isValid,
        invalidMessage: 'Введите корректный год',
      }

    default:
      return {
        isValid,
        invalidMessage: 'Введите значение',
      }
  }
}

export default formValidator
