import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setInitialStateSelectLists } from 'store/selectLists/selectLists.actions'
import { setInitialStateOrders } from 'store/orders/orders.actions'
import { checkVersionRequest } from 'store/version/version.actions'

import SearchOrderHeader from 'components/organisms/SearchOrderHeader'
import SearchOrderBody from 'components/organisms/SearchOrderBody'

export const SearchOrderPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkVersionRequest())

    return () => {
      dispatch(setInitialStateSelectLists())
      dispatch(setInitialStateOrders())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <SearchOrderHeader />
      <div className="row flex-grow-1">
        <SearchOrderBody />
      </div>
    </div>
  )
}

export default SearchOrderPage
