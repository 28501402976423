import { all, takeEvery } from 'redux-saga/effects'
import { getBillingSettingsSaga } from 'store/billing/sagas/get-billing-settings'
import { setBillingSettingsSaga } from 'store/billing/sagas/set-billing-settings'
import { getCurrentBillingSettingsSaga } from 'store/billing/sagas/get-current-billing-settings'
import { getCompanyAccountSaga } from 'store/billing/sagas/get-company-account'

import * as actions from './billing.actions'

export default function* () {
  yield all([
    takeEvery(actions.GET_BILLING_SETTINGS_REQUEST, getBillingSettingsSaga),
    takeEvery(actions.SET_BILLING_SETTINGS_REQUEST, setBillingSettingsSaga),
    takeEvery(actions.GET_CURRENT_BILLING_SETTINGS_REQUEST, getCurrentBillingSettingsSaga),
    takeEvery(actions.GET_COMPANY_ACCOUNT_REQUEST, getCompanyAccountSaga),
  ])
}
