import { CheckedValue, Order, OrderData, OrderFields, OrderFilterId, OrderFormData, OrderTypeId, ResolutionId } from 'types/order'
import { User, UserRole } from 'types/user'
import { SelectLists } from 'types/selectLists'

import getStringFromSelectList from './getStringFromSelectList'
import { ACTIVE_ORDER_STATUSES, EFFECTIVE_ORDER_STATUSES } from '../constants/order'

const conditionallyAppendTexts = (conditionsAndTexts: [boolean, string][]): string =>
  conditionsAndTexts.reduce((result, [condition, text]) => (condition ? `${result}${text}` : result), '')

const doesOrderHaveOneOfTypes = (order: OrderData | OrderFormData, types: OrderTypeId[]) =>
  types.includes(String(order[OrderFields.ORDER_TYPE]) as OrderTypeId)

const doesOrderHaveLogisticFields = (order: OrderData | OrderFormData): boolean =>
  doesOrderHaveOneOfTypes(order, [
    OrderTypeId.LOGISTIC_AND_DRIVER,
    OrderTypeId.LOGISTIC_PAYER,
    OrderTypeId.LOGISTIC_CONTRACTOR,
    OrderTypeId.LOGISTIC,
    OrderTypeId.COUNTERPARTY_BORROWER_AND_TS,
  ])

const doesOrderHaveDriverFields = (order: OrderData | OrderFormData): boolean =>
  doesOrderHaveOneOfTypes(order, [
    OrderTypeId.LOGISTIC_AND_DRIVER,
    OrderTypeId.DRIVER,
    OrderTypeId.DRIVER_FOR_LOADING,
    OrderTypeId.DRIVER_AND_TS,
    OrderTypeId.DRIVER_DOC_CHECK,
  ])

const doesOrderHaveStateDriverFields = (order: OrderData | OrderFormData): boolean => doesOrderHaveOneOfTypes(order, [OrderTypeId.STATE_DRIVER])

const doesOrderHaveEmployeeFields = (order: OrderData | OrderFormData): boolean => doesOrderHaveOneOfTypes(order, [OrderTypeId.EMPLOYEE])

const doesOrderHaveTsFields = (order: OrderData | OrderFormData): boolean =>
  doesOrderHaveOneOfTypes(order, [
    OrderTypeId.LOGISTIC_AND_DRIVER,
    OrderTypeId.DRIVER_AND_TS,
    OrderTypeId.TS,
    OrderTypeId.COUNTERPARTY_BORROWER_AND_TS,
  ])

const doesOrderHaveTripFields = (order: OrderData | OrderFormData): boolean =>
  doesOrderHaveOneOfTypes(order, [OrderTypeId.LOGISTIC_AND_DRIVER, OrderTypeId.DRIVER_AND_TS, OrderTypeId.DRIVER_FOR_LOADING, OrderTypeId.TS])

const getLogisticTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [
      !order[OrderFields.LOGISTIC_FIRST_FACE_BITH] && !!order[OrderFields.LOGISTIC_FIRST_FACE],
      `\n - Паспорт директора (${order[OrderFields.LOGISTIC_FIRST_FACE]})`,
    ],
    [!order[OrderFields.LOGISTIC_FIO] || !order[OrderFields.LOGISTIC_TEL], `\n - Контакт перевозчика (${order[OrderFields.LOGISTIC_FIRST_FACE]})`],
  ])

const getDriverTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [!order[OrderFields.DRIVER_BITH] || !order[OrderFields.DRIVER_SER_NUM], '\n - Паспорт водителя'],
    [!order[OrderFields.DRIVER_DOC] || !order[OrderFields.DRIVER_DOC_DATE], '\n - ВУ водителя'],
    [!order[OrderFields.DRIVER_DATA_START_DT] && !order[OrderFields.DRIVER_DATA_START_DT_STATUS], '\n - ТД с водителем'],
    [!order[OrderFields.DRIVER_TEL_1], '\n - Телефон водителя'],
  ])

const getStateDriverTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [!order[OrderFields.DRIVER_BITH] || !order[OrderFields.DRIVER_SER_NUM], '\n - Паспорт водителя'],
    [!order[OrderFields.DRIVER_DOC] || !order[OrderFields.DRIVER_DOC_DATE], '\n - ВУ водителя'],
    [!order[OrderFields.DRIVER_TEL_1], '\n - Телефон водителя'],
  ])

const getEmployeeTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [!order[OrderFields.DRIVER_BITH] || !order[OrderFields.DRIVER_SER_NUM], '\n - Паспорт сотрудника'],
    [!order[OrderFields.DRIVER_TEL_1], '\n - Телефон сотрудника'],
  ])

const getTsTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [!order[OrderFields.TS_OWNER], '\n - СТС на тягач'],
    [!!order[OrderFields.TS_OWNER] && !order[OrderFields.TS_OWNER_TEL], `\n - Телефон собственника тягача (${order[OrderFields.TS_OWNER]})`],
    [!order[OrderFields.TRAILER_STATUS] && !order[OrderFields.TRAILER_OWNER], '\n - СТС на прицеп'],
    [
      !order[OrderFields.TRAILER_STATUS] && !!order[OrderFields.TRAILER_OWNER] && !order[OrderFields.TRAILER_OWNER_TEL],
      `\n - Телефон собственника прицепа (${order[OrderFields.TRAILER_OWNER]})`,
    ],
  ])

const getTripTexts = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [!order[OrderFields.ROUTE_DATE_START], '\n - Дату отгрузки'],
    [!order[OrderFields.ROUTE_TRIP], '\n - Маршрут'],
    [!order[OrderFields.CARGO], '\n - Характер груза'],
  ])

export const getTextOfDataRequestTemplate = (order: OrderData | OrderFormData): string =>
  conditionallyAppendTexts([
    [doesOrderHaveLogisticFields(order), getLogisticTexts(order)],
    [doesOrderHaveDriverFields(order), getDriverTexts(order)],
    [doesOrderHaveStateDriverFields(order), getStateDriverTexts(order)],
    [doesOrderHaveEmployeeFields(order), getEmployeeTexts(order)],
    [doesOrderHaveTsFields(order), getTsTexts(order)],
    [doesOrderHaveTripFields(order), getTripTexts(order)],
  ])

export const getOrderFilter = (id: string) => {
  switch (String(id)) {
    case OrderFilterId.ALL_ACTIVE_ORDERS:
      return (order: Order) => ACTIVE_ORDER_STATUSES.includes(String(order[OrderFields.RESOLUTION]) as ResolutionId)

    case OrderFilterId.NEW_ORDERS:
      return (order: Order) => String(order[OrderFields.RESOLUTION]) === ResolutionId.NEW_ORDER

    case OrderFilterId.CLARIFY_DATA:
      return (order: Order) => String(order[OrderFields.RESOLUTION]) === ResolutionId.CLARIFY_DATA

    case OrderFilterId.L1_PROCESSING:
      return (order: Order) => String(order[OrderFields.RESOLUTION]) === ResolutionId.L1_PROCESSING

    case OrderFilterId.E_PROCESSING:
      return (order: Order) => String(order[OrderFields.RESOLUTION]) === ResolutionId.E_PROCESSING

    case OrderFilterId.ALL_ORDERS:
      return () => true

    case OrderFilterId.ALL_FINISHED_ORDERS:
      return (order: Order) => !ACTIVE_ORDER_STATUSES.includes(String(order[OrderFields.RESOLUTION]) as ResolutionId)

    case OrderFilterId.USER_ORDERS:
      return (order: Order, currentUser: User) => {
        if (currentUser.role === UserRole.MANAGER) {
          return (
            [ResolutionId.NEW_ORDER, ResolutionId.CLARIFY_DATA, ResolutionId.DRIVER_IS_NOT_RESPONDING, ResolutionId.L1_PROCESSING].includes(
              String(order[OrderFields.RESOLUTION]) as ResolutionId,
            ) &&
            (!order[OrderFields.EXECUTOR_L1] || order[OrderFields.EXECUTOR_L1] === currentUser.id)
          )
        }

        if (currentUser.role === UserRole.EXPERT) {
          return (
            (order[OrderFields.EXECUTOR_EXPERT] === currentUser.id || !order[OrderFields.EXECUTOR_EXPERT]) &&
            ((String(order[OrderFields.RESOLUTION]) === ResolutionId.NEW_ORDER && !order[OrderFields.EXECUTOR_L1]) ||
              String(order[OrderFields.RESOLUTION]) === ResolutionId.FOR_E ||
              String(order[OrderFields.RESOLUTION]) === ResolutionId.E_PROCESSING)
          )
        }

        return false
      }

    case OrderFilterId.USER_ACTIVE_ORDERS:
      return (order: Order, currentUser: User, selectList: SelectLists) => {
        if (currentUser.role === UserRole.MANAGER) {
          return (
            [ResolutionId.FOR_E, ResolutionId.E_PROCESSING, ResolutionId.PREPARATION].includes(
              // @ts-ignore
              String(order[OrderFields.RESOLUTION]),
            ) && getStringFromSelectList(order[OrderFields.EXECUTOR_L1], selectList.executor) === currentUser.name
          )
        }

        if (currentUser.role === UserRole.EXPERT) {
          const resolutionIsIntermediate = [
            ResolutionId.CLARIFY_DATA,
            ResolutionId.DRIVER_IS_NOT_RESPONDING,
            ResolutionId.L1_PROCESSING,
            ResolutionId.PREPARATION,
          ].includes(
            // @ts-ignore
            String(order[OrderFields.RESOLUTION]),
          )
          const userIsCurrentE = order[OrderFields.EXECUTOR_EXPERT] === currentUser.id

          const resolutionIsActive = [
            ResolutionId.CLARIFY_DATA,
            ResolutionId.DRIVER_IS_NOT_RESPONDING,
            ResolutionId.L1_PROCESSING,
            ResolutionId.FOR_E,
            ResolutionId.E_PROCESSING,
          ].includes(
            // @ts-ignore
            String(order[OrderFields.RESOLUTION]),
          )
          const userIsPreviousE = order[OrderFields.PREVIOUS_EXECUTOR_EXPERT] === currentUser.id

          return (resolutionIsIntermediate && userIsCurrentE) || (userIsPreviousE && resolutionIsActive)
        }

        return false
      }

    default:
      return () => true
  }
}

export const getCheckedValueByType = (checkedValue: CheckedValue[], type: string) =>
  checkedValue.find((check) => check.field === type) || { field: type, data: [] }

export const getLastOrderFromCheckedValue = (checkedValue: CheckedValue | undefined) => {
  const pastEffectiveOrders =
    checkedValue?.data?.filter((check) => EFFECTIVE_ORDER_STATUSES.includes(String(check.resolution_id) as ResolutionId)) || []
  return pastEffectiveOrders.sort((first, second) => (new Date(first.date_create_cus) > new Date(second.date_create_cus) ? -1 : 1))[0]
}

export const getResolutionsForExpertByOrderType = (type: OrderTypeId): ResolutionId[] => {
  switch (String(type) as OrderTypeId) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      return [
        ResolutionId.SHIPMENT_ALLOWED,
        ResolutionId.SHIPMENT_FORBIDDEN,
        ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
        ResolutionId.DRIVER_IS_NOT_RESPONDING,
        ResolutionId.CLARIFY_DATA,
        ResolutionId.L1_PROCESSING,
      ]

    case OrderTypeId.DRIVER_AND_TS:
    case OrderTypeId.DRIVER_FOR_LOADING:
      return [
        ResolutionId.SHIPMENT_ALLOWED,
        ResolutionId.SHIPMENT_FORBIDDEN,
        ResolutionId.DRIVER_IS_NOT_RESPONDING,
        ResolutionId.CLARIFY_DATA,
        ResolutionId.L1_PROCESSING,
      ]

    case OrderTypeId.EMPLOYEE:
    case OrderTypeId.STATE_DRIVER:
      return [
        ResolutionId.COOPERATION_AGREED,
        ResolutionId.COOPERATION_FORBIDDEN,
        ResolutionId.DRIVER_IS_NOT_RESPONDING,
        ResolutionId.CLARIFY_DATA,
        ResolutionId.L1_PROCESSING,
      ]

    case OrderTypeId.TS:
    case OrderTypeId.LOGISTIC_CONTRACTOR:
      return [ResolutionId.SHIPMENT_ALLOWED, ResolutionId.SHIPMENT_FORBIDDEN, ResolutionId.CLARIFY_DATA, ResolutionId.L1_PROCESSING]

    case OrderTypeId.DRIVER_DOC_CHECK:
    case OrderTypeId.LOGISTIC_PAYER:
    case OrderTypeId.COUNTERPARTY_BORROWER_AND_TS:
    case OrderTypeId.PHONE_CHECK:
      return [ResolutionId.COOPERATION_AGREED, ResolutionId.COOPERATION_FORBIDDEN, ResolutionId.CLARIFY_DATA, ResolutionId.L1_PROCESSING]

    default:
      return [
        ResolutionId.SHIPMENT_ALLOWED,
        ResolutionId.SHIPMENT_FORBIDDEN,
        ResolutionId.COOPERATION_AGREED,
        ResolutionId.COOPERATION_FORBIDDEN,
        ResolutionId.LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN,
        ResolutionId.DRIVER_IS_NOT_RESPONDING,
        ResolutionId.CLARIFY_DATA,
        ResolutionId.L1_PROCESSING,
      ]
  }
}
