import { FileProps } from './file'
import { Props } from './core/props'

export enum OrderFields {
  ORDER_NUMBER = 'orderNumber', // Номер заявки
  ORDER_TIME = 'orderTime', // Время создания заявки
  STARTED_CREATING_AT = 'startedCreatingAt', // Время захода на страницу создания заявки
  EXECUTOR = 'executor', // Исполнитель
  EXECUTOR_L1 = 'executorL1', // Исполнитель L1
  EXECUTOR_EXPERT = 'executorExpert', // Исполнитель EXPERT
  ORDER_DATE = 'orderDate', // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
  CLIENT_NAME = 'clientName', // Клиент - заказчик проверки
  ORDER_TEXT = 'orderText', // Текст заявки от клиента
  ORDER_TYPE = 'orderType', // Тип заявки
  PRIORITY = 'priority', // Приоритет заявки
  RESOLUTION = 'resolution', // Резолюция
  RESOLUTION_COMMENT = 'resolutionComment', // Комментарий к резолюции
  ORDER_COMMENT = 'orderComment', // Комментарий
  LOGISTIC_NAME = 'logisticName', // Перевозчик. Название юрлица
  LOGISTIC_INN = 'logisticInn', // Перевозчик. ИНН
  LOGISTIC_TYPE = 'logisticType', // Тип перевозчика (Юрлицо, ИП, самозанятый)
  LOGISTIC_OGRN = 'logisticOgrn', // Перевозчик. ОГРН(ИП)
  LOGISTIC_ATI = 'logisticAti', // Перевозчик. Ати
  LOGISTIC_ATI_STATUS = 'logisticAtiStatus', // Перевозчик. Ати. Статус. true - Есть в АТИ. false - Нет в АТИ.
  LOGISTIC_FIRST_FACE = 'logisticFirstFace', // Перевозчик.1е лицо
  LOGISTIC_FIRST_FACE_BITH = 'logisticFirstFaceBith', // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
  LOGISTIC_FIRST_FACE_INN = 'logisticFirstFaceInn', // Перевозчик.ИНН 1го лица
  LOGISTIC_FIO = 'logisticFio', // Перевозчик. ФИО контакта
  LOGISTIC_TEL = 'logisticTel', // Перевозчик. Телефон контакта
  LOGISTIC_TEL_2 = 'logisticTel2', // Перевозчик. Телефон контакта 2
  CARRIER_FIRST_FACE_PHONE_1 = 'carrierFirstFacePhone1', // Перевозчик. Телефон первого лица
  CARRIER_FIRST_FACE_PHONE_2 = 'carrierFirstFacePhone2', // Перевозчик. Телефон первого лица
  CARRIER_EMAIL = 'carrierEmail', // Перевозчик. Email
  DRIVER_FIO = 'driverFio', // Водитель. - Менеджер ФИО
  DRIVER_BITH = 'driverBith', // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
  DRIVER_SER_NUM = 'driverSerNum', // Водитель. Серия и номер паспорта
  DRIVER_DOC = 'driverDoc', // Водитель. Номер ВУ
  IS_DRIVER_DOC_VALID = 'isDriverDocValid', // Водитель. Действительно ли ВУ (true - да, false - нет, null - не проверяли)
  DRIVER_DOC_DATE = 'driverDocDate', // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
  DRIVER_DATA_START_DT = 'driverDataStartDt', // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
  DRIVER_TEL_1 = 'driverTel1', // Водитель. Телефон 1
  DRIVER_TEL_2 = 'driverTel2', // Водитель. Телефон 2
  DRIVER_DATA_START_DT_STATUS = 'driverDataStartDtStatus', // Параметр состояния галочки Дата начала ТД
  TS_OWNER = 'tsOwner', // ТС. Собственник.
  TS_OWNER_TEL = 'tsOwnerTel', // ТС. Собственник. Телефон
  TS_OWNER_LICENSE_PLATE = 'tsOwnerLicensePlate', // ТС. Собственник. Госномер
  TRAILER_STATUS = 'trailerStatus', // Без прицепа bool
  TRAILER_OWNER = 'trailerOwner', // Прицеп. Собственник
  TRAILER_OWNER_TEL = 'trailerOwnerTel', // Прицеп. Собственник. Телефон
  TRAILER_OWNER_LICENSE_PLATE = 'trailerOwnerLicensePlate', // Прицеп. Собственник. Госномер
  ROUTE_DATE_START = 'routeDateStart', // Рейс. Дата начала. Вид: "yyyy-MM-dd"
  ROUTE_TRIP = 'routeTrip', // Рейс. Маршрут
  CARGO = 'cargo', // Груз
  SHIPPER = 'shipper', // Поле Грузоотправитель/Заказчик
  FILES = 'files', // id файла к этому заказу, или массив id-ов
  LINK_TO_FOLDER = 'linkToFolder',
  COMPANY_COMMENT = 'companyComment', // Комментарий к компании
  CHECKED_FIELDS = 'checkedFields', // Данные о уже проверенных значениях
  PREVIOUS_EXECUTOR_EXPERT = 'previousExecutorExpert',
  PREVIOUS_RESOLUTION = 'previousResolution',
  PREVIOUS_RESOLUTION_COMMENT = 'previousResolutionComment',
  TRANSPORT_YEAR = 'transportYear',
  TRAILER_YEAR = 'trailerYear',
  TRANSPORT_STS_DATE = 'transportStsDate',
  TRAILER_STS_DATE = 'trailerStsDate',
  TRANSPORT_RENT = 'transportRent',
  TRAILER_RENT = 'trailerRent',
  DRIVER_CREDIT_RATING = 'driverCreditRating',
  HAS_BELARUSIANS = 'hasBelarusians',
  CUSTOMER_FEATURES = 'customerFeatures',
}

export interface OrderInCheckedValue {
  order: string
  type_order_id: string
  resolution_id: number
  date_create_cus: string
  logistic_first_face?: string
  logistic_first_face_bith?: string
  driver_tel1?: string
  driver_tel2?: string
  logistic_tel?: string
  logistic_tel2?: string
  customer_id: string
  executor_expert_id: string
}

export interface CheckedValue {
  field: string
  data: OrderInCheckedValue[] | null
}

export interface Order {
  [OrderFields.ORDER_NUMBER]: string
  [OrderFields.ORDER_TIME]: string
  [OrderFields.ORDER_DATE]: string
  [OrderFields.PRIORITY]?: string
  [OrderFields.LOGISTIC_NAME]: string
  [OrderFields.DRIVER_FIO]: string
  [OrderFields.RESOLUTION]: string
  [OrderFields.RESOLUTION_COMMENT]: string
  [OrderFields.CLIENT_NAME]?: string
  [OrderFields.ORDER_COMMENT]?: string
  [OrderFields.EXECUTOR_L1]?: string
  [OrderFields.EXECUTOR_EXPERT]?: string
  [OrderFields.ORDER_TYPE]?: number
  [OrderFields.PREVIOUS_EXECUTOR_EXPERT]?: number | null
  [OrderFields.PREVIOUS_RESOLUTION]?: number | null
  [OrderFields.PREVIOUS_RESOLUTION_COMMENT]?: string | null
  [OrderFields.DRIVER_CREDIT_RATING]: boolean
  [OrderFields.HAS_BELARUSIANS]: boolean | null

  // Если есть аттрибут search. START
  [OrderFields.LOGISTIC_INN]?: string
  [OrderFields.LOGISTIC_OGRN]?: string
  [OrderFields.LOGISTIC_FIRST_FACE]?: string
  [OrderFields.LOGISTIC_FIRST_FACE_INN]?: string
  [OrderFields.LOGISTIC_FIO]?: string
  [OrderFields.LOGISTIC_TEL]?: string
  [OrderFields.LOGISTIC_TEL_2]?: string
  [OrderFields.DRIVER_TEL_1]?: string
  [OrderFields.DRIVER_TEL_2]?: string
  [OrderFields.TS_OWNER]?: string
  [OrderFields.TS_OWNER_TEL]?: string
  [OrderFields.TRAILER_OWNER]?: string
  [OrderFields.TRAILER_OWNER_TEL]?: string
  [OrderFields.TS_OWNER_LICENSE_PLATE]?: string
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]?: string
  // search. END
}

export interface OrderFormData {
  [OrderFields.EXECUTOR]: string // Исполнитель (Для создания)
  [OrderFields.EXECUTOR_L1]: string // Исполнитель L1 (Для редактирования)
  [OrderFields.EXECUTOR_EXPERT]: string // Исполнитель Expert (Для редактирования)
  [OrderFields.ORDER_DATE]: string // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
  [OrderFields.CLIENT_NAME]: string // Клиент - заказчик проверки
  [OrderFields.ORDER_TEXT]: string // Текст заявки от клиента
  [OrderFields.ORDER_TYPE]: OrderTypeId // Тип заявки
  [OrderFields.PRIORITY]: string // Приоритет заявки
  [OrderFields.RESOLUTION]: string // Резолюция
  [OrderFields.RESOLUTION_COMMENT]: string // Комментарий к резолюции
  [OrderFields.ORDER_COMMENT]: string // Комментарий
  [OrderFields.LOGISTIC_NAME]: string // Перевозчик. Название юрлица
  [OrderFields.LOGISTIC_INN]: string // Перевозчик. ИНН
  [OrderFields.LOGISTIC_TYPE]: LogisticType | null // Тип перевозчика (Юрлицо, ИП, самозанятый)
  [OrderFields.LOGISTIC_OGRN]: string // Перевозчик. ОГРН(ИП)
  [OrderFields.LOGISTIC_ATI]: string // Перевозчик. Ати. Статус
  [OrderFields.LOGISTIC_ATI_STATUS]: boolean // Перевозчик. Ати
  [OrderFields.LOGISTIC_FIRST_FACE]: string // Перевозчик.1е лицо
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: string // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
  [OrderFields.LOGISTIC_FIRST_FACE_INN]: string // Перевозчик.ИНН 1го лица
  [OrderFields.LOGISTIC_FIO]: string // Перевозчик. ФИО контакта
  [OrderFields.LOGISTIC_TEL]: string // Перевозчик. Телефон контакта
  [OrderFields.LOGISTIC_TEL_2]: string // Перевозчик. Телефон контакта 2
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: string
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: string
  [OrderFields.CARRIER_EMAIL]: string // Первозчик. Email
  [OrderFields.DRIVER_FIO]: string // Водитель. - Менеджер ФИО
  [OrderFields.DRIVER_BITH]: string // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_SER_NUM]: string // Водитель. Серия и номер паспорта
  [OrderFields.DRIVER_DOC]: string // Водитель. Номер ВУ
  [OrderFields.IS_DRIVER_DOC_VALID]: boolean | null
  [OrderFields.DRIVER_DOC_DATE]: string // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_DATA_START_DT]: string // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_TEL_1]: string // Водитель. Телефон 1
  [OrderFields.DRIVER_TEL_2]: string // Водитель. Телефон 2
  [OrderFields.DRIVER_DATA_START_DT_STATUS]: boolean // Параметр состояния галочки Дата начала ТД
  [OrderFields.TS_OWNER]: string // ТС. Собственник.
  [OrderFields.TS_OWNER_TEL]: string // ТС. Собственник. Телефон
  [OrderFields.TS_OWNER_LICENSE_PLATE]: string // TC. Собственник. Госномер
  [OrderFields.TRAILER_STATUS]: boolean // Параметр состояния галочки Без прицепа
  [OrderFields.TRAILER_OWNER]: string // Прицеп. Собственник
  [OrderFields.TRAILER_OWNER_TEL]: string // Прицеп. Собственник. Телефон
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: string // Прицеп. Собственник. Госномер
  [OrderFields.ROUTE_DATE_START]: string // Рейс. Дата начала. Вид: "yyyy-MM-dd"
  [OrderFields.ROUTE_TRIP]: string // Рейс. Маршрут
  [OrderFields.CARGO]: string // Груз
  [OrderFields.SHIPPER]: string // Поле Грузоотправтиль/Заказчик
  [OrderFields.FILES]: number[] | FileProps[] // TODO refactor
  [OrderFields.STARTED_CREATING_AT]: string
  [OrderFields.TRANSPORT_YEAR]: number | null
  [OrderFields.TRAILER_YEAR]: number | null
  [OrderFields.TRANSPORT_STS_DATE]: string
  [OrderFields.TRAILER_STS_DATE]: string
  [OrderFields.TRANSPORT_RENT]: boolean
  [OrderFields.TRAILER_RENT]: boolean
  [OrderFields.DRIVER_CREDIT_RATING]: boolean
  [OrderFields.HAS_BELARUSIANS]: boolean
  [OrderFields.CUSTOMER_FEATURES]: Props
}

export interface OrderData {
  [OrderFields.ORDER_TIME]: string // Время создания заявки
  [OrderFields.ORDER_NUMBER]: string // Номер заявки
  [OrderFields.EXECUTOR_L1]: string // Исполнитель L1
  [OrderFields.EXECUTOR_EXPERT]: string // Исполнитель EXPERT
  [OrderFields.ORDER_DATE]: string // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
  [OrderFields.CLIENT_NAME]: string // Клиент - заказчик проверки
  [OrderFields.ORDER_TEXT]: string // Текст заявки от клиента
  [OrderFields.ORDER_TYPE]: OrderTypeId // Тип заявки
  [OrderFields.PRIORITY]: string // Приоритет заявки
  [OrderFields.RESOLUTION]: string // Резолюция
  [OrderFields.RESOLUTION_COMMENT]: string // Комментарий к резолюции
  [OrderFields.ORDER_COMMENT]: string // Комментарий
  [OrderFields.LOGISTIC_NAME]: string // Перевозчик. Название юрлица
  [OrderFields.LOGISTIC_INN]: string // Перевозчик. ИНН
  [OrderFields.LOGISTIC_TYPE]: LogisticType | null // Тип перевозчика (Юрлицо, ИП, самозанятый)
  [OrderFields.LOGISTIC_OGRN]: string // Перевозчик. ОГРН(ИП)
  [OrderFields.LOGISTIC_ATI]: string // Перевозчик. Ати
  [OrderFields.LOGISTIC_ATI_STATUS]: boolean // Перевозчик. Ати. Статус
  [OrderFields.LOGISTIC_FIRST_FACE]: string // Перевозчик.1е лицо
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: string // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
  [OrderFields.LOGISTIC_FIRST_FACE_INN]: string // Перевозчик.ИНН 1го лица
  [OrderFields.LOGISTIC_FIO]: string // Перевозчик. ФИО контакта
  [OrderFields.LOGISTIC_TEL]: string // Перевозчик. Телефон контакта
  [OrderFields.LOGISTIC_TEL_2]: string // Перевозчик. Телефон контакта 2
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: string
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: string
  [OrderFields.CARRIER_EMAIL]: string // Первозчик. Email
  [OrderFields.DRIVER_FIO]: string // Водитель. - Менеджер ФИО
  [OrderFields.DRIVER_BITH]: string // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_SER_NUM]: string // Водитель. Серия и номер паспорта
  [OrderFields.DRIVER_DOC]: string // Водитель. Номер ВУ
  [OrderFields.IS_DRIVER_DOC_VALID]: boolean | null
  [OrderFields.DRIVER_DOC_DATE]: string // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_DATA_START_DT]: string // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
  [OrderFields.DRIVER_TEL_1]: string // Водитель. Телефон 1
  [OrderFields.DRIVER_TEL_2]: string // Водитель. Телефон 2
  [OrderFields.DRIVER_DATA_START_DT_STATUS]: boolean // Параметр состояния галочки Дата начала ТД
  [OrderFields.TS_OWNER]: string // ТС. Собственник.
  [OrderFields.TS_OWNER_TEL]: string // ТС. Собственник. Телефон
  [OrderFields.TS_OWNER_LICENSE_PLATE]: string // TC. Собственник. Госномер
  [OrderFields.TRAILER_STATUS]: boolean // Параметр состояния галочки Дата начала ТД
  [OrderFields.TRAILER_OWNER]: string // Прицеп. Собственник
  [OrderFields.TRAILER_OWNER_TEL]: string // Прицеп. Собственник. Телефон
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: string // Прицеп. Собственник. Госномер
  [OrderFields.ROUTE_DATE_START]: string // Рейс. Дата начала. Вид: "yyyy-MM-dd"
  [OrderFields.ROUTE_TRIP]: string // Рейс. Маршрут
  [OrderFields.CARGO]: string // Груз
  [OrderFields.SHIPPER]: string // Поле Грузоотправтиль/Заказчик
  [OrderFields.FILES]: FileProps[]
  [OrderFields.LINK_TO_FOLDER]: string
  [OrderFields.COMPANY_COMMENT]: string // Комментарий к компании клиента
  [OrderFields.CHECKED_FIELDS]: CheckedValue[]
  [OrderFields.PREVIOUS_EXECUTOR_EXPERT]: number | null
  [OrderFields.PREVIOUS_RESOLUTION]: number | null
  [OrderFields.PREVIOUS_RESOLUTION_COMMENT]: string | null
  [OrderFields.TRANSPORT_YEAR]: number | null
  [OrderFields.TRAILER_YEAR]: number | null
  [OrderFields.TRANSPORT_STS_DATE]: string
  [OrderFields.TRAILER_STS_DATE]: string
  [OrderFields.TRANSPORT_RENT]: boolean
  [OrderFields.TRAILER_RENT]: boolean
  [OrderFields.DRIVER_CREDIT_RATING]: boolean
  [OrderFields.HAS_BELARUSIANS]: boolean
  [OrderFields.CUSTOMER_FEATURES]: Props
}

export interface GetOrdersResponseData {
  ordersList: Order[]
  withoutExecutor: number
  numberOfOrdersPerDay: number
  orderCount: number
}

export interface OrderFormErrorProps {
  isValid: undefined | true | false
  invalidMessage: string
}

export interface CreateOrderResponseData {
  code: number // Код ответа.
  fields: {
    [OrderFields.ORDER_NUMBER]: string // Номер заявки. Вид: "yyMMdd-hhmm01"
    linkToFolder: string // Ссылка на папку с док-ми
  }
}

export interface EditOrderData {
  [OrderFields.ORDER_NUMBER]: string
  fields: {
    [key: string]: boolean | string | number | null
  }
}

export interface OrderFormErrorsProps {
  [OrderFields.EXECUTOR]: OrderFormErrorProps
  [OrderFields.EXECUTOR_L1]: OrderFormErrorProps
  [OrderFields.EXECUTOR_EXPERT]: OrderFormErrorProps
  [OrderFields.ORDER_DATE]: OrderFormErrorProps
  [OrderFields.CLIENT_NAME]: OrderFormErrorProps
  [OrderFields.ORDER_TEXT]: OrderFormErrorProps
  [OrderFields.ORDER_TYPE]: OrderFormErrorProps
  [OrderFields.PRIORITY]: OrderFormErrorProps
  [OrderFields.RESOLUTION]: OrderFormErrorProps
  [OrderFields.RESOLUTION_COMMENT]: OrderFormErrorProps
  [OrderFields.ORDER_COMMENT]: OrderFormErrorProps
  [OrderFields.LOGISTIC_NAME]: OrderFormErrorProps
  [OrderFields.LOGISTIC_INN]: OrderFormErrorProps
  [OrderFields.LOGISTIC_TYPE]: OrderFormErrorProps
  [OrderFields.LOGISTIC_OGRN]: OrderFormErrorProps
  [OrderFields.LOGISTIC_ATI]: OrderFormErrorProps
  [OrderFields.LOGISTIC_ATI_STATUS]: OrderFormErrorProps
  [OrderFields.LOGISTIC_FIRST_FACE]: OrderFormErrorProps
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: OrderFormErrorProps
  [OrderFields.LOGISTIC_FIRST_FACE_INN]: OrderFormErrorProps
  [OrderFields.LOGISTIC_FIO]: OrderFormErrorProps
  [OrderFields.LOGISTIC_TEL]: OrderFormErrorProps
  [OrderFields.LOGISTIC_TEL_2]: OrderFormErrorProps
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: OrderFormErrorProps
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: OrderFormErrorProps
  [OrderFields.CARRIER_EMAIL]: OrderFormErrorProps
  [OrderFields.DRIVER_FIO]: OrderFormErrorProps
  [OrderFields.DRIVER_BITH]: OrderFormErrorProps
  [OrderFields.DRIVER_SER_NUM]: OrderFormErrorProps
  [OrderFields.DRIVER_DOC]: OrderFormErrorProps
  [OrderFields.IS_DRIVER_DOC_VALID]: OrderFormErrorProps
  [OrderFields.DRIVER_DOC_DATE]: OrderFormErrorProps
  [OrderFields.DRIVER_DATA_START_DT]: OrderFormErrorProps
  [OrderFields.DRIVER_TEL_1]: OrderFormErrorProps
  [OrderFields.DRIVER_TEL_2]: OrderFormErrorProps
  [OrderFields.DRIVER_DATA_START_DT_STATUS]: OrderFormErrorProps
  [OrderFields.TS_OWNER]: OrderFormErrorProps
  [OrderFields.TS_OWNER_TEL]: OrderFormErrorProps
  [OrderFields.TS_OWNER_LICENSE_PLATE]: OrderFormErrorProps
  [OrderFields.TRAILER_STATUS]: OrderFormErrorProps
  [OrderFields.TRAILER_OWNER]: OrderFormErrorProps
  [OrderFields.TRAILER_OWNER_TEL]: OrderFormErrorProps
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: OrderFormErrorProps
  [OrderFields.ROUTE_DATE_START]: OrderFormErrorProps
  [OrderFields.ROUTE_TRIP]: OrderFormErrorProps
  [OrderFields.CARGO]: OrderFormErrorProps
  [OrderFields.SHIPPER]: OrderFormErrorProps
  [OrderFields.FILES]: OrderFormErrorProps
  [OrderFields.TRANSPORT_YEAR]: OrderFormErrorProps
  [OrderFields.TRAILER_YEAR]: OrderFormErrorProps
  [OrderFields.TRANSPORT_STS_DATE]: OrderFormErrorProps
  [OrderFields.TRAILER_STS_DATE]: OrderFormErrorProps
  [OrderFields.TRANSPORT_RENT]: OrderFormErrorProps
  [OrderFields.TRAILER_RENT]: OrderFormErrorProps
  [OrderFields.DRIVER_CREDIT_RATING]: OrderFormErrorProps
  [OrderFields.HAS_BELARUSIANS]: OrderFormErrorProps
}

export interface GetOrderData {
  [OrderFields.ORDER_NUMBER]: string
}

export enum OrderTypeName {
  LOGISTIC_AND_DRIVER = 'logisticAndDriver',
  DRIVER_AND_TS = 'driverAndTs',
  DRIVER = 'driver',
  LOGISTIC = 'logistic',
  EMPLOYEE = 'employee',
  TS = 'ts',
  DRIVER_DOC_CHECK = 'driverDocCheck',
  LOGISTIC_PAYER = 'logisticPayer',
  LOGISTIC_CONTRACTOR = 'logisticContractor',
  STATE_DRIVER = 'stateDriver',
  DRIVER_FOR_LOADING = 'driverForLoading',
  COUNTERPARTY_AND_TS = 'COUNTERPARTY_AND_TS',
  PHONE_CHECK = 'phoneCheck',
}

export enum OrderTypeId {
  LOGISTIC_AND_DRIVER = '1', // Перевозчик + водитель + ТС
  DRIVER_AND_TS = '2', // Водитель + ТС
  LOGISTIC = '3', // Контрагент: юрлицо или ИП
  DRIVER = '4', // Водитель
  EMPLOYEE = '5', // Сотрудник в штат
  TS = '6', // ТС
  DRIVER_DOC_CHECK = '7', // Проверка ВУ
  LOGISTIC_PAYER = '8', // Контрагент - плательщик
  LOGISTIC_CONTRACTOR = '9', // Контрагент - перевозчик/подрядчик
  STATE_DRIVER = '10', // Водитель в штат
  DRIVER_FOR_LOADING = '11', // Водитель на погрузку
  COUNTERPARTY_BORROWER_AND_TS = '12', // КА-заемщик + ТС
  PHONE_CHECK = '13', // Проверка телефона
}

export enum ResolutionId {
  WIP = '1', // В работе
  CLARIFY_DATA = '2', // Уточнить данные
  SHIPMENT_ALLOWED = '3', // Отгрузка разрешена
  LEGAL_ENTITY_PERMITTED_DRIVER_FORBIDDEN = '4', // Юрлицо/ИП разрешено, водитель ЗАПРЕЩЕН.
  SHIPMENT_FORBIDDEN = '5', // Отгрузка ЗАПРЕЩЕНА
  CHECK_CANCELED = '6', // Проверка отменена
  COOPERATION_AGREED = '7', // Сотрудничество согласовано
  COOPERATION_FORBIDDEN = '8', // Сотрудничество ЗАПРЕЩЕНО
  NEW_ORDER = '9', // Новая заявка
  L1_PROCESSING = '10', // L1 обработка
  CLARIFIED_DATA = '11', // Уточнили данные
  FOR_E = '12', // Для E
  E_PROCESSING = '13', // E обработка
  DRIVER_IS_NOT_RESPONDING = '14', // Водитель не отвечает
  PREPARATION = '15',
}

export enum OrderSpaceName {
  HEADER = 'header',
  ORDER = 'order',
  CONTACT = 'contact',
  LOGISTIC = 'logistic',
  DRIVER = 'driver',
  TS = 'ts',
  ROUTE = 'route',
  FILES = 'files',
}

export enum OrderFormSpaceId {
  HEADER = 'header',
  ORDER = 'order',
  CONTACT = 'contact',
  LOGISTIC = 'logistic',
  DRIVER = 'driver',
  TS = 'ts',
  ROUTE = 'route',
  FILES = 'files',
}

export interface LogisticInfoProps {
  fields: {
    [OrderFields.LOGISTIC_OGRN]?: string
    [OrderFields.LOGISTIC_FIRST_FACE]?: string
    [OrderFields.LOGISTIC_NAME]: string
    [OrderFields.LOGISTIC_FIRST_FACE_INN]?: string
    [OrderFields.LOGISTIC_FIRST_FACE_BITH]?: string
    [OrderFields.LOGISTIC_ATI]?: string
    [OrderFields.LOGISTIC_ATI_STATUS]?: boolean
    organType?: 'INDIVIDUAL' | 'LEGAL'
  }
}

export interface GetLogisticInfo {
  [OrderFields.LOGISTIC_INN]: string
}

export enum OrderBodyType {
  ORDER = 'order',
  AUTO_CHECKS = 'auto_checks',
  EXTERNAL_CHAT = 'external_chat',
  INTERNAL_CHAT = 'internal_chat',
  CERTIFICATE = 'certificate',
  ORDER_HISTORY = 'order_history',
}

export enum LogisticType {
  LEGAL = 'legal',
  INDIVIDUAL = 'individual',
  SELF_EMPLOYED = 'self-employed',
  FOREIGN = 'foreign',
}

export enum PriorityId {
  NONE = '1',
  URGENTLY = '2',
}

export enum OrderFilterId {
  ALL_ACTIVE_ORDERS = 'all-active-orders',
  ALL_ORDERS = 'all-orders',
  ALL_FINISHED_ORDERS = 'all-finished-orders',
  USER_ORDERS = 'user-orders',
  USER_ACTIVE_ORDERS = 'user-active-orders',
  NEW_ORDERS = 'new-orders',
  CLARIFY_DATA = 'clarify-data',
  L1_PROCESSING = 'l1-processing',
  E_PROCESSING = 'e-processing',
}

export enum OrderFilterName {
  ALL_ACTIVE_ORDERS = 'Активные заявки',
  ALL_ORDERS = 'Все заявки за сегодня и вчера',
  ALL_FINISHED_ORDERS = 'Завершенные заявки',
  USER_ORDERS = 'Мои заявки',
  USER_ACTIVE_ORDERS = 'Мои активные заявки',
  NEW_ORDERS = 'Новые заявки',
  CLARIFY_DATA = 'Заявки на уточнении данных',
  L1_PROCESSING = 'Заявки в L1 обработке',
  E_PROCESSING = 'Заявки в E обработке',
}

export enum OrderStatus {
  IN_PROGRESS = 'in-progress',
  CLARIFICATION = 'clarification',
  CLOSED = 'closed',
}
