import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

export interface SwitchControlProps {
  id?: string
  value: boolean
  onChangeHandler(value: boolean, id?: string): void
  label?: string
  required?: boolean
}

export const SwitchControl: React.FC<SwitchControlProps> = ({ id, value, onChangeHandler, label, required = false }) => {
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.checked, id), [onChangeHandler, id])

  const name = useMemo(() => uuidv4(), [])

  return (
    <Form.Group className="m-0">
      <Form.Check id={name} type="switch" checked={value} onChange={onChange} required={required} label={label} style={{ userSelect: 'none' }} />
    </Form.Group>
  )
}

export default SwitchControl
