import { AppState } from 'store/root.reducer'

import { LoadingStatus } from 'types/loading'

export const selectUsersState = (state: AppState) => state?.users

export const selectCurrentUser = (state: AppState) => selectUsersState(state).currentUser

export const isCurrentUserLoadingInProgress = (state: AppState) =>
  selectUsersState(state)?.loadingCurrentUser === LoadingStatus.PENDING || selectUsersState(state)?.loadingCurrentUser === LoadingStatus.NONE

export const selectLoadingLogin = (state: AppState) => selectUsersState(state)?.loadingLogin

export const selectToken = (state: AppState) => (selectCurrentUser(state) ? selectCurrentUser(state)!.token : '')

export const selectLoadingUpdateUserSettingsStatus = (state: AppState) => selectUsersState(state)?.loadingUpdateUserSettings
