import { Action } from 'redux-actions'
import { call, put, select } from 'redux-saga/effects'

import { Props } from 'types/core/props'
import { getTemplateRequest } from 'requests/template'
import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { getTemplateFailure, getTemplateSuccess } from 'store/template/template.actions'

export function* getTemplateSaga(action: Action<Props>) {
  try {
    const token = yield select(selectToken)

    const response = yield call(getTemplateRequest, action.payload, token)

    yield put(getTemplateSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Получение шаблона',
        defaultText: 'Не удалось получить шаблон.',
      }),
    )
    console.error(`[getTemplateSaga]: ${error}`)
    yield put(getTemplateFailure(error))
  }
}
