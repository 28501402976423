import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { DictionaryEntryType, PhoneDictionaryLinkSource, PhoneDictionaryLinkSourceName } from 'types/dictionary'

import { linkDictionaryEntriesRequest } from 'store/dictionary/dictionary.actions'
import { useDictionaryByEntry, usePhoneDictionaryLinksByEntry } from 'store/dictionary/dictionary.hooks'

import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import BoldText from 'components/atoms/BoldText'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import PhoneVerificationPanel from 'components/molecules/OrderSpace/components/PhoneVerificationPanel'
import DictionaryPhoneForm from 'components/molecules/OrderSpace/components/DictionaryPhoneForm'
import WhatsappNumberLink from 'components/atoms/WhatsappNumberLink'
import ViberNumberLink from 'components/atoms/ViberNumberLink'
import Indent from 'components/atoms/Indent'

interface PhonesFromDictionaryProps {
  person: {
    name: string
    birthday: string
  }
  existingPhones: string[]
}

export const PhonesFromDictionary: React.FC<PhonesFromDictionaryProps> = ({ person, existingPhones }) => {
  const dispatch = useDispatch()

  const [phoneFormValue, setPhoneFormValue] = useState('')
  const [phones, setPhones] = useState<string[]>([])

  const personEntry = useDictionaryByEntry({ ...person, type: DictionaryEntryType.PERSON })
  const phoneFormEntry = useDictionaryByEntry({ phone: phoneFormValue, type: DictionaryEntryType.PHONE })

  const dictionaryLinks = usePhoneDictionaryLinksByEntry(
    {
      ...person,
      type: DictionaryEntryType.PERSON,
    },
    phones,
  )

  useEffect(() => {
    setPhones((prev) => {
      const newState = [...prev]

      const entries = personEntry?.response?.entries || []
      entries.forEach((entry) => {
        const isPhone = entry.type === DictionaryEntryType.PHONE
        const isNewPhone = !prev.find((phone) => phone === entry.phone)
        const inNotExistingPhone = !existingPhones.includes(entry.phone)
        if (isPhone && isNewPhone && inNotExistingPhone) newState.push(entry.phone)
      })

      return newState
    })
  }, [existingPhones, personEntry])

  const handleAddPhone = async (phone: string, linkSource: PhoneDictionaryLinkSource) => {
    const firstEntry = { ...person, type: DictionaryEntryType.PERSON }
    const secondEntry = { phone, type: DictionaryEntryType.PHONE }

    dispatch(linkDictionaryEntriesRequest({ entries: [firstEntry, secondEntry], linkData: { linkSource } }))
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '4px' }}>
        {phones.map((phone, index) => (
          <div key={phone}>
            <div key={phone} className="d-flex justify-content-between">
              <div className="d-inline-block">
                <TextWithPrefixAndCopy text={<BoldText text={<PhoneNumberLink phone={phone} />} />} prefix="Тел:" copiedText={phone} />
              </div>
              <div className="d-inline-block flex-grow-1">
                <PhoneVerificationPanel person={person} phone={phone} />
              </div>
            </div>
            <Indent>
              <div className="d-flex align-items-center align-middle" style={{ marginBottom: '4px' }}>
                <WhatsappNumberLink phone={phone} />
                <ViberNumberLink phone={phone} />
              </div>
              {dictionaryLinks[index]?.data?.linkSource ? (
                <div style={{ color: 'rgb(153, 153, 153)' }}>
                  Способ верификации: {PhoneDictionaryLinkSourceName[dictionaryLinks[index]?.data?.linkSource as PhoneDictionaryLinkSource]}
                </div>
              ) : null}
            </Indent>
          </div>
        ))}
      </div>
      <div className="mt-2">
        <DictionaryPhoneForm
          value={phoneFormValue}
          onChange={setPhoneFormValue}
          loadingStatus={phoneFormEntry?.status}
          handleSubmit={handleAddPhone}
        />
      </div>
    </>
  )
}

export default PhonesFromDictionary
