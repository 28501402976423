import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditCustomerSettingsData } from 'types/customer'

import { editCustomerSettings } from 'requests/customer'

import { editCustomerSettingsSuccess, editCustomerSettingsFailure, getCustomersRequest } from 'store/admin/admin.actions'

export function* editCustomerSettingsSaga(action: Action<EditCustomerSettingsData>) {
  try {
    const token = yield select(selectToken)

    yield call(editCustomerSettings, action.payload, token)

    yield put(editCustomerSettingsSuccess())
    yield put(
      addAlert({
        text: `Настройки клиента успешно изменены.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Панель администратора',
      }),
    )
    yield put(getCustomersRequest())
  } catch (error) {
    yield put(editCustomerSettingsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Редактирование клиента',
        defaultText: 'Не удалось изменить настройки.',
      }),
    )
    console.error(`[editCustomerSettingsSaga]: ${error}`)
    captureException(error)
  }
}
