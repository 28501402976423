import React from 'react'

import { OrderFields } from 'types/order'

import ListItem from './ListItem'

const CompanyName: React.FC<any> = ({ onChange }) => {
  return <ListItem onChange={onChange} id={OrderFields.LOGISTIC_NAME} label="Название юрлица:" />
}

export default CompanyName
