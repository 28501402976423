import { createAction } from 'redux-actions'
import { TelegramChat } from 'types/telegram-chat'

export const FETCH_GET_TELEGRAM_CHAT_LIST_REQUEST = 'FETCH_GET_TELEGRAM_CHAT_LIST_REQUEST'
export const fetchGetTelegramChatListRequest = createAction<void>(FETCH_GET_TELEGRAM_CHAT_LIST_REQUEST)

export const FETCH_GET_TELEGRAM_CHAT_LIST_SUCCESS = 'FETCH_GET_TELEGRAM_CHAT_LIST_SUCCESS'
export const fetchGetTelegramChatListSuccess = createAction<TelegramChat[]>(FETCH_GET_TELEGRAM_CHAT_LIST_SUCCESS)

export const FETCH_GET_TELEGRAM_CHAT_LIST_FAILURE = 'FETCH_GET_TELEGRAM_CHAT_LIST_FAILURE'
export const fetchGetTelegramChatListFailure = createAction<void>(FETCH_GET_TELEGRAM_CHAT_LIST_FAILURE)

export const SET_INITIAL_TELEGRAM_CHAT_LIST = 'SET_INITIAL_TELEGRAM_CHAT_LIST'
export const setInitialTelegramChatList = createAction<void>(SET_INITIAL_TELEGRAM_CHAT_LIST)
