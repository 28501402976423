import { FileDataType } from 'types/file'

import { ViewerImageButton } from 'components/molecules/ViewerImage'
import { DropdownMenuItemProps } from 'components/molecules/DropdownMenu'

export const FORM_ITEMS_FOR_PASSPORTS = [
  {
    title: 'Паспорт-фото',
    data: { type: FileDataType.PASSPORT_NAME, entity: null },
    index: 1,
  },
  {
    title: 'Паспорт-прописка',
    data: { type: FileDataType.PASSPORT_REG, entity: null },
    index: 1,
  },
  {
    title: 'Паспорт-фото',
    data: { type: FileDataType.PASSPORT_NAME, entity: null },
    index: 2,
  },
  {
    title: 'Паспорт-прописка',
    data: { type: FileDataType.PASSPORT_REG, entity: null },
    index: 2,
  },
]

export const FORM_ITEMS_FOR_DRIVER_LICENSE = [
  {
    title: 'ВУ-переднее',
    data: { type: FileDataType.DRIVER_LICENSE_FRONT, entity: null },
  },
  {
    title: 'ВУ-заднее',
    data: { type: FileDataType.DRIVER_LICENSE_BACK, entity: null },
  },
]

export const FORM_ITEMS_FOR_STS = [
  {
    title: 'СТС-собственник',
    data: {
      type: FileDataType.STS_OWNER,
      entity: null,
    },
    index: 1,
  },
  {
    title: 'СТС-VIN',
    data: {
      type: FileDataType.STS_VIN,
      entity: null,
    },
    index: 1,
  },
  {
    title: 'СТС-собственник',
    data: {
      type: FileDataType.STS_OWNER,
      entity: null,
    },
    index: 2,
  },
  {
    title: 'СТС-VIN',
    data: {
      type: FileDataType.STS_VIN,
      entity: null,
    },
    index: 2,
  },
]

export const ALL_VIEWER_BUTTONS = [
  ViewerImageButton.CLOSE,
  ViewerImageButton.ROTATE,
  ViewerImageButton.ZOOM_IN,
  ViewerImageButton.ZOOM_OUT,
  ViewerImageButton.BACK,
  ViewerImageButton.NEXT,
]

export const VIEWER_BUTTONS_FOR_FORM = [ViewerImageButton.CLOSE, ViewerImageButton.ROTATE, ViewerImageButton.ZOOM_IN, ViewerImageButton.ZOOM_OUT]

export const IMAGE_TYPES_FOR_LOGISTIC: DropdownMenuItemProps[] = [
  {
    id: 1,
    node: 'Паспорт-фото',
    data: {
      type: FileDataType.PASSPORT_NAME,
    },
  },
  {
    id: 2,
    node: 'Паспорт-прописка',
    data: {
      type: FileDataType.PASSPORT_REG,
    },
  },
]

export const IMAGE_TYPES_FOR_DRIVER: DropdownMenuItemProps[] = [
  {
    id: 1,
    node: 'Паспорт-фото',
    data: {
      type: FileDataType.PASSPORT_NAME,
    },
  },
  {
    id: 2,
    node: 'Паспорт-прописка',
    data: {
      type: FileDataType.PASSPORT_REG,
    },
  },
  {
    id: 3,
    node: 'ВУ-переднее',
    data: {
      type: FileDataType.DRIVER_LICENSE_FRONT,
    },
  },
  {
    id: 4,
    node: 'ВУ-заднее',
    data: {
      type: FileDataType.DRIVER_LICENSE_BACK,
    },
  },
]

export const IMAGE_TYPES_FOR_TS: DropdownMenuItemProps[] = [
  {
    id: 5,
    node: 'СТС-собственник',
    data: {
      type: FileDataType.STS_OWNER,
      count: 2,
    },
  },
  {
    id: 6,
    node: 'СТС-VIN',
    data: {
      type: FileDataType.STS_VIN,
      count: 2,
    },
  },
]
