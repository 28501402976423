import { all } from 'redux-saga/effects'

import billingSaga from 'store/billing/billing.saga'
import ordersSaga from 'store/orders/orders.saga'
import usersSaga from 'store/users/users.saga'
import selectListsSaga from 'store/selectLists/selectLists.saga'
import orderSaga from 'store/order/order.saga'
import alertsSaga from 'store/alerts/alerts.saga'
import autoCheckSaga from 'store/autoChecks/autoChecks.saga'
import telegramChatListSaga from 'store/telegramChatList/telegramChatList.saga'
import axiosErrorSaga from 'store/http-error/http-error.saga'
import versionSaga from 'store/version/version.saga'
import dadataSaga from 'store/dadata/dadata.saga'
import dataGatewaySaga from 'store/data-gateway/data-gateway.saga'
import utilsSaga from 'store/utils/utils.saga'
import fileSaga from 'store/file/file.saga'
import newsSaga from 'store/news/news.saga'
import adminSaga from 'store/admin/admin.saga'
import expertsOnlineSaga from 'store/experts-online/experts-online.saga'
import dictionarySaga from 'store/dictionary/dictionary.saga'
import templateSaga from 'store/template/template.saga'

export default function* () {
  yield all([
    ordersSaga(),
    usersSaga(),
    selectListsSaga(),
    orderSaga(),
    alertsSaga(),
    autoCheckSaga(),
    telegramChatListSaga(),
    axiosErrorSaga(),
    versionSaga(),
    dadataSaga(),
    dataGatewaySaga(),
    utilsSaga(),
    fileSaga(),
    newsSaga(),
    adminSaga(),
    expertsOnlineSaga(),
    billingSaga(),
    dictionarySaga(),
    templateSaga(),
  ])
}
