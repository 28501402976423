import React from 'react'

interface IndentProps {
  children: string | React.ReactNode
}

export const Indent: React.FC<IndentProps> = ({ children }) => {
  return <div className="ml-2">{children}</div>
}

export default Indent
