import { Feature, User, UserRole } from 'types/user'
import { OrderFields } from 'types/order'
import { phoneRegex } from 'utils/phone'

const OrderFormManagerRules = {
  [OrderFields.EXECUTOR]: 'required',
  [OrderFields.ORDER_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2})?$/',
  [OrderFields.CLIENT_NAME]: 'required',
  [OrderFields.ORDER_TEXT]: 'required',
  [OrderFields.ORDER_TYPE]: '',
  [OrderFields.PRIORITY]: '',
  [OrderFields.RESOLUTION]: '',
  [OrderFields.RESOLUTION_COMMENT]: '',
  [OrderFields.ORDER_COMMENT]: '',
  [OrderFields.LOGISTIC_NAME]: 'required',
  [OrderFields.LOGISTIC_INN]: 'required|regex:/^[0-9]{10}([0-9]{2})?$/',
  [OrderFields.LOGISTIC_OGRN]: 'required|regex:/^[0-9]{13}([0-9]{2})?$/',
  [OrderFields.LOGISTIC_ATI]: 'required',
  [OrderFields.LOGISTIC_FIRST_FACE]: 'required',
  [OrderFields.LOGISTIC_FIRST_FACE_BITH]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.LOGISTIC_FIRST_FACE_INN]: 'required|regex:/^[0-9]{10}([0-9]{2})?$/',
  [OrderFields.LOGISTIC_FIO]: 'required',
  [OrderFields.LOGISTIC_TEL]: ['required', phoneRegex],
  [OrderFields.LOGISTIC_TEL_2]: [phoneRegex],
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: ['required', phoneRegex],
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: [phoneRegex],
  [OrderFields.CARRIER_EMAIL]: 'regex:/[a-z0-9]!*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/',
  [OrderFields.DRIVER_FIO]: 'required',
  [OrderFields.DRIVER_BITH]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.DRIVER_SER_NUM]: 'required',
  [OrderFields.DRIVER_DOC]: 'required',
  [OrderFields.DRIVER_DOC_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.DRIVER_DATA_START_DT]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.DRIVER_TEL_1]: ['required', phoneRegex],
  [OrderFields.DRIVER_TEL_2]: [phoneRegex],
  [OrderFields.DRIVER_DATA_START_DT_STATUS]: '',
  [OrderFields.TS_OWNER]: 'required',
  [OrderFields.TS_OWNER_TEL]: ['required', phoneRegex],
  [OrderFields.TS_OWNER_LICENSE_PLATE]: 'required',
  [OrderFields.TRAILER_OWNER]: 'required',
  [OrderFields.TRAILER_OWNER_TEL]: ['required', phoneRegex],
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: 'required',
  [OrderFields.ROUTE_DATE_START]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/',
  [OrderFields.ROUTE_TRIP]: '',
  [OrderFields.CARGO]: '',
  [OrderFields.SHIPPER]: '',
  [OrderFields.FILES]: '',
  [OrderFields.TRANSPORT_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
  [OrderFields.TRANSPORT_STS_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.TRAILER_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
  [OrderFields.TRAILER_STS_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
}

const OrderFormCustomerRules = {
  [OrderFields.EXECUTOR]: 'required',
  [OrderFields.ORDER_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2})?$/',
  [OrderFields.CLIENT_NAME]: 'required',
  [OrderFields.ORDER_TEXT]: '',
  [OrderFields.ORDER_TYPE]: 'required',
  [OrderFields.PRIORITY]: '',
  [OrderFields.RESOLUTION]: '',
  [OrderFields.RESOLUTION_COMMENT]: '',
  [OrderFields.ORDER_COMMENT]: '',
  [OrderFields.LOGISTIC_NAME]: 'required',
  [OrderFields.LOGISTIC_INN]: 'required|regex:/^[0-9]{10}([0-9]{2})?$/',
  [OrderFields.LOGISTIC_FIO]: 'required',
  [OrderFields.LOGISTIC_TEL]: ['required', phoneRegex],
  [OrderFields.LOGISTIC_TEL_2]: [phoneRegex],
  [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: ['required', phoneRegex],
  [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: [phoneRegex],
  [OrderFields.CARRIER_EMAIL]: 'required',
  [OrderFields.DRIVER_FIO]: 'required',
  [OrderFields.DRIVER_BITH]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
  [OrderFields.DRIVER_TEL_1]: ['required', phoneRegex],
  [OrderFields.DRIVER_TEL_2]: [phoneRegex],
  [OrderFields.TS_OWNER]: 'required',
  [OrderFields.TRANSPORT_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
  [OrderFields.TS_OWNER_TEL]: ['required', phoneRegex],
  [OrderFields.TS_OWNER_LICENSE_PLATE]: 'required',
  [OrderFields.TRAILER_OWNER]: 'required',
  [OrderFields.TRAILER_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
  [OrderFields.TRAILER_OWNER_TEL]: ['required', phoneRegex],
  [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: 'required',
  [OrderFields.ROUTE_DATE_START]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/',
  [OrderFields.ROUTE_TRIP]: '',
  [OrderFields.CARGO]: '',
  [OrderFields.SHIPPER]: '',
  [OrderFields.FILES]: '',
}

const getOrderFormRules = (user: User) => {
  switch (user!.role) {
    case UserRole.CUSTOMER:
      // eslint-disable-next-line no-case-declarations
      const result = { ...OrderFormCustomerRules }

      if (user?.features?.[Feature.ALL_FIELDS_ARE_REQUIRED]) {
        result[OrderFields.ROUTE_DATE_START] = 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/'
        result[OrderFields.SHIPPER] = 'required'
        result[OrderFields.ROUTE_TRIP] = 'required'
        result[OrderFields.CARGO] = 'required'
      }

      return result

    default:
      return OrderFormManagerRules
  }
}

export default getOrderFormRules
