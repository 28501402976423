import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import CopyToClipboard from 'react-copy-to-clipboard'

import { OrderBodyType, OrderFields } from 'types/order'
import { LoadingStatus } from 'types/loading'
import { AlertVariant } from 'types/alert'

import { selectOrderFetchGetStatus, selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { selectNotifications } from 'store/notifications/notifications.selectors'

import Header from 'components/molecules/Header'
import { Icon, IconType } from 'components/atoms/Icon'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import getStringFromSelectList from 'utils/getStringFromSelectList'
import getNotificationsForOrder from 'utils/getNotificationsForOrder'

import classes from './style.module.css'
import { OrderHeaderProps } from './index'
import { BackLink } from '../../atoms/BackLink'

export const Customer: React.FC<OrderHeaderProps> = ({ orderBodyType, setOrderBodyType }) => {
  const dispatchStore = useDispatch()
  const form = useSelector(selectOrderRead)
  const loadingFetchStatus = useSelector(selectOrderFetchGetStatus)
  const selectLists = useSelector(selectSelectLists)
  const { orderNumber } = useParams()
  const notifications = useSelector(selectNotifications)

  const leftButton = useMemo(() => <BackLink />, [])

  const handleOrderLinkCopy = useCallback(() => {
    dispatchStore(
      addAlert({
        header: 'Успешное копирование',
        text: `Ссылка на заявку ${orderNumber} успешно скопирована`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
      }),
    )
  }, [dispatchStore, orderNumber])

  const headerName = useMemo(
    () => (
      <CopyToClipboard text={`${process.env.REACT_APP_WEB}/order/${orderNumber}`} onCopy={handleOrderLinkCopy}>
        <span className={classes.headerName}>{orderNumber}</span>
      </CopyToClipboard>
    ),
    [orderNumber, handleOrderLinkCopy],
  )

  const rightButton = useMemo(
    () => (
      <CopyToClipboard text={`${process.env.REACT_APP_WEB}/order/${orderNumber}`} onCopy={handleOrderLinkCopy}>
        <div className={classes.headerButton}>
          <Icon type={IconType.Share} width="36px" height="36px" />
        </div>
      </CopyToClipboard>
    ),
    [orderNumber, handleOrderLinkCopy],
  )

  const headerMenuHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.currentTarget.id && (event.currentTarget.id === OrderBodyType.ORDER || event.currentTarget.id === OrderBodyType.EXTERNAL_CHAT))
      setOrderBodyType(event.currentTarget.id)
  }

  const notificationsForOrder = useMemo(() => getNotificationsForOrder(orderNumber, notifications), [orderNumber, notifications])

  return (
    <div className="sticky-top">
      <Header leftButton={leftButton} headerName={headerName} rightButton={rightButton}>
        {loadingFetchStatus === LoadingStatus.SUCCESS ? (
          <>
            <div className="bg-dark row text-light" style={{ padding: '0 5px 0 5px' }}>
              <div className={classNames('col-12', classes.header_resolution)}>
                {getStringFromSelectList(form[OrderFields.RESOLUTION], selectLists[OrderFields.RESOLUTION])}
              </div>
              <div className={classNames('col-12', classes.header_resolutionComment)}>
                {form[OrderFields.RESOLUTION_COMMENT] ? <TextWithLineBreaks text={form[OrderFields.RESOLUTION_COMMENT]} /> : null}
              </div>
            </div>
            <div className={classNames('bg-white row', classes.header_menu)}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className={classNames('col-6 text-center', classes.header_menu_item, orderBodyType === OrderBodyType.ORDER ? classes.active : '')}
                onClick={headerMenuHandler}
                id={OrderBodyType.ORDER}
              >
                <Icon type={IconType.CheckCircle} className={classes.orderIcon} />
                <span className={classes.header_menu_item_text}>&nbsp; Заявка</span>
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className={classNames(
                  'col-6 text-center',
                  classes.header_menu_item,
                  orderBodyType === OrderBodyType.EXTERNAL_CHAT ? classes.active : '',
                )}
                onClick={headerMenuHandler}
                id={OrderBodyType.EXTERNAL_CHAT}
              >
                <div>
                  <Icon type={IconType.Comment} className={classes.chatIcon} />
                  {notificationsForOrder.length === 2 || (notificationsForOrder.length && !notificationsForOrder[0].isInternal) ? (
                    <span className={classNames(classes.dot, 'bg-danger')} />
                  ) : null}
                </div>
                <span className={classes.header_menu_item_text}>&nbsp; Чат</span>
              </div>
            </div>
          </>
        ) : null}
      </Header>
    </div>
  )
}

export default Customer
