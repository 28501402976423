import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import { OrderFields } from 'types/order'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'
import { changeOrderHandler } from 'store/order/order.actions'

import { getDateForServer } from 'utils/date'

import InputControl from 'components/molecules/InputControl'
import InputDate from 'components/molecules/InputDate'
import Title from 'components/atoms/Title'

import { RouteProps } from './index'

const Manager: React.FC<RouteProps> = ({ onChange }) => {
  const dispatchStore = useDispatch()
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const routeDateStartSetToday = useCallback(() => {
    dispatchStore(
      changeOrderHandler({
        [OrderFields.ROUTE_DATE_START]: getDateForServer(new Date()),
      }),
    )
  }, [dispatchStore])

  const routeDateStartSetTomorrow = useCallback(() => {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    dispatchStore(
      changeOrderHandler({
        [OrderFields.ROUTE_DATE_START]: getDateForServer(tomorrow),
      }),
    )
  }, [dispatchStore])

  return (
    <>
      <Title text="Рейс и маршрут" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputDate
              id={OrderFields.ROUTE_DATE_START}
              value={form[OrderFields.ROUTE_DATE_START]}
              isValid={errors[OrderFields.ROUTE_DATE_START].isValid}
              invalidMessage={errors[OrderFields.ROUTE_DATE_START].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Дата начала:"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
            <Button className="p-0" style={{ flex: '1', marginRight: '4px' }} onClick={routeDateStartSetToday}>
              Сегодня
            </Button>
            <Button className="p-0" style={{ flex: '1', marginLeft: '4px' }} onClick={routeDateStartSetTomorrow}>
              Завтра
            </Button>
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.SHIPPER}
              value={form[OrderFields.SHIPPER]}
              isValid={errors[OrderFields.SHIPPER].isValid}
              invalidMessage={errors[OrderFields.SHIPPER].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Грузоотправитель / Заказчик:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.ROUTE_TRIP}
              value={form[OrderFields.ROUTE_TRIP]}
              isValid={errors[OrderFields.ROUTE_TRIP].isValid}
              invalidMessage={errors[OrderFields.ROUTE_TRIP].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Маршрут:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.CARGO}
              value={form[OrderFields.CARGO]}
              isValid={errors[OrderFields.CARGO].isValid}
              invalidMessage={errors[OrderFields.CARGO].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Груз:"
            />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
