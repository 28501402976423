import { User, UserRole } from 'types/user'
import { SelectLists } from 'types/selectLists'
import { LogisticType, OrderFields } from 'types/order'

import { getDateTimeForServer, getMoscowDate, setMoscowTimezone } from 'utils/date'
import getIDFromSelectList from 'utils/getIDFromSelectList'

const getInitialValues = (currentUser: User | null, selectLists: SelectLists, currentTime: string | null) => {
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return {
        [OrderFields.EXECUTOR]: '', // Исполнитель
        [OrderFields.ORDER_DATE]: '',
        [OrderFields.ORDER_TEXT]: '', // Текст заявки от клиента
        [OrderFields.ORDER_TYPE]: '', // Тип заявки
        [OrderFields.PRIORITY]: '1', // Приоритет заявки
        [OrderFields.LOGISTIC_INN]: '', // Перевозчик. ИНН
        [OrderFields.LOGISTIC_TYPE]: LogisticType.LEGAL,
        [OrderFields.LOGISTIC_FIO]: '', // Перевозчик. ФИО контакта
        [OrderFields.LOGISTIC_TEL]: '', // Перевозчик. Телефон контакта
        [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: '', // Перевозчик. Телефон первого лица
        [OrderFields.CARRIER_EMAIL]: '', // Перевозчик. Email
        [OrderFields.DRIVER_FIO]: '', // Водитель. - Менеджер ФИО
        [OrderFields.DRIVER_BITH]: '', // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
        [OrderFields.DRIVER_TEL_1]: '', // Водитель. Телефон 1
        [OrderFields.TS_OWNER]: '', // ТС. Собственник.
        [OrderFields.TS_OWNER_TEL]: '', // ТС. Собственник. Телефон
        [OrderFields.TS_OWNER_LICENSE_PLATE]: '', // ТС. Собственник. Госномер
        [OrderFields.TRAILER_OWNER]: '', // Прицеп. Собственник
        [OrderFields.TRAILER_OWNER_TEL]: '', // Прицеп. Собственник. Телефон
        [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: '', // Прицеп. Собственник. Госномер
        [OrderFields.ROUTE_DATE_START]: '', // Рейс. Дата начала. Вид: "yyyy-MM-dd"
        [OrderFields.ROUTE_TRIP]: '', // Рейс. Маршрут
        [OrderFields.CARGO]: '', // Груз
        [OrderFields.SHIPPER]: '', // Поле Грузоотправтиль/Заказчик
        [OrderFields.FILES]: [], // id файла к этому заказу, или массив id-ов
        [OrderFields.STARTED_CREATING_AT]: currentTime ? getDateTimeForServer(setMoscowTimezone(new Date(currentTime))) : '',
        [OrderFields.TRANSPORT_RENT]: false,
        [OrderFields.TRAILER_RENT]: false,
        [OrderFields.DRIVER_CREDIT_RATING]: false,
      }

    default:
      return {
        [OrderFields.ORDER_DATE]: getDateTimeForServer(getMoscowDate()),
        [OrderFields.EXECUTOR]: getIDFromSelectList(currentUser!.name, selectLists?.executor),
        [OrderFields.CLIENT_NAME]: '', // Клиент - заказчик проверки
        [OrderFields.ORDER_TEXT]: '', // Текст заявки от клиента
        [OrderFields.ORDER_TYPE]: '1', // Тип заявки
        [OrderFields.PRIORITY]: '1', // Приоритет заявки
        [OrderFields.ORDER_COMMENT]: '', // Внутренний комментарий
        [OrderFields.LOGISTIC_NAME]: '', // Перевозчик. Название юрлица
        [OrderFields.LOGISTIC_INN]: '', // Перевозчик. ИНН
        [OrderFields.LOGISTIC_TYPE]: LogisticType.LEGAL,
        [OrderFields.LOGISTIC_OGRN]: '', // Перевозчик. ОГРН(ИП)
        [OrderFields.LOGISTIC_ATI]: '', // Перевозчик. Ати
        [OrderFields.LOGISTIC_ATI_STATUS]: true, // Перевозчик. Ати. Статус
        [OrderFields.LOGISTIC_FIRST_FACE]: '', // Перевозчик.1е лицо
        [OrderFields.LOGISTIC_FIRST_FACE_BITH]: '', // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
        [OrderFields.LOGISTIC_FIRST_FACE_INN]: '', // Перевозчик.ИНН 1го лица
        [OrderFields.LOGISTIC_FIO]: '', // Перевозчик. ФИО контакта
        [OrderFields.LOGISTIC_TEL]: '', // Перевозчик. Телефон контакта
        [OrderFields.DRIVER_FIO]: '', // Водитель. - Менеджер ФИО
        [OrderFields.DRIVER_BITH]: '', // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
        [OrderFields.DRIVER_SER_NUM]: '', // Водитель. Серия и номер паспорта
        [OrderFields.DRIVER_DOC]: '', // Водитель. Номер ВУ
        [OrderFields.DRIVER_DOC_DATE]: '', // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
        [OrderFields.DRIVER_DATA_START_DT]: '', // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
        [OrderFields.DRIVER_TEL_1]: '', // Водитель. Телефон 1
        [OrderFields.DRIVER_TEL_2]: '', // Водитель. Телефон 2
        [OrderFields.DRIVER_DATA_START_DT_STATUS]: false, // Параметр состояния галочки Дата начала ТД
        [OrderFields.TS_OWNER]: '', // ТС. Собственник.
        [OrderFields.TS_OWNER_TEL]: '', // ТС. Собственник. Телефон
        [OrderFields.TS_OWNER_LICENSE_PLATE]: '', // ТС. Собственник. Госномер
        [OrderFields.TRAILER_OWNER]: '', // Прицеп. Собственник
        [OrderFields.TRAILER_OWNER_TEL]: '', // Прицеп. Собственник. Телефон
        [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: '', // Прицеп. Собственник. Госномер
        [OrderFields.ROUTE_DATE_START]: '', // Рейс. Дата начала. Вид: "yyyy-MM-dd"
        [OrderFields.ROUTE_TRIP]: '', // Рейс. Маршрут
        [OrderFields.CARGO]: '', // Груз
        [OrderFields.SHIPPER]: '', // Поле Грузоотправтиль/Заказчик
        [OrderFields.FILES]: [], // id файла к этому заказу, или массив id-ов
        [OrderFields.STARTED_CREATING_AT]: '',
        [OrderFields.TRANSPORT_YEAR]: null,
        [OrderFields.TRAILER_YEAR]: null,
        [OrderFields.TRANSPORT_STS_DATE]: '',
        [OrderFields.TRAILER_STS_DATE]: '',
        [OrderFields.TRANSPORT_RENT]: false,
        [OrderFields.TRAILER_RENT]: false,
        [OrderFields.DRIVER_CREDIT_RATING]: false,
        [OrderFields.HAS_BELARUSIANS]: false,
      }
  }
}

export default getInitialValues
