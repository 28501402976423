import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import { selectOrderRead } from 'store/order/order.selectors'
import { OrderData, OrderFields, ResolutionId } from 'types/order'
import { EFFECTIVE_ORDER_STATUSES } from 'constants/order'

import { HistoryItem } from './history-item'

const OrderHistory = () => {
  const order: OrderData = useSelector(selectOrderRead)
  const location = useLocation()
  const queryObject = useMemo(() => queryString.parse(location.search), [location])
  const field: OrderFields = queryObject.field as OrderFields
  const data = order[OrderFields.CHECKED_FIELDS].find((checkedField) => checkedField.field === field)

  const effectiveChecks = useMemo(
    () => data?.data?.filter((check) => EFFECTIVE_ORDER_STATUSES.includes(String(check.resolution_id) as ResolutionId)) || [],
    [data],
  )
  const sortedChecks = useMemo(
    () => effectiveChecks.sort((first, second) => (new Date(first.date_create_cus) > new Date(second.date_create_cus) ? -1 : 1)),
    [effectiveChecks],
  )

  return (
    <div>
      <div className="container-fluid">
        <span className="navbar-brand mb-0 h1">Уже проверяли {((order as any) || {})[field]}</span>
      </div>
      <div className="list-group">
        {sortedChecks.map((check) => (
          <HistoryItem key={check.order} check={check} />
        ))}
      </div>
    </div>
  )
}

export default OrderHistory
