import { LogisticType } from 'types/order'

export default class Company {
  constructor(private inn: string, private type: LogisticType) {}

  public isValidInn() {
    const validInnLengths = [10, 12]
    return validInnLengths.includes(this.inn.length) && [LogisticType.LEGAL, LogisticType.INDIVIDUAL].includes(this.type)
  }

  public isForeign() {
    return this.type === LogisticType.FOREIGN
  }

  public isSelfEmployed() {
    return this.type === LogisticType.SELF_EMPLOYED
  }
}
