import React from 'react'
import { useSelector } from 'react-redux'

import { selectOrderWrite } from 'store/order/order.selectors'

import { LogisticType, OrderFields } from 'types/order'

import ListItem from './ListItem'
import Labeler from '../labeler'

const DirectorName: React.FC<any> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)

  const labeler = new Labeler(form[OrderFields.LOGISTIC_TYPE] as LogisticType, form[OrderFields.LOGISTIC_FIRST_FACE])

  return (
    <ListItem
      onChange={onChange}
      id={OrderFields.LOGISTIC_FIRST_FACE}
      label={labeler.getDirectorName()}
      readonly={form[OrderFields.LOGISTIC_TYPE] === LogisticType.INDIVIDUAL || form[OrderFields.LOGISTIC_TYPE] === LogisticType.LEGAL}
    />
  )
}

export default DirectorName
