import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectAutoChecks } from 'store/autoChecks/autoChecks.selectors'
import { selectOrderRead } from 'store/order/order.selectors'

import { getStringWithSpacesFromFields } from 'utils/string'
import { getDateForWeb, getDateFromServerDate } from 'utils/date'

import LogisticRegionAlert from 'components/molecules/OrderSpace/spaces/Header/components/LogisticRegionAlert'
import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'
import { Carrier } from 'components/molecules/OrderSpace/carrier'
import { EMAIL_IS_UNVERIFIED_TEXT } from 'components/molecules/OrderSpace/spaces/Header/components/ForExpertForm/VerifyEmailForm'

export const CarrierWarning: React.FC = () => {
  const order = useSelector(selectOrderRead)

  const autoChecks = useSelector(selectAutoChecks)

  const carrier = useMemo(() => new Carrier(order, autoChecks), [order, autoChecks])

  const previousOrder = carrier.getPreviousOrder()
  const previousOrderIsNegative = carrier.isPreviousOrderNegative()
  const firstFaceHasChanged = carrier.hasFirstFaceChanged()
  const phonesHaveChanged = carrier.havePhonesChanged()

  const companyHasNotOkved = carrier.hasNotTransportOkved()
  const carrierIsNotActive = carrier.isNotActive()
  const firstFaceAndContactAreDifferent = carrier.areFirstFaceAndContactDifferent()
  const isRussiaCompany = carrier.isRussia()

  const emailIsNotVerified = order[OrderFields.ORDER_COMMENT]?.includes(EMAIL_IS_UNVERIFIED_TEXT)

  return (
    <>
      {previousOrder ? (
        <>
          {previousOrderIsNegative ? (
            <div>
              <span className={classes.redBackgroundColor}>В прошлой заявке негативная резолюция!</span>
            </div>
          ) : null}
          {firstFaceHasChanged ? (
            <div>
              <span className={classes.redBackgroundColor}>
                В прошлой заявке другое первое лицо! (
                {getStringWithSpacesFromFields(
                  previousOrder.logistic_first_face,
                  getDateForWeb(getDateFromServerDate(previousOrder.logistic_first_face_bith || '')),
                ) || 'Первое лицо не указано'}
                )
              </span>
            </div>
          ) : null}
          {phonesHaveChanged ? (
            <div>
              <span className={classes.redBackgroundColor}>
                В прошлой заявке другие телефоны контакта! (
                {getStringWithSpacesFromFields(previousOrder.logistic_tel, previousOrder.logistic_tel2) || 'Телефоны не указаны'})
              </span>
            </div>
          ) : null}
        </>
      ) : null}
      {firstFaceAndContactAreDifferent ? (
        <div>
          <span className={classes.redBackgroundColor}>Первое лицо и контакт различаются ({order[OrderFields.LOGISTIC_FIO]})</span>
        </div>
      ) : null}
      {isRussiaCompany ? (
        <>
          {companyHasNotOkved ? (
            <div>
              <span className={classes.redBackgroundColor}>Компания не содержит транспортных ОКВЭДов</span>
            </div>
          ) : null}
          {carrierIsNotActive ? (
            <div>
              <span className={classes.redBackgroundColor}>Компания не действующая!</span>
            </div>
          ) : null}
          <LogisticRegionAlert />
        </>
      ) : null}
      {emailIsNotVerified ? (
        <div>
          <span className={classes.redBackgroundColor}>{EMAIL_IS_UNVERIFIED_TEXT}</span>
        </div>
      ) : null}
    </>
  )
}
