import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Spinner } from 'react-bootstrap'

import { PhoneDictionaryLinkSource, PhoneDictionaryLinkSourceName } from 'types/dictionary'
import { LoadingStatus } from 'types/loading'

import { useValidation } from 'utils/useValidation'
import { phoneRegex } from 'utils/phone'

import InputPhone from 'components/molecules/InputPhone'
import { Icon, IconType } from 'components/atoms/Icon'
import DropdownMenu from 'components/molecules/DropdownMenu'

import classes from './style.module.css'

const validateRules = {
  phone: ['required', phoneRegex],
}

interface SecondPhoneFormProps {
  handleSubmit: (phone: string, linkSource: PhoneDictionaryLinkSource) => Promise<void>
  loadingStatus: LoadingStatus
  value: string
  onChange(value: string): void
}

export const DictionaryPhoneForm: React.FC<SecondPhoneFormProps> = ({ loadingStatus, handleSubmit, value, onChange }) => {
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)

  const { validate } = useValidation(validateRules)

  const handleChange = useCallback(
    (val) => {
      if (loadingStatus !== LoadingStatus.PENDING) {
        onChange(val)

        const validator = validate({ phone: val })
        setIsValid(val ? validator.passes() : undefined)
      }
    },
    [loadingStatus, onChange, validate],
  )

  const onClear = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onChange('')
      setIsValid(undefined)
    },
    [onChange],
  )

  const onSubmit = useCallback(
    (item) => {
      if (isValid && loadingStatus !== LoadingStatus.PENDING) {
        handleSubmit(value, item.id)
        onChange('')
        setIsValid(undefined)
      }
    },
    [handleSubmit, isValid, loadingStatus, onChange, value],
  )

  return (
    <form onSubmit={onSubmit} className="col-lg-4 col-md-6 col-sm-12 p-0 position-relative">
      <InputPhone
        id="phone"
        onChangeHandler={handleChange}
        value={value}
        placeholder="Телефон для справочника"
        className={classes.input}
        isValid={isValid}
        invalidMessage="Введите корректный телефон"
        disabled={loadingStatus === LoadingStatus.PENDING}
      />
      <div className={classes.buttons}>
        {loadingStatus === LoadingStatus.PENDING ? (
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
        ) : (
          <>
            {value ? (
              <button type="button" onClick={onClear} className={classNames(classes.button, classes.close)}>
                <Icon type={IconType.Close} width="1rem" height="1rem" />
              </button>
            ) : null}
            <DropdownMenu
              toggleText={<Icon type={IconType.Check} width="1rem" height="1rem" />}
              toggleVariant="link"
              classNameForMenuItem={classes.dropdownItem}
              classNameForMenu="p-0"
              classNameForToggle={classNames(classes.button, !isValid ? classes.disabled : '')}
              onClickItem={onSubmit}
              disabled={!isValid}
              list={Object.values(PhoneDictionaryLinkSource).map((source) => ({
                id: source,
                node: PhoneDictionaryLinkSourceName[source],
              }))}
            />
          </>
        )}
      </div>
    </form>
  )
}

export default DictionaryPhoneForm
