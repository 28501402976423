import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { fetchOrder } from 'requests/order'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { GetOrderData, OrderData } from 'types/order'

import { fetchGetOrderFailure, fetchGetOrderSuccess } from '../order.actions'

export function* fetchGetOrderSaga(action: Action<GetOrderData>) {
  try {
    const token = yield select(selectToken)

    const response: OrderData = yield call(fetchOrder, action.payload, token)
    yield put(fetchGetOrderSuccess(response))
    // yield put(
    //   addAlert({
    //     text: `Заявка ${action.payload[OrderFields.ORDER_NUMBER]} успешно получена.`,
    //     lifetime: 3000,
    //     variant: AlertVariant.SUCCESS,
    //     header: 'Создание заявки',
    //   }),
    // )
  } catch (error) {
    yield put(fetchGetOrderFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение заявки',
        defaultText: 'Не удалось получить заявку.',
      }),
    )
    console.error(`[fetchGetOrderSaga]: ${error}`)
    captureException(error)
  }
}
