import { Notification } from 'types/notifications'

const isOrderInNotifications = (orderNumber: string, notifications: Notification[]) => {
  const notificationsForOrder = notifications.filter((val) => {
    return val.orderId === orderNumber
  })

  return !!notificationsForOrder.length
}

export default isOrderInNotifications
