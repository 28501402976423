import { put } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { fetchLogoutFailure, fetchLogoutSuccess } from '../users.actions'

function removeCurrentUser() {
  localStorage.removeItem('token')
  localStorage.removeItem('login')
  localStorage.removeItem('role')
  localStorage.removeItem('name')
}

export function* fetchLogoutSaga() {
  try {
    removeCurrentUser()

    yield put(fetchLogoutSuccess())
  } catch (error) {
    yield put(fetchLogoutFailure(error))
    console.error(`[fetchLoginSaga]: ${error}`)
    captureException(error)
  }
}
