import React from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'
import Index from 'components/molecules/OrderSpace/spaces/Header/components/Expert'
import Customer from './Customer'
import Manager from './Manager'

export const Header: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer />

    case UserRole.EXPERT:
      return <Index />

    default:
      return <Manager />
  }
}

export default Header
