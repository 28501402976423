import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ButtonVariant } from 'react-bootstrap/types'
import classNames from 'classnames'

export interface DropdownMenuItemProps {
  id: number | string
  node: React.ReactNode | string
  disabled?: boolean
  onClick?(item: DropdownMenuItemProps, event?: React.MouseEvent<any>): void
  data?: any
}

export interface DropdownMenuProps {
  toggleText: string | React.ReactNode
  toggleVariant: ButtonVariant
  list: DropdownMenuItemProps[]
  disabled?: boolean
  classNameForMenu?: string
  classNameForMenuItem?: string
  classNameForToggle?: string
  onClickItem?(item: DropdownMenuItemProps, event?: React.MouseEvent<any>): void
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  toggleText,
  toggleVariant,
  list,
  disabled = false,
  classNameForMenu,
  classNameForMenuItem,
  classNameForToggle,
  onClickItem,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className={classNameForToggle} variant={toggleVariant} disabled={disabled}>
        {toggleText}
      </Dropdown.Toggle>
      <Dropdown.Menu className={classNameForMenu}>
        {list.map((item) => (
          <Dropdown.Item
            className={classNames('p-0', classNameForMenuItem)}
            disabled={item.disabled}
            key={item.id}
            onClick={(event) =>
              // eslint-disable-next-line no-nested-ternary
              item.onClick ? item.onClick(item, event) : onClickItem ? onClickItem(item, event) : null
            }
          >
            {item.node}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownMenu
