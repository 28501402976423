import { User, Feature, UserRole } from 'types/user'
import { OrderFormData, LogisticType, OrderFields, OrderFormSpaceId } from 'types/order'
import { phoneRegex } from 'utils/phone'

const getCustomerSpaceRules = (space: OrderFormSpaceId) => {
  switch (space) {
    case OrderFormSpaceId.HEADER:
      return {}

    case OrderFormSpaceId.ORDER:
      return {
        [OrderFields.ORDER_TYPE]: 'required',
      }

    case OrderFormSpaceId.CONTACT:
      return {
        [OrderFields.LOGISTIC_FIO]: 'required',
        [OrderFields.LOGISTIC_TEL]: ['required', phoneRegex],
        [OrderFields.LOGISTIC_TEL_2]: [phoneRegex],
        [OrderFields.CARRIER_EMAIL]: 'required',
      }

    case OrderFormSpaceId.LOGISTIC:
      return {
        [OrderFields.LOGISTIC_INN]: 'required|regex:/^[0-9]{10}([0-9]{2})?$/',
        [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: [phoneRegex],
        [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: [phoneRegex],
      }

    case OrderFormSpaceId.DRIVER:
      return {
        [OrderFields.DRIVER_FIO]: 'required',
        [OrderFields.DRIVER_BITH]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.DRIVER_TEL_1]: ['required', phoneRegex],
        [OrderFields.DRIVER_TEL_2]: [phoneRegex],
      }

    case OrderFormSpaceId.TS:
      return {
        [OrderFields.TS_OWNER]: 'required',
        [OrderFields.TRANSPORT_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
        [OrderFields.TS_OWNER_TEL]: ['required', phoneRegex],
        [OrderFields.TRAILER_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
        [OrderFields.TRAILER_OWNER_TEL]: [phoneRegex],
      }

    case OrderFormSpaceId.ROUTE:
      return {}

    case OrderFormSpaceId.FILES:
      return {
        [OrderFields.FILES]: 'required',
      }

    default:
      throw new Error(`Необработанный блок в правилах: ${space}`)
  }
}

const getManagerSpaceRules = (space: OrderFormSpaceId) => {
  switch (space) {
    case OrderFormSpaceId.HEADER:
      return {}

    case OrderFormSpaceId.ORDER:
      return {
        [OrderFields.ORDER_DATE]: 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2})?$/',
        [OrderFields.CLIENT_NAME]: 'required',
      }

    case OrderFormSpaceId.CONTACT:
      return {
        [OrderFields.LOGISTIC_TEL]: [phoneRegex],
        [OrderFields.LOGISTIC_TEL_2]: [phoneRegex],
      }

    case OrderFormSpaceId.LOGISTIC:
      return {
        [OrderFields.LOGISTIC_INN]: 'regex:/^[0-9]{10}([0-9]{2})?$/',
        [OrderFields.LOGISTIC_OGRN]: 'regex:/^[0-9]{13}([0-9]{2})?$/',
        [OrderFields.LOGISTIC_FIRST_FACE_BITH]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.LOGISTIC_FIRST_FACE_INN]: 'regex:/^[0-9]{10}([0-9]{2})?$/',
        [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: [phoneRegex],
        [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: [phoneRegex],
      }

    case OrderFormSpaceId.DRIVER:
      return {
        [OrderFields.DRIVER_BITH]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.DRIVER_DOC_DATE]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.DRIVER_DATA_START_DT]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.DRIVER_TEL_1]: [phoneRegex],
        [OrderFields.DRIVER_TEL_2]: [phoneRegex],
      }

    case OrderFormSpaceId.TS:
      return {
        [OrderFields.TS_OWNER_TEL]: [phoneRegex],
        [OrderFields.TRAILER_OWNER_TEL]: [phoneRegex],
        [OrderFields.TRANSPORT_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
        [OrderFields.TRANSPORT_STS_DATE]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
        [OrderFields.TRAILER_YEAR]: `min:1900|max:${new Date().getFullYear()}`,
        [OrderFields.TRAILER_STS_DATE]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/|before:tomorrow',
      }

    case OrderFormSpaceId.ROUTE:
      return {
        [OrderFields.ROUTE_DATE_START]: 'regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/',
      }

    case OrderFormSpaceId.FILES:
      return {}

    default:
      throw new Error(`Необработанный блок в правилах: ${space}`)
  }
}

const getCreateOrderRules = (form: OrderFormData, currentUser: User, spaces: OrderFormSpaceId[]) => {
  let result: Partial<{ [key in OrderFields]: any }> = {}

  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      for (let i = 0; i < spaces.length; i += 1) {
        result = { ...result, ...getCustomerSpaceRules(spaces[i]) }
      }

      if (spaces.includes(OrderFormSpaceId.TS) && !form[OrderFields.TRAILER_STATUS]) {
        result[OrderFields.TRAILER_OWNER] = 'required'
        result[OrderFields.TRAILER_OWNER_TEL] = ['required', phoneRegex]
      }

      if (currentUser?.features?.[Feature.ALL_FIELDS_ARE_REQUIRED] && spaces.includes(OrderFormSpaceId.ROUTE)) {
        result[OrderFields.ROUTE_DATE_START] = 'required|regex:/^([0-9]{4})-([0-9]{2})-([0-9]{2})?$/'
        result[OrderFields.SHIPPER] = 'required'
        result[OrderFields.ROUTE_TRIP] = 'required'
        result[OrderFields.CARGO] = 'required'
      }

      break

    default:
      for (let i = 0; i < spaces.length; i += 1) result = { ...result, ...getManagerSpaceRules(spaces[i]) }
      break
  }

  if (String([OrderFields.LOGISTIC_INN]) in result && form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN) {
    // @ts-ignore
    delete result[OrderFields.LOGISTIC_INN]
  }
  if (String([OrderFields.LOGISTIC_OGRN]) in result && form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN) {
    // @ts-ignore
    delete result[OrderFields.LOGISTIC_OGRN]
  }

  return result
}

export default getCreateOrderRules
