import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditCustomerData } from 'types/customer'

import { editCustomer } from 'requests/customer'

import { editCustomerSuccess, editCustomerFailure } from 'store/admin/admin.actions'

export function* editCustomerSaga(action: Action<EditCustomerData>) {
  try {
    const token = yield select(selectToken)

    yield call(editCustomer, action.payload, token)

    yield put(editCustomerSuccess())
    yield put(
      addAlert({
        text: `Клиент успешно изменен.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Редактирование клиента',
      }),
    )
  } catch (error) {
    yield put(editCustomerFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Редактирование клиента',
        defaultText: 'Не удалось отредактировать клиента.',
      }),
    )
    console.error(`[editCustomerSaga]: ${error}`)
    captureException(error)
  }
}
