import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'
import { UserRole } from 'types/user'
import { FileForDocumentsForm, FileProps, SupportedDocumentTypes } from 'types/file'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'

import InputFile from 'components/molecules/InputFile'
import Gallery from 'components/molecules/Gallery'
import DocumentsForm from 'components/molecules/DocumentsForm'
import Title from 'components/atoms/Title'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'
import TextWithMiniPrefix from 'components/atoms/TextWithMiniPrefix'

const Manager: React.FC = () => {
  const { orderNumber } = useParams()
  const form = useSelector(selectOrderRead)
  const currentUser = useSelector(selectCurrentUser)
  const documentPreviews = form[OrderFields.FILES].reduce((list: FileForDocumentsForm[], item: FileProps) => {
    if (item?.data?.inSlot || (item?.data?.recognitionType && SupportedDocumentTypes.includes(item?.data?.recognitionType))) {
      return [...list, { href_file: item.uuid, data: item.data ?? null, name: item.name }]
    }

    return list
  }, [])
  const HandleDownloadFile = () => {}

  return (
    <>
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <Title text="От клиента" />
            {form[OrderFields.COMPANY_COMMENT] && currentUser!.role !== UserRole.EXPERT ? (
              <>
                <TextWithMiniPrefix text={<TextWithLineBreaks text={form[OrderFields.COMPANY_COMMENT]} />} prefix="Комментарий к клиенту:" />
              </>
            ) : null}

            {form[OrderFields.ORDER_TEXT] && currentUser!.role !== UserRole.EXPERT ? (
              <>
                <TextWithMiniPrefix text={<TextWithLineBreaks text={form[OrderFields.ORDER_TEXT]} />} prefix="Текст заявки от клиента:" />
              </>
            ) : null}

            {/* Комментарий к заявке от клиента для эксперта */}
            {form[OrderFields.ORDER_TEXT] && currentUser!.role === UserRole.EXPERT ? (
              <>
                <span style={{ padding: '4px 8px', backgroundColor: '#e57373' }}>Текст заявки от клиента</span>
                <div
                  style={{
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem',
                    padding: '4px',
                    marginBottom: '4px',
                  }}
                >
                  <TextWithLineBreaks text={form[OrderFields.ORDER_TEXT]} />
                </div>
              </>
            ) : null}

            {/* <a */}
            {/*  id={OrderFields.LINK_TO_FOLDER} */}
            {/*  className={classNames( */}
            {/*    classes.buttonDocs, */}
            {/*    'col-lg-3 col-md-6 col-sm-12 btn btn-outline-primary', */}
            {/*  )} */}
            {/*  target="_blank" */}
            {/*  rel="noopener noreferrer" */}
            {/*  href={form[OrderFields.LINK_TO_FOLDER]} */}
            {/* > */}
            {/*  Документы &nbsp; */}
            {/*  <Icon type={IconType.ExternalLink} /> */}
            {/* </a> */}

            {documentPreviews && documentPreviews.length && currentUser!.role !== UserRole.MANAGER ? (
              <>
                <hr />
                Документы:
                <Gallery images={documentPreviews} />
                <hr />
              </>
            ) : null}
            <InputFile id={OrderFields.FILES} uploadedFiles={form[OrderFields.FILES]} handleResponse={HandleDownloadFile} orderNumber={orderNumber} />
            {currentUser!.role === UserRole.MANAGER ? <DocumentsForm /> : null}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
