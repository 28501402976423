import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { GetDictionaryEntryRequest } from 'types/dictionary'

import { getDictionaryLinkedEntries } from 'requests/dictionary'

import { selectToken } from 'store/users/users.selectors'
import { getDictionaryLinkedEntriesFailure, getDictionaryLinkedEntriesSuccess } from 'store/dictionary/dictionary.actions'

export function* getDictionaryLinkedEntriesSaga(action: Action<GetDictionaryEntryRequest>) {
  try {
    const token = yield select(selectToken)

    const response = yield call(getDictionaryLinkedEntries, action.payload, token)

    yield put(getDictionaryLinkedEntriesSuccess({ sourceEntry: action.payload.entry, response }))
  } catch (error) {
    console.error(`[getDictionaryEntrySaga]: ${error}`)
    yield put(getDictionaryLinkedEntriesFailure(action.payload))
  }
}
