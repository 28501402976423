import { LoadingStatus } from 'types/loading'
import { Props } from 'types/core/props'

export type DictionaryId = string

export enum DictionaryEntryType {
  PERSON = 'person',
  PHONE = 'phone',
}

export interface DictionaryEntry {
  type: DictionaryEntryType
  [key: string]: any
}

export interface DictionaryLink {
  entryIds: string[]
  createdAt: Date
  data: Props
}

export interface GetDictionaryEntryRequest {
  entry: DictionaryEntry
  referenceTypes: DictionaryEntryType[]
}

export interface GetDictionaryEntryResponse {
  entries: DictionaryEntry[]
  links: DictionaryLink[]
}

export type LinkDictionaryEntryRequest = { entries: [DictionaryEntry, DictionaryEntry]; linkData: Props }

export type LinkDictionaryEntryResponse = [GetDictionaryEntryResponse, GetDictionaryEntryResponse]

export type UnlinkDictionaryEntryRequest = [DictionaryEntry, DictionaryEntry]

export interface DictionaryEntryState {
  response: GetDictionaryEntryResponse | null
  status: LoadingStatus
}

export interface PersonDictionaryEntry extends DictionaryEntry {
  name: string
  birthday: string
}

export interface PhoneDictionaryEntry extends DictionaryEntry {
  phone: string
}

export enum PhoneDictionaryLinkSource {
  CALL_VERIFIED_PHONE = 'call-verified-phone',
  SBP = 'sbp',
  DG = 'dg',
}

export const PhoneDictionaryLinkSourceName = {
  [PhoneDictionaryLinkSource.CALL_VERIFIED_PHONE]: 'Звонком на вериф.телефон',
  [PhoneDictionaryLinkSource.SBP]: 'Проверено через СБП',
  [PhoneDictionaryLinkSource.DG]: 'Найдено в DG',
}
