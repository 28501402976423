import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { OrderFields, OrderSpaceName } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'

import getStringFromSelectList from 'utils/getStringFromSelectList'

import getSpaces from 'components/organisms/OrderBody/utils/getSpaces'
import { Icon, IconType } from 'components/atoms/Icon'

import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'

const BlockHeader: React.FC = () => {
  const form = useSelector(selectOrderRead)
  const selectLists = useSelector(selectSelectLists)

  const spaces = getSpaces(String(form[OrderFields.ORDER_TYPE]))

  return (
    <>
      <div className={classes.orderType}>
        <div className="d-flex">
          {spaces.includes(OrderSpaceName.LOGISTIC) ? (
            <span className={classes.icon} style={{ margin: 'auto 2px' }}>
              <Icon type={IconType.BusinessCenter2} width="26px" height="26px" />
            </span>
          ) : null}
          {spaces.includes(OrderSpaceName.DRIVER) ? (
            <span className={classes.icon} style={{ margin: 'auto 2px' }}>
              <Icon type={IconType.Driver} width="24px" height="24px" />
            </span>
          ) : null}
          {spaces.includes(OrderSpaceName.TS) ? (
            <span className={classNames(classes.icon, classes.truck)} style={{ margin: 'auto 2px' }}>
              <Icon type={IconType.Truck} width="40px" height="40px" />
            </span>
          ) : null}
        </div>
        <div className="w-100" style={{ margin: 'auto' }}>
          <span className="p-0 m-0 w-100">{getStringFromSelectList(form[OrderFields.ORDER_TYPE], selectLists?.orderType)}</span>
        </div>
      </div>
    </>
  )
}

export default BlockHeader
