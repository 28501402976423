import { Action, handleActions } from 'redux-actions'

import { Notification } from 'types/notifications'

import * as actions from './notifications.actions'

export interface NotificationsState {
  notifications: Notification[]
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
}

const notificationsUpdateSuccess = (state: NotificationsState, action: Action<Notification[]>): NotificationsState => {
  return {
    ...state,
    notifications: [...action.payload],
  }
}

export default handleActions<NotificationsState, any>(
  {
    [actions.NOTIFICATIONS_UPDATE_SUCCESS]: notificationsUpdateSuccess,
  },
  initialNotificationsState,
)
