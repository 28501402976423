import React from 'react'
import SwitchControl from 'components/molecules/SwitchControl'
import Button from 'components/molecules/Button'

import classes from './style.module.css'

export interface UserSchedulerItemProps {
  user: { id: number; isOnline: boolean; name: string; workPower: number }
  onChange(value: any, key: string, userId: number): void
  isLoading: boolean
}

export const UserSchedulerItem: React.FC<UserSchedulerItemProps> = ({ user, onChange, isLoading }) => {
  return (
    <li className="list-group-item d-flex justify-content-between flex-wrap" key={user.name}>
      <div className="d-flex align-items-center" style={{ gap: '12px' }}>
        <div className="d-inline-block">
          <SwitchControl
            value={user.isOnline}
            label={user.name}
            onChangeHandler={(value) => {
              onChange(value, 'isOnline', user.id)
            }}
          />
        </div>
      </div>
      <div className={classes.buttonsWrapper}>
        {[0.5, 1, 1.5, 2].map((value) => (
          <Button
            className={classes.button}
            isLoading={isLoading}
            onClick={() => onChange(value, 'workPower', user.id)}
            content={`${value * 100}%`}
            disabled={user.workPower === value}
            key={value}
            hideLoadingText
          />
        ))}
      </div>
    </li>
  )
}

export default UserSchedulerItem
