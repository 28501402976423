import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

export interface InputControlProps {
  type: string
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string): void
  onBlurHandler?(id?: string, value?: string): void
  onFocusHandler?(id?: string): void
  className?: string
  label?: string
  placeholder?: string
  invalidMessage?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  autoFocus?: boolean
}

export const InputControl: React.FC<InputControlProps> = ({
  type,
  id,
  value,
  isValid,
  onChangeHandler,
  onBlurHandler = () => {},
  onFocusHandler = () => {},
  className = '',
  label,
  placeholder = '',
  invalidMessage = '',
  required = false,
  readonly = false,
  disabled = false,
  autoFocus = false,
}) => {
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, id), [onChangeHandler, id])

  const onBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currValue = e.target.value
      const newValue = currValue.replace(/\s{2,}/g, ' ')
      if (currValue !== newValue) onChangeHandler(newValue, id)

      onBlurHandler(id, newValue)
    },
    [onChangeHandler, id, onBlurHandler],
  )

  const onFocus = useCallback(() => onFocusHandler(id), [onFocusHandler, id])

  const name = useMemo(() => uuidv4(), [])

  return (
    <Form.Group className="m-0">
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        isValid={isValid}
        isInvalid={isValid === false}
        type={type}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        id={id}
        required={required}
        readOnly={readonly}
        className={className}
        disabled={disabled}
        autoComplete="none"
        name={name}
        autoFocus={autoFocus}
      />
      {invalidMessage && isValid === false ? <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback> : null}
    </Form.Group>
  )
}

export default InputControl
