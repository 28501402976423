import { ExistingFileTypes, FileForDocumentsForm, FileProps } from 'types/file'

export const getImagesPreview = (files: FileProps[]) =>
  files?.reduce((list: FileForDocumentsForm[], item: any) => {
    if (!item?.data?.entity && !item?.data?.type && (item?.data?.inSlot || item?.data?.recognitionType)) {
      return [...list, { href_file: item.uuid, data: item.data ?? null, name: item.name }]
    }

    return list
  }, [])

export const getImages = (files: FileProps[]) => {
  const { slotFiles, recognitionFiles } = files?.reduce(
    (result: { slotFiles: FileForDocumentsForm[]; recognitionFiles: FileForDocumentsForm[] }, item: any) => {
      const elem = { name: item.name, href_file: item.uuid, data: item.data, type: null }
      return item?.data?.inSlot
        ? { ...result, slotFiles: [...result.slotFiles, elem] }
        : { ...result, recognitionFiles: [...result.recognitionFiles, elem] }
    },
    { slotFiles: [], recognitionFiles: [] },
  )

  return [...slotFiles, ...recognitionFiles]
}

export const getExistingTypesOfImages = (files: FileForDocumentsForm[]) =>
  files?.reduce((list: ExistingFileTypes[], item: FileForDocumentsForm) => {
    return item?.data?.type ? [...list, { type: item?.data?.type, entity: item?.data?.entity || null, inSlot: item.data?.inSlot }] : list
  }, [])
