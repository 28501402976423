import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { OrderFields, PriorityId } from 'types/order'
import { Feature } from 'types/user'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'

import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'

import SelectControl from 'components/molecules/SelectControl'
import TextareaControl from 'components/molecules/TextareaControl'
import Title from 'components/atoms/Title'

import { OrderProps } from './index'
import OrderTypes from './OrderTypes'

const Customer: React.FC<OrderProps> = ({ onChange }) => {
  const currentUser = useSelector(selectCurrentUser)
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const selectLists = useSelector(selectSelectLists)

  const types = useMemo(
    () => OrderTypes.getForExternalUser(currentUser?.features?.[Feature.AVAILABLE_ORDER_TYPES] || [], selectLists?.orderType || []),
    [currentUser, selectLists],
  )

  const allowPriority = currentUser?.features?.[Feature.ALLOW_PRIORITY]

  return (
    <>
      <Title text="Заявка" />
      {!errors[OrderFields.ORDER_TYPE].isValid ? (
        <div style={{ margin: '4px -16px' }}>
          <div role="alert" className="fade m-0 alert alert-warning show">
            Внимание!
            <br />
            Выберете тип заявки, и появится следующая часть формы.
          </div>
        </div>
      ) : null}
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <SelectControl
              value={form[OrderFields.ORDER_TYPE]}
              isValid={errors[OrderFields.ORDER_TYPE].isValid}
              invalidMessage={errors[OrderFields.ORDER_TYPE].invalidMessage}
              id={OrderFields.ORDER_TYPE}
              options={types}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Тип заявки:"
              withEmptyValue
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <SelectControl
              value={form[OrderFields.PRIORITY]}
              isValid={errors[OrderFields.PRIORITY].isValid}
              invalidMessage={errors[OrderFields.PRIORITY].invalidMessage}
              id={OrderFields.PRIORITY}
              options={selectLists?.priority}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Приоритет заявки:"
              disabled={!allowPriority}
            />
            {!allowPriority && (
              <div className="small text-danger mt-1">
                Функционал изменение приоритета заявки вам недоступен.
                <br />
                Сейчас вы можете создавать заявки только с обычным приоритетом.
                <br />
                Запросите доступ на создание срочных заявок у своего руководства.
                <br />
                Стоимость создания срочной заявки +500р к цене заявки. Время рассмотрения срочных заявок до 15 минут.
              </div>
            )}
            {allowPriority && String(form[OrderFields.PRIORITY]) === PriorityId.URGENTLY && (
              <div className="small text-secondary mt-1">Стоимость создания срочной заявки +500р к цене заявки.</div>
            )}
          </div>
        </li>
        {form[OrderFields.ORDER_TYPE] ? (
          <li className="list-group-item row">
            <div className="p-0">
              <TextareaControl
                value={form[OrderFields.ORDER_TEXT]}
                isValid={errors[OrderFields.ORDER_TEXT].isValid}
                invalidMessage={errors[OrderFields.ORDER_TEXT].invalidMessage}
                id={OrderFields.ORDER_TEXT}
                onChangeHandler={onChange}
                label="Примечания:"
                rows={6}
              />
            </div>
          </li>
        ) : null}
      </ul>
    </>
  )
}

export default Customer
