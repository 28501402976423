import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { OrderTypeId } from 'types/order'

import { getDateFromServerDate, getMoscowDate } from 'utils/date'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

interface OrderTimerProps {
  orderDate: string
  orderType: number | undefined
}

export const OrderTimer: React.FC<OrderTimerProps> = ({ orderDate, orderType }) => {
  const [now, setNow] = useState(getMoscowDate())
  const date = getDateFromServerDate(orderDate)

  const orderDiff = date ? new Date(now.getTime() - date.getTime()) : null

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(getMoscowDate())
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  const isTimeExceeded = useCallback((type, dateDiff) => {
    if (dateDiff) {
      if (type === OrderTypeId.DRIVER_DOC_CHECK) return dateDiff.getTime() > 60 * 60 * 1000
      return dateDiff.getTime() > 30 * 60 * 1000
    }
    return false
  }, [])

  return (
    <>
      {orderDiff ? (
        <>
          {isTimeExceeded(orderType, orderDiff) ? <Icon type={IconType.Bell} className={classes.bell} /> : <Icon type={IconType.Time} />}
          <span className={classNames('align-middle', orderDiff.getTime() > 30 * 60 * 1000 ? 'text-danger' : '')}>
            &nbsp;
            {orderDiff.getUTCDate() - 1 > 0 ? <>{orderDiff.getUTCDate() - 1}д</> : null}
            {orderDiff.getUTCHours() > 0 || orderDiff.getUTCDate() - 1 > 0 ? <>{orderDiff.getUTCHours()}ч</> : null}
            {orderDiff.getUTCMinutes()}м &nbsp;
          </span>
        </>
      ) : null}
    </>
  )
}

export default OrderTimer
