import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import TextWithPrefix from 'components/atoms/TextWithPrefix'
import Title from 'components/atoms/Title'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'

const Customer: React.FC = () => {
  const order = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <Title text="Собственники ТС и прицепа" />
          <div className="p-0">
            <TextWithPrefix text={`${order[OrderFields.TS_OWNER] || ''}${order[OrderFields.TRANSPORT_RENT] ? ' (аренда)' : ''}`} prefix="Тягач/ТС:" />
            <TextWithPrefix text={order[OrderFields.TS_OWNER_LICENSE_PLATE]} prefix="Госномер:" />
            <TextWithPrefix text={order[OrderFields.TRANSPORT_YEAR]} prefix="Год выпуска:" />
            <TextWithPrefix text={<PhoneNumberLink phone={order[OrderFields.TS_OWNER_TEL]} />} prefix="Телефон:" />
            {order[OrderFields.TRAILER_STATUS] ? (
              <p>Без прицепа</p>
            ) : (
              <>
                <TextWithPrefix
                  text={`${order[OrderFields.TRAILER_OWNER] || ''}${order[OrderFields.TRAILER_RENT] ? ' (аренда)' : ''}`}
                  prefix="Прицеп:"
                />
                <TextWithPrefix text={order[OrderFields.TRAILER_OWNER_LICENSE_PLATE]} prefix="Госномер:" />
                <TextWithPrefix text={order[OrderFields.TRAILER_YEAR]} prefix="Год выпуска:" />
                <TextWithPrefix text={<PhoneNumberLink phone={order[OrderFields.TRAILER_OWNER_TEL]} />} prefix="Телефон:" />
              </>
            )}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
