import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import Title from 'components/atoms/Title'

const Customer: React.FC = () => {
  const form = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <Title text="Рейс" />
          <div className="p-0">
            <p className="m-0 p-0">{form[OrderFields.ROUTE_DATE_START]}</p>
            <p className="m-0 p-0">{form[OrderFields.SHIPPER]}</p>
            <p className="m-0 p-0">{form[OrderFields.ROUTE_TRIP]}</p>
            <p className="m-0 p-0">{form[OrderFields.CARGO]}</p>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Customer
