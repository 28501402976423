import React from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'
import Customer from './Customer'

export const Files: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer />

    default:
      return null
  }
}

export default Files
