import React from 'react'

import classes from './style.module.css'

export interface HeaderProps {
  leftButton?: React.ReactNode
  headerName?: string | React.ReactNode
  rightButton?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ leftButton = null, headerName = '', rightButton = null, children = null }) => {
  return (
    <>
      <div className="bg-dark row p-2 position-relative">
        {leftButton && <div className={classes.leftButton}>{leftButton}</div>}
        {headerName && (
          <div className="text-center text-light w-100">
            <h4 className="m-1">{headerName}</h4>
          </div>
        )}
        {rightButton && <div className={classes.rightButton}>{rightButton}</div>}
      </div>
      {children}
    </>
  )
}

export default Header
