import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'
import { Action } from 'redux-actions'

import { UpdateFileData } from 'types/file'
import { OrderFields } from 'types/order'

import { updateFileData } from 'requests/file'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'
import { selectOrderRead, selectOrderWrite } from 'store/order/order.selectors'
import { setInitialValuesOrderRead, setInitialValuesOrderWrite } from 'store/order/order.actions'

import { updateFileDataFailure, updateFileDataSuccess } from '../file.actions'

export function* updateFileDataSaga(action: Action<UpdateFileData>) {
  try {
    const token = yield select(selectToken)

    const response = yield call(updateFileData, action.payload.image.href_file, action.payload.data, token)

    const ordersRead = yield select(selectOrderRead)
    const ordersWrite = yield select(selectOrderWrite)

    const files = ordersWrite?.[OrderFields.FILES]?.length ? ordersWrite[OrderFields.FILES] : ordersRead[OrderFields.FILES]
    const fileIndex = files.findIndex((file: any) => file.uuid === action.payload.image.href_file)
    if (fileIndex !== -1) {
      const newFiles = [...(files || [])]
      newFiles[fileIndex] = {
        ...newFiles[fileIndex],
        data: response.data,
      }
      yield put(
        ordersWrite?.[OrderFields.FILES]?.length
          ? setInitialValuesOrderWrite({
              [OrderFields.FILES]: newFiles,
            })
          : setInitialValuesOrderRead({
              [OrderFields.FILES]: newFiles,
            }),
      )
    } else console.error('[updateFileDataSaga]: File not found')
    yield put(updateFileDataSuccess(action.payload))
  } catch (error) {
    yield put(updateFileDataFailure(action.payload))
    yield put(
      addHttpError({
        error,
        header: 'Изменение данных о файле',
        defaultText: `Не удалось изменить данные о файле ${action.payload.image.name}.`,
      }),
    )
    console.error(`[updateFileDataSaga]: ${error}`)
    captureException(error)
  }
}
