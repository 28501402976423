import React from 'react'

const TextWithPrefix: React.FC<{ text: string | React.ReactNode; prefix: string }> = ({ text, prefix }) => (
  <p className="p-0 m-0">
    <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
      {prefix}
    </span>
    &nbsp;
    {text}
  </p>
)

export default TextWithPrefix
