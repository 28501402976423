import _ from 'lodash-es'
import { ExistingFileTypes } from 'types/file'
import { OrderSpaceName, OrderTypeId } from 'types/order'

import { DropdownMenuItemProps } from 'components/molecules/DropdownMenu'
import getSpaces from 'components/organisms/OrderBody/utils/getSpaces'

import { IMAGE_TYPES_FOR_DRIVER, IMAGE_TYPES_FOR_LOGISTIC, IMAGE_TYPES_FOR_TS } from './constants'

export const isTypeExist = (existingTypes: ExistingFileTypes[], type: DropdownMenuItemProps) =>
  existingTypes.filter((item) => item.type === type.data?.type).length >= (type.data?.count || 1)

const getExistingTypes = (types: DropdownMenuItemProps[], existingTypes: ExistingFileTypes[]) => {
  const result = [...existingTypes]

  types.forEach((type) => {
    const typeId = result.findIndex((existingType) => existingType.type === type.data?.type)
    if (typeId !== -1) result.splice(typeId, 1)
  })

  return result
}

export const getFreeTypes = (existingTypes: ExistingFileTypes[], orderType: OrderTypeId): DropdownMenuItemProps[] => {
  const spaces = getSpaces(orderType)
  const types: DropdownMenuItemProps[] = []

  let existingTypesTemp = [...existingTypes]
  if (spaces.includes(OrderSpaceName.LOGISTIC)) {
    types.push(...IMAGE_TYPES_FOR_LOGISTIC.filter((type) => !isTypeExist(existingTypesTemp, type)))
    existingTypesTemp = getExistingTypes(IMAGE_TYPES_FOR_LOGISTIC, existingTypesTemp)
  }
  if (spaces.includes(OrderSpaceName.DRIVER)) {
    types.push(...IMAGE_TYPES_FOR_DRIVER.filter((type) => !isTypeExist(existingTypesTemp, type)))
    existingTypesTemp = getExistingTypes(IMAGE_TYPES_FOR_DRIVER, existingTypesTemp)
  }
  if (spaces.includes(OrderSpaceName.TS)) {
    types.push(...IMAGE_TYPES_FOR_TS.filter((type) => !isTypeExist(existingTypesTemp, type)))
    existingTypesTemp = getExistingTypes(IMAGE_TYPES_FOR_TS, existingTypesTemp)
  }

  return _.uniqBy(types, 'id')
}
