import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { Icon, IconType } from 'components/atoms/Icon'

import { getNameOfMonth, getMoscowDate } from 'utils/date'

import classes from './style.module.css'

enum dayType {
  ACTIVE = 'day_active',
  CURRENT = 'day_current',
  DISABLED = 'day_disabled',
}

interface dayProps {
  type: dayType
  day: number
  month: number
  year: number
}

export interface CalendarForInputProps {
  onChange(value: Date): void
  value: string
  withTime?: boolean
}

const getWeekDay = (date: Date) => {
  const days = [6, 0, 1, 2, 3, 4, 5]

  return days[date.getDay()]
}

export const CalendarForInput: React.FC<CalendarForInputProps> = ({ value, withTime = false, onChange }) => {
  const [currentDate, setCurrentDate] = useState(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!value || isNaN(Date.parse(value))) {
      return getMoscowDate()
    }
    return new Date(value)
  })

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!value || isNaN(Date.parse(value))) {
      setCurrentDate(getMoscowDate())
    } else {
      setCurrentDate(new Date(value))
    }
  }, [value])

  const handleYears = useCallback(
    (count) => {
      const newDate = new Date(currentDate)
      newDate.setFullYear(newDate.getFullYear() + count)
      setCurrentDate(newDate)
    },
    [currentDate, setCurrentDate],
  )

  const handleMonth = useCallback(
    (count) => {
      const newDate = new Date(currentDate)
      newDate.setMonth(newDate.getMonth() + count)
      setCurrentDate(newDate)
    },
    [currentDate, setCurrentDate],
  )

  const days: dayProps[] = useMemo(() => {
    const response = []
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    const dayOfWeek = getWeekDay(firstDay)
    const date = new Date(firstDay)
    date.setDate(date.getDate() - dayOfWeek)

    const currentDay = new Date(value)
    currentDay.setHours(0)
    currentDay.setMinutes(0)

    for (let i = 0; i < 42; i += 1) {
      response.push({
        type:
          // eslint-disable-next-line no-nested-ternary
          date.valueOf() - currentDay.valueOf() === 0
            ? dayType.CURRENT
            : date.getMonth() === currentDate.getMonth()
            ? dayType.ACTIVE
            : dayType.DISABLED,
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      })
      date.setDate(date.getDate() + 1)
    }
    return response
  }, [currentDate, value])

  return (
    <div className={classes.container}>
      <div className="d-inline-block">
        <div className="d-flex justify-content-between align-items-center">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className={classNames(classes.backArrow, 'btn btn-light')} onClick={() => handleYears(-1)}>
            <Icon type={IconType.Arrow} />
          </span>
          <span>{currentDate.getFullYear() || ''}</span>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className={classNames(classes.arrow, 'btn btn-light')} onClick={() => handleYears(1)}>
            <Icon type={IconType.Arrow} />
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className={classNames(classes.backArrow, 'btn btn-light')} onClick={() => handleMonth(-1)}>
            <Icon type={IconType.Arrow} />
          </span>
          <span>{getNameOfMonth(currentDate) || ''}</span>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className={classNames(classes.arrow, 'btn btn-light')} onClick={() => handleMonth(1)}>
            <Icon type={IconType.Arrow} />
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className={classNames('btn disabled', classes.day)}>пн</span>
          <span className={classNames('btn disabled', classes.day)}>вт</span>
          <span className={classNames('btn disabled', classes.day)}>ср</span>
          <span className={classNames('btn disabled', classes.day)}>чт</span>
          <span className={classNames('btn disabled', classes.day)}>пт</span>
          <span className={classNames('btn disabled', classes.day)}>сб</span>
          <span className={classNames('btn disabled', classes.day)}>вс</span>
        </div>
        <div>
          {days.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                className={classNames('btn btn-light', classes.day, classes[day.type])}
                onClick={() => {
                  onChange(new Date(day.year, day.month, day.day, currentDate.getHours(), currentDate.getMinutes()))
                }}
              >
                {day.day}
              </span>
              {(index + 1) % 7 === 0 && index !== 0 ? <br /> : null}
            </span>
          ))}
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className={classNames('btn btn-link')}
          onClick={() => {
            onChange(getMoscowDate())
          }}
        >
          Сегодня
        </span>
      </div>

      {withTime ? ( // TODO
        <div className="d-inline-block" style={{ flex: 1 }}>
          <div className="d-flex h-100">
            <div className={classes.hours}>
              {new Array(24).fill(1).map((_empty, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classNames('btn btn-light', index === Number(value.slice(11, 13)) ? classes.hour_current : '', classes.hour)}
                  onClick={() => {
                    const newDate = new Date(currentDate)
                    newDate.setHours(index)
                    onChange(newDate)
                  }}
                >
                  {index}
                </span>
              ))}
            </div>
            <div className={classes.minutes}>
              {new Array(60).fill(1).map((_empty, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classNames('btn btn-light', index === Number(value.slice(14, 16)) ? classes.minute_current : '', classes.minute)}
                  onClick={() => {
                    const newDate = new Date(currentDate)
                    newDate.setMinutes(index)
                    onChange(newDate)
                  }}
                >
                  {index}
                </span>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CalendarForInput
