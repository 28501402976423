import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'store/users/users.selectors'

import Manager from './Manager'

export const Files: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)

  switch (currentUser!.role) {
    default:
      return <Manager />
  }
}

export default Files
