import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/users/users.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectCurrentTime } from 'store/utils/utils.selectors'

import { Feature, UserRole } from 'types/user'

import OrderForm from 'components/organisms/OrderForm'
import BlockingWarning from 'components/organisms/CreateOrderBody/BlockingWarning'
import getInitialValues from './utils/getInitialValues'

export const CreateOrderBody: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)
  const selectLists = useSelector(selectSelectLists)
  const currentTime = useSelector(selectCurrentTime)

  const initialValues = useMemo(() => getInitialValues(currentUser, selectLists, currentTime), [currentTime, currentUser, selectLists])

  const currentUserIsCustomer = currentUser!.role === UserRole.CUSTOMER
  const currentUserIsBlocked = currentUser!.features?.[Feature.PAYMENT_STATE] === 'blocked'
  if (currentUserIsCustomer && currentUserIsBlocked) return <BlockingWarning />

  return <OrderForm isCreate initialValues={initialValues} />
}

export default CreateOrderBody
