import React from 'react'
import { useSelector } from 'react-redux'

import InnForm from 'components/molecules/OrderFormSpace/spaces/Logistic/components/InnForm'
import Title from 'components/atoms/Title'

import { OrderFields, OrderTypeId } from 'types/order'
import { getTitleForCarrierByOrderType } from 'utils/title'

import { selectOrderWrite } from 'store/order/order.selectors'
import CompanyForm from './components/CompanyForm'
import { LogisticProps } from './index'

const Customer: React.FC<LogisticProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const orderTypeId = String(form[OrderFields.ORDER_TYPE])
  const titleText = getTitleForCarrierByOrderType(orderTypeId as OrderTypeId)

  return (
    <>
      <Title text={titleText} />
      <ul className="list-group needs-validation" style={{ margin: '0 -15px' }}>
        <InnForm onChange={onChange} />
        <CompanyForm onChange={onChange} />
      </ul>
    </>
  )
}

export default Customer
