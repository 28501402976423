import React from 'react'

import { OrderTypeId } from 'types/order'

import LogisticHintText from './LogisticHintText'
import DriverHintText from './DriverHintText'
import EmployeeHintText from './EmployeeHintText'
import TsHintText from './TsHintText'
import StateDriverHintText from './StateDriverHintText'

interface HintProps {
  orderType: OrderTypeId
}

export const Hint: React.FC<HintProps> = ({ orderType }) => {
  switch (String(orderType)) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      return (
        <>
          <LogisticHintText /> <DriverHintText /> <TsHintText />
        </>
      )

    case OrderTypeId.LOGISTIC:
    case OrderTypeId.LOGISTIC_CONTRACTOR:
    case OrderTypeId.LOGISTIC_PAYER:
      return <LogisticHintText />

    case OrderTypeId.DRIVER_AND_TS:
      return (
        <>
          <DriverHintText /> <TsHintText />
        </>
      )

    case OrderTypeId.DRIVER:
    case OrderTypeId.DRIVER_FOR_LOADING:
      return <DriverHintText />

    case OrderTypeId.STATE_DRIVER:
      return <StateDriverHintText />

    case OrderTypeId.EMPLOYEE:
      return <EmployeeHintText />

    case OrderTypeId.TS:
      return (
        <>
          <TsHintText />
        </>
      )

    default:
      return null
  }
}

export default Hint
