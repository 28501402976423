import React, { useEffect, useRef, FC, RefObject, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { socketNotifications } from 'requests/socket'
import { Icon, IconType } from 'components/atoms/Icon'

import { selectNotifications } from 'store/notifications/notifications.selectors'
import styles from './style.module.css'

interface NotificationsDropdownProps {
  isOpen: boolean
  onClose: () => void
}

export const NotificationsDropdown: FC<NotificationsDropdownProps> = ({ isOpen, onClose }) => {
  const notifications = useSelector(selectNotifications)
  const dropdownRef: RefObject<HTMLDivElement> = useRef(null)

  const handleRemoveNotification = (orderId: string) => {
    socketNotifications.emit('reset', orderId)
  }

  const handleRemoveAllNotifications = () => {
    socketNotifications.emit('resetAll')
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClickOutside)
    } else {
      window.removeEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside, isOpen])

  return (
    <div className={styles.dropdown}>
      {isOpen && (
        <div className={styles.dropdownContent} ref={dropdownRef}>
          {/* eslint-disable-next-line react/button-has-type */}
          <button
            onClick={handleRemoveAllNotifications}
            className={notifications.length === 0 ? styles.disabled : styles.clearAllBtn}
            disabled={notifications.length === 0}
          >
            Очистить уведомления
          </button>
          {notifications.length === 0 ? (
            <p style={{ textAlign: 'center', marginTop: '8px' }}>У вас нет новых уведомлений</p>
          ) : (
            <ul className="p-0 m-0">
              {notifications.map((notification) => (
                // eslint-disable-next-line react/jsx-key
                <Link to={`/order/${notifications[0].orderId}/chat`} className={styles.link}>
                  <li key={notification.orderId} className={styles.notificationItem} style={{ listStyleType: 'none' }}>
                    <div style={{ paddingRight: '26px' }}>У вас новое сообщение по заявке {notification.orderId}</div>
                    <div style={{ color: '#abacad', fontSize: '12px' }}>{new Date(notification.createdAt).toLocaleString()}</div>
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleRemoveNotification(notification.orderId)
                      }}
                      className={styles.removeBtn}
                      style={{ display: 'flex', padding: 0 }}
                    >
                      <Icon type={IconType.Close} width="16px" height="16px" />
                    </button>
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}
