import React from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'

import Customer from './Customer'
import Manager from './Manager'
import Expert from './Expert'

export const TS: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer />

    case UserRole.EXPERT:
      return <Expert />

    default:
      return <Manager />
  }
}

export default TS
