import React from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'

import Manager from './Manager'
import Customer from './Customer'

export interface TSProps {
  onChange(value: string | boolean | number | null, id?: string): void
}

export const TS: React.FC<TSProps> = ({ onChange }) => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer onChange={onChange} />

    default:
      return <Manager onChange={onChange} />
  }
}

export default TS
