import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getRegionByOkato } from './okato-region'
import classes from './style.module.css'
import { selectCurrentUser } from '../../../store/users/users.selectors'
import { UserRole } from '../../../types/user'

const PhoneRegion: React.FC<{ passport: string }> = ({ passport }) => {
  const currentUser = useSelector(selectCurrentUser)

  const isExpert = currentUser!.role === UserRole.EXPERT

  const region = useMemo(() => {
    if (!isExpert || !passport) return '—'
    return getRegionByOkato((passport || '').substr(0, 2))
  }, [isExpert, passport])

  return isExpert && passport ? <div className={classes.passportRegion}>Регион:&nbsp;{region}</div> : null
}

export default PhoneRegion
