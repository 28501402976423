import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import { OrderFields } from 'types/order'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'

import InputFio from 'components/molecules/InputFio'
import InputPhone from 'components/molecules/InputPhone'
import RelatedField from 'components/molecules/RelatedField'
import InputControl from 'components/molecules/InputControl'
import Title from 'components/atoms/Title'

import { TSProps } from './index'

const Customer: React.FC<TSProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const withoutTrailerHandler = useCallback(() => {
    onChange('', OrderFields.TRAILER_OWNER)
    onChange('', OrderFields.TRAILER_OWNER_LICENSE_PLATE)
    onChange('', OrderFields.TRAILER_OWNER_TEL)
    onChange(false, OrderFields.TRAILER_RENT)
  }, [onChange])

  const onChangeLicensePlate = useCallback(
    (value, id) => {
      onChange(value ? value.replace(/\s/g, '').toUpperCase() : '', id)
    },
    [onChange],
  )

  return (
    <>
      <Title text="Собственники ТС" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputFio
              id={OrderFields.TS_OWNER}
              value={form[OrderFields.TS_OWNER]}
              isValid={errors[OrderFields.TS_OWNER].isValid}
              invalidMessage={errors[OrderFields.TS_OWNER].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Имя собственника тягача (СТРОГО по СТС):"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
            <Button
              className="p-0"
              style={{ flex: '1', marginRight: '4px' }}
              disabled={!form[OrderFields.TRAILER_STATUS]}
              onClick={() => {
                onChange(!form[OrderFields.TRAILER_STATUS], OrderFields.TRAILER_STATUS)
              }}
            >
              С прицепом
            </Button>
            <Button
              className="p-0"
              style={{ flex: '1', marginLeft: '4px' }}
              disabled={form[OrderFields.TRAILER_STATUS]}
              onClick={() => {
                onChange(!form[OrderFields.TRAILER_STATUS], OrderFields.TRAILER_STATUS)
                withoutTrailerHandler()
              }}
            >
              Без прицепа
            </Button>
          </div>

          <RelatedField
            fieldName={OrderFields.TS_OWNER}
            fieldToCopy={OrderFields.LOGISTIC_FIO}
            relatedFields={{ [OrderFields.TS_OWNER_TEL]: OrderFields.LOGISTIC_TEL }}
          />
          <RelatedField
            fieldName={OrderFields.TS_OWNER}
            fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE}
            relatedFields={{ [OrderFields.TS_OWNER_TEL]: OrderFields.CARRIER_FIRST_FACE_PHONE_1 }}
          />
          <RelatedField
            fieldName={OrderFields.TS_OWNER}
            fieldToCopy={OrderFields.DRIVER_FIO}
            relatedFields={{ [OrderFields.TS_OWNER_TEL]: OrderFields.DRIVER_TEL_1 }}
          />
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              id={OrderFields.TS_OWNER_LICENSE_PLATE}
              type="text"
              value={form[OrderFields.TS_OWNER_LICENSE_PLATE]}
              isValid={errors[OrderFields.TS_OWNER_LICENSE_PLATE].isValid}
              invalidMessage={errors[OrderFields.TS_OWNER_LICENSE_PLATE].invalidMessage}
              onChangeHandler={onChangeLicensePlate}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Госномер тягача:"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
            <Button
              className="p-0"
              style={{ flex: '1', marginRight: '4px' }}
              disabled={!form[OrderFields.TRANSPORT_RENT]}
              onClick={() => {
                onChange(!form[OrderFields.TRANSPORT_RENT], OrderFields.TRANSPORT_RENT)
              }}
            >
              Не в аренде
            </Button>
            <Button
              className="p-0"
              style={{ flex: '1', marginLeft: '4px' }}
              disabled={form[OrderFields.TRANSPORT_RENT]}
              onClick={() => {
                onChange(!form[OrderFields.TRANSPORT_RENT], OrderFields.TRANSPORT_RENT)
              }}
            >
              В аренде
            </Button>
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              id={OrderFields.TRANSPORT_YEAR}
              type="number"
              value={form[OrderFields.TRANSPORT_YEAR] ? String(form[OrderFields.TRANSPORT_YEAR]) : ''}
              isValid={errors[OrderFields.TRANSPORT_YEAR].isValid}
              invalidMessage={errors[OrderFields.TRANSPORT_YEAR].invalidMessage}
              onChangeHandler={(value, id) => onChange(value ? Number(value) : null, id)}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Год выпуска тягача:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              id={OrderFields.TS_OWNER_TEL}
              value={form[OrderFields.TS_OWNER_TEL]}
              isValid={errors[OrderFields.TS_OWNER_TEL].isValid}
              invalidMessage={errors[OrderFields.TS_OWNER_TEL].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон собственника тягача:"
            />
          </div>
          <RelatedField fieldName={OrderFields.TS_OWNER_TEL} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
          <RelatedField fieldName={OrderFields.TS_OWNER_TEL} fieldToCopy={OrderFields.CARRIER_FIRST_FACE_PHONE_1} relatedFields={{}} />
          <RelatedField fieldName={OrderFields.TS_OWNER_TEL} fieldToCopy={OrderFields.DRIVER_TEL_1} relatedFields={{}} />
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputFio
              id={OrderFields.TRAILER_OWNER}
              value={form[OrderFields.TRAILER_OWNER]}
              isValid={form[OrderFields.TRAILER_STATUS] ? undefined : errors[OrderFields.TRAILER_OWNER].isValid}
              invalidMessage={errors[OrderFields.TRAILER_OWNER].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Имя собственника прицепа (СТРОГО по СТС):"
              disabled={form[OrderFields.TRAILER_STATUS]}
            />
          </div>

          {form[OrderFields.TRAILER_STATUS] ? null : (
            <>
              <RelatedField
                fieldName={OrderFields.TRAILER_OWNER}
                fieldToCopy={OrderFields.LOGISTIC_FIO}
                relatedFields={{ [OrderFields.TRAILER_OWNER_TEL]: OrderFields.LOGISTIC_TEL }}
              />
              <RelatedField
                fieldName={OrderFields.TRAILER_OWNER}
                fieldToCopy={OrderFields.LOGISTIC_FIRST_FACE}
                relatedFields={{ [OrderFields.TRAILER_OWNER_TEL]: OrderFields.CARRIER_FIRST_FACE_PHONE_1 }}
              />
              <RelatedField
                fieldName={OrderFields.TRAILER_OWNER}
                fieldToCopy={OrderFields.DRIVER_FIO}
                relatedFields={{ [OrderFields.TRAILER_OWNER_TEL]: OrderFields.DRIVER_TEL_1 }}
              />
              <RelatedField
                fieldName={OrderFields.TRAILER_OWNER}
                fieldToCopy={OrderFields.TS_OWNER}
                relatedFields={{ [OrderFields.TRAILER_OWNER_TEL]: OrderFields.TS_OWNER_TEL }}
              />
            </>
          )}
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              id={OrderFields.TRAILER_OWNER_LICENSE_PLATE}
              type="text"
              value={form[OrderFields.TRAILER_OWNER_LICENSE_PLATE]}
              isValid={form[OrderFields.TRAILER_STATUS] ? undefined : errors[OrderFields.TRAILER_OWNER_LICENSE_PLATE].isValid}
              invalidMessage={errors[OrderFields.TRAILER_OWNER_LICENSE_PLATE].invalidMessage}
              onChangeHandler={onChangeLicensePlate}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Госномер прицепа:"
              disabled={form[OrderFields.TRAILER_STATUS]}
            />
          </div>
          {!form[OrderFields.TRAILER_STATUS] ? (
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
              <Button
                className="p-0"
                style={{ flex: '1', marginRight: '4px' }}
                disabled={!form[OrderFields.TRAILER_RENT]}
                onClick={() => {
                  onChange(!form[OrderFields.TRAILER_RENT], OrderFields.TRAILER_RENT)
                }}
              >
                Не в аренде
              </Button>
              <Button
                className="p-0"
                style={{ flex: '1', marginLeft: '4px' }}
                disabled={form[OrderFields.TRAILER_RENT]}
                onClick={() => {
                  onChange(!form[OrderFields.TRAILER_RENT], OrderFields.TRAILER_RENT)
                }}
              >
                В аренде
              </Button>
            </div>
          ) : null}
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              id={OrderFields.TRAILER_YEAR}
              type="number"
              value={form[OrderFields.TRAILER_YEAR] ? String(form[OrderFields.TRAILER_YEAR]) : ''}
              isValid={form[OrderFields.TRAILER_STATUS] ? undefined : errors[OrderFields.TRAILER_YEAR].isValid}
              invalidMessage={errors[OrderFields.TRAILER_YEAR].invalidMessage}
              onChangeHandler={(value, id) => onChange(value ? Number(value) : null, id)}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Год выпуска прицепа:"
              disabled={form[OrderFields.TRAILER_STATUS]}
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              id={OrderFields.TRAILER_OWNER_TEL}
              value={form[OrderFields.TRAILER_OWNER_TEL]}
              isValid={form[OrderFields.TRAILER_STATUS] ? undefined : errors[OrderFields.TRAILER_OWNER_TEL].isValid}
              invalidMessage={errors[OrderFields.TRAILER_OWNER_TEL].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон собственника прицепа:"
              disabled={form[OrderFields.TRAILER_STATUS]}
            />
          </div>
          {form[OrderFields.TRAILER_STATUS] ? null : (
            <>
              <RelatedField fieldName={OrderFields.TRAILER_OWNER_TEL} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
              <RelatedField fieldName={OrderFields.TRAILER_OWNER_TEL} fieldToCopy={OrderFields.CARRIER_FIRST_FACE_PHONE_1} relatedFields={{}} />
              <RelatedField fieldName={OrderFields.TRAILER_OWNER_TEL} fieldToCopy={OrderFields.DRIVER_TEL_1} relatedFields={{}} />
              <RelatedField fieldName={OrderFields.TRAILER_OWNER_TEL} fieldToCopy={OrderFields.TS_OWNER_TEL} relatedFields={{}} />
            </>
          )}
        </li>
      </ul>
    </>
  )
}

export default Customer
