import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Button as ReactButton, Spinner } from 'react-bootstrap'

import { LoadingStatus } from 'types/loading'
import { OrderFields, ResolutionId } from 'types/order'
import { selectCurrentUser } from 'store/users/users.selectors'
import { selectLoadingTakeOrderStatus } from 'store/orders/orders.selectors'
import { selectOrderRead } from 'store/order/order.selectors'
import { useOrderTaking } from 'hooks/use-order-taking'

export const TakeOrderButton: React.FC = () => {
  const { orderNumber } = useParams()
  const currentUser = useSelector(selectCurrentUser)

  const { takeOrder, canSelectOrderToTake } = useOrderTaking()

  const loadingTakeOrderStatus = useSelector(selectLoadingTakeOrderStatus)
  const form = useSelector(selectOrderRead)

  const isFreeOrder = useMemo(
    () =>
      (!form[OrderFields.EXECUTOR_EXPERT] ||
        (String(form[OrderFields.EXECUTOR_EXPERT]) === String(currentUser!.id) && String(form[OrderFields.RESOLUTION]) === ResolutionId.FOR_E)) &&
      canSelectOrderToTake,
    [form, currentUser, canSelectOrderToTake],
  )

  return isFreeOrder ? (
    <ReactButton
      className="btn btn-primary col-lg-3 col-md-6 col-sm-12"
      style={{ marginBottom: '8px' }}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        takeOrder(orderNumber)
      }}
      disabled={loadingTakeOrderStatus === LoadingStatus.PENDING}
    >
      <div className="d-flex align-items-center justify-content-center">
        {loadingTakeOrderStatus === LoadingStatus.PENDING ? (
          <>
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
            &nbsp; Загрузка..
          </>
        ) : (
          <>Взять заявку</>
        )}
      </div>
    </ReactButton>
  ) : null
}

export default TakeOrderButton
