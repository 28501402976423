import { createAction } from 'redux-actions'
import { AttachPdfData, DGQuery } from 'types/data-gateway'

export const FETCH_DATA_GATEWAY_REQUEST = 'FETCH_DATA_GATEWAY_REQUEST'
export const fetchDataGatewayRequest = createAction<DGQuery>(FETCH_DATA_GATEWAY_REQUEST)

export const FETCH_DATA_GATEWAY_SUCCESS = 'FETCH_DATA_GATEWAY_SUCCESS'
export const fetchDataGatewaySuccess = createAction<void>(FETCH_DATA_GATEWAY_SUCCESS)

export const FETCH_DATA_GATEWAY_FAILURE = 'FETCH_DATA_GATEWAY_FAILURE'
export const fetchDataGatewayFailure = createAction<Error>(FETCH_DATA_GATEWAY_FAILURE)

export const SET_INITIAL_DATA_GATEWAY_STATE = 'SET_INITIAL_DATA_GATEWAY_STATE'
export const setInitialDataGatewayState = createAction<void>(SET_INITIAL_DATA_GATEWAY_STATE)

export const ATTACH_PDF_REQUEST = 'ATTACH_PDF_REQUEST'
export const attachPdfRequest = createAction<AttachPdfData>(ATTACH_PDF_REQUEST)

export const ATTACH_PDF_SUCCESS = 'ATTACH_PDF_SUCCESS'
export const attachPdfSuccess = createAction<void>(ATTACH_PDF_SUCCESS)

export const ATTACH_PDF_FAILURE = 'ATTACH_PDF_FAILURE'
export const attachPdfFailure = createAction<Error>(ATTACH_PDF_FAILURE)
