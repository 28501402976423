import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import CertificateLink from 'components/atoms/CertificateLink'
import PhoneRegion from 'components/atoms/PhoneRegion'
import BacklitTextForCheckedValue from 'components/atoms/BacklitTextForCheckedValue'
import { Icon, IconType } from 'components/atoms/Icon'
import BoldText from 'components/atoms/BoldText'

import Indent from 'components/atoms/Indent'
import classes from './style.module.css'

const Expert: React.FC = () => {
  const order = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.Truck} width="40px" height="40px" />
            </span>
            <div className={classes.title}>Собственники ТС и прицепа</div>
            <div className="d-flex flex-column mb-2" style={{ gap: '2px' }}>
              <TextWithPrefixAndCopy
                text={
                  <>
                    <BoldText text={order[OrderFields.TS_OWNER] || ''} />
                    {order[OrderFields.TRANSPORT_RENT] ? ' (аренда)' : ''}
                  </>
                }
                copiedText={order[OrderFields.TS_OWNER]}
                prefix="Тягач/ТС:"
              />
              <TextWithPrefix text={<BoldText text={order[OrderFields.TRANSPORT_STS_DATE]} />} prefix="Дата получения СТС:" />
              <TextWithPrefixAndCopy
                text={<BoldText text={order[OrderFields.TS_OWNER_LICENSE_PLATE]} />}
                copiedText={order[OrderFields.TS_OWNER_LICENSE_PLATE]}
                prefix="Госномер:"
              />
              <Indent>
                <BacklitTextForCheckedValue field={OrderFields.TS_OWNER_LICENSE_PLATE} />
                <CertificateLink type="vehicle" identityData={{ Госномер: order[OrderFields.TS_OWNER_LICENSE_PLATE] }} />
              </Indent>
              <TextWithPrefix text={<BoldText text={order[OrderFields.TRANSPORT_YEAR]} />} prefix="Год выпуска:" />
              <TextWithPrefixAndCopy
                text={<BoldText text={<PhoneNumberLink phone={order[OrderFields.TS_OWNER_TEL]} />} />}
                copiedText={order[OrderFields.TS_OWNER_TEL]}
                prefix="Тел:"
              />
              <Indent>
                <PhoneRegion phone={order[OrderFields.TS_OWNER_TEL]} />
                <CertificateLink type="phone" identityData={{ Телефон: order[OrderFields.TS_OWNER_TEL] }} />
              </Indent>
            </div>
            {order[OrderFields.TRAILER_STATUS] ? (
              <p>Без прицепа</p>
            ) : (
              <div className="d-flex flex-column" style={{ gap: '2px' }}>
                <TextWithPrefixAndCopy
                  text={
                    <>
                      <BoldText text={order[OrderFields.TRAILER_OWNER] || ''} />
                      {order[OrderFields.TRAILER_RENT] ? ' (аренда)' : ''}
                    </>
                  }
                  copiedText={order[OrderFields.TRAILER_OWNER]}
                  prefix="Прицеп:"
                />
                <TextWithPrefix text={<BoldText text={order[OrderFields.TRAILER_STS_DATE]} />} prefix="Дата получения СТС:" />
                <TextWithPrefixAndCopy
                  text={<BoldText text={order[OrderFields.TRAILER_OWNER_LICENSE_PLATE]} />}
                  copiedText={order[OrderFields.TRAILER_OWNER_LICENSE_PLATE]}
                  prefix="Госномер:"
                />
                <Indent>
                  <BacklitTextForCheckedValue field={OrderFields.TRAILER_OWNER_LICENSE_PLATE} />
                  <CertificateLink type="vehicle" identityData={{ Госномер: order[OrderFields.TRAILER_OWNER_LICENSE_PLATE] }} />
                </Indent>
                <TextWithPrefix text={<BoldText text={order[OrderFields.TRAILER_YEAR]} />} prefix="Год выпуска:" />
                <TextWithPrefixAndCopy
                  text={<BoldText text={<PhoneNumberLink phone={order[OrderFields.TRAILER_OWNER_TEL]} />} />}
                  copiedText={order[OrderFields.TRAILER_OWNER_TEL]}
                  prefix="Тел:"
                />
                <Indent>
                  <PhoneRegion phone={order[OrderFields.TRAILER_OWNER_TEL]} />
                  <CertificateLink type="phone" identityData={{ Телефон: order[OrderFields.TRAILER_OWNER_TEL] }} />
                </Indent>
              </div>
            )}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Expert
