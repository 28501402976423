import { useCallback } from 'react'

const Validator = require('validatorjs')

export const useValidation = (rules: { [key: string]: string | Array<string> }) => {
  const validate = useCallback((data: any) => new Validator(data, rules, { required: 'Вы забыли атрибут :attribute' }), [rules])
  const validateProperty = useCallback((id: string, value: string) => new Validator({ [id]: value }, { [id]: rules[id] }), [rules])

  return { validate, validateProperty }
}
