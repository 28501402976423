import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { selectNews } from 'store/news/news.selectors'
import { getNewsRequest } from 'store/news/news.actions'

import { News, NewsType } from 'types/news'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

export const NewsCarousel: React.FC = () => {
  const dispatch = useDispatch()
  const news: News = useSelector(selectNews)

  useEffect(() => {
    dispatch(getNewsRequest())
  }, [dispatch])

  const currentWeek = useMemo(() => {
    const currentDate = new Date()
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days = Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000))

    return String(Math.ceil(days / 7))
  }, [])

  const currentNews = news[currentWeek] || null

  const name = `news_week_${currentWeek}_${currentNews?.id}_is_hidden`

  const [isHiddenOffer, setIsHiddenOffer] = useState(true)

  useEffect(() => {
    if (currentNews) setIsHiddenOffer(Boolean(localStorage.getItem(name) || false))
  }, [currentNews, name])

  const hideOffer = useCallback(() => {
    setIsHiddenOffer(true)
    localStorage.setItem(name, 'true')
  }, [name])

  const currentClass = useMemo(() => {
    switch (currentNews?.type) {
      case NewsType.INFO:
        return classes.link_info

      case NewsType.WARNING:
        return classes.link_warning

      case NewsType.PRIMARY:
        return classes.link_primary

      default:
        return classes.link_info
    }
  }, [currentNews])

  if (!currentNews || isHiddenOffer) return null

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <a
          href={currentNews?.link}
          className={classNames('text-white text-center d-flex align-items-center justify-content-center', classes.link, currentClass)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className={classes.text}>
            {currentNews?.text}&nbsp;
            {currentNews?.link && <span className={classes.text_decoration_underline}>{currentNews?.linkText || 'Подробнее...'}</span>}
          </span>
        </a>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.close} onClick={hideOffer}>
          <Icon type={IconType.Close} width="24px" height="24px" />
        </div>
      </div>
    </div>
  )
}

export default NewsCarousel
