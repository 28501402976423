import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectOrderFormErrors, selectOrderLogisticInfoStatus, selectOrderWrite } from 'store/order/order.selectors'

import { LogisticType, OrderFields } from 'types/order'
import { LoadingStatus } from 'types/loading'

import Company from '../company'
import CompanyName from './CompanyName'
import DirectorName from './DirectorName'
import DirectorAlert from './DirectorAlert'
import PhoneBox from './PhoneBox'

const CompanyForm: React.FC<any> = ({ onChange }) => {
  const [hasDirectorPhone, setHasDirectorPhone] = useState(true)

  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const status = useSelector(selectOrderLogisticInfoStatus)

  const company = useMemo(() => new Company(form[OrderFields.LOGISTIC_INN], form[OrderFields.LOGISTIC_TYPE] as LogisticType), [form])

  const isVisibleForm =
    (company.isValidInn() && status === LoadingStatus.SUCCESS) ||
    (errors[OrderFields.LOGISTIC_INN].isValid && company.isSelfEmployed()) ||
    company.isForeign()

  const toggleHasDirectorPhone = () => {
    onChange('', OrderFields.CARRIER_FIRST_FACE_PHONE_1)
    onChange('', OrderFields.CARRIER_FIRST_FACE_PHONE_2)
    setHasDirectorPhone((value) => !value)
  }

  if (!isVisibleForm) return null

  return (
    <>
      {!company.isSelfEmployed() ? <CompanyName onChange={onChange} /> : null}
      <DirectorName onChange={onChange} />
      {!hasDirectorPhone ? <DirectorAlert onButtonClick={toggleHasDirectorPhone} /> : null}
      <PhoneBox onChange={onChange} onButtonClick={toggleHasDirectorPhone} hasDirectorPhone={hasDirectorPhone} />
    </>
  )
}

export default CompanyForm
