import { Action, handleActions } from 'redux-actions'

import { Alert } from 'types/alert'
import * as actions from './alerts.actions'

export interface AlertsState {
  alerts: Alert[]
  count: number
}

export const initialAlertsState: AlertsState = {
  alerts: [],
  count: 0,
}

const addAlert = (state: AlertsState, action: Action<Alert>): AlertsState => {
  return {
    ...state,
    alerts: [
      {
        ...action.payload,
        id: state.count + 1,
      },
      ...state.alerts,
    ],
    count: state.count + 1,
  }
}

const removeAlert = (state: AlertsState, action: Action<Alert>): AlertsState => ({
  ...state,
  alerts: [...state.alerts.filter((alert) => alert.id !== action.payload.id)],
})

export default handleActions<AlertsState, any>(
  {
    [actions.FETCH_ADD_ALERT]: addAlert,
    [actions.REMOVE_ALERT]: removeAlert,
  },
  initialAlertsState,
)
