import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { ListGroup } from 'react-bootstrap'

import InputControl from 'components/molecules/InputControl'

import classes from './style.module.css'

export interface InputTextWithAutocompleteProps {
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string, isAutocomplete?: boolean): void
  onBlurHandler?(id?: string, value?: string): void
  className?: string
  classNameForAutocompleteContainer?: string
  label?: string
  placeholder?: string
  invalidMessage?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  autocompleteArray: string[]
}

export const InputTextWithAutocomplete: React.FC<InputTextWithAutocompleteProps> = ({
  id,
  value,
  isValid,
  onChangeHandler,
  onBlurHandler = () => {},
  className,
  classNameForAutocompleteContainer,
  label,
  placeholder,
  invalidMessage,
  required = false,
  readonly = false,
  disabled = false,
  autocompleteArray,
}) => {
  const [visible, setVisible] = useState(false)

  const autocompleteHandler = useCallback(
    (text: string) => {
      onChangeHandler(text, id, true)
    },
    [id, onChangeHandler],
  )

  const onFocus = useCallback(() => {
    setVisible(true)
  }, [])

  const onBlur = useCallback(
    (_id, val) => {
      setVisible(false)
      onBlurHandler(id, val)
    },
    [onBlurHandler, id],
  )

  return (
    <>
      <InputControl
        type="text"
        id={id}
        value={value}
        isValid={isValid}
        invalidMessage={invalidMessage}
        onBlurHandler={onBlur}
        onFocusHandler={onFocus}
        onChangeHandler={onChangeHandler}
        className={className}
        placeholder={placeholder}
        readonly={readonly}
        label={label}
        disabled={disabled}
        required={required}
      />
      {visible && autocompleteArray.length ? (
        <div className="position-relative">
          <ListGroup className={classNames(classes.autocomplete_container, classNameForAutocompleteContainer)}>
            {autocompleteArray.map((text) => (
              <ListGroup.Item
                action
                variant="light"
                key={text}
                onClick={() => autocompleteHandler(text)}
                onMouseDown={(event: any) => {
                  event.preventDefault()
                  event.stopPropagation()
                  return false
                }}
                className={classes.autocomplete_item}
              >
                {text}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ) : null}
    </>
  )
}

export default InputTextWithAutocomplete
