const okatoRegion: { [key: string]: string } = {
  '79': 'Респ Адыгея',
  '84': 'Респ Алтай',
  '01': 'Алтайский край',
  '10': 'Амурская обл',
  '11': 'Архангельская обл',
  '12': 'Астраханская обл',
  '55': 'г Байконур',
  '80': 'Респ Башкортостан',
  '14': 'Белгородская обл',
  '15': 'Брянская обл',
  '81': 'Респ Бурятия',
  '17': 'Владимирская обл',
  '18': 'Волгоградская обл',
  '19': 'Вологодская обл',
  '20': 'Воронежская обл',
  '82': 'Респ Дагестан',
  '99': 'Еврейская Аобл',
  '76': 'Забайкальский край',
  '24': 'Ивановская обл',
  '26': 'Респ Ингушетия',
  '25': 'Иркутская обл',
  '83': 'Респ Кабардино-Балкарская',
  '27': 'Калининградская обл',
  '85': 'Респ Калмыкия',
  '29': 'Калужская обл',
  '30': 'Камчатский край',
  '91': 'Респ Карачаево-Черкесская',
  '86': 'Респ Карелия',
  '32': 'Кемеровская область - Кузбасс',
  '33': 'Кировская обл',
  '87': 'Респ Коми',
  '34': 'Костромская обл',
  '03': 'Краснодарский край',
  '04': 'Красноярский край',
  '35': 'Респ Крым',
  '37': 'Курганская обл',
  '38': 'Курская обл',
  '41': 'Ленинградская обл',
  '42': 'Липецкая обл',
  '44': 'Магаданская обл',
  '88': 'Респ Марий Эл',
  '89': 'Респ Мордовия',
  '45': 'г Москва',
  '46': 'Московская обл',
  '47': 'Мурманская обл',
  '22': 'Нижегородская обл',
  '49': 'Новгородская обл',
  '50': 'Новосибирская обл',
  '52': 'Омская обл',
  '53': 'Оренбургская обл',
  '54': 'Орловская обл',
  '56': 'Пензенская обл',
  '57': 'Пермский край',
  '05': 'Приморский край',
  '58': 'Псковская обл',
  '60': 'Ростовская обл',
  '61': 'Рязанская обл',
  '36': 'Самарская обл',
  '40': 'г Санкт-Петербург',
  '63': 'Саратовская обл',
  '98': 'Респ Саха /Якутия/',
  '64': 'Сахалинская обл',
  '65': 'Свердловская обл',
  '67': 'г Севастополь',
  '90': 'Респ Северная Осетия - Алания',
  '66': 'Смоленская обл',
  '07': 'Ставропольский край',
  '68': 'Тамбовская обл',
  '92': 'Респ Татарстан',
  '28': 'Тверская обл',
  '69': 'Томская обл',
  '70': 'Тульская обл',
  '93': 'Респ Тыва',
  '71': 'Тюменская обл',
  '94': 'Респ Удмуртская',
  '73': 'Ульяновская обл',
  '08': 'Хабаровский край',
  '95': 'Респ Хакасия',
  '75': 'Челябинская обл',
  '96': 'Респ Чеченская',
  '97': 'Чувашская Республика - Чувашия',
  '77': 'Чукотский АО',
  '78': 'Ярославская обл',
}

export const getRegionByOkato = (okato: string) => {
  return okatoRegion[okato] || '—'
}
