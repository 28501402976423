import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { FileForDocumentsForm } from 'types/file'

import { selectToken } from 'store/users/users.selectors'

import { ViewerImage, ViewerImageButton } from 'components/molecules/ViewerImage'

import classes from './style.module.css'

interface GalleryProps {
  images: FileForDocumentsForm[]
  isRightSide?: boolean
  classNameForItem?: string
}

interface GalleryState {
  isViewerEnabled: boolean
  currentImage: FileForDocumentsForm | null
  currentImageIndex: number | null
  buttonsForViewer: ViewerImageButton[]
}

const initialGalleryState = {
  isViewerEnabled: false,
  currentImage: null,
  currentImageIndex: null,
  buttonsForViewer: [
    ViewerImageButton.CLOSE,
    ViewerImageButton.ROTATE,
    ViewerImageButton.ZOOM_IN,
    ViewerImageButton.ZOOM_OUT,
    ViewerImageButton.BACK,
    ViewerImageButton.NEXT,
  ],
}

const Gallery: React.FC<GalleryProps> = ({ images, isRightSide, classNameForItem }) => {
  const token = useSelector(selectToken)

  const defineButtons = useCallback(
    (currentImageIndex) => {
      const buttons = [ViewerImageButton.CLOSE, ViewerImageButton.ROTATE, ViewerImageButton.ZOOM_IN, ViewerImageButton.ZOOM_OUT]
      if (currentImageIndex) buttons.push(ViewerImageButton.BACK)
      if (Number(currentImageIndex) < images.length - 1) buttons.push(ViewerImageButton.NEXT)
      return buttons
    },
    [images],
  )

  const [state, setState] = useState<GalleryState>({ ...initialGalleryState })

  const handleImageClick = useCallback(
    (image: FileForDocumentsForm, index: number) => {
      setState((prev) => ({
        ...prev,
        isViewerEnabled: true,
        currentImage: image,
        currentImageIndex: index,
        buttonsForViewer: defineButtons(index),
      }))
    },
    [defineButtons],
  )

  const handleImageBackClick = useCallback(() => {
    setState((prev) => {
      if (!prev.currentImageIndex) return prev

      return {
        ...prev,
        currentImage: images[prev.currentImageIndex - 1],
        currentImageIndex: prev.currentImageIndex - 1,
        buttonsForViewer: defineButtons(prev.currentImageIndex - 1),
      }
    })
  }, [defineButtons, images])

  const handleImageNextClick = useCallback(() => {
    setState((prev) => {
      if (prev.currentImageIndex === null || prev.currentImageIndex === images.length - 1) return prev

      return {
        ...prev,
        currentImage: images[prev.currentImageIndex + 1],
        currentImageIndex: prev.currentImageIndex + 1,
        buttonsForViewer: defineButtons(prev.currentImageIndex + 1),
      }
    })
  }, [defineButtons, images])

  const onCloseViewer = () => {
    setState(initialGalleryState)
  }

  if (!images.length) return null

  return (
    <>
      <div className={classes.galleryWrapper}>
        {images.map((image: FileForDocumentsForm, index: number) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            key={image.href_file}
            onClick={() => handleImageClick(image, index)}
            className={classNames(classes.galleryItem, classNameForItem)}
            style={{ transform: `rotate(${image?.data?.rotation || 0}deg)` }}
          >
            <img
              src={`${process.env.REACT_APP_FILES_SERVER}/file/${image.href_file}?token=${token}`}
              alt={`${image.data?.type || image.data?.recognitionType} ${image.name}`}
            />
          </div>
        ))}
      </div>
      {state.isViewerEnabled && state.currentImage ? (
        <ViewerImage
          image={state.currentImage}
          isRightSide={isRightSide}
          onBack={handleImageBackClick}
          onNext={handleImageNextClick}
          onClose={onCloseViewer}
          buttons={state.buttonsForViewer}
        />
      ) : null}
    </>
  )
}

export default Gallery
