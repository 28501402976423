import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import TextWithPrefix from 'components/atoms/TextWithPrefix'
import CertificateLink from 'components/atoms/CertificateLink'
import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'

const Manager: React.FC = () => {
  const order = useSelector(selectOrderRead)

  return (
    <>
      <ul className="list-group row needs-validation">
        <li className="list-group-item">
          <div className="p-0">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              className={classNames(classes.anchor)}
            >
              <Icon type={IconType.Truck} width="40px" height="40px" />
            </span>
            <div className={classes.title}>Собственники ТС и прицепа</div>
            <TextWithPrefixAndCopy
              text={`${order[OrderFields.TS_OWNER] || ''}${order[OrderFields.TRANSPORT_RENT] ? ' (аренда)' : ''}`}
              copiedText={order[OrderFields.TS_OWNER]}
              prefix="Тягач/ТС:"
            />
            <TextWithPrefix text={order[OrderFields.TRANSPORT_STS_DATE]} prefix="Дата получения СТС:" />
            <TextWithPrefixAndCopy
              text={order[OrderFields.TS_OWNER_LICENSE_PLATE]}
              copiedText={order[OrderFields.TS_OWNER_LICENSE_PLATE]}
              prefix="Госномер:"
            />
            <CertificateLink type="vehicle" identityData={{ Госномер: order[OrderFields.TS_OWNER_LICENSE_PLATE] }} />
            <TextWithPrefix text={order[OrderFields.TRANSPORT_YEAR]} prefix="Год выпуска:" />
            <TextWithPrefixAndCopy
              text={<PhoneNumberLink phone={order[OrderFields.TS_OWNER_TEL]} />}
              copiedText={order[OrderFields.TS_OWNER_TEL]}
              prefix="Тел:"
            />
            {order[OrderFields.TRAILER_STATUS] ? (
              <p>Без прицепа</p>
            ) : (
              <div className="mt-2">
                <TextWithPrefixAndCopy
                  text={`${order[OrderFields.TRAILER_OWNER] || ''}${order[OrderFields.TRAILER_RENT] ? ' (аренда)' : ''}`}
                  copiedText={order[OrderFields.TRAILER_OWNER]}
                  prefix="Прицеп:"
                />
                <TextWithPrefix text={order[OrderFields.TRAILER_STS_DATE]} prefix="Дата получения СТС:" />
                <TextWithPrefixAndCopy
                  text={order[OrderFields.TRAILER_OWNER_LICENSE_PLATE]}
                  copiedText={order[OrderFields.TRAILER_OWNER_LICENSE_PLATE]}
                  prefix="Госномер:"
                />
                <TextWithPrefix text={order[OrderFields.TRAILER_YEAR]} prefix="Год выпуска:" />
                <TextWithPrefixAndCopy
                  text={<PhoneNumberLink phone={order[OrderFields.TRAILER_OWNER_TEL]} />}
                  copiedText={order[OrderFields.TRAILER_OWNER_TEL]}
                  prefix="Тел:"
                />
              </div>
            )}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
