import { OrderFields, PriorityId } from 'types/order'
import { groupOrders } from './group-orders'
import { SortableOrder } from '../sortable-order'

export class ExpertSorter {
  static sort(orders: SortableOrder[]) {
    const descSortedOrders = [...orders].reverse()
    const [urgentOrders, regularOrders] = groupOrders(descSortedOrders, ExpertSorter.isUrgent)
    return [...urgentOrders, ...regularOrders]
  }

  static isUrgent(order: SortableOrder) {
    return String(order[OrderFields.PRIORITY]) === PriorityId.URGENTLY
  }
}
