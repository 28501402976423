import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { LinkDictionaryEntryRequest, LinkDictionaryEntryResponse } from 'types/dictionary'
import { linkDictionaryEntry } from 'requests/dictionary'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { linkDictionaryEntriesFailure, linkDictionaryEntriesSuccess } from 'store/dictionary/dictionary.actions'

export function* linkDictionaryEntriesSaga(action: Action<LinkDictionaryEntryRequest>) {
  try {
    const token = yield select(selectToken)

    const response: LinkDictionaryEntryResponse = yield call(linkDictionaryEntry, action.payload, token)

    yield put(linkDictionaryEntriesSuccess({ sourceEntries: action.payload.entries, response }))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Верификация телефона',
        defaultText: 'Не удалось верифицировать телефон.',
      }),
    )
    console.error(`[linkDictionaryEntriesSaga]: ${error}`)
    yield put(linkDictionaryEntriesFailure(action.payload))
  }
}
