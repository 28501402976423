import React from 'react'

export const EmployeeHintText = () => (
  <>
    СОТРУДНИК
    <br />
    <ul>
      <li>паспорт</li>
    </ul>
  </>
)

export default EmployeeHintText
