import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchSelectListsRequest, setInitialStateSelectLists } from 'store/selectLists/selectLists.actions'
import { fetchOrdersRequest, setInitialStateOrders } from 'store/orders/orders.actions'
import { checkVersionRequest } from 'store/version/version.actions'

import OrderListHeader from 'components/organisms/OrderListHeader'
import OrderListBody from 'components/organisms/OrderListBody'

export const OrderListPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkVersionRequest())

    dispatch(fetchOrdersRequest())
    dispatch(fetchSelectListsRequest())

    return () => {
      dispatch(setInitialStateSelectLists())
      dispatch(setInitialStateOrders())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <OrderListHeader />
      <div className="row flex-grow-1">
        <OrderListBody />
      </div>
    </div>
  )
}

export default OrderListPage
