import React from 'react'
import { useSelector } from 'react-redux'

import { UserRole } from 'types/user'

import { selectCurrentUser } from 'store/users/users.selectors'
import Customer from './Customer'
import Manager from './Manager'

export interface HeaderProps {
  onChange(value: string, id?: string): void
  isCreate?: boolean
}

export const Header: React.FC<HeaderProps> = ({ onChange, isCreate = false }) => {
  const currentUser = useSelector(selectCurrentUser)
  switch (currentUser!.role) {
    case UserRole.CUSTOMER:
      return <Customer />

    default:
      return <Manager onChange={onChange} isCreate={isCreate} />
  }
}

export default Header
