import React from 'react'
import { useSelector } from 'react-redux'

import { selectOrderWrite } from 'store/order/order.selectors'
import { LogisticType, OrderFields } from 'types/order'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './popup-icon.module.css'
import Labeler from '../labeler'

const PopupIcon: React.FC = () => {
  const form = useSelector(selectOrderWrite)
  const labeler = new Labeler(form[OrderFields.LOGISTIC_TYPE] as LogisticType, form[OrderFields.LOGISTIC_FIRST_FACE])

  return (
    <div className={classes.icon}>
      <Icon type={IconType.Hint} />
      <div className={classes.popup}>Телефон {labeler.getDirectorNameForAlert()} повышает надежность и уменьшает время проверки</div>
    </div>
  )
}

export default PopupIcon
