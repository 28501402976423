import React from 'react'
import classNames from 'classnames'
import { OrderStatus } from 'types/order'

interface ProgressBarProps {
  status: OrderStatus
  currentTime: number
  maxTime: number
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ status, currentTime, maxTime }) => {
  const isRunning = status === OrderStatus.IN_PROGRESS

  const calculatePercentage = (current: number, max: number) => {
    if (max <= 0) return 0
    return Math.floor(Math.min((current / max) * 100, 100))
  }

  const overallPercentage = calculatePercentage(currentTime, maxTime)
  const timeLeft = maxTime - currentTime
  const isCloseToEnd = timeLeft <= 0

  return (
    <div>
      <div className="progress" style={{ height: '1rem' }}>
        <div
          className={classNames('progress-bar', {
            'bg-info progress-bar-animated progress-bar-striped': isRunning && isCloseToEnd,
            'bg-secondary': status === OrderStatus.CLARIFICATION && !isCloseToEnd,
            'bg-success progress-bar-animated progress-bar-striped': isRunning,
          })}
          role="progressbar"
          style={{
            width: `${overallPercentage}%`,
            transition: isRunning ? 'width 60s linear' : 'none',
          }}
          aria-valuenow={overallPercentage}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  )
}
