import { createAction } from 'redux-actions'

import { UpdateFileData } from 'types/file'

export const UPDATE_FILE_DATA_REQUEST = 'UPDATE_FILE_DATA_REQUEST'
export const updateFileDataRequest = createAction<UpdateFileData>(UPDATE_FILE_DATA_REQUEST)

export const UPDATE_FILE_DATA_SUCCESS = 'UPDATE_FILE_DATA_SUCCESS'
export const updateFileDataSuccess = createAction<UpdateFileData>(UPDATE_FILE_DATA_SUCCESS)

export const UPDATE_FILE_DATA_FAILURE = 'UPDATE_FILE_DATA_FAILURE'
export const updateFileDataFailure = createAction<UpdateFileData>(UPDATE_FILE_DATA_FAILURE)

export const ROTATE_FILE_REQUEST = 'ROTATE_FILE_REQUEST'
export const rotateFileRequest = createAction<UpdateFileData>(ROTATE_FILE_REQUEST)

export const ROTATE_FILE_SUCCESS = 'ROTATE_FILE_SUCCESS'
export const rotateFileSuccess = createAction<UpdateFileData>(ROTATE_FILE_SUCCESS)

export const ROTATE_FILE_FAILURE = 'ROTATE_FILE_FAILURE'
export const rotateFileFailure = createAction<UpdateFileData>(ROTATE_FILE_FAILURE)
