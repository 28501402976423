import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'

import SelectControl from 'components/molecules/SelectControl'
import TextareaControl from 'components/molecules/TextareaControl'
import InputDateTime from 'components/molecules/InputDateTime'

import Title from 'components/atoms/Title'
import { OrderProps } from './index'
import OrderTypes from './OrderTypes'

const Manager: React.FC<OrderProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const selectLists = useSelector(selectSelectLists)

  const types = useMemo(() => OrderTypes.getForInternalUser(selectLists?.orderType || []), [selectLists])

  const allowPriority = form[OrderFields.CUSTOMER_FEATURES].allowPriority === true

  return (
    <>
      <Title text="От клиента" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputDateTime
              id={OrderFields.ORDER_DATE}
              value={form[OrderFields.ORDER_DATE]}
              isValid={errors[OrderFields.ORDER_DATE].isValid}
              invalidMessage={errors[OrderFields.ORDER_DATE].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Дата и время получения заявки от клиента:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <SelectControl
              value={form[OrderFields.CLIENT_NAME]}
              isValid={errors[OrderFields.CLIENT_NAME].isValid}
              invalidMessage={errors[OrderFields.CLIENT_NAME].invalidMessage}
              id={OrderFields.CLIENT_NAME}
              options={selectLists?.clientName}
              onChangeHandler={onChange}
              label="Название клиента:"
              withEmptyValue
              className="col-lg-4 col-md-6 col-sm-12"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <TextareaControl
              value={form[OrderFields.ORDER_TEXT]}
              isValid={errors[OrderFields.ORDER_TEXT].isValid}
              invalidMessage={errors[OrderFields.ORDER_TEXT].invalidMessage}
              id={OrderFields.ORDER_TEXT}
              onChangeHandler={onChange}
              label="Текст заявки от клиента:"
              rows={6}
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <SelectControl
              value={form[OrderFields.ORDER_TYPE]}
              isValid={errors[OrderFields.ORDER_TYPE].isValid}
              invalidMessage={errors[OrderFields.ORDER_TYPE].invalidMessage}
              id={OrderFields.ORDER_TYPE}
              options={types}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Тип заявки:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <SelectControl
              value={form[OrderFields.PRIORITY]}
              isValid={errors[OrderFields.PRIORITY].isValid}
              invalidMessage={errors[OrderFields.PRIORITY].invalidMessage}
              id={OrderFields.PRIORITY}
              options={selectLists?.priority}
              onChangeHandler={onChange}
              disabled={!allowPriority}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Приоритет заявки:"
            />
            {!allowPriority && <div className="small text-secondary mt-1">У клиента не подключены срочные заявки.</div>}
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
