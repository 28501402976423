import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { fetchClarifyData } from 'requests/order'
import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditOrderData, OrderFields } from 'types/order'

import { fetchClarifyDataFailure, fetchClarifyDataSuccess, fetchGetOrderRequest } from '../order.actions'

export function* fetchClarifyDataSaga(action: Action<EditOrderData>) {
  try {
    const token = yield select(selectToken)

    yield call(fetchClarifyData, action.payload, token)

    yield put(fetchClarifyDataSuccess())
    yield put(
      addAlert({
        text: `Данные уточнены.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Уточнение данных',
      }),
    )
  } catch (error) {
    yield put(fetchClarifyDataFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Уточнение данных',
        defaultText: 'Не удалось уточнить данные.',
      }),
    )
    console.error(`[fetchClarifyDataSaga]: ${error}`)
    captureException(error)
  } finally {
    yield put(
      fetchGetOrderRequest({
        [OrderFields.ORDER_NUMBER]: action.payload[OrderFields.ORDER_NUMBER],
      }),
    )
  }
}
