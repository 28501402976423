import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { AuthForm } from 'components/organisms/AuthForm'
import { checkVersionRequest } from 'store/version/version.actions'

import classes from './style.module.css'

export const AuthPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkVersionRequest())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-3 col-md-6 p-sm-0">
          <div className={classes.AuthForm} style={{ marginBottom: '5px' }}>
            <AuthForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthPage
