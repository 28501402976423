import React from 'react'

const TextWithMiniPrefix: React.FC<{
  text: string | React.ReactNode
  prefix: string
  withoutLineBreak?: boolean
}> = ({ text, prefix, withoutLineBreak = false }) => (
  <p className="p-0 m-0">
    <span style={{ color: 'rgb(153, 153, 153)' }} className="p-0 m-0">
      <small>{prefix}</small>
    </span>
    {withoutLineBreak ? <>&nbsp;</> : <br />}
    {text}
  </p>
)

export default TextWithMiniPrefix
