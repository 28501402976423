import { OrderFields, OrderFormErrorsProps, OrderFormSpaceId, OrderTypeId, OrderTypeName } from 'types/order'
import { User, UserRole } from 'types/user'

const managerSpacesForCreate = {
  [OrderTypeName.LOGISTIC_AND_DRIVER]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.CONTACT,
    OrderFormSpaceId.LOGISTIC,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.COUNTERPARTY_AND_TS]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.LOGISTIC,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.DRIVER_AND_TS]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.DRIVER]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER, OrderFormSpaceId.FILES],
  [OrderTypeName.PHONE_CHECK]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER],
  [OrderTypeName.DRIVER_FOR_LOADING]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.ROUTE,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.LOGISTIC]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.CONTACT,
    OrderFormSpaceId.LOGISTIC,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.TS]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.TS, OrderFormSpaceId.ROUTE, OrderFormSpaceId.FILES],
}

const managerSpacesForEdit = {
  [OrderTypeName.LOGISTIC_AND_DRIVER]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.CONTACT,
    OrderFormSpaceId.LOGISTIC,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
  ],
  [OrderTypeName.COUNTERPARTY_AND_TS]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.LOGISTIC, OrderFormSpaceId.TS],
  [OrderTypeName.DRIVER_AND_TS]: [
    OrderFormSpaceId.HEADER,
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
  ],
  [OrderTypeName.DRIVER]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER],
  [OrderTypeName.PHONE_CHECK]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER],
  [OrderTypeName.DRIVER_FOR_LOADING]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER, OrderFormSpaceId.ROUTE],
  [OrderTypeName.LOGISTIC]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.CONTACT, OrderFormSpaceId.LOGISTIC],
  [OrderTypeName.TS]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.TS, OrderFormSpaceId.ROUTE],
}

const customerSpacesForCreate = {
  [OrderTypeName.LOGISTIC_AND_DRIVER]: [
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.CONTACT,
    OrderFormSpaceId.LOGISTIC,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.COUNTERPARTY_AND_TS]: [OrderFormSpaceId.ORDER, OrderFormSpaceId.LOGISTIC, OrderFormSpaceId.TS, OrderFormSpaceId.FILES],
  [OrderTypeName.DRIVER_AND_TS]: [
    OrderFormSpaceId.ORDER,
    OrderFormSpaceId.DRIVER,
    OrderFormSpaceId.TS,
    OrderFormSpaceId.ROUTE,
    OrderFormSpaceId.FILES,
  ],
  [OrderTypeName.DRIVER]: [OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER, OrderFormSpaceId.FILES],
  [OrderTypeName.PHONE_CHECK]: [OrderFormSpaceId.HEADER, OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER],
  [OrderTypeName.DRIVER_FOR_LOADING]: [OrderFormSpaceId.ORDER, OrderFormSpaceId.DRIVER, OrderFormSpaceId.ROUTE, OrderFormSpaceId.FILES],
  [OrderTypeName.LOGISTIC]: [OrderFormSpaceId.ORDER, OrderFormSpaceId.CONTACT, OrderFormSpaceId.LOGISTIC, OrderFormSpaceId.FILES],
  [OrderTypeName.TS]: [OrderFormSpaceId.ORDER, OrderFormSpaceId.TS, OrderFormSpaceId.ROUTE, OrderFormSpaceId.FILES],
}

const getSpacesForManager = (orderType: string, isCreate: boolean) => {
  switch (String(orderType)) {
    case OrderTypeId.LOGISTIC_AND_DRIVER:
      return isCreate ? managerSpacesForCreate[OrderTypeName.LOGISTIC_AND_DRIVER] : managerSpacesForEdit[OrderTypeName.LOGISTIC_AND_DRIVER]

    case OrderTypeId.DRIVER_AND_TS:
      return isCreate ? managerSpacesForCreate[OrderTypeName.DRIVER_AND_TS] : managerSpacesForEdit[OrderTypeName.DRIVER_AND_TS]

    case OrderTypeId.LOGISTIC:
    case OrderTypeId.LOGISTIC_PAYER:
    case OrderTypeId.LOGISTIC_CONTRACTOR:
      return isCreate ? managerSpacesForCreate[OrderTypeName.LOGISTIC] : managerSpacesForEdit[OrderTypeName.LOGISTIC]

    case OrderTypeId.DRIVER:
    case OrderTypeId.STATE_DRIVER:
      return isCreate ? managerSpacesForCreate[OrderTypeName.DRIVER] : managerSpacesForEdit[OrderTypeName.DRIVER]

    case OrderTypeId.DRIVER_FOR_LOADING:
      return isCreate ? managerSpacesForCreate[OrderTypeName.DRIVER_FOR_LOADING] : managerSpacesForEdit[OrderTypeName.DRIVER_FOR_LOADING]

    case OrderTypeId.EMPLOYEE:
      return isCreate ? managerSpacesForCreate[OrderTypeName.DRIVER] : managerSpacesForEdit[OrderTypeName.DRIVER]

    case OrderTypeId.TS:
      return isCreate ? managerSpacesForCreate[OrderTypeName.TS] : managerSpacesForEdit[OrderTypeName.TS]

    case OrderTypeId.DRIVER_DOC_CHECK:
      return isCreate ? managerSpacesForCreate[OrderTypeName.DRIVER] : managerSpacesForEdit[OrderTypeName.DRIVER]

    case OrderTypeId.COUNTERPARTY_BORROWER_AND_TS:
      return isCreate ? managerSpacesForCreate[OrderTypeName.COUNTERPARTY_AND_TS] : managerSpacesForEdit[OrderTypeName.COUNTERPARTY_AND_TS]

    case OrderTypeId.PHONE_CHECK:
      return isCreate ? managerSpacesForCreate[OrderTypeName.PHONE_CHECK] : managerSpacesForEdit[OrderTypeName.PHONE_CHECK]

    default:
      return isCreate ? managerSpacesForCreate[OrderTypeName.LOGISTIC_AND_DRIVER] : managerSpacesForEdit[OrderTypeName.LOGISTIC_AND_DRIVER]
  }
}

const spacesForCustomer = {
  [OrderTypeId.LOGISTIC_AND_DRIVER]: customerSpacesForCreate[OrderTypeName.LOGISTIC_AND_DRIVER],
  [OrderTypeId.DRIVER_AND_TS]: customerSpacesForCreate[OrderTypeName.DRIVER_AND_TS],
  [OrderTypeId.LOGISTIC]: customerSpacesForCreate[OrderTypeName.LOGISTIC],
  [OrderTypeId.LOGISTIC_PAYER]: customerSpacesForCreate[OrderTypeName.LOGISTIC],
  [OrderTypeId.LOGISTIC_CONTRACTOR]: customerSpacesForCreate[OrderTypeName.LOGISTIC],
  [OrderTypeId.DRIVER]: customerSpacesForCreate[OrderTypeName.DRIVER],
  [OrderTypeId.STATE_DRIVER]: customerSpacesForCreate[OrderTypeName.DRIVER],
  [OrderTypeId.DRIVER_FOR_LOADING]: customerSpacesForCreate[OrderTypeName.DRIVER_FOR_LOADING],
  [OrderTypeId.EMPLOYEE]: customerSpacesForCreate[OrderTypeName.DRIVER],
  [OrderTypeId.TS]: customerSpacesForCreate[OrderTypeName.TS],
  [OrderTypeId.DRIVER_DOC_CHECK]: customerSpacesForCreate[OrderTypeName.DRIVER],
  [OrderTypeId.COUNTERPARTY_BORROWER_AND_TS]: customerSpacesForCreate[OrderTypeName.COUNTERPARTY_AND_TS],
  [OrderTypeId.PHONE_CHECK]: customerSpacesForCreate[OrderTypeName.PHONE_CHECK],
}

const getSpacesForCustomer = (orderType: string, errors: OrderFormErrorsProps) => {
  let spaces = [...customerSpacesForCreate[OrderTypeName.LOGISTIC_AND_DRIVER]]
  if (!orderType) return [OrderFormSpaceId.ORDER]

  const type = String(orderType) as OrderTypeId
  if (type in spacesForCustomer) spaces = spacesForCustomer[type]

  if (spaces.includes(OrderFormSpaceId.CONTACT)) {
    const contactFullNameIsValid = errors[OrderFields.LOGISTIC_FIO].isValid
    const contactPhoneIsValid = errors[OrderFields.LOGISTIC_TEL].isValid
    const contactEmailIsValid = errors[OrderFields.CARRIER_EMAIL].isValid
    if (!contactFullNameIsValid || !contactPhoneIsValid || !contactEmailIsValid) return [OrderFormSpaceId.ORDER, OrderFormSpaceId.CONTACT]
  }

  return spaces
}

export const getSpaces = (orderType: string, isCreate: boolean, user: User, errors: OrderFormErrorsProps) => {
  switch (user!.role) {
    case UserRole.CUSTOMER:
      return getSpacesForCustomer(orderType, errors)

    default:
      // eslint-disable-next-line no-case-declarations
      const spaces = getSpacesForManager(orderType, isCreate)

      if (user!.role === UserRole.MANAGER) return spaces.filter((space) => space !== OrderFormSpaceId.FILES)

      return spaces
  }
}

export default getSpaces
