import { call, put, select } from 'redux-saga/effects'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'
import { fetchExpertsOnline } from 'requests/experts-online'

import { fetchExpertsOnlineFailure, fetchExpertsOnlineSuccess } from '../experts-online.actions'

export function* getExpertsOnlineSaga() {
  try {
    const token = yield select(selectToken)

    const response: number[] = yield call(fetchExpertsOnline, token)
    yield put(fetchExpertsOnlineSuccess(response))
  } catch (error) {
    yield put(fetchExpertsOnlineFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение экспертов online',
        defaultText: 'Не удалось получить экспертов online.',
      }),
    )
    console.error(`[getExpertsOnlineSaga]: ${error}`)
  }
}
