import { Action, handleActions } from 'redux-actions'
import { CreateOrderResponseData, OrderData, OrderFields, OrderFormData, OrderFormErrorProps, OrderFormErrorsProps, OrderTypeId } from 'types/order'
import { LoadingStatus } from 'types/loading'

import * as actions from './order.actions'

export interface OrderState {
  orderWrite: OrderFormData
  orderRead: OrderData
  loadingFilesStatus: LoadingStatus
  loadingFetchCreateStatus: LoadingStatus
  loadingFetchGetStatus: LoadingStatus
  loadingFetchEditStatus: LoadingStatus
  loadingLogisticInfoStatus: LoadingStatus
  loadingExecutorFormStatus: LoadingStatus
  loadingResolutionFormStatus: LoadingStatus
  loadingClarifyFormStatus: LoadingStatus
  responseCreate: CreateOrderResponseData | null
  errors: OrderFormErrorsProps
  changedFields: {
    [key: string]: string | boolean | number
  }
}

export const initialOrderState: OrderState = {
  orderWrite: {
    [OrderFields.EXECUTOR]: '', // Исполнитель
    [OrderFields.EXECUTOR_L1]: '', // Исполнитель L1
    [OrderFields.EXECUTOR_EXPERT]: '', // Исполнитель EXPERT
    [OrderFields.ORDER_DATE]: '', // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
    [OrderFields.CLIENT_NAME]: '', // Клиент - заказчик проверки
    [OrderFields.ORDER_TEXT]: '', // Текст заявки от клиента
    [OrderFields.ORDER_TYPE]: OrderTypeId.LOGISTIC_AND_DRIVER, // Тип заявки
    [OrderFields.PRIORITY]: '1', // Приоритет заявки
    [OrderFields.RESOLUTION]: '', // Резолюция
    [OrderFields.RESOLUTION_COMMENT]: '', // Комментарий к резолюции
    [OrderFields.ORDER_COMMENT]: '', // Внутренний комментарий
    [OrderFields.LOGISTIC_NAME]: '', // Перевозчик. Название юрлица
    [OrderFields.LOGISTIC_INN]: '', // Перевозчик. ИНН
    [OrderFields.LOGISTIC_TYPE]: null,
    [OrderFields.LOGISTIC_OGRN]: '', // Перевозчик. ОГРН(ИП)
    [OrderFields.LOGISTIC_ATI]: '', // Перевозчик. Ати
    [OrderFields.LOGISTIC_ATI_STATUS]: true, // Перевозчик. Ати. Статус
    [OrderFields.LOGISTIC_FIRST_FACE]: '', // Перевозчик.1е лицо
    [OrderFields.LOGISTIC_FIRST_FACE_BITH]: '', // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
    [OrderFields.LOGISTIC_FIRST_FACE_INN]: '', // Перевозчик.ИНН 1го лица
    [OrderFields.LOGISTIC_FIO]: '', // Перевозчик. ФИО контакта
    [OrderFields.LOGISTIC_TEL]: '', // Перевозчик. Телефон контакта
    [OrderFields.LOGISTIC_TEL_2]: '', // Перевозчик. Телефон контакта 2
    [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: '',
    [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: '',
    [OrderFields.CARRIER_EMAIL]: '', // Перевозчик. Email
    [OrderFields.DRIVER_FIO]: '', // Водитель. - Менеджер ФИО
    [OrderFields.DRIVER_BITH]: '', // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_SER_NUM]: '', // Водитель. Серия и номер паспорта
    [OrderFields.DRIVER_DOC]: '', // Водитель. Номер ВУ
    [OrderFields.IS_DRIVER_DOC_VALID]: null,
    [OrderFields.DRIVER_DOC_DATE]: '', // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_DATA_START_DT]: '', // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_TEL_1]: '', // Водитель. Телефон 1
    [OrderFields.DRIVER_TEL_2]: '', // Водитель. Телефон 2
    [OrderFields.DRIVER_DATA_START_DT_STATUS]: false, // Параметр состояния галочки Дата начала ТД
    [OrderFields.TS_OWNER]: '', // ТС. Собственник.
    [OrderFields.TS_OWNER_TEL]: '', // ТС. Собственник. Телефон
    [OrderFields.TS_OWNER_LICENSE_PLATE]: '', // ТС. Собственник. Госномер
    [OrderFields.TRAILER_STATUS]: false, // Параметр состояния галочки Без прицепа
    [OrderFields.TRAILER_OWNER]: '', // Прицеп. Собственник
    [OrderFields.TRAILER_OWNER_TEL]: '', // Прицеп. Собственник. Телефон
    [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: '', // Прицеп. Собственник. Госномер
    [OrderFields.ROUTE_DATE_START]: '', // Рейс. Дата начала. Вид: "yyyy-MM-dd"
    [OrderFields.ROUTE_TRIP]: '', // Рейс. Маршрут
    [OrderFields.CARGO]: '', // Груз
    [OrderFields.SHIPPER]: '', // Поле Грузоотправтиль/Заказчик
    [OrderFields.FILES]: [], // id файла к этому заказу, или массив id-ов
    [OrderFields.STARTED_CREATING_AT]: '',
    [OrderFields.TRANSPORT_YEAR]: null,
    [OrderFields.TRAILER_YEAR]: null,
    [OrderFields.TRANSPORT_STS_DATE]: '',
    [OrderFields.TRAILER_STS_DATE]: '',
    [OrderFields.TRANSPORT_RENT]: false,
    [OrderFields.TRAILER_RENT]: false,
    [OrderFields.DRIVER_CREDIT_RATING]: false,
    [OrderFields.HAS_BELARUSIANS]: false,
    [OrderFields.CUSTOMER_FEATURES]: {},
  },
  orderRead: {
    [OrderFields.ORDER_NUMBER]: '',
    [OrderFields.ORDER_TIME]: '',
    [OrderFields.EXECUTOR_L1]: '', // Исполнитель L1
    [OrderFields.EXECUTOR_EXPERT]: '', // Исполнитель EXPERT
    [OrderFields.ORDER_DATE]: '', // Дата и время заявки от клиента. Вид: "yyyy-MM-dd hh:mm"
    [OrderFields.CLIENT_NAME]: '', // Клиент - заказчик проверки
    [OrderFields.ORDER_TEXT]: '', // Текст заявки от клиента
    [OrderFields.ORDER_TYPE]: OrderTypeId.LOGISTIC_AND_DRIVER, // Тип заявки
    [OrderFields.PRIORITY]: '1', // Приоритет заявки
    [OrderFields.RESOLUTION]: '', // Резолюция
    [OrderFields.RESOLUTION_COMMENT]: '', // Комментарий к резолюции
    [OrderFields.ORDER_COMMENT]: '', // Внутренний комментарий
    [OrderFields.LOGISTIC_NAME]: '', // Перевозчик. Название юрлица
    [OrderFields.LOGISTIC_INN]: '', // Перевозчик. ИНН
    [OrderFields.LOGISTIC_TYPE]: null, // Тип перевозчика. (Юрлицо, ИП, самозанятый)
    [OrderFields.LOGISTIC_OGRN]: '', // Перевозчик. ОГРН(ИП)
    [OrderFields.LOGISTIC_ATI]: '', // Перевозчик. Ати
    [OrderFields.LOGISTIC_ATI_STATUS]: true, // Перевозчик. Ати. Статус
    [OrderFields.LOGISTIC_FIRST_FACE]: '', // Перевозчик.1е лицо
    [OrderFields.LOGISTIC_FIRST_FACE_BITH]: '', // Перевозчик. 1е лицо. ДР. Вид: "yyyy-MM-dd"
    [OrderFields.LOGISTIC_FIRST_FACE_INN]: '', // Перевозчик.ИНН 1го лица
    [OrderFields.LOGISTIC_FIO]: '', // Перевозчик. ФИО контакта
    [OrderFields.LOGISTIC_TEL]: '', // Перевозчик. Телефон контакта
    [OrderFields.LOGISTIC_TEL_2]: '', // Перевозчик. Телефон контакта 2
    [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: '',
    [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: '',
    [OrderFields.CARRIER_EMAIL]: '', // Перевозчик. Email
    [OrderFields.DRIVER_FIO]: '', // Водитель. - Менеджер ФИО
    [OrderFields.DRIVER_BITH]: '', // Водитель. Дата рождения. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_SER_NUM]: '', // Водитель. Серия и номер паспорта
    [OrderFields.DRIVER_DOC]: '', // Водитель. Номер ВУ
    [OrderFields.IS_DRIVER_DOC_VALID]: null,
    [OrderFields.DRIVER_DOC_DATE]: '', // Водитель. ВУ. Дата выдачи. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_DATA_START_DT]: '', // Водитель. Дата начала ТД. Вид: "yyyy-MM-dd"
    [OrderFields.DRIVER_TEL_1]: '', // Водитель. Телефон 1
    [OrderFields.DRIVER_TEL_2]: '', // Водитель. Телефон 2
    [OrderFields.DRIVER_DATA_START_DT_STATUS]: false, // Параметр состояния галочки Дата начала ТД
    [OrderFields.TS_OWNER]: '', // ТС. Собственник.
    [OrderFields.TS_OWNER_TEL]: '', // ТС. Собственник. Телефон
    [OrderFields.TS_OWNER_LICENSE_PLATE]: '', // ТС. Собственник. Госномер
    [OrderFields.TRAILER_STATUS]: false, // Параметр состояния галочки Без прицепа
    [OrderFields.TRAILER_OWNER]: '', // Прицеп. Собственник
    [OrderFields.TRAILER_OWNER_TEL]: '', // Прицеп. Собственник. Телефон
    [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: '', // Прицеп. Собственник. Госномер
    [OrderFields.ROUTE_DATE_START]: '', // Рейс. Дата начала. Вид: "yyyy-MM-dd"
    [OrderFields.ROUTE_TRIP]: '', // Рейс. Маршрут
    [OrderFields.CARGO]: '', // Груз
    [OrderFields.SHIPPER]: '', // Поле Грузоотправтиль/Заказчик
    [OrderFields.FILES]: [], // id файла к этому заказу, или массив id-ов
    [OrderFields.LINK_TO_FOLDER]: '', // Ссылка на гугл диск
    [OrderFields.COMPANY_COMMENT]: '', // Комментарий к клиенту
    [OrderFields.CHECKED_FIELDS]: [],
    [OrderFields.PREVIOUS_EXECUTOR_EXPERT]: null,
    [OrderFields.PREVIOUS_RESOLUTION]: null,
    [OrderFields.PREVIOUS_RESOLUTION_COMMENT]: null,
    [OrderFields.TRANSPORT_YEAR]: null,
    [OrderFields.TRAILER_YEAR]: null,
    [OrderFields.TRANSPORT_STS_DATE]: '',
    [OrderFields.TRAILER_STS_DATE]: '',
    [OrderFields.TRANSPORT_RENT]: false,
    [OrderFields.TRAILER_RENT]: false,
    [OrderFields.DRIVER_CREDIT_RATING]: false,
    [OrderFields.HAS_BELARUSIANS]: false,
    [OrderFields.CUSTOMER_FEATURES]: {},
  },
  changedFields: {},
  loadingFilesStatus: LoadingStatus.NONE,
  loadingFetchCreateStatus: LoadingStatus.NONE,
  loadingFetchGetStatus: LoadingStatus.NONE,
  loadingFetchEditStatus: LoadingStatus.NONE,
  loadingLogisticInfoStatus: LoadingStatus.NONE,
  loadingExecutorFormStatus: LoadingStatus.NONE,
  loadingResolutionFormStatus: LoadingStatus.NONE,
  loadingClarifyFormStatus: LoadingStatus.NONE,
  responseCreate: null,
  errors: {
    [OrderFields.EXECUTOR]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.EXECUTOR_L1]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.EXECUTOR_EXPERT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ORDER_DATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.CLIENT_NAME]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ORDER_TEXT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ORDER_TYPE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.PRIORITY]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.RESOLUTION]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.RESOLUTION_COMMENT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ORDER_COMMENT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_NAME]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_INN]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_TYPE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_OGRN]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_ATI]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_ATI_STATUS]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_FIRST_FACE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_FIRST_FACE_BITH]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_FIRST_FACE_INN]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_FIO]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_TEL]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.LOGISTIC_TEL_2]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.CARRIER_EMAIL]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_FIO]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_BITH]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_SER_NUM]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_DOC]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.IS_DRIVER_DOC_VALID]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_DOC_DATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_DATA_START_DT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_TEL_1]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_TEL_2]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_DATA_START_DT_STATUS]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TS_OWNER]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TS_OWNER_TEL]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TS_OWNER_LICENSE_PLATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_STATUS]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_OWNER]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_OWNER_TEL]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_OWNER_LICENSE_PLATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ROUTE_DATE_START]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.ROUTE_TRIP]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.CARGO]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.SHIPPER]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.FILES]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRANSPORT_YEAR]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_YEAR]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRANSPORT_STS_DATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_STS_DATE]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRANSPORT_RENT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.TRAILER_RENT]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.DRIVER_CREDIT_RATING]: {
      isValid: undefined,
      invalidMessage: '',
    },
    [OrderFields.HAS_BELARUSIANS]: {
      isValid: undefined,
      invalidMessage: '',
    },
  },
}

const fetchCreateOrderRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchCreateStatus: LoadingStatus.PENDING,
})

const fetchCreateOrderSuccess = (state: OrderState, action: Action<CreateOrderResponseData>): OrderState => ({
  ...state,
  responseCreate: action.payload,
  loadingFetchCreateStatus: LoadingStatus.SUCCESS,
})

const fetchCreateOrderFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchCreateStatus: LoadingStatus.FAILED,
})

const changeHandler = (state: OrderState, action: Action<{ [key: string]: string | boolean | number }>): OrderState => ({
  ...state,
  orderWrite: {
    ...state.orderWrite,
    ...action.payload,
  },
  changedFields: {
    ...state.changedFields,
    ...action.payload,
  },
})

const setOrderWriteInitialValues = (state: OrderState, action: Action<{ [key: string]: string | boolean | number }>): OrderState => ({
  ...state,
  orderWrite: {
    ...state.orderWrite,
    ...action.payload,
  },
})

const fetchAddFileOrderRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingFilesStatus: LoadingStatus.PENDING,
})

const fetchAddFileOrderSuccess = (state: OrderState, action: Action<number>): OrderState => ({
  ...state,
  orderWrite: {
    ...state.orderWrite, // @ts-ignore TODO remove it
    [OrderFields.FILES]: [...state.orderWrite[OrderFields.FILES], action.payload],
  },
  loadingFilesStatus: LoadingStatus.PENDING,
})

const fetchAddFileOrderFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingFilesStatus: LoadingStatus.FAILED,
})

const fetchAddFileOrderFinish = (state: OrderState): OrderState => ({
  ...state,
  loadingFilesStatus: LoadingStatus.SUCCESS,
})

const orderSetInitialState = (): OrderState => ({
  ...initialOrderState,
})

const fetchLogisticInfoRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingLogisticInfoStatus: LoadingStatus.PENDING,
})

const fetchLogisticInfoSuccess = (state: OrderState): OrderState => ({
  ...state,
  loadingLogisticInfoStatus: LoadingStatus.SUCCESS,
})

const fetchLogisticInfoFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingLogisticInfoStatus: LoadingStatus.FAILED,
})

const errorsOrderHandler = (state: OrderState, action: Action<{ [key: string]: OrderFormErrorProps }>): OrderState => ({
  ...state,
  errors: { ...state.errors, ...action.payload },
})

const fetchGetOrderRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchGetStatus: LoadingStatus.PENDING,
})

const fetchGetOrderSuccess = (state: OrderState, action: Action<OrderData>): OrderState => ({
  ...state,
  orderRead: { ...state.orderWrite, ...action.payload },
  loadingFetchGetStatus: LoadingStatus.SUCCESS,
})

const fetchGetOrderFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchGetStatus: LoadingStatus.FAILED,
})

const fetchSetExecutorRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingExecutorFormStatus: LoadingStatus.PENDING,
})

const fetchSetExecutorSuccess = (state: OrderState): OrderState => ({
  ...state,
  loadingExecutorFormStatus: LoadingStatus.SUCCESS,
})

const fetchSetExecutorFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingExecutorFormStatus: LoadingStatus.FAILED,
})

const fetchSetResolutionRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingResolutionFormStatus: LoadingStatus.PENDING,
})

const fetchSetResolutionSuccess = (state: OrderState): OrderState => ({
  ...state,
  loadingResolutionFormStatus: LoadingStatus.SUCCESS,
})

const fetchSetResolutionFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingResolutionFormStatus: LoadingStatus.FAILED,
})

const fetchEditOrderRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchEditStatus: LoadingStatus.PENDING,
})

const fetchEditOrderSuccess = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchEditStatus: LoadingStatus.SUCCESS,
})

const fetchEditOrderFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingFetchEditStatus: LoadingStatus.FAILED,
})

const fetchClarifyDataRequest = (state: OrderState): OrderState => ({
  ...state,
  loadingClarifyFormStatus: LoadingStatus.PENDING,
})

const fetchClarifyDataSuccess = (state: OrderState): OrderState => ({
  ...state,
  loadingClarifyFormStatus: LoadingStatus.SUCCESS,
})

const fetchClarifyDataFailure = (state: OrderState): OrderState => ({
  ...state,
  loadingClarifyFormStatus: LoadingStatus.FAILED,
})

const setInitialChangedFields = (state: OrderState): OrderState => ({
  ...state,
  changedFields: {},
})

const setOrderReadInitialValues = (state: OrderState, action: Action<{ [key: string]: any }>): OrderState => ({
  ...state,
  orderRead: {
    ...state.orderRead,
    ...action.payload,
  },
})

export default handleActions<OrderState, any>(
  {
    [actions.FETCH_CREATE_ORDER_REQUEST]: fetchCreateOrderRequest,
    [actions.FETCH_CREATE_ORDER_SUCCESS]: fetchCreateOrderSuccess,
    [actions.FETCH_CREATE_ORDER_FAILURE]: fetchCreateOrderFailure,
    [actions.CHANGE_ORDER_HANDLER]: changeHandler,
    [actions.FETCH_ADD_FILE_ORDER_REQUEST]: fetchAddFileOrderRequest,
    [actions.FETCH_ADD_FILE_ORDER_SUCCESS]: fetchAddFileOrderSuccess,
    [actions.FETCH_ADD_FILE_ORDER_FAILURE]: fetchAddFileOrderFailure,
    [actions.FETCH_ADD_FILE_ORDER_FINISH]: fetchAddFileOrderFinish,
    [actions.ORDER_SET_INITIAL_STATE]: orderSetInitialState,
    [actions.FETCH_ORDER_GET_LOGISTIC_INFO_REQUEST]: fetchLogisticInfoRequest,
    [actions.FETCH_ORDER_GET_LOGISTIC_INFO_SUCCESS]: fetchLogisticInfoSuccess,
    [actions.FETCH_ORDER_GET_LOGISTIC_INFO_FAILURE]: fetchLogisticInfoFailure,
    [actions.ERRORS_ORDER_HANDLER]: errorsOrderHandler,
    [actions.FETCH_GET_ORDER_REQUEST]: fetchGetOrderRequest,
    [actions.FETCH_GET_ORDER_SUCCESS]: fetchGetOrderSuccess,
    [actions.FETCH_GET_ORDER_FAILURE]: fetchGetOrderFailure,
    [actions.SET_INITIAL_VALUES_ORDER_WRITE]: setOrderWriteInitialValues,
    [actions.FETCH_SET_EXECUTOR_REQUEST]: fetchSetExecutorRequest,
    [actions.FETCH_SET_EXECUTOR_SUCCESS]: fetchSetExecutorSuccess,
    [actions.FETCH_SET_EXECUTOR_FAILURE]: fetchSetExecutorFailure,
    [actions.FETCH_SET_RESOLUTION_REQUEST]: fetchSetResolutionRequest,
    [actions.FETCH_SET_RESOLUTION_SUCCESS]: fetchSetResolutionSuccess,
    [actions.FETCH_SET_RESOLUTION_FAILURE]: fetchSetResolutionFailure,
    [actions.FETCH_EDIT_ORDER_REQUEST]: fetchEditOrderRequest,
    [actions.FETCH_EDIT_ORDER_SUCCESS]: fetchEditOrderSuccess,
    [actions.FETCH_EDIT_ORDER_FAILURE]: fetchEditOrderFailure,
    [actions.FETCH_CLARIFY_DATA_REQUEST]: fetchClarifyDataRequest,
    [actions.FETCH_CLARIFY_DATA_SUCCESS]: fetchClarifyDataSuccess,
    [actions.FETCH_CLARIFY_DATA_FAILURE]: fetchClarifyDataFailure,
    [actions.SET_INITIAL_CHANGED_FIELDS]: setInitialChangedFields,
    [actions.SET_INITIAL_VALUES_ORDER_READ]: setOrderReadInitialValues,
  },
  initialOrderState,
)
