import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectToken } from 'store/users/users.selectors'

import { InlineIcon } from '@iconify/react'
import downloadIcon from '@iconify/icons-bi/download'

import classes from '../../InputFile.module.css'

export const DownloadAll: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const token = useSelector(selectToken)
  return (
    <div style={{ marginBottom: '5px', paddingBottom: '5px', borderBottom: '1px solid black' }}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${process.env.REACT_APP_FILES_SERVER}/archive/${orderNumber}?token=${token}`}
        style={{ fontSize: '1.1rem', color: '#007bff' }}
        className={classNames(classes.galleryItem, classes.hasButDownload)}
      >
        <InlineIcon icon={downloadIcon} width={24} height={24} style={{ marginRight: '8px' }} />
        Скачать все файлы
      </a>
    </div>
  )
}

export default DownloadAll
