import React from 'react'

export const LogisticHintText = () => (
  <>
    ПЕРЕВОЗЧИК
    <br />
    ООО
    <br />
    <ul>
      <li>свидетельство ИНН (при наличии)</li>
      <li>свидетельство ОГРН (при наличии)</li>
      <li>
        паспорт руководителя фирмы (если отказываются, то не настаивать, но предупредить что может не пройти СБ) – страницы лицевая и с постоянной
        регистрацией
      </li>
      <li>решение (протокол собрания) учредителей о назначении генерального директора</li>
    </ul>
    ИП
    <br />
    <ul>
      <li>свидетельство ИНН (при наличии)</li>
      <li>свидетельство ОГРН (при наличии)</li>
      <li>паспорт ИП – страницы лицевая и с постоянной регистрацией</li>
    </ul>
  </>
)

export default LogisticHintText
