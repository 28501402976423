import { call, put, select } from 'redux-saga/effects'

import { getCurrentBillingSettings } from 'requests/billing'

import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { getCurrentBillingSettingsFailure, getCurrentBillingSettingsSuccess } from 'store/billing/billing.actions'

export function* getCurrentBillingSettingsSaga() {
  try {
    const token = yield select(selectToken)

    const response = yield call(getCurrentBillingSettings, token)

    yield put(getCurrentBillingSettingsSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Получение настроек биллинга',
        defaultText: 'Не удалось получить настройки биллинга.',
      }),
    )
    console.error(`[getCurrentBillingSettingsSaga]: ${error}`)
    yield put(getCurrentBillingSettingsFailure(error))
  }
}
