import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { LoadingStatus } from 'types/loading'
import { OrderFields, OrderSpaceName } from 'types/order'

import { selectLoadingTakeOrderStatus } from 'store/orders/orders.selectors'
import { setInitialStateOrders } from 'store/orders/orders.actions'
import { fetchGetOrderRequest } from 'store/order/order.actions'
import { selectOrderRead } from 'store/order/order.selectors'

import getSpaces from 'components/organisms/OrderBody/utils/getSpaces'
import ResolutionForm from 'components/molecules/OrderSpace/spaces/Header/components/ResolutionForm'

import TakeOrderButton from './TakeOrderButton'
import GeneralInfo from './GeneralInfo'
import Route from './Route'
import Carrier from './Carrier'
import Driver from './Driver'
import Transport from './Transport'
import PreviousResolution from './PreviousResolution'
import BlockHeader from './BlockHeader'

const Expert: React.FC = () => {
  const dispatch = useDispatch()
  const { orderNumber } = useParams()
  const loadingTakeOrderStatus = useSelector(selectLoadingTakeOrderStatus)
  const form = useSelector(selectOrderRead)

  const spaces = getSpaces(String(form[OrderFields.ORDER_TYPE]))

  useEffect(() => {
    if (loadingTakeOrderStatus === LoadingStatus.SUCCESS) {
      dispatch(
        fetchGetOrderRequest({
          [OrderFields.ORDER_NUMBER]: orderNumber,
        }),
      )
      dispatch(setInitialStateOrders())
    }
  }, [dispatch, orderNumber, loadingTakeOrderStatus])

  return (
    <>
      <ul className="list-group">
        <li className="list-group-item row">
          <div className="p-0 m-0">
            <GeneralInfo />
            {spaces.includes(OrderSpaceName.ROUTE) ? <Route /> : null}
            <BlockHeader />
            {spaces.includes(OrderSpaceName.LOGISTIC) ? <Carrier /> : null}
            {spaces.includes(OrderSpaceName.DRIVER) ? <Driver /> : null}
            {spaces.includes(OrderSpaceName.TS) ? <Transport /> : null}
            <PreviousResolution />

            <hr style={{ borderTop: '2px solid rgba(0,0,0,.125)', margin: '1rem -20px' }} />

            <TakeOrderButton />
            <ResolutionForm />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Expert
