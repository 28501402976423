import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { CreateUserData } from 'types/user'

import { fetchCreateUser } from 'requests/user'

import { fetchCreateUserFailure, fetchCreateUserSuccess } from 'store/admin/admin.actions'

export function* fetchCreateUserSaga(action: Action<CreateUserData>) {
  try {
    const token = yield select(selectToken)

    const result = yield call(fetchCreateUser, action.payload, token)

    if (result.status === 'success') yield put(fetchCreateUserSuccess())
    yield put(
      addAlert({
        text: result.text,
        lifetime: 3000,
        variant: result.status === 'success' ? AlertVariant.SUCCESS : AlertVariant.DANGER,
        header: 'Панель администратора',
      }),
    )
  } catch (error) {
    yield put(fetchCreateUserFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Создание пользователя',
        defaultText: 'Не удалось создать пользователя.',
      }),
    )
    console.error(`[fetchCreateUserSaga]: ${error}`)
    captureException(error)
  }
}
