import React, { useCallback, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

import { Icon, IconType } from 'components/atoms/Icon'

import classes from './style.module.css'
import { InputControlProps } from '../InputControl'

enum PasswordType {
  TEXT = 'text',
  PASSWORD = 'password',
}

export const PasswordInput: React.FC<InputControlProps> = ({
  type,
  id,
  value,
  isValid,
  onChangeHandler,
  label,
  placeholder = '',
  invalidMessage = '',
  required = false,
}) => {
  const [isPasswordType, setIsPasswordType] = useState(type === PasswordType.PASSWORD)

  const changeTypeHandler = useCallback(() => {
    setIsPasswordType((prev) => !prev)
  }, [setIsPasswordType])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, id), [onChangeHandler, id])

  return (
    <Form.Group>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <InputGroup>
        <Form.Control
          isValid={isValid}
          isInvalid={isValid === false}
          type={isPasswordType ? PasswordType.PASSWORD : PasswordType.TEXT}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          id={id}
          required={required}
        />
        <InputGroup.Append>
          <Button variant="light" onClick={changeTypeHandler} className={classes.changePasswordTypeButton}>
            <Icon type={IconType.Eye} />
          </Button>
        </InputGroup.Append>
        {invalidMessage && isValid === false ? <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback> : null}
      </InputGroup>
    </Form.Group>
  )
}

export default PasswordInput
