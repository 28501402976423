import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import { Icon, IconType } from '../Icon'
import classes from './style.module.css'

export const BackLink: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  const goBack = useCallback(
    (e) => {
      e.preventDefault()
      const chunks = location.pathname.split('/').filter((chunk) => chunk)

      if (chunks[0] === 'order' && chunks.length === 2 && window.localStorage.search) {
        history.push(`/search/?value=${window.localStorage.search}`)
        window.localStorage.search = ''
        return
      }

      chunks.pop()
      history.push(`/${chunks.join('/')}`)
    },
    [history, location.pathname],
  )

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link to="#" className={classes.leftButton} onClick={goBack}>
      <Icon type={IconType.BackArrow} width="36px" height="36px" />
    </Link>
  )
}
