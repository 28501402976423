import { Action, handleActions } from 'redux-actions'
import * as actions from './version.actions'

export interface VersionState {
  version: number | null
}

export const initialVersionState: VersionState = {
  version: null,
}

const checkVersionSuccess = (state: VersionState, { payload }: Action<number>): VersionState => ({
  ...state,
  version: payload,
})

export default handleActions<VersionState, any>(
  {
    [actions.CHECK_VERSION_SUCCESS]: checkVersionSuccess,
  },
  initialVersionState,
)
