import { createAction } from 'redux-actions'
import { News } from 'types/news'

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST'
export const getNewsRequest = createAction<void>(GET_NEWS_REQUEST)

export const GET_NEWS_PENDING = 'GET_NEWS_PENDING'
export const getNewsPending = createAction<void>(GET_NEWS_PENDING)

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS'
export const getNewsSuccess = createAction<News>(GET_NEWS_SUCCESS)

export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE'
export const getNewsFailure = createAction<Error>(GET_NEWS_FAILURE)
