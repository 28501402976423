import { LogisticType } from 'types/order'

export default class Labeler {
  private directorNameLabels = {
    [LogisticType.LEGAL]: 'Гендир',
    [LogisticType.INDIVIDUAL]: 'ИП',
    [LogisticType.SELF_EMPLOYED]: 'Самозанятый',
    [LogisticType.FOREIGN]: 'Первое лицо',
  }

  private directorLabelsWithDeclension = {
    [LogisticType.LEGAL]: 'гендира',
    [LogisticType.INDIVIDUAL]: 'ИП',
    [LogisticType.SELF_EMPLOYED]: 'самозанятого',
    [LogisticType.FOREIGN]: 'первого лица компании',
  }

  constructor(private type: LogisticType, private name: string) {}

  public getDirectorName() {
    return `${this.directorNameLabels[this.type]}, ФИО:`
  }

  public getDirectorNameForAlert() {
    // if (this.name) return this.name
    return this.directorLabelsWithDeclension[this.type]
  }

  public getDirectorNameForButton() {
    return this.directorLabelsWithDeclension[this.type]
  }

  public getDirectorNameForPhone() {
    if (this.name) return this.name
    return this.directorNameLabels[this.type]
  }
}
