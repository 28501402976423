import React from 'react'
import { useSelector } from 'react-redux'

import { LogisticType, OrderFields } from 'types/order'

import { selectOrderFormErrors, selectOrderWrite } from 'store/order/order.selectors'

import InputPhone from 'components/molecules/InputPhone'
import Button from 'components/molecules/Button'
import RelatedField from 'components/molecules/RelatedField'

import PopupIcon from './PopupIcon'
import Labeler from '../labeler'

const PhoneBox: React.FC<any> = ({ onChange, hasDirectorPhone, onButtonClick }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)

  const labeler = new Labeler(form[OrderFields.LOGISTIC_TYPE] as LogisticType, form[OrderFields.LOGISTIC_FIRST_FACE])

  const isVisibleSecondPhone = errors[OrderFields.CARRIER_FIRST_FACE_PHONE_1].isValid

  const firstPhoneLabel = `${labeler.getDirectorNameForPhone()}, телефон:`
  const secondPhoneLabel = `${labeler.getDirectorNameForPhone()}, второй телефон:`

  const buttonLabel = `У меня нет телефона ${labeler.getDirectorNameForButton()}`

  return (
    <>
      <li className="list-group-item">
        <div className="p-0 m-0 col-lg-4 col-md-6 col-sm-12">
          <InputPhone
            id={OrderFields.CARRIER_FIRST_FACE_PHONE_1}
            value={form[OrderFields.CARRIER_FIRST_FACE_PHONE_1]}
            isValid={hasDirectorPhone ? errors[OrderFields.CARRIER_FIRST_FACE_PHONE_1].isValid : undefined}
            invalidMessage={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_1].invalidMessage}
            onChangeHandler={onChange}
            disabled={!hasDirectorPhone}
            label={
              <div className="position-relative">
                <div style={{ maxWidth: 'calc(100% - 24px)' }}>{firstPhoneLabel}</div>
                {hasDirectorPhone ? <PopupIcon /> : null}
              </div>
            }
          />
        </div>
        {hasDirectorPhone ? (
          <>
            <div className="w-100">
              <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_1} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
              <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_1} fieldToCopy={OrderFields.DRIVER_TEL_1} relatedFields={{}} />
            </div>
            <Button className="p-0 m-0" onClick={onButtonClick} content={buttonLabel} variant="link" />
          </>
        ) : null}
      </li>
      {isVisibleSecondPhone ? (
        <li className="list-group-item">
          <div className="p-0">
            <InputPhone
              id={OrderFields.CARRIER_FIRST_FACE_PHONE_2}
              value={form[OrderFields.CARRIER_FIRST_FACE_PHONE_2]}
              isValid={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_2].isValid}
              invalidMessage={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_2].invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label={secondPhoneLabel}
            />
          </div>
        </li>
      ) : null}
    </>
  )
}

export default PhoneBox
