import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/react'

import { selectToken } from 'store/users/users.selectors'
import { fetchCurrentTime } from 'requests/utils'

import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchCurrentTimeSuccess, fetchCurrentTimeFailure } from '../utils.actions'

export function* fetchCurrentTimeSaga() {
  try {
    const token = yield select(selectToken)

    const response = yield call(fetchCurrentTime, token)

    yield put(fetchCurrentTimeSuccess(response))
  } catch (error) {
    yield put(fetchCurrentTimeFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение текущей даты',
        defaultText: 'Не удалось получить текущую дату.',
      }),
    )
    console.error(`[fetchCurrentTimeSaga]: ${error}`)
    captureException(error)
  }
}
