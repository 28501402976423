import { all, takeLatest } from 'redux-saga/effects'

import * as actionsOrder from 'store/order/order.actions'
import * as actions from './autoChecks.actions'

import { tryToFetchAutoCheckSaga } from './sagas/try-to-fetch-auto-check'
import { fetchLastAutoCheckSaga } from './sagas/fetch-last-auto-checks'

export default function* () {
  yield all([
    takeLatest(actions.FETCH_AUTO_CHECKS_REQUEST, tryToFetchAutoCheckSaga),
    takeLatest(actions.FETCH_LAST_AUTO_CHECKS_REQUEST, fetchLastAutoCheckSaga),
    takeLatest(actionsOrder.SET_INITIAL_VALUES_ORDER_READ, tryToFetchAutoCheckSaga),
  ])
}
