import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'
import { GetAutoCheckData, AutoCheckStatus, AutoCheck } from 'types/auto-checks'

import * as actions from './autoChecks.actions'

export interface AutoChecksState {
  loadingAutoChecks: LoadingStatus
  status: AutoCheckStatus
  checks: AutoCheck[]
}

export const initialAutoChecksState: AutoChecksState = {
  loadingAutoChecks: LoadingStatus.NONE,
  status: AutoCheckStatus.NONE,
  checks: [],
}

const autoChecksRequest = (state: AutoChecksState): AutoChecksState => {
  return {
    ...state,
    loadingAutoChecks: LoadingStatus.PENDING,
  }
}

const autoChecksSuccess = (state: AutoChecksState, action: Action<GetAutoCheckData>): AutoChecksState => {
  return {
    ...state,
    loadingAutoChecks: LoadingStatus.SUCCESS,
    status: action.payload.status,
    checks: action.payload.checks,
  }
}

const autoChecksFailure = (state: AutoChecksState): AutoChecksState => {
  return {
    ...state,
    loadingAutoChecks: LoadingStatus.FAILED,
  }
}

const setInitialState = (): AutoChecksState => ({
  ...initialAutoChecksState,
})

export default handleActions<AutoChecksState, any>(
  {
    [actions.FETCH_AUTO_CHECKS_REQUEST]: autoChecksRequest,
    [actions.FETCH_AUTO_CHECKS_SUCCESS]: autoChecksSuccess,
    [actions.FETCH_AUTO_CHECKS_FAILURE]: autoChecksFailure,
    [actions.AUTO_CHECKS_SET_INITIAL_STATE]: setInitialState,
  },
  initialAutoChecksState,
)
