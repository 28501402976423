import React from 'react'
import classNames from 'classnames'

import { CountryCode } from 'types/phone'

import classes from './style.module.css'

export interface CountryFlagProps {
  code: CountryCode
  disabled?: boolean
}

export const CountryFlag: React.FC<CountryFlagProps> = ({ code, disabled = false }) => {
  return <div className={classNames(classes.flag, classes[code], disabled ? classes.disabled : null)} />
}

export default CountryFlag
