import { Action, handleActions } from 'redux-actions'
import { SelectLists } from 'types/selectLists'
import { LoadingStatus } from 'types/loading'

import * as actions from './selectLists.actions'

export interface SelectListsState {
  lists: SelectLists
  loadingStatus: LoadingStatus
}

export const initialSelectListsState: SelectListsState = {
  lists: {
    priority: [],
    orderType: [],
    executor: [],
    resolution: [],
    clientName: [],
  },
  loadingStatus: LoadingStatus.NONE,
}

const fetchSelectListsRequest = (state: SelectListsState): SelectListsState => ({
  ...state,
  loadingStatus: LoadingStatus.PENDING,
})

const fetchSelectListsSuccess = (state: SelectListsState, { payload }: Action<SelectLists>): SelectListsState => ({
  ...state,
  lists: payload,
  loadingStatus: LoadingStatus.SUCCESS,
})

const fetchSelectListsFailure = (state: SelectListsState): SelectListsState => ({
  ...state,
  loadingStatus: LoadingStatus.FAILED,
})

const setInitialState = () => ({
  ...initialSelectListsState,
})

export default handleActions<SelectListsState, any>(
  {
    [actions.FETCH_SELECT_LISTS_REQUEST]: fetchSelectListsRequest,
    [actions.FETCH_SELECT_LISTS_SUCCESS]: fetchSelectListsSuccess,
    [actions.FETCH_SELECT_LISTS_FAILURE]: fetchSelectListsFailure,
    [actions.SET_INITIAL_STATE_SELECT_LISTS]: setInitialState,
  },
  initialSelectListsState,
)
