import React from 'react'
import ReactDOM from 'react-dom'
import './utils/sentry'
import App from './app'
import * as serviceWorker from './service-worker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { phoneRegex } from './utils/phone'
export { CountryMask } from './types/phone'
export { CountryPrefix } from './types/phone'
export { CountryName } from './types/phone'
export { CountryCode } from './types/phone'
