import { apiAutoChecksServer, transformResponse } from './axios'

export const fetchGetAutoCheck = (orderNumber: string, token: string) =>
  transformResponse(
    apiAutoChecksServer.get(`/order/${orderNumber}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const fetchLastAutoChecks = (token: string) =>
  transformResponse(
    apiAutoChecksServer.get('/last-checks/', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
