import { put, delay, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { captureException } from '@sentry/react'

import { Alert as AlertProps } from 'types/alert'

import { removeAlert } from '../alerts.actions'
import { selectAlertsCount } from '../alerts.selectors'

export function* handleAddAlert(alert: Action<AlertProps>) {
  try {
    const count = yield select(selectAlertsCount)
    if (alert.payload.lifetime) {
      yield delay(alert.payload.lifetime)

      yield put(removeAlert({ ...alert.payload, id: count }))
    }
  } catch (error) {
    console.error(`[handleAddAlert]: ${error}`)
    captureException(error)
  }
}
